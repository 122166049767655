import { motion } from 'framer-motion';

export const ChatLoading = () => {
    return (
        <div className="flex flex-col items-center justify-center w-full">
            <LoadingText />
        </div>
    );
};

const LoadingText = () => {
    const pulseVariants = {
        initial: { opacity: 0.5 },
        animate: { opacity: 1 }
    };

    const transition = {
        duration: 0.8,
        repeat: Infinity,
        repeatType: 'reverse',
        ease: 'easeInOut'
    };

    return (
        <div className="flex gap-x-2.5 w-full">
            <motion.div
                className="h-[10px] w-[25%] bg-gray-200 rounded"
                variants={pulseVariants}
                initial="initial"
                animate="animate"
                transition={transition}
            />
            <motion.div
                className="h-[10px] w-[20%] bg-gray-200 rounded"
                variants={pulseVariants}
                initial="initial"
                animate="animate"
                transition={{ ...transition, delay: 0.2 }}
            />
            <motion.div
                className="h-[10px] w-[30%] bg-gray-200 rounded"
                variants={pulseVariants}
                initial="initial"
                animate="animate"
                transition={{ ...transition, delay: 0.4 }}
            />
            <motion.div
                className="h-[10px] w-[15%] bg-gray-200 rounded"
                variants={pulseVariants}
                initial="initial"
                animate="animate"
                transition={{ ...transition, delay: 0.6 }}
            />
        </div>
    );
};
