import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectUIViewLoading } from '../../../../../redux/reducers/generic/projectArchitecture';
// Assuming `generateTestFiles` and `extractErrorsWithPaths` are implemented elsewhere
import {
    indexTsx,
    indexRouterTsx,
    stylesCss,
    containerTsx,
    routerAppTsx,
    allTestsPassed,
    extractErrorsWithPaths,
    tailwind_config,
    postcss_config,
    index_css,
    vite_config_ts,
    ts_config,
    lib,
    button,
    components_json
} from '../SandpackFiles';
import { setUIView } from '../../../../../redux/reducers/generic/projectArchitecture';
import { getUIView } from '../../../../../redux/actions/solo/getUIView';
import { correctErrors } from '../../../../../redux/actions/solo/correctErrors';
const compilePathsForUIViews = ({ uiViews, nodes }) => {
    // Adjust this function to correctly retrieve and format the node label.
    const getNodeLabelById = ({ nodeId, nodes }) => {
        const node = nodes.find(node => node._id === nodeId);
        return node ? node.label.replace(/\s+/g, '') : '';
    };

    const paths = {};

    uiViews.forEach(uiView => {
        const nodeLabel = getNodeLabelById({ nodeId: uiView.nodeId, nodes });

        // Ensure we are creating the correct path for the component files
        uiView.files.forEach(file => {
            const fileName =
                file.path === '/App.tsx' ? '/index.tsx' : file.path;
            const newPath = `/components/${nodeLabel}${fileName}`;
            paths[newPath] = { code: file.code, uiViewId: uiView._id };
        });
    });

    return paths;
};

export const useFileManagement = ({
    dispatch,
    projectId,
    nodeId,
    node,
    uiView,
    setUIView,
    setImageUrl,
    setFileKeyScreenshot,
    projectArchitecture
}) => {
    const [files, setFiles] = useState(null);
    const [testFiles, setTestFiles] = useState(null);
    const [filesWithTests, setFilesWithTests] = useState(null);
    const [testingPhase, setTestingPhase] = useState(false);
    let graph = projectArchitecture?.graphs.find(g => g.side == 'frontend');
    let nodes = graph?.nodes;

    // set files
    useEffect(() => {
        if (uiView && uiView.files?.length > 0) {
            console.log(uiView);

            if (!uiView.routingTable) {
                setImageUrl(null);
                setFileKeyScreenshot(null);

                const paths = (uiView?.files || []).reduce((acc, file) => {
                    acc[file.path] = { code: file.code };
                    return acc;
                }, {});
                // Safely add container.tsx and index.tsx to the files object
                // This code assumes files is now always an object, never undefined

                paths['/Container.tsx'] = { code: containerTsx.trim() };
                paths['/index.tsx'] = { code: indexTsx.trim() };
                // paths['/tailwind.config.js'] = { code: tailwind_config.trim() };
                // paths['/postcss.config.js'] = { code: postcss_config.trim() };
                paths['/styles.css'] = { code: index_css.trim() };
                // paths['/vite.config.ts'] = { code: vite_config_ts.trim() };
                // paths['/tsconfig.json'] = { code: ts_config.trim() };
                // paths['/src/lib/utils.ts'] = { code: lib.trim() };
                // paths['/src/components/ui/button.tsx'] = { code: button.trim() };
            //    paths['/styles.css'] = { code: stylesCss.trim() };
                paths['/tsconfig.json'] = {
                    code: `{
          "compilerOptions": {
            "target": "es6",
            "module": "commonjs",
            "strict": true,
            "esModuleInterop": true,
            "skipLibCheck": true,
            "forceConsistentCasingInFileNames": true,
            "moduleResolution": "node",
            "outDir": "./dist",
            "rootDir": "./src",
            "typeRoots": ["./node_modules/@types"],
            "baseUrl": ".",
            "paths": {
              "*": ["node_modules/*", "src/types/*"]
            }
          },
          "include": ["src/**/*"],
          "exclude": ["node_modules", "dist"]
        }`
                };
                const tfiles = uiView?.files.reduce((testAcc, file) => {
                    if (file.path.endsWith('.tsx')) {
                        // Adjust as necessary for .js or .tsx files
                        const testFilePath = file.path.replace(
                            /\.tsx$/,
                            '.test.tsx'
                        );
                        const pathLength = file.path.split('/').length;
                        const componentName =
                            file.path === '/App.tsx'
                                ? 'App'
                                : file.path.split('/')[pathLength - 2];
                        const importPath =
                            file.path === '/App.tsx'
                                ? file.path
                                : file.path.replace(/\/index\.tsx$/, '');

                        const testFileContent = `
                import React from 'react';
                import { render } from '@testing-library/react';
                import { BrowserRouter as Router } from 'react-router-dom';
                import ${componentName} from '${importPath}';
                describe('${componentName}', () => {
                  it('renders without crashing', () => {
                    try {
                      render(<Router><${componentName} /></Router>);
                    }
                    catch(error) {
                      console.log(error)

                    }
                    // Add any additional assertions here
                  });
                });`;
                        testAcc[testFilePath] = { code: testFileContent };
                    }
                    return testAcc;
                }, {});

                const fwtests = {
                    ...paths,
                    ...tfiles
                };

                setFiles(paths);
                setTestFiles(tfiles);
                setFilesWithTests(fwtests);
            } else {
                const paths = compilePathsForUIViews({
                    uiViews: uiView.views,
                    nodes
                });

                // Safely add container.tsx and index.tsx to the files object
                // This code assumes files is now always an object, never undefined
                let routingTableArray = JSON.parse(uiView.routingTable);
                try {
                    routingTableArray = JSON.parse(routingTableArray);
                } catch (e) {
                    console.log(e);
                }

                const routesWithoutCore = routingTableArray.filter(
                    route => route.component !== 'Core'
                );
                const routingFile = routerAppTsx({
                    routes: JSON.stringify(routesWithoutCore)
                }).trim();

                paths['/App.tsx'] = { code: routingFile };
                paths['/index.tsx'] = { code: indexRouterTsx.trim() };
                // paths['/tailwind.config.js'] = { code: tailwind_config.trim() };
                // paths['/postcss.config.js'] = { code: postcss_config.trim() };
                paths['/styles.css'] = { code: index_css.trim() };
                // paths['/vite.config.ts'] = { code: vite_config_ts.trim() };
                // paths['/tsconfig.json'] = { code: ts_config.trim() };
                // paths['/src/lib/utils.ts'] = { code: lib.trim() };
                // paths['/src/components/ui/button.tsx'] = { code: button.trim() };
              //  paths['/styles.css'] = { code: stylesCss.trim() };

                setFiles(paths);
            }
        }
    }, [uiView]);

    useEffect(() => {
        if (uiView && uiView.passed && uiView.tested) {
            setTestingPhase(false);
        } else {
            if (filesWithTests) {
                console.log(filesWithTests);
                console.log(uiView.status);
                if (uiView.status && uiView.status == 'finished')
                    setTestingPhase(true);
            }
        }
    }, [filesWithTests]);

    const regenerateUI = () => {
        // alert(node._id)
        dispatch(setUIView(null));
        dispatch(
            getUIView({
                nodeId: node._id,
                projectId: projectId,
                forceNew: true
            })
        );
    };

    const onTestComplete = specs => {
        const numberOfKeys = Object.keys(specs).length;
        if (numberOfKeys > 1) {
            // Extract errors and their paths into an array
            let errors = extractErrorsWithPaths(specs);
            console.log(specs);

            // Filter out 'React is not defined' errors
            errors = errors.filter(
                error => !error.message.startsWith('React is not defined')
            );

            if (errors.length < 1) {
                setTestingPhase(false);
                dispatch(
                    correctErrors({ projectId, nodeId, version: 0, errors })
                );
                setFilesWithTests(null);
            } else {
                if (errors.length > 1) {
                    // don't include App.tsx unless its the only error
                    errors = errors.filter(error => error.path !== '/App.tsx');
                }
                //   setTestingPhase(false);
                // todo versioning
                dispatch(
                    correctErrors({ projectId, nodeId, version: 0, errors })
                );
                console.log('Not all tests pass or some tests have errors.');
            }
        } else {
            console.log(specs);
            console.log('didnt test right');
            console.log(filesWithTests);
        }
    };

    return {
        files,
        testFiles,
        filesWithTests,
        regenerateUI,
        onTestComplete,
        testingPhase,
        setFiles,
        setTestingPhase,
        setTestFiles,
        setFilesWithTests
    };
};
