import React, { createContext, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getProjectsByUser } from '../redux/actions/solo/getProjectsByUser';
import {
    selectCreationMethodFilter,
    selectMatchWithDevsOnly,
    selectProjects,
    selectProjectsSkip,
    selectProjectsTotal,
    selectIgnorePreDevEmails,
    selectSearchQuery,
    selectSort,
    selectOnlyRevealedProjects,
    selectOnlyPremiumLeadGenEnabled
} from '../redux/reducers/generic/projects';
import { useParams } from 'react-router-dom';
import { getProjectsByEnterpriseOrganization } from '../redux/actions/enterprise/getProjectsByEnterpriseOrganization';
import { projectsLimit } from '../constants';
import { useState } from 'react';
import { setEnterpriseId } from '../redux/reducers/enterprise/enterpriseOrganization';
import { selectIsLeadGen } from '../redux/reducers/generic/project';
import { getProjectsByLeadGen } from '../redux/actions/enterprise/getProjectsByLeadGen';

const ProjectsContext = createContext();

export function ProjectsProvider({ children, isAdmin }) {
    const { doesSessionExist } = useSessionContext();
    const projects = useSelector(selectProjects);
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const skip = useSelector(selectProjectsSkip);
    const totalProjects = useSelector(selectProjectsTotal);
    const searchQuery = useSelector(selectSearchQuery);
    const sort = useSelector(selectSort);
    const creationMethodFilter = useSelector(selectCreationMethodFilter);
    const matchWithDevsOnly = useSelector(selectMatchWithDevsOnly);
    const [prevSearchQuery, setPrevSearchQuery] = useState(searchQuery);
    const [prevCreationMethodFilter, setPrevCreationMethodFilter] =
        useState(creationMethodFilter);
    const [prevSort, setPrevSort] = useState(sort);
    const ignorePreDevEmails = useSelector(selectIgnorePreDevEmails);
    const [prevMatchWithDevsOnly, setPrevMatchWithDevsOnly] =
        useState(matchWithDevsOnly);
    const [prevIgnorePreDevEmails, setPrevIgnorePreDevEmails] =
        useState(ignorePreDevEmails);
    const isLeadGen =
        useSelector(selectIsLeadGen) ||
        window.location.pathname.includes('leadGeneration');

    const [prevLeadGen, setPrevLeadGen] = useState(isLeadGen);
    const onlyRevealedProjects = useSelector(selectOnlyRevealedProjects);
    const [prevOnlyRevealedProjects, setPrevOnlyRevealedProjects] =
        useState(onlyRevealedProjects);

    const onlyPremiumLeadGenEnabled = useSelector(
        selectOnlyPremiumLeadGenEnabled
    );
    const [prevOnlyPremiumLeadGenEnabled, setPrevOnlyPremiumLeadGenEnabled] =
        useState(onlyPremiumLeadGenEnabled);

    const [prevEnterpriseOrganizationId, setPrevEnterpriseOrganizationId] =
        useState(enterpriseOrganizationId);

    let shouldFetchMore =
        totalProjects > projects.length ||
        projects.length === 0 ||
        prevSearchQuery !== searchQuery ||
        creationMethodFilter !== prevCreationMethodFilter ||
        JSON.stringify(sort) !== JSON.stringify(prevSort) ||
        prevMatchWithDevsOnly !== matchWithDevsOnly ||
        prevIgnorePreDevEmails !== ignorePreDevEmails ||
        prevLeadGen !== isLeadGen ||
        prevOnlyRevealedProjects !== onlyRevealedProjects ||
        prevOnlyPremiumLeadGenEnabled !== onlyPremiumLeadGenEnabled ||
        prevEnterpriseOrganizationId !== enterpriseOrganizationId;

    useEffect(() => {
        if (isLeadGen !== prevLeadGen) {
            setPrevLeadGen(isLeadGen);
        }
    }, [isLeadGen, prevLeadGen]);

    useEffect(() => {
        if (enterpriseOrganizationId !== prevEnterpriseOrganizationId) {
            setPrevEnterpriseOrganizationId(enterpriseOrganizationId);
        }
    }, [enterpriseOrganizationId, prevEnterpriseOrganizationId]);

    useEffect(() => {
        if (prevOnlyRevealedProjects !== onlyRevealedProjects) {
            setPrevOnlyRevealedProjects(onlyRevealedProjects);
        }
    }, [onlyRevealedProjects, prevOnlyRevealedProjects]);

    useEffect(() => {
        if (prevOnlyPremiumLeadGenEnabled !== onlyPremiumLeadGenEnabled) {
            setPrevOnlyPremiumLeadGenEnabled(onlyPremiumLeadGenEnabled);
        }
    }, [onlyPremiumLeadGenEnabled, prevOnlyPremiumLeadGenEnabled]);

    useEffect(() => {
        if (prevSearchQuery !== searchQuery) {
            setPrevSearchQuery(searchQuery);
        }
    }, [searchQuery, prevSearchQuery]);

    useEffect(() => {
        if (prevIgnorePreDevEmails !== ignorePreDevEmails) {
            setPrevIgnorePreDevEmails(ignorePreDevEmails);
        }
    }, [ignorePreDevEmails, prevIgnorePreDevEmails]);

    useEffect(() => {
        if (prevCreationMethodFilter !== creationMethodFilter) {
            setPrevCreationMethodFilter(creationMethodFilter);
        }
    }, [creationMethodFilter, prevCreationMethodFilter]);

    useEffect(() => {
        if (prevMatchWithDevsOnly !== matchWithDevsOnly) {
            setPrevMatchWithDevsOnly(matchWithDevsOnly);
        }
    }, [matchWithDevsOnly, prevMatchWithDevsOnly]);

    useEffect(() => {
        if (prevSort !== sort) {
            setPrevSort(sort);
        }
    }, [sort, prevSort]);

    useEffect(() => {
        dispatch(setEnterpriseId(enterpriseOrganizationId));
    }, [enterpriseOrganizationId]);

    useEffect(() => {
        if (doesSessionExist && shouldFetchMore) {
            if (isLeadGen) {
                dispatch(
                    getProjectsByLeadGen({
                        enterpriseOrganizationId,
                        skip,
                        limit: projectsLimit,
                        searchQuery,
                        sort,
                        onlyRevealedProjects,
                        onlyPremiumLeadGenEnabled
                    })
                );
            } else if (enterpriseOrganizationId) {
                dispatch(
                    getProjectsByEnterpriseOrganization({
                        enterpriseOrganizationId,
                        skip,
                        limit: projectsLimit,
                        searchQuery,
                        creationMethodFilter,
                        sort,
                        matchWithDevsOnly,
                        ignorePreDevEmails
                    })
                );
            } else {
                dispatch(
                    getProjectsByUser({
                        skip,
                        limit: projectsLimit,
                        searchQuery
                    })
                );
            }
        }
    }, [
        doesSessionExist,
        enterpriseOrganizationId,
        skip,
        searchQuery,
        creationMethodFilter,
        sort,
        matchWithDevsOnly,
        ignorePreDevEmails,
        isLeadGen,
        onlyRevealedProjects,
        onlyPremiumLeadGenEnabled
    ]);

    return (
        <ProjectsContext.Provider
            value={{ projects: !isAdmin ? projects : [], isAdmin }}
        >
            {children}
        </ProjectsContext.Provider>
    );
}

export const useProjects = () => useContext(ProjectsContext);
