import { useEffect, useState } from 'react';
import { chatEndingIndex, getChatEndingIndex } from '../../../constants';
import { useProject } from '../../../context-providers/Project';
import { Placeholder } from './Placeholder';
import App from './ReactFlowAuto/App';
import { useSelector } from 'react-redux';
import {
    selectBudgetFilterActivated,
    selectGraphVersion
} from '../../../redux/reducers/generic/projectArchitecture';

export const ProjectArchitecture = () => {
    const { chatHistory, project, projectArchitecture, graphType } =
        useProject();
    const graph = projectArchitecture.graphs.find(g => g.side === graphType);
    const budgetFilterActivated = useSelector(selectBudgetFilterActivated);
    const graphVersion = useSelector(selectGraphVersion);
    const [prevBudget, setPrevBudget] = useState(project.budget);
    const [prevBudgetFilterActivated, setPrevBudgetFilterActivated] = useState(
        budgetFilterActivated
    );
    const currentIndex = getChatEndingIndex(chatHistory.data);

    const showPlaceholder = project.skip
        ? false
        : currentIndex < chatEndingIndex;

    const [key, setKey] = useState(0);

    useEffect(() => {
        if (budgetFilterActivated !== prevBudgetFilterActivated) {
            setPrevBudgetFilterActivated(budgetFilterActivated);
            setKey(prevKey => prevKey + 1);
        }
        if (project.budget !== prevBudget) {
            setPrevBudget(project.budget);
            if (budgetFilterActivated) {
                setKey(prevKey => prevKey + 1);
            }
        }
    }, [budgetFilterActivated, project.budget]);

    return (
        <div className="flex flex-col h-full w-full">
            <div
                className="w-full h-full"
                id="graph_side"
                style={{ backgroundColor: 'transparent' }}
            >
                {graph && !showPlaceholder ? (
                    <App key={`${key}`} />
                ) : showPlaceholder ? (
                    <Placeholder />
                ) : null}
            </div>
        </div>
    );
};
