import { client } from '../../../api/client';
import { EDIT_SUBTASK } from '../../../api/graphQL/solo/mutator';

export const editSubTask = ({subTaskId, description, complexityEstimate, hours, roles, index}) => async (dispatch, getState) => {
    try {
 
        let { data } = await client.mutate({
            mutation: EDIT_SUBTASK,
            variables: { subTaskId, description, complexityEstimate, hours, roles, index }
        });

        return data.editSubTask;
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
    }
};
