import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import autosize from 'autosize';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'react-textarea-autosize';
import { useProject } from '../../../../context-providers/Project';
import { handleChatSubmit } from '../../../../redux/actions/solo/handleChatSubmit';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import { selectEnterpriseOrganization } from '../../../../redux/reducers/enterprise/enterpriseOrganization';
import { selectIsLeadGen } from '../../../../redux/reducers/generic/project';
import { AttachFile } from './AttachFile';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export const Input = ({
    image_url,
    setImageUrl,
    fileKeyS3,
    setFileKeyS3,
    setFile,
    setProgress,
    isUploading,
    textareaRef,
    answer,
    setAnswer,
    setShowDemoBanner,
    setUploading,
    progress,
    file
}) => {
    let { chatHistory, projectId } = useProject();
    let enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const { isShare, isSolo, isWidget, status, isLeadGen } =
        useSubscriptionPayment();

    const leadgen = useSelector(selectIsLeadGen);

    let waiting =
        chatHistory.waitingForChatResponse || chatHistory.waitingForSuggestion;
    let chat = chatHistory.data;

    const { accessTokenPayload } = useSessionContext();

    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const [params] = useSearchParams();
    const username = params.get('username');

    const onChange = e => {
        setAnswer(e.target.value);
        autosize(document.querySelectorAll('textarea'));
    };

    // various effects for the chatinput
    useEffect(() => {
        if (!waiting && textareaRef && textareaRef.current) {
            autosize(textareaRef);
            if (window.innerWidth > 1024) textareaRef.current.focus(); // Refocus the textarea
        }
    }, [chat, waiting]);

    const soloNoGo =
        ((isSolo && status !== 'active') || isShare) && chat.length > 20;

    const enterpriseNoGo =
        !isSolo &&
        enterpriseOrganization?.subscriptionStatus?.status !== 'active' &&
        chat.length > 20;

    //alert(enterpriseNoGo)

    // define submit event handler
    const handleAnswerSubmit = async (event, ta) => {
        event.preventDefault();

        console.log('handleAnswerSubmit');

        if (isShare) {
            setShowDemoBanner(true);
            toast.error('Login to pre.dev to chat further.');
            return;
        }

        if (isLeadGen) {
            toast.error(
                'You are not allowed to chat further in the lead gen portal.'
            );
            return;
        }

        if (soloNoGo) {
            toast.error('Upgrade account to premium for unlimited access.');
            setShowDemoBanner(true);
            return;
        }

        if (enterpriseNoGo) {
            toast.error('Upgrade account to premium for unlimited access.');
            setShowDemoBanner(true);
            return;
        }

        if (!answer) answer = document.getElementById('answer').value;

        if ((answer === '' || !answer) && !fileKeyS3 && !image_url) return;
        if (waiting) return;

        try {
            dispatch(
                handleChatSubmit({
                    answer,
                    image_url,
                    fileKeyS3,
                    projectId,
                    userEmail: accessTokenPayload?.email,
                    username,
                    isEnterprise: !isSolo,
                    isWidget
                })
            );
            console.log('dispatched');
            setAnswer('');
            setFile(null);
            setFileKeyS3(null);
            setImageUrl(null);
            setProgress(0);

            autosize.destroy(document.querySelectorAll('textarea'));

            // textareaRef.current.style.height = '40px';
        } catch (err) {
            console.log(err);
            toast.error('Error submitting answer');
        }
    };

    return (
        <form className="mt-2 dark" onSubmit={handleAnswerSubmit}>
            <label for="chat-input" class="sr-only">
                Message
            </label>
            <div class="relative">
                <div className="flex items-center relative">
                    <TextArea
                        textareaRef={textareaRef}
                        placeholder={waiting ? 'Responding...' : 'Message...'}
                        onKeyDown={e => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                handleAnswerSubmit(e, 'ta');
                            }
                        }}
                        className={`h-full w-full max-h-[150px] min-h-[40px] resize-none rounded-xl bg-slate-200 p-4 ${
                            isUploading || file ? 'pl-[160px]' : 'pl-[45px]'
                        } pr-20 text-sm dark:bg-slate-800 dark:text-slate-200 py-4 flex items-center`}
                        style={{
                            lineHeight: '20px'
                        }}
                        id="answer"
                        rows="1"
                        ref={textareaRef}
                        onChange={onChange}
                        value={answer}
                        disabled={waiting}
                    />
                    <div className="absolute left-2 top-1/2 transform -translate-y-1/2">
                        <AttachFile
                            setImageUrl={setImageUrl}
                            setFileKeyS3={setFileKeyS3}
                            setFile={setFile}
                            setProgress={setProgress}
                            progress={progress}
                            setUploading={setUploading}
                            isUploading={isUploading}
                            file={file}
                            from={'chat'}
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={waiting || isUploading}
                        className={`absolute right-2 rounded-lg px-4 py-2 text-sm font-medium text-white ${
                            waiting ||
                            isUploading ||
                            (answer === '' && !fileKeyS3 && !image_url)
                                ? 'bg-gray-500 cursor-not-allowed'
                                : 'bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700'
                        }`}
                    >
                        <PaperAirplaneIcon className="w-5 h-5" />
                    </button>
                </div>
            </div>
        </form>
    );
};
