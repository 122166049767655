import React, { useState } from 'react';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { ClockIcon } from '@heroicons/react/24/outline';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import Modal from '../../Components/Modal';
import PurchaseCreditsModal from './PurchaseCreditsModal';

export const Credits = () => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const { leadGenCreditsLeft, leadGenSubscriptionStatus } =
        enterpriseOrganization?.leadGenData;
    const { isSubbed } = useSubscriptionPayment();
    const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);

    const formatDate = dateString => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    if (!isSubbed) return null;

    return (
        <>
            <div className="flex items-center justify-end space-x-4 mb-4 sm:mb-0">
                <div className="flex flex-col items-start">
                    <div className="flex items-center space-x-2">
                        <CurrencyDollarIcon className="h-5 w-5 text-gray-400" />
                        <span className="text-sm font-medium text-gray-700">
                            {leadGenCreditsLeft} Credits Left
                        </span>
                    </div>
                    {leadGenSubscriptionStatus &&
                        leadGenSubscriptionStatus.current_period_end && (
                            <div className="flex items-center space-x-2 mt-1">
                                <ClockIcon className="h-5 w-5 text-gray-400" />
                                <span className="text-sm text-gray-500">
                                    Renews on{' '}
                                    {formatDate(
                                        leadGenSubscriptionStatus.current_period_end
                                    )}
                                </span>
                            </div>
                        )}
                </div>
                <button
                    onClick={() => setIsPurchaseModalOpen(true)}
                    className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                    Purchase Credits
                </button>
            </div>
            <Modal
                open={isPurchaseModalOpen}
                onClose={() => setIsPurchaseModalOpen(false)}
                maxWidth="max-w-2xl"
                title="Lead Generation Credits"
            >
                <PurchaseCreditsModal
                    onClose={() => setIsPurchaseModalOpen(false)}
                />
            </Modal>
        </>
    );
};
