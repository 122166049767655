import { GET_ENTERPRISE_ORGANIZATIONS_BY_USER } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchEnterpriseOrganizationsRequest,
    fetchEnterpriseOrganizationsSuccess,
    fetchEnterpriseOrganizationsFailure
} from '../../reducers/enterprise/enterpriseOrganizations';

export const getEnterpriseOrganizationsByUser =
    () => async (dispatch, getState) => {
        dispatch(fetchEnterpriseOrganizationsRequest());
        try {
            const { data } = await client.query({
                query: GET_ENTERPRISE_ORGANIZATIONS_BY_USER,
                variables: {},
                fetchPolicy: 'network-only'
            });
            dispatch(
                fetchEnterpriseOrganizationsSuccess(
                    data.enterpriseOrganizationsByUser
                )
            );
        } catch (error) {
            console.log({ error });
            dispatch(fetchEnterpriseOrganizationsFailure(error));
        }
    };
