import { NewProjectInput } from './input';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { useProjects } from '../../../context-providers/Projects';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { soloTrialLimit } from '../../../constants';
import { useEffect } from 'react';

export function NewProject() {
    let { subscription } = useSubscriptionPayment();
    let { projects } = useProjects();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let showPage = false;

    //Show the subscribe modal if the user is not subscribed
    useEffect(() => {
        if (
            projects.length >= soloTrialLimit &&
            !subscription.loading &&
            subscription.status !== 'active'
        ) {
            navigate(`/projects/${projects[0]._id}`);
            // dispatch(toggleSubscribeModal(true));
        }
    }, [projects, subscription]);

    //Show the page if the user is subscribed or has no projects
    if (projects.length < soloTrialLimit || subscription.status === 'active') {
        showPage = true;
    }

    return (
        showPage && (
            <div className="mt-20 pt-20 flex flex-col h-full justify-center items-center w-full">
                <div className="flex flex-row w-full items-center justify-center -mt-36">
                    <div className="text-center mx-4 max-w-xl">
                        <img
                            src="/predevblack.png"
                            alt="pre.dev"
                            className="h-16 w-auto mx-auto mb-2"
                        />
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900">
                            Welcome to pre.dev
                        </h2>
                        <p className="font-light text-gray-500 dark:text-gray-400 text-lg">
                            Get started by creating a new project.
                        </p>

                        <div className="mt-6">
                            <NewProjectInput />
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}
