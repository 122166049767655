import { HeroSection } from './HeroSection';
import { FeaturesSection } from './Features';
import { Footer } from '../Footer';
import { InfoSection3 } from './InfoSection3';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useNavigate } from 'react-router-dom';
import { NavigationBar } from './NavigationBar';
import { Portfolio } from './Portfolio';
import { setRedirectURL } from '../../constants';
import { ChoosePage } from './ChoosePage';
import { useDispatch } from 'react-redux';
import { clearStateProjects } from '../../redux/reducers/generic/projects';
import { useEffect } from 'react';
import { Partners } from './Partners';

export const EnterpriseLanding = () => {
    const sessionContext = useSessionContext();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    useEffect(() => {
        return () => dispatch(clearStateProjects());
    }, []);

    const onGetStarted = () => {
        setRedirectURL('/enterprise/dashboard');
        if (sessionContext.doesSessionExist === true) {
            navigate('/enterprise/dashboard');
        } else {
            navigate('/auth/?redirectToPath=%2Fenterprise%2Fdashboard');
        }
    };

    return (
        !sessionContext.isLoading && (
            <div className="bg-black">
                <NavigationBar onGetStarted={onGetStarted} />

                <HeroSection onGetStarted={onGetStarted} />
                <ChoosePage />

                <FeaturesSection />
                <Partners />

                <Portfolio />
                <InfoSection3 onGetStarted={onGetStarted} />

                <Footer />
            </div>
        )
    );
};
