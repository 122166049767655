import { getWebsiteDomain } from '../../../../auth_config';
import { getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import { setRedirectURL } from '../../../../constants';

export const SignInButton = () => {
    async function thirdPartySignInClicked(name) {
        try {
            setRedirectURL(window.location.pathname + window.location.search);
            const authUrl = await getAuthorisationURLWithQueryParamsAndSetState(
                {
                    thirdPartyId: name,
                    frontendRedirectURI:
                        getWebsiteDomain() + '/auth/callback/' + name
                }
            );
            // Redirect user to the OAuth provider
            window.location.assign(authUrl);
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                window.alert(err.message);
            } else {
                console.log(err);
            }
        }
    }

    return (
        <button
            onClick={() => thirdPartySignInClicked('atlassian')}
            type="button"
            className="inline-flex items-center gap-x-4 rounded-md bg-blue-700 px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 whitespace-nowrap"
        >
            <img src="/atlassian.svg" alt="Atlassian" />
            Sign In with Atlassian to Sync
        </button>
    );
};
