import { client } from '../../../api/client';
import { EDIT_USER_STORY } from '../../../api/graphQL/solo/mutator';

export const editUserStory = (storyId, projectId, description, priority, acceptanceCriteria) => async (dispatch, getState) => {
    try {
        projectId = projectId.toString();

        let { data } = await client.mutate({
            mutation: EDIT_USER_STORY,
            variables: { storyId, projectId, description, priority, acceptanceCriteria }
        });

        return data.editUserStory;
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
    }
};
