import { UserGroupIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import SidebarItems from './SidebarItems';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import trackEvent from '../../../helpers/trackEvent';

export default function DesktopSidebar() {
    const { accessTokenPayload } = useSessionContext();
    return (
        <div className="hidden lg:block lg:inset-y-0 lg:flex w-[350px] lg:flex-col h-full">
            <div class="max-w-xl h-full rounded-lg border border-slate-300 border-t-0 border-l-0 bg-slate-50 pb-8 dark:border-slate-200/10 dark:bg-slate-900">
                <div className="flex justify-between">
                    <div className="flex h-16 shrink-0 items-center">
                        <Link to="/">
                            <img
                                className="ml-2 h-8 w-auto rounded-full p-[3px]"
                                src="/predevblack.png"
                            />
                        </Link>
                        <Link to="/" className="flex">
                            <span className="ml-1 font-semibold self-center">
                                pre.dev
                            </span>
                            <span className="mt-3 ml-2 self-center text-xs font-semibold whitespace-nowrap text-blue-600">
                                Solo
                            </span>
                        </Link>
                    </div>
                    <Link
                        onClick={() =>
                            trackEvent('enterpriseSwitchClicked', {
                                userEmail: accessTokenPayload?.email
                            })
                        }
                        to="/enterprise/dashboard"
                        className="flex items-center"
                    >
                        <a class="ml-4 inline-flex text-green-500 items-center justify-center px-2 py-1 me-3 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            <UserGroupIcon className="w-3 h-3 mr-2" />
                            Enterprise
                        </a>
                    </Link>
                </div>

                <SidebarItems />
            </div>
        </div>
    );
}
