'use client';

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger
} from '../Components/magicui/accordion';

const faqs = [
    {
        question: 'Who is pre.dev designed for?',
        answer: (
            <div>
                <p className="text-gray-700 dark:text-gray-300 mb-4">
                    pre.dev is designed for a wide range of professionals
                    involved in product development and management. This
                    includes product managers, founders, entrepreneurs,
                    development team leads, and enterprise product teams. It's
                    particularly valuable for:
                </p>
                <ul className="list-none space-y-2 mb-4">
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            Product managers looking to streamline their
                            documentation and planning processes.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            Startup founders who need to quickly develop and
                            iterate on product ideas.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            Enterprise product teams aiming to improve
                            collaboration and efficiency.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            Development agencies managing multiple client
                            projects simultaneously.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            Non-technical founders who need assistance in
                            translating their ideas into structured plans.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            Agile teams seeking to enhance their sprint planning
                            and backlog management.
                        </span>
                    </li>
                </ul>
                <p className="text-gray-700 dark:text-gray-300">
                    Whether you're working on B2C or B2B software products,
                    pre.dev can help you plan, document, and execute your
                    product development more effectively.
                </p>
            </div>
        )
    },
    {
        question: 'How does this differentiate vs Chat GPT?',
        answer: "pre.dev is custom built for planning. It is fine tuned and leverages unique data-sets and orchestration techniques to build upon foundational models. It also has specialized features like roadmap generation, Jira/Linear integration, and UI/UX code generation that ChatGPT, Claude, and LLama doesn't offer."
    },

    {
        question: 'We already have PMs, why should I use this?',
        answer: 'pre.dev can augment and enhance PM productivity, not replace them. It can help PMs generate documentation and plans faster, allowing them to focus on strategy and execution. Given its trained on historical task completion data, it can often also better accurately estimate completion of tasks vs human intuition.'
    },
    {
        question: 'Why should I stay subscribed?',
        answer: 'Product development is a never-ending cycle.  Continuous improvements and new features being added regularly. The more you use it, the more valuable it becomes as it learns your product and processes. Consistent time and cost savings in the product development lifecycle.'
    },
    {
        question: 'Is my data safe?',
        answer: "All data is private and encrypted at rest on solo and enterprise plans.  pre.dev does not train on any enterprise clients' data."
    },
    {
        question: 'Can this be used by teams? Do you have an enterprise plan?',
        answer: "pre.dev is great for teams, and we offer enterprise plans tailored to your organization's needs starting at $99 per seat. Please contact us at arjun@pre.dev for more information or if you need more than 5 seats."
    }
];

export function FAQ() {
    return (
        <section id="faq">
            <div className="py-14">
                <div className="container mx-auto px-4 md:px-8">
                    <div className="mx-auto max-w-5xl text-center">
                        <h4 className="text-xl font-bold tracking-tight text-black dark:text-white">
                            FAQs
                        </h4>
                        <h2 className="text-4xl font-bold tracking-tight text-black dark:text-white sm:text-6xl">
                            Frequently Asked Questions
                        </h2>
                        <p className="mt-6 text-xl leading-8 text-black/80 dark:text-white">
                            Need help with something? Here are some of the most
                            common questions we get.
                        </p>
                    </div>
                    <div className="container mx-auto my-12 ">
                        <Accordion
                            type="single"
                            collapsible
                            className="flex w-full flex-col items-center justify-center"
                            defaultValue={faqs[0].question}
                        >
                            {faqs.map((faq, idx) => (
                                <AccordionItem
                                    key={idx}
                                    value={faq.question}
                                    className="w-full"
                                >
                                    <AccordionTrigger className="text-black dark:text-white">
                                        {faq.question}
                                    </AccordionTrigger>
                                    <AccordionContent className="text-black dark:text-gray-300">
                                        {faq.answer}
                                    </AccordionContent>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                    <h4 className="mb-12 text-center text-sm font-medium tracking-tight text-foreground/80">
                        Still have questions? Email us at{' '}
                        <a href="mailto:arjun@pre.dev" className="underline">
                            arjun@pre.dev
                        </a>
                    </h4>
                </div>
            </div>
        </section>
    );
}
