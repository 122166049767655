import { useSelector, useDispatch } from 'react-redux';
import {
    selectShowRequestModal,
    setMeetingRequested
} from '../../../redux/reducers/generic/project';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { requestMeeting } from '../../../redux/actions/solo/requestMeeting';
import { useParams } from 'react-router-dom';
import trackEvent from '../../../helpers/trackEvent';

export default function Calendly({ setCompleted }) {
    const open = useSelector(selectShowRequestModal);
    const { accessTokenPayload } = useSessionContext();
    const { projectId } = useParams();

    const dispatch = useDispatch();

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log('onProfilePageViewed'),
        onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
        onEventTypeViewed: () => console.log('onEventTypeViewed'),
        onEventScheduled: e => {
            console.log('onEventScheduled', e);
            if (projectId) dispatch(requestMeeting(projectId));
            if (setCompleted) setCompleted(true);
            dispatch(setMeetingRequested(true));

            //tracking events for meeting scheduled
            if (projectId) {
                trackEvent('meetingScheduledWithPreDev', {
                    userEmail: accessTokenPayload?.email,
                    userId: accessTokenPayload?.userId,
                    conversionId: projectId,
                    projectId
                });
            } else {
                trackEvent('landingMeetingWithPreDev', {});
            }
        }
    });

    return (
        <InlineWidget
            url={'https://calendly.com/pre_dev/free-expert-consultation5'}
            prefill={{
                email: accessTokenPayload?.email
            }}
            styles={{
                maxHeight: '865px',
                height: '865px'
            }}
        />
    );
}
