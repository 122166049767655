import { useState } from 'react';
import { AtSymbolIcon } from '@heroicons/react/24/outline';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import Modal from '../../Components/Modal';
import { useDispatch } from 'react-redux';
import { tagColleague } from '../../../redux/actions/enterprise/tagColleague';
import { toast } from 'sonner';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../Components/Spinner';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';

export const TagColleague = ({ message }) => {
    const { isEnterprise } = useSubscriptionPayment();
    const [open, setOpen] = useState(false);

    const handleClickedTag = () => {
        setOpen(true);
    };

    return (
        isEnterprise && (
            <>
                <button
                    onClick={handleClickedTag}
                    className="hover:text-slate-200 dark:hover:text-slate-50"
                >
                    <AtSymbolIcon className="h-5" />
                </button>
                <TagModal open={open} setOpen={setOpen} message={message} />
            </>
        )
    );
};

const TagModal = ({ open, setOpen, message }) => {
    const dispatch = useDispatch();

    const { enterpriseUsers } = useEnterpriseOrganization();
    const { projectId, enterpriseOrganizationId } = useParams();
    const [loading, setLoading] = useState(false);

    const handleTagColleague = async () => {
        console.log(message);
        setLoading(true);

        let result = await dispatch(
            tagColleague({
                enterpriseOrganizationId,
                projectId,
                userEmail: chosenColleague,
                comment,
                messageId: message._id?.toString()
            })
        );
        setLoading(false);
        if (result) {
            toast.success('Colleague tagged');
            setOpen(false);
        }
    };

    const [chosenColleague, setChosenColleague] = useState(
        enterpriseUsers[0]?.userEmail
    );
    const [comment, setComment] = useState(null);

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Tag Colleague
                </h2>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        Select a colleague to help answer the latest question.
                    </p>
                </div>
                <div className="mt-5">
                    <select
                        id="colleague"
                        name="colleague"
                        value={chosenColleague}
                        onChange={e => setChosenColleague(e.target.value)}
                        onSelect={e => setChosenColleague(e.target.value)}
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                        {enterpriseUsers.map(user => (
                            <option key={user.userEmail} value={user.userEmail}>
                                {user.userEmail}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mt-5">
                    <label
                        htmlFor="comment"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Comment
                    </label>
                    <div className="mt-1">
                        <textarea
                            id="comment"
                            name="comment"
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <button
                        disabled={loading}
                        onClick={handleTagColleague}
                        className="inline-flex items-center gap-x-4 justify-center rounded-md border border-transparent bg-teal-700 py-2 px-4 text-sm font-medium text-white hover:bg-teal-800 focus:ring-4 focus:ring-gray-200 focus:outline-none"
                    >
                        Tag Colleague
                        {loading && <Spinner w="4" color="white" />}
                    </button>
                </div>
            </>
        </Modal>
    );
};
