// src/components/EditCountsComponent.js
import { gql, useQuery } from '@apollo/client';

const GET_EDIT_COUNTS = gql`
    query GetEditCounts {
        getEditCounts {
            successfulEditsCount
            unsuccessfulEditsCount
        }
    }
`;

function EditCountsComponent() {
    const { loading, error, data } = useQuery(GET_EDIT_COUNTS);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            <h3>Edit Counts</h3>
            <p>Successful Edits: {data.getEditCounts.successfulEditsCount}</p>
            <p>
                Unsuccessful Edits: {data.getEditCounts.unsuccessfulEditsCount}
            </p>
        </div>
    );
}

export default EditCountsComponent;
