import { ENABLE_LEAD_GEN } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import { updateProject } from '../../reducers/generic/projects';
import { fetchProjectSuccess } from '../../reducers/generic/project';

export const enableLeadGen =
    ({ projectId, value }) =>
    async dispatch => {
        try {
            const { data } = await client.mutate({
                mutation: ENABLE_LEAD_GEN,
                variables: { projectId, value },
                fetchPolicy: 'network-only'
            });

            if (data?.enableLeadGen) {
                dispatch(updateProject(data.enableLeadGen));
                dispatch(fetchProjectSuccess(data.enableLeadGen));
                return data.enableLeadGen;
            }

            return false;
        } catch (error) {
            console.error('Error enabling lead gen:', error);
            return false;
        }
    };
