import { SET_PROJECT_SCHEDULING_LINK } from '../../../api/graphQL/solo/mutator';
import { client } from '../../../api/client';
import { fetchProjectSuccess } from '../../reducers/generic/project';
import { updateProject } from '../../reducers/generic/projects';
import { getUserProfile } from './getUserProfile';
export const setSchedulingLink =
    (projectId, schedulingLink) => async dispatch => {
        try {
            const { data } = await client.mutate({
                mutation: SET_PROJECT_SCHEDULING_LINK,
                variables: { projectId, schedulingLink },
                fetchPolicy: 'network-only'
            });

            if (data?.setProjectSchedulingLink) {
                dispatch(fetchProjectSuccess(data.setProjectSchedulingLink));
                dispatch(updateProject(data.setProjectSchedulingLink));
            }
            dispatch(getUserProfile());

            return data?.setProjectSchedulingLink;
        } catch (error) {
            console.error('Error setting scheduling link:', error);
            if (error.graphQLErrors) {
                const apiUnavailableError = error.graphQLErrors.find(err =>
                    err.message.includes('API is temporarily unavailable')
                );
                if (apiUnavailableError) {
                    alert(
                        'API is temporarily unavailable. Please try again later.'
                    );
                }
            }
            throw error;
        }
    };
