import { client } from '../../../api/client';
import { ADD_TECH_STACK_ITEM_TO_PROJECT } from '../../../api/graphQL/solo/mutator';
import { getSowStatuses } from './getSowStatuses';

export const addTechStackItem =
    ({ projectId, name }) =>
    async (dispatch, getState) => {
        try {
            let { data } = await client.mutate({
                mutation: ADD_TECH_STACK_ITEM_TO_PROJECT,
                variables: { projectId, name }
            });

            dispatch(getSowStatuses(projectId));

            return data.addTechStackItemToProject;
        } catch (error) {
            throw new Error(error);
        }
    };
