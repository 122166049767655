import { UPGRADE_TO_SOLO_PREMIUM } from '../../../api/graphQL';
import { client } from '../../../api/client';

export const upgradeToSoloPremium = () => async (dispatch, getState) => {
    try {
        let { data } = await client.mutate({
            mutation: UPGRADE_TO_SOLO_PREMIUM
        });
        return data.upgradeToSoloPremium;
    } catch (error) {
        console.log({ error });
        return false;
    }
};
