import { useProject } from '../../../context-providers/Project';
import { BudgetSlider } from './BudgetSlider';
import { useState, useEffect } from 'react';
import SkillLevel from './skill_level';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectSow,
    selectSkillLevel,
    selectPrices,
    setPrice
} from '../../../redux/reducers/generic/projectArchitecture';
import { parseTotalHours, getPrice } from '../../../constants';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { selectProjectArchitectureLoading } from '../../../redux/reducers/generic/projectArchitecture';
import { updateProjectBudget } from '../../../redux/actions/solo/updateProjectBudget';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { toast } from 'sonner';
import trackEvent from '../../../helpers/trackEvent';

export const BudgetData = () => {
    const { project } = useProject();
    const { isShare, isHomePage } = useSubscriptionPayment();
    let architectureLoading = useSelector(selectProjectArchitectureLoading);

    const { accessTokenPayload } = useSessionContext();
    const userEmail = accessTokenPayload?.email;
    const canEdit = project.userEmail === userEmail || isHomePage;
    const prices = useSelector(selectPrices);

    const [budget, setBudget] = useState(project.budget || 5000);
    const dispatch = useDispatch();
    const [debouncedBudget, setDebouncedBudget] = useState(budget);
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedBudget(budget);
        }, 300);

        return () => clearTimeout(timer);
    }, [budget]);

    useEffect(() => {
        if (debouncedBudget !== project.budget && canEdit) {
            trackEvent('changedBudget', {
                userEmail: project.userEmail,
                userId: accessTokenPayload?.userId
            });
            dispatch(
                updateProjectBudget(project._id, debouncedBudget.toString())
            );
        }
    }, [debouncedBudget, project._id, canEdit]);

    const handleBudgetChange = newBudget => {
        if (!isShare && canEdit) {
            setBudget(newBudget);
        } else if (!canEdit) {
            toast.error("You don't have permission to edit the budget.");
        }
    };
    const skillLevel = useSelector(selectSkillLevel);

    let sowContent = useSelector(selectSow);
    const hours = sowContent ? parseTotalHours(sowContent?.milestones) : 0;

    const [price, setPriceLocal] = useState(
        getPrice(skillLevel, hours, prices)
    );

    useEffect(() => {
        const calcPrice = getPrice(skillLevel, hours, prices);
        setPriceLocal(calcPrice);
        dispatch(setPrice(calcPrice));
    }, [prices, skillLevel, hours]);

    return (
        <>
            <div className="flex flex-col items-start space-y-2 text-white">
                <div className="flex flex-col items-center justify-between w-full space-y-2">
                    {architectureLoading && (
                        <div className="flex items-center justify-center space-x-4 gap-y-2 my-2">
                            <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
                            <p className="text-sm text-gray-300">
                                Recalculating pricing estimate...
                            </p>
                        </div>
                    )}

                    <div className="flex items-center justify-between w-full">
                        <SkillLevel />

                        {hours > 0 && (
                            <div className="flex items-center space-x-2">
                                <span className="text-xl font-semibold">
                                    ~{hours}
                                </span>
                                <span className="text-md"> Hours</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <BudgetSlider
                budget={budget}
                estimateData={hours > 0 ? price / 100 : null}
                setBudget={handleBudgetChange}
                textColor="text-white"
                disabled={!canEdit}
                showFilter={true}
            />
        </>
    );
};
