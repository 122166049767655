import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectShowRequestModal,
    setMeetingRequested,
    setShowRequestModal
} from '../../../../../redux/reducers/generic/project';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import { requestEnterpriseMeeting } from '../../../../../redux/actions/enterprise_widget/requestEnterpriseMeeting';
import {
    selectEnterpriseWidgetProjectId,
    selectUserEmail
} from '../../../../../redux/reducers/enterprise_widget/enterpriseWidget';
import { selectEnterpriseOrganization } from '../../../../../redux/reducers/enterprise/enterpriseOrganization';
import Modal from '../../../../Components/Modal';

export default function RequestModal() {
    const open = useSelector(selectShowRequestModal);
    const projectId = useSelector(selectEnterpriseWidgetProjectId);
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const userEmail = useSelector(selectUserEmail);

    const dispatch = useDispatch();

    const [completed, setCompleted] = useState(false);

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log('onProfilePageViewed'),
        onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
        onEventTypeViewed: () => console.log('onEventTypeViewed'),
        onEventScheduled: e => {
            dispatch(requestEnterpriseMeeting(projectId));
            setCompleted(true);
            dispatch(setMeetingRequested(true));
        }
    });

    const calendlyWidget = enterpriseOrganization?.calendlyLink?.startsWith(
        'https://meetings.hubspot.com/'
    ) ? (
        <iframe
            src={enterpriseOrganization.calendlyLink}
            style={{
                maxHeight: '700px',
                height: '700px',
                width: '100%',
                border: 'none'
            }}
        />
    ) : (
        <InlineWidget
            url={enterpriseOrganization?.calendlyLink}
            prefill={{
                email: userEmail
            }}
            styles={{
                maxHeight: '700px',
                height: '700px'
            }}
        />
    );

    const successMessage = (
        <>
            <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                    />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                    >
                        Meeting Request Successful!
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            We will be reaching out to soon to finalize the
                            project requirements and begin building!
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClose={() => dispatch(setShowRequestModal(false))}
                    onClick={() => dispatch(setShowRequestModal(false))}
                >
                    Continue
                </button>
            </div>
        </>
    );

    return (
        <Modal
            open={open}
            onClose={() => dispatch(setShowRequestModal(false))}
            maxWidth="max-w-4xl"
        >
            {completed ? successMessage : calendlyWidget}
        </Modal>
    );
}
