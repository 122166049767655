import { Graph, Milestone } from './graphTypes';

export const filterUserFlowGraphByBudget = (
    maxBudget: number,
    userFlowGraph: Graph
) => {
    const subTaskAddedMap = new Map();
    try {
        const firstNode = userFlowGraph.nodes.find(node => node.depth === 0);
        const queue = [firstNode];
        let remainingBudget = maxBudget;

        const nodesSeen = new Map();
        nodesSeen.set(firstNode.id, true);

        while (queue.length > 0) {
            const currentNode = queue.shift();
            const stories = currentNode?.data?.stories;

            if (stories) {
                let numStoriesRemoved = 0;

                stories.forEach(story => {
                    let numSubTasksRemoved = 0;
                    let storyHours = 0;

                    story.subTasks.forEach(subTask => {
                        if (remainingBudget >= subTask.cost) {
                            remainingBudget -= subTask.cost;
                            subTaskAddedMap.set(subTask._id, true);
                            storyHours += subTask.hours;
                        } else {
                            numSubTasksRemoved += 1;
                        }
                    });

                    story.hours = storyHours;

                    if (numSubTasksRemoved === story.subTasks.length) {
                        numStoriesRemoved += 1;
                        story.opacity = 20;
                        story.removed = true;
                    } else if (numSubTasksRemoved > 0) {
                        story.opacity = 50;
                        story.partiallyRemoved = true;
                    }
                });

                const currentNodeModified = userFlowGraph.nodes.find(
                    node => node.id === currentNode.id
                );

                if (numStoriesRemoved === stories.length) {
                    currentNodeModified.removed = true;
                    currentNodeModified.data.opacity = 20;
                } else if (numStoriesRemoved > 0) {
                    currentNodeModified.partiallyRemoved = true;
                    currentNodeModified.data.opacity = 70;
                } else {
                    currentNodeModified.data.opacity = 100;
                }

                userFlowGraph.nodes = userFlowGraph.nodes.map(node =>
                    node.id === currentNode.id ? currentNodeModified : node
                );
            }

            const edges = userFlowGraph.edges.filter(
                edge =>
                    edge.source === currentNode.id ||
                    edge.target === currentNode.id
            );
            const connectingNodes = edges.map(edge => {
                const targetNode = userFlowGraph.nodes.find(
                    node => node.id === edge.target
                );
                const sourceNode = userFlowGraph.nodes.find(
                    node => node.id === edge.source
                );
                return edge.source === currentNode.id ? targetNode : sourceNode;
            });

            connectingNodes.forEach(node => {
                if (!nodesSeen.get(node.id)) {
                    nodesSeen.set(node.id, true);
                    queue.push(node);
                }
            });
        }

        return {
            newUserFlowGraph: userFlowGraph,
            subTaskAddedMap
        };
    } catch (error) {
        console.log({ error });
        return {
            newUserFlowGraph: userFlowGraph,
            subTaskAddedMap
        };
    }
};

export const filterMilestonesByBudget = (
    milestones: Milestone[],
    subTaskAddedMap: Map<string, boolean>
) => {
    try {
        const milestonesModified = milestones.reduce((acc, milestone) => {
            let stories = [];
            milestone.stories.forEach(story => {
                const includedSubTasks = story.subTasks.filter(subTask =>
                    subTaskAddedMap.get(subTask._id)
                );

                story.hours = includedSubTasks.reduce(
                    (total, subTask) => total + subTask.hours,
                    0
                );
                story.subTasks = includedSubTasks;

                if (includedSubTasks.length > 0 && story.hours > 0) {
                    stories.push(story);
                } else {
                    story.removed = true;
                }
            });

            if (stories.length > 0) {
                milestone.stories = stories;
                milestone.hours = stories.reduce(
                    (total, story) => total + story.hours,
                    0
                );
                acc.push(milestone);
            }
            return acc;
        }, []);

        return milestonesModified;
    } catch (error) {
        console.log({ error });
        return milestones;
    }
};

export const filterGraphByBudget = (
    graph: Graph,
    subTaskAddedMap: Map<string, boolean>
) => {
    try {
        graph.nodes = graph.nodes.map(node => {
            let stories = [];
            let numStoriesRemoved = 0;
            for (let story of node.data.stories) {
                let subTasks = [];
                let numSubTasksRemoved = 0;
                for (let subTask of story.subTasks) {
                    if (subTaskAddedMap.get(subTask._id)) {
                        subTasks.push(subTask);
                    } else {
                        numSubTasksRemoved += 1;
                    }
                }
                story.subTasks = subTasks;
                if (numSubTasksRemoved === story.subTasks.length) {
                    numStoriesRemoved += 1;
                    story.removed = true;
                }
            }
            if (numStoriesRemoved === node.data.stories.length) {
                node.data.opacity = 20;
            } else if (numStoriesRemoved > 0) {
                node.data.opacity = 70;
            } else {
                node.data.opacity = 100;
            }

            node.data.stories = stories;
            return node;
        });
        return graph;
    } catch (error) {
        console.log({ error });
        return graph;
    }
};
