import { Input } from './Input';
import { useEffect } from 'react';
import $ from 'jquery';
import { useEnterpriseOrganizations } from '../../../context-providers/EnterpriseOrganizations';
import { useDispatch } from 'react-redux';
import { clearStateProjects } from '../../../redux/reducers/generic/projects';

export default function NewOrganization() {
    let id = 'createNewProject1';
    let { enterpriseOrganizations } = useEnterpriseOrganizations();
    const dispatch = useDispatch();

    let showPage = false;

    const userSubscription = {
        loading: false,
        status: 'active'
    };

    if (
        enterpriseOrganizations.length == 0 ||
        userSubscription.status === 'active'
    ) {
        showPage = true;
    }

    useEffect(() => {
        setTimeout(() => {
            try {
                //triger focus on input with id createNewProject
                $(`#${id}`).trigger('focus');
            } catch (err) {
                console.log(err);
            }
        }, 100);
        dispatch(clearStateProjects());
    }, []);
    return (
        showPage && (
            <div className="flex flex-col h-full justify-center items-center w-full">
                <div className="flex flex-row w-full items-center justify-center -mt-36">
                    <div className="text-center mx-4 max-w-lg">
                        <img
                            src="/predevblack.png"
                            alt="pre.dev"
                            className="h-16 w-auto mx-auto mb-2"
                        />
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900">
                            Welcome to pre.dev
                        </h2>
                        <p className="font-light text-gray-500 dark:text-gray-400 text-lg">
                            Get started by creating a new organization.
                        </p>

                        <div className="mt-6">
                            <Input id={id} />
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}
