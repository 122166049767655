import { useDispatch, useSelector } from 'react-redux';
import {
    selectLoadingSowCustom,
    selectSow
} from '../../../../redux/reducers/generic/projectArchitecture';
import { RefreshCw } from 'react-feather';
import { useProject } from '../../../../context-providers/Project';
import { generateSowCustom } from '../../../../redux/actions/solo/generateSoWCustom';
import { selectProjectArchitectureLoading } from '../../../../redux/reducers/generic/projectArchitecture';
import { Tooltip } from 'react-tooltip';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { toggleSubscribeModal } from '../../../../redux/reducers/generic/billing';
import 'react-tooltip/dist/react-tooltip.css';

export const GenerateLatestCustomSow = () => {
    const dispatch = useDispatch();
    const isLoadingSow = useSelector(selectLoadingSowCustom);
    let sow = useSelector(selectSow);
    const type = sow?.type || 'mvp';
    const { project, projectId } = useProject();
    const isLoadingArchitecture = useSelector(selectProjectArchitectureLoading);
    const { isSubbed } = useSubscriptionPayment();

    const up_to_date = project.sow_custom_up_to_date;

    const handleClick = async () => {
        if (!isSubbed) {
            dispatch(toggleSubscribeModal(true));
        } else {
            await dispatch(generateSowCustom(projectId, type));
        }
    };

    if (up_to_date || isLoadingSow || isLoadingArchitecture || !sow?.completed)
        return null;

    return (
        <>
            <button
                onClick={handleClick}
                data-tooltip-id="generate-sow-tooltip"
                data-tooltip-content="Generate Latest Docs"
                className="p-2 text-blue-600 border border-blue-700 rounded-full hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-colors duration-200"
            >
                <RefreshCw className="h-4 w-4" />
            </button>
            <Tooltip id="generate-sow-tooltip" place="top" />
        </>
    );
};
