import { client } from '../../../api/client';
import { REMOVE_EXISTING_PROJECT_CONTEXT } from '../../../api/graphQL/solo/mutator';
import { removeFromExistingProjectContexts } from '../../reducers/generic/project';

export const removeExistingProjectContext =
    ({ existingProjectContextId }) =>
    async dispatch => {
        try {
            const { data } = await client.mutate({
                mutation: REMOVE_EXISTING_PROJECT_CONTEXT,
                variables: {
                    existingProjectContextId
                }
            });

            if (data.removeExistingProjectContext) {
                dispatch(
                    removeFromExistingProjectContexts(existingProjectContextId)
                );
            }

            return true;
        } catch (error) {
            console.log({ error });
        }
    };
