import { REQUEST_MEETING } from '../../../api/graphQL';
import { client } from '../../../api/client';
import { setRequestingMeeting } from '../../reducers/generic/project';

export const requestMeeting = projectId => async (dispatch, getState) => {
    dispatch(setRequestingMeeting(true));
    try {
        await client.mutate({
            mutation: REQUEST_MEETING,
            variables: { projectId: projectId }
        });
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
    }
    dispatch(setRequestingMeeting(false));
};
