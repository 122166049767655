import { motion, Variants } from 'framer-motion';
import { useState } from 'react';

function cn(...classes) {
    return classes.filter(Boolean).join(' ');
}

interface WordPullUpProps {
    words: string;
    delayMultiple?: number;
    wrapperFramerProps?: Variants;
    framerProps?: Variants;
    className?: string;
    onAnimationEnd?: () => void;
}

export default function WordPullUp({
    words,
    wrapperFramerProps = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                delay: 0.2,
                staggerChildren: 0.1
            }
        }
    },
    framerProps = {
        hidden: { y: 20, opacity: 0 },
        show: { y: 0, opacity: 1 }
    },
    className,
    onAnimationEnd
}: WordPullUpProps) {
    const [animationFinished, setAnimationFinished] = useState(false);

    const handleAnimationComplete = () => {
        setAnimationFinished(true);
        if (onAnimationEnd) {
            onAnimationEnd();
        }
    };

    const shimmerKeyframes = `
    @keyframes shimmer {
      0%, 90%, 100% {
        background-position: calc(-100% - var(--shimmer-width)) 0;
      }
      30%, 60% {
        background-position: calc(100% + var(--shimmer-width)) 0;
      }
    }
  `;

    return (
        <>
            <style>{shimmerKeyframes}</style>
            <motion.h1
                variants={wrapperFramerProps}
                initial="hidden"
                animate="show"
                onAnimationComplete={handleAnimationComplete}
                className={cn(
                    'font-display text-left text-4xl font-bold tracking-[-0.02em] drop-shadow-sm',
                    className,
                    animationFinished &&
                        'bg-clip-text bg-no-repeat bg-gradient-to-r from-transparent via-black/80 via-50% to-transparent dark:via-white/80'
                )}
                style={
                    animationFinished
                        ? {
                              animation: 'shimmer 8s infinite',
                              backgroundSize: 'var(--shimmer-width) 100%',
                              transition:
                                  'background-position 1s cubic-bezier(.6,.6,0,1) infinite'
                          }
                        : undefined
                }
            >
                {words.split(' ').map((word, i) => (
                    <motion.span
                        key={i}
                        variants={framerProps}
                        style={{ display: 'inline-block', paddingRight: '8px' }}
                    >
                        {word === '' ? <span>&nbsp;</span> : word}
                    </motion.span>
                ))}
            </motion.h1>
        </>
    );
}
