import { GET_ENTERPRISE_ORGANIZATION_BY_USER } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchEnterpriseOrganizationRequest,
    fetchEnterpriseOrganizationSuccess,
    fetchEnterpriseOrganizationFailure
} from '../../reducers/enterprise/enterpriseOrganization';
import { fetchApiDomainsSuccess } from '../../reducers/enterprise/clientOnboarding';
import {
    fetchEnterpriseUsersSuccess,
    updateNumUsersLeft
} from '../../reducers/enterprise/enterpriseUsers';
import { setAPIKey } from '../../reducers/enterprise_widget/enterpriseWidget';

export const getEnterpriseOrganizationByUser =
    enterpriseOrganizationId => async (dispatch, getState) => {
        dispatch(fetchEnterpriseOrganizationRequest());

        try {
            const { data } = await client.query({
                query: GET_ENTERPRISE_ORGANIZATION_BY_USER,
                variables: { enterpriseOrganizationId },
                fetchPolicy: 'network-only'
            });
            dispatch(
                fetchEnterpriseOrganizationSuccess(
                    data.enterpriseOrganizationByUser
                )
            );
            dispatch(
                fetchApiDomainsSuccess(
                    data.enterpriseOrganizationByUser.apiDomains
                )
            );

            dispatch(setAPIKey(data.enterpriseOrganizationByUser.apiKey));

            dispatch(
                updateNumUsersLeft(
                    data.enterpriseOrganizationByUser.numUsersLeft
                )
            );
            dispatch(
                fetchEnterpriseUsersSuccess(
                    data.enterpriseOrganizationByUser.enterpriseUsers
                )
            );
        } catch (error) {
            console.log({ error });
            dispatch(fetchEnterpriseOrganizationFailure(error));
        }
    };
