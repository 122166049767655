import { MIGRATE_PROJECT } from '../../../api/graphQL/solo/mutator';
import { client } from '../../../api/client';

export const migrateProject =
    (projectId, fileKeyS3) => async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: MIGRATE_PROJECT,
                variables: { projectId, fileKeyS3 }
            });

            const migrationResult = data.migrateProject;

            return migrationResult;
        } catch (error) {
            console.error('Project migration error:', error);

            if (error.message.includes('API is temporarily unavailable.')) {
                alert(
                    'API is temporarily unavailable. Please try again later.'
                );
            } else {
                alert(error.message);
            }

            return false;
        }
    };
