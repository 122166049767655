import { useState } from 'react';
import Modal from '../../Components/Modal';
import { useProject } from '../../../context-providers/Project';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { IoNotifications } from 'react-icons/io5';
import moment from 'moment';

export const ProposalButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { project } = useProject();
    const { isHomePage, isSolo } = useSubscriptionPayment();
    const [lastSeenDate, setLastSeenDate] = useState(
        window.localStorage.getItem('lastSeenProposals') || 0
    );

    const handleOpenModal = () => {
        setLastSeenDate(Date.now().toString());
        window.localStorage.setItem('lastSeenProposals', Date.now().toString());
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    if (!project?.numProposals) {
        return null;
    }

    let lastProposalDate =
        project?.proposals?.[project?.proposals?.length - 1]?.created;

    let notSeen = new Date(lastProposalDate) > new Date(lastSeenDate);

    return (
        (isSolo || isHomePage) &&
        project?.numProposals > 0 && (
            <>
                <div className="relative">
                    <button
                        onClick={handleOpenModal}
                        className="flex items-center gap-x-2 px-3 py-1 text-sm bg-white text-black rounded-lg hover:bg-gray-100 transition-colors"
                    >
                        <span className="font-medium">Proposals</span>
                    </button>
                    {notSeen && (
                        <div className="absolute -top-2 -right-2">
                            <IoNotifications className="w-5 h-5 text-red-500" />
                        </div>
                    )}
                </div>

                <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    maxWidth="max-w-lg"
                    title={`Proposals (${project.proposals.length})`}
                    bgColor="bg-black"
                    titleColor="text-white"
                >
                    <div className="space-y-6 mt-4">
                        {project.proposals.map((proposal, index) => (
                            <ProposalItem proposal={proposal} index={index} />
                        ))}
                    </div>
                </Modal>
            </>
        )
    );
};

const ProposalItem = ({ proposal, index }) => {
    return (
        <div
            key={index}
            className="flex flex-row justify-between items-center bg-gray-800 p-4 rounded-lg"
        >
            <div className="flex flex-col">
                <a
                    href={proposal.enterpriseWebsiteURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-3 hover:underline text-blue-400"
                >
                    {proposal.enterpriseLogoURL && (
                        <img
                            src={proposal.enterpriseLogoURL}
                            alt={proposal.enterpriseName}
                            className="h-10 rounded-full p-2 bg-white rounded-xl"
                        />
                    )}
                    <h3 className="font-medium text-xl text-white">
                        {proposal.enterpriseName}
                    </h3>
                </a>
                <p className="text-sm text-gray-300 mt-2">
                    {moment(proposal?.created).format('MMM D, YYYY')}
                </p>
            </div>

            {proposal.enterpriseSchedulingLink && (
                <a
                    href={proposal.enterpriseSchedulingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                    Schedule Meeting
                </a>
            )}
        </div>
    );
};
