import { PURCHASE_LEAD_GEN_CREDITS } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchEnterpriseOrganizationSuccess,
    fetchEnterpriseOrganizationFailure
} from '../../reducers/enterprise/enterpriseOrganization';
import { toast } from 'sonner';

export function purchaseLeadGenCredits({ enterpriseOrganizationId, quantity }) {
    return async function (dispatch) {
        try {
            const { data } = await client.mutate({
                mutation: PURCHASE_LEAD_GEN_CREDITS,
                variables: { enterpriseOrganizationId, quantity },
                fetchPolicy: 'network-only'
            });

            if (data.purchaseLeadGenCredits) {
                dispatch(
                    fetchEnterpriseOrganizationSuccess(
                        data.purchaseLeadGenCredits
                    )
                );
            }
        } catch (error) {
            console.error('Error purchasing Lead Gen Credits:', error);
            toast.error('Error purchasing Lead Gen Credits');
            // dispatch(fetchEnterpriseOrganizationFailure(error));
        }
    };
}
