import React from 'react';
import { Dropdown, Avatar } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { toggleBillingModalOpen } from '../../../redux/reducers/generic/billing';
import { BillingModal } from './BillingModal';

export default function Settings() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function onLogout() {
        await signOut();
        window.location.href = '/';
        navigate('/');
    }

    const { accessTokenPayload } = useSessionContext();
    return (
        <a
            href="#"
            className="flex items-center gap-x-4 py-3 text-sm font-semibold leading-6 text-gray-900"
        >
            <div className="">
                <BillingModal />
                <div className="flex w-full">
                    <Dropdown
                        inline
                        label={
                            <>
                                <Cog6ToothIcon
                                    className="h-8 w-8"
                                    aria-hidden="true"
                                />
                                <div className="w-full">
                                    <h1>Settings</h1>
                                </div>
                            </>
                        }
                    >
                        <Dropdown.Header>
                            <span className="block truncate text-sm font-medium">
                                {accessTokenPayload.email}
                            </span>
                        </Dropdown.Header>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            onClick={() => {
                                dispatch(toggleBillingModalOpen());
                                // window.location.href = "/admin-projects";
                            }}
                        >
                            Billing
                        </Dropdown.Item>
                        <Dropdown.Item onClick={onLogout}>
                            Sign out
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            </div>
        </a>
    );
}
