import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FeedBackButton } from '../Components/FeedbackButton';

export const MobileSidebar = ({ handleGetStartedClick, loginText }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { pathname } = useLocation();

    return (
        <>
            <div
                id="topNav"
                className="h-[46px] grid grid-cols-3 py-4 shadow-sm sm:px-6 md:hidden"
            >
                <button
                    type="button"
                    className="text-gray-400 md:hidden -ml-4"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-8 w-8" aria-hidden="true" />
                </button>

                <div className="text-right"></div>
            </div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="z-40 relative md:hidden"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={() =>
                                                setSidebarOpen(false)
                                            }
                                        >
                                            <span className="sr-only">
                                                Close sidebar
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex grow flex-col overflow-y-auto bg-gray-900 text-white px-6">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <a
                                                href="/enterprise"
                                                className="flex justify-end"
                                            >
                                                <img
                                                    src="/predev.png"
                                                    className="h-8"
                                                    alt="Company Logo"
                                                />
                                                <span className="ml-2 self-center">
                                                    pre.dev
                                                </span>
                                                <span className="mt-3 ml-2 self-center text-xs font-semibold whitespace-nowrap text-green-400">
                                                    Enterprise
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <nav className="flex flex-1 flex-col h-full">
                                        <ul
                                            role="list"
                                            className="flex-grow flex flex-col justify-between"
                                        >
                                            <li>
                                                <ul
                                                    role="list"
                                                    className="-mx-2 space-y-1"
                                                >
                                                    <div className="flex items-center ml-2">
                                                        <div className="flex flex-col md:order-2">
                                                            <button
                                                                onClick={
                                                                    handleGetStartedClick
                                                                }
                                                                type="button"
                                                                className="text-white bg-green-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:block"
                                                            >
                                                                {loginText}{' '}
                                                                <span aria-hidden="true">
                                                                    &rarr;
                                                                </span>
                                                            </button>
                                                            <hr className="my-4" />

                                                            <div>
                                                                <FeedBackButton />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </li>
                                            <li className="-mx-6 mt-auto border-t border-gray-300"></li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
