export const businessTypes = [
    'Finance',
    'Healthcare',
    'Education',
    'Retail',
    'Manufacturing',
    'Media & Entertainment',
    'Gaming',
    'Telecommunications',
    'Automotive',
    'Energy',
    'Utilities',
    'Agriculture',
    'Government',
    'Non-Profit',
    'Transportation & Logistics',
    'Real Estate',
    'Professional Services',
    'Construction',
    'Food & Beverage',
    'Pharmaceuticals',
    'Biotechnology',
    'Hospitality & Tourism',
    'Legal Services',
    'Insurance',
    'Human Resources & Staffing',
    'Consulting',
    'IT & Software Development',
    'Marketing & Advertising',
    'Customer Service & Support',
    'E-Commerce',
    'Cybersecurity',
    'Cloud Services',
    'Supply Chain Management',
    'Financial Services',
    'Investment & Asset Management',
    'Machine Learning & AI',
    'Data Science & Analytics',
    'Content Creation & Distribution',
    'Environmental Services',
    'Digital Transformation',
    'Blockchain & Cryptocurrency',
    'Agritech',
    'Healthcare Technology',
    'Education Technology',
    'Smart Cities',
    'Logistics & Fulfillment',
    'Product Development',
    'Consumer Goods',
    'Aerospace & Defense',
    'Social Media',
    'Humanitarian Aid',
    'Smart Home & IoT',
    'Mobility & Transportation',
    'Online Learning & Training',
    'Freelance & Gig Economy',
    'Petroleum & Mining',
    'SaaS',
    'PaaS',
    'B2B Solutions',
    'B2C Solutions',
    'Real-Time Analytics',
    'Predictive Analytics',
    'Compliance & Risk Management',
    'Digital Wallets & Payment Processing',
    'Mobile Solutions',
    'Augmented & Virtual Reality',
    'Gaming & Interactive Media',
    'Personal Finance & Budgeting',
    'Home Automation',
    'Medical Devices',
    'Bioinformatics',
    'Agricultural Data & Analytics',
    'Remote Work Solutions',
    'Telehealth & Remote Medicine',
    'Customer Relationship Management (CRM)',
    'Enterprise Resource Planning (ERP)',
    'Human Capital Management (HCM)',
    'Supply Chain Optimization',
    'Video Streaming & Content Delivery',
    'Event Management',
    'Content Management',
    'Creative Services',
    'Sustainability & Green Technology',
    'Network Infrastructure',
    'Workforce Management',
    'Data Governance & Compliance',
    'Knowledge Management',
    'Robotic Process Automation (RPA)',
    'Subscription Services',
    'Fleet Management',
    'Quality Assurance & Testing',
    'Financial Technology (FinTech)',
    'PropTech',
    'Legal Technology (LegalTech)',
    'Health & Wellness',
    'Sales Enablement',
    'Learning & Development',
    'Market Research',
    'Product Lifecycle Management',
    'Customer Success',
    'Innovation & Research',
    'Digital Identity & Authentication',
    'Transportation Services',
    'Virtual Assistants & Chatbots',
    'Field Service Management',
    'Revenue Operations',
    'Embedded Finance',
    'Data Engineering',
    'Agricultural Robotics',
    'Edge Computing',
    'Inventory & Asset Tracking',
    'Workforce Training & Compliance',
    'Threat Detection & Prevention',
    'Process Automation',
    'Customer Engagement',
    'Risk Assessment & Underwriting',
    'Logistics & Supply Chain Visibility',
    'Omnichannel Retail',
    'Data Center Management',
    'Energy Management & Optimization',
    'Advertising Technology (AdTech)',
    'User Experience & Interface Design',
    'Innovation Hubs & Incubators',
    'Urban Planning & Development',
    'Behavioral Health',
    'Genomics',
    'AI Research',
    'Financial Risk & Fraud Detection',
    'Healthcare Informatics',
    'Resource Planning & Allocation',
    'Market Intelligence',
    'Dynamic Pricing & Yield Management',
    'Telecom Infrastructure',
    'Cloud Migration',
    'Product Data Management',
    'User Testing',
    'Data Privacy Solutions',
    'Workplace Safety & Compliance',
    'Climate Change & Environmental Monitoring',
    'User Personalization',
    'Employee Engagement & Productivity',
    'Intelligent Automation',
    'Collaborative Software',
    'Workflow Management',
    'Event Streaming',
    'Predictive Maintenance',
    'Regulatory Compliance',
    'Corporate Social Responsibility',
    'Project Management',
    'Resource Management',
    'Workflow Automation',
    'Augmented Analytics',
    'Integrated Campaigns',
    'Energy Trading',
    'Supply Chain Finance',
    'B2B Payments',
    'Waste Management',
    'Market Expansion',
    'Brand Management',
    'Innovation Strategy',
    '3D Printing',
    'User Acquisition',
    'Product Innovation',
    'Asset Tracking',
    'DevOps',
    'Cloud Security',
    'Network Security',
    'Food Processing',
    'Digital Health',
    'Telecom Services',
    'Supply Chain Visibility',
    'Asset Protection',
    'Digital Assistants',
    'Green Energy',
    'Real Estate Technology',
    'Customer Analytics',
    'Behavioral Analytics',
    'Business Continuity',
    'Digital Commerce',
    'Product Customization',
    'Data Warehousing',
    'Database Management',
    'API Management',
    'Connected Devices',
    'Smart Agriculture',
    'Financial Inclusion',
    'Business Analytics',
    'Digital Archiving',
    'IoT Analytics',
    'Energy Efficiency',
    'Civic Technology',
    'Social Impact',
    'Application Hosting',
    'Risk Intelligence',
    'Field Operations',
    'Big Data Solutions',
    'Multi-Channel Engagement',
    'Personalization',
    'Augmented Workforce',
    'Cognitive Computing',
    'Programmatic Advertising',
    'Local Commerce',
    'Digital Loyalty',
    'Remote Monitoring',
    'Predictive Healthcare',
    'Population Health',
    'Telecom Operations',
    'On-Demand Services',
    'User Segmentation',
    'Inventory Control',
    'Manufacturing Analytics',
    'Automated Forecasting',
    'Virtual Events',
    'Field Robotics',
    'Content Syndication',
    'Telemedicine',
    'Remote Asset Management',
    'Tech-Enabled Services',
    'Customer Portals',
    'Connected Cars',
    'Agricultural Sustainability',
    'Employee Wellness',
    'Fleet Operations',
    'Enterprise Mobility',
    'Identity Verification',
    'Clinical Trials',
    'Genetic Analysis',
    'Ethical AI',
    'Global Payments',
    'Subscription Billing',
    'Distribution Networks',
    'Retail Banking',
    'Consumer Banking',
    'Supply Chain Risk',
    'Automated Insights',
    'Performance Monitoring',
    'Data Quality Management',
    'Integrated Customer Support',
    'Smart Factory',
    'Geospatial Analytics',
    'Product Onboarding',
    'Contactless Payments',
    'Real-Time Payments',
    'Collaborative Learning',
    'Conversational AI',
    'Multi-Cloud Management',
    'Hybrid Cloud',
    'Product Recommendations',
    'Automated Ticketing',
    'User-Generated Content',
    'Video Analytics',
    'Microservices',
    'API Security',
    'Privacy Solutions',
    'Computer Vision',
    'Fraud Detection',
    'Risk Management',
    'Employee Training',
    'Natural Language Processing',
    'Sentiment Analysis',
    'Supply Chain Automation',
    'Social Commerce',
    'Managed Services',
    'Market Analysis',
    'Urban Mobility',
    'Inventory Optimization',
    'Customer Loyalty Programs',
    'Smart Wearables',
    'Geolocation Services',
    'Augmented Design',
    'Real Estate Data',
    'Sensor Data Analysis',
    'Vehicle Analytics',
    'Intelligent Transport Systems',
    'Digital Fundraising',
    'Mobile Payments',
    'Digital Agriculture',
    'Online Insurance',
    'Precision Agriculture',
    'Agricultural Robotics',
    'Operational Analytics',
    'Contact Tracing',
    'Employee Safety',
    'Machine Vision',
    'Video Conferencing',
    'Digital Storytelling',
    'Location-Based Advertising',
    'Wearable Healthcare',
    'Spatial Computing',
    'Telecommunication Services',
    'Cyber Threat Intelligence',
    'Autonomous Vehicles',
    'Immersive Media',
    'RegTech',
    'Speech Recognition',
    'Digital Banking',
    'Augmented Design Tools',
    'Mobile Commerce',
    'Fitness Technology',
    'Healthcare Financing',
    'Fleet Telematics',
    'Data Monetization',
    'Behavioral Health Solutions',
    'Telecommunication Analytics',
    'Life Sciences',
    'Artificial General Intelligence',
    'Blockchain Solutions',
    'Digital Twin Technology',
    'Customer Intelligence',
    'Machine Learning Operations (MLOps)',
    'Digital Agriculture Analytics',
    'Digital Risk Management',
    'Crypto Trading',
    'Mobile Health Applications',
    'Real Estate Crowdfunding',
    'Financial Data Services',
    'Privacy-Enhancing Technologies',
    'Cloud Database',
    'Mobile Video Editing',
    'Energy Conservation',
    'Customer Data Platform',
    'Event Tracking',
    'Emotional Intelligence',
    'Contextual Marketing',
    'User Engagement',
    'Global Mobility',
    'Knowledge Process Outsourcing',
    'Service Design',
    'Capacity Planning',
    'Sales Acceleration',
    'Biopharma',
    'Mobile Robotics',
    'User Journey Mapping',
    'Contextual Commerce',
    'Medical Simulation',
    'Connected Health',
    'Smart Contracts',
    'Insurance Technology',
    'Legal Compliance',
    'Crowdfunding',
    'Mobile Advertising',
    'Security Analytics',
    'Customer Journey Analytics',
    'Telemedicine',
    'Geospatial Intelligence',
    'Brand Safety',
    'Inventory Management',
    'Digital Workspace',
    'User Interaction Tracking',
    'Innovation Management',
    'Workforce Optimization',
    'Product-Led Growth',
    'Digital Ecosystems',
    'Social Impact Analytics',
    'Augmented Reality Training',
    'Ethical Hacking',
    'Patient Experience',
    'Biometrics',
    'Mobile Development',
    'Environmental Analysis',
    'Video Production',
    'Contextual Targeting',
    'Cloud-Native Solutions',
    'Medical Imaging',
    'Self-Service Analytics',
    'Process Mining',
    'Occupational Health',
    'Procurement Analytics',
    'Vehicle Fleet Management',
    'Robotic Surgery',
    'IT Operations',
    'Predictive Advertising',
    'Employee Monitoring',
    'Microlearning',
    'Web3 Development',
    'AI-Powered Marketing',
    'Market Forecasting',
    'Clinical Data Management',
    'Elderly Care',
    'Employee Assistance',
    'Product Security',
    'Real-Time Collaboration',
    'Security Operations',
    'Employee Onboarding',
    'Remote Learning',
    'Remote Work Analytics',
    'Managed IT Services',
    'Digital Advertising Platforms',
    'Hybrid Workforce',
    'Healthcare Marketing',
    'Smart Retail',
    'Privacy Management',
    'Elder Care Solutions',
    'Wearable Device Management',
    'Hyperlocal Marketing',
    'Subscription Management',
    'Omnichannel Retailing',
    'Elder Care Management',
    'Telecom Billing Solutions',
    'Interactive Learning',
    'AI-Powered Chatbots',
    'Business Process Outsourcing',
    'In-App Advertising',
    'Recruiting Automation',
    'Smart Farming',
    'Real-Time Reporting',
    'Sustainable Sourcing',
    'Customer Journey Mapping',
    'Industrial Automation',
    'Enterprise Data Integration',
    'NLP-Powered Tools'
];
