import { useSelector } from 'react-redux';
import { selectSow } from '../../../redux/reducers/generic/projectArchitecture';
import { RoadmapChart } from './RoadmapChart';
import { JiraSync } from './JiraSync';
import { LinearSync } from './LinearSync';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { toggleBillingModalOpen } from '../../../redux/reducers/generic/billing';
import { useDispatch } from 'react-redux';
import { useProject } from '../../../context-providers/Project';
import { TopHeader } from '../Docs/TopHeader';

export const Roadmap = () => {
    let sow = useSelector(selectSow);
    let isLoadingSow = sow?.projectId ? !sow.completed : true;
    const { projectId } = useProject();

    const { tier, isShare, isSubbed, isHomePage, isEnterprise, isSolo } =
        useSubscriptionPayment();
    const showUpgrade = tier === 'SoloPlus' || !isSubbed;

    const showJiraLinearSync =
        !isLoadingSow && isSubbed && (isEnterprise || tier === 'SoloPremium');

    return (
        <div className="bg-white overflow-y-scroll min-w-[350px] min-h-[300px] h-full flex flex-col gap-y-4 rounded-lg">
            <TopHeader titleOverride="Roadmap" />

            {!sow?.projectId && <LoadingSoW />}
            <div
                className={`flex flex-col gap-x-2 gap-y-2 justify-end items-center`}
            >
                {showUpgrade && (isSolo || isEnterprise) && (
                    <UpgradeToPremium text={isSolo ? 'Solo' : 'Enterprise'} />
                )}

                {showJiraLinearSync && (
                    <>
                        <JiraSync projectId={projectId} />
                        <LinearSync projectId={projectId} />
                    </>
                )}
            </div>

            <RoadmapChart sow={sow} type={sow?.type} projectId={projectId} />
        </div>
    );
};

const UpgradeToPremium = ({ text }) => {
    const dispatch = useDispatch();

    const handleUpgrade = () => {
        dispatch(toggleBillingModalOpen(true));
    };

    return (
        <div className="w-full flex items-center justify-center">
            <button
                onClick={handleUpgrade}
                className="flex flex-row items-center gap-4 bg-green-600 hover:bg-green-500 text-white rounded-lg px-4 py-3 text-sm"
            >
                <img src="/atlassian.svg" alt="Premium icon" className="h-6" />
                <img src="/linear.svg" alt="Premium icon" className="h-6" />
                <div className="text-left">
                    <div>
                        Upgrade to <b>{text} Premium</b>
                    </div>
                    <div>to Sync with Jira / Linear</div>
                </div>
            </button>
        </div>
    );
};

const LoadingSoW = () => {
    return (
        <div className="w-full h-64 flex items-center justify-center">
            <div className="flex flex-col items-center space-y-4">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></div>
                <p className="text-gray-600 text-lg font-medium">
                    Loading Roadmap...
                </p>
                <p className="text-gray-400 text-sm">
                    Please wait while we prepare your project roadmap
                </p>
            </div>
        </div>
    );
};
