import { useState, useEffect, useRef } from 'react';
import { Share2Icon } from 'lucide-react';
import { ForceGraph2D } from 'react-force-graph';

export function ArchitectureGeneration() {
    return {
        Icon: Share2Icon,
        name: 'Technical Architecture',
        description:
            'Instantly visualize your high-level architecture, as pre.dev can map any new or existing products.',
        href: '/auth/?redirectToPath=%2Fprojects',
        cta: 'Get Started',
        className: 'col-span-3 lg:col-span-1',
        background: (
            <div className="absolute h-[200] w-[200] border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_70%)] group-hover:scale-105">
                <SimpleGraph />
            </div>
        )
    };
}

const SimpleGraph = () => {
    const fgRef = useRef(null);
    const [graphData, setGraphData] = useState({
        nodes: [
            { id: 'Landing', label: 'Landing', hours: 30 },
            { id: 'Signup', label: 'Signup', hours: 8 },
            { id: 'Signin', label: 'Signin', hours: 9 },
            { id: 'Home', label: 'Home', hours: 20 },
            { id: 'Settings', label: 'Settings', hours: 10 },
            { id: 'Feed', label: 'Feed', hours: 40 },
            { id: 'Profile', label: 'Profile', hours: 15 }
        ],
        links: [
            { source: 'Landing', target: 'Home' },
            { source: 'Home', target: 'Settings' },
            { source: 'Home', target: 'Feed' },
            { source: 'Feed', target: 'Profile' },
            { source: 'Landing', target: 'Signup' },
            { source: 'Landing', target: 'Signin' },
            { source: 'Signup', target: 'Signin' }
        ]
    });

    useEffect(() => {
        if (!fgRef.current) return;
        fgRef.current.d3Force('link').distance(link => 30); // Adjusted distance for bounciness
    }, [graphData]);

    return (
        <ForceGraph2D
            ref={fgRef}
            graphData={graphData}
            width={400}
            height={400}
            nodeRelSize={8} // Adjusted node size for visibility
            nodeId="id"
            linkColor={link => {
                return 'white';
            }}
            enableZoomInteraction={false}
            linkCurvature="curvature"
            linkWidth={link => 1} // Consistent link width
            d3VelocityDecay={0.1} // Adjusted for bounciness
            cooldownTicks={100} // Shorter cooldown for quicker layout stabilization
            linkDirectionalParticles={4}
            linkDirectionalParticleWidth={link => 1.5}
            onEngineStop={() => {
                fgRef.current.zoomToFit(500, 40);
            }}
            autoPauseRedraw={false}
            nodeCanvasObject={(node, ctx, globalScale) => {
                try {
                    let hours = node.hours || 0;
                    let NODE_R = Math.max(4, (hours / 40) * 50);
                    if (NODE_R > 10) {
                        NODE_R = 10;
                    }

                    // Adjusted color generation
                    const baseColor = '#888888'; // Adjust as needed
                    let fillColor =
                        hours > 0
                            ? `hsl(${
                                  (Math.pow(hours % 14, 2) * 10) % 1000
                              }, 60%, 60%)`
                            : baseColor;

                    // Draw the smaller circle for the node itself on top of the larger circle
                    const nodeCircle = new Path2D();
                    nodeCircle.arc(
                        node.x,
                        node.y,
                        NODE_R,
                        0,
                        2 * Math.PI,
                        false
                    );
                    ctx.fillStyle = fillColor;
                    ctx.fill(nodeCircle);
                    let label = node.label;
                    label =
                        label.length > 15
                            ? label.substring(0, 15) + '...'
                            : label;
                    const fontSize = 10 / globalScale;
                    ctx.font = `${fontSize}px Sans-Serif`;
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fillStyle = 'white';
                    ctx.fillText(label, node.x, node.y - NODE_R - fontSize);
                } catch (err) {
                    console.log(err);
                }
            }}
        />
    );
};
