import UploadFile from './UploadFile';
import { FileList } from '../FileList';

export const SoWFormat = () => {
    return (
        <div className="flex flex-col gap-4">
            <UploadFile />
            <FileList type="sow_format" />
        </div>
    );
};
