import { MAKE_USER_ADMIN_OF_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import {
    fetchEnterpriseUsersRequest,
    fetchEnterpriseUsersSuccess,
    fetchEnterpriseUsersFailure
} from '../../reducers/enterprise/enterpriseUsers';

export const makeUserAdminOfEnterpriseOrganization =
    (userEmail, enterpriseOrganizationId) => async (dispatch, getState) => {
        dispatch(fetchEnterpriseUsersRequest());
        try {
            const { data } = await client.mutate({
                mutation: MAKE_USER_ADMIN_OF_ENTERPRISE_ORGANIZATION,
                variables: {
                    userEmail: userEmail,
                    enterpriseOrganizationId: enterpriseOrganizationId
                },
                fetchPolicy: 'network-only'
            });

            dispatch(
                fetchEnterpriseUsersSuccess(
                    data.makeUserAdminOfEnterpriseOrganization.enterpriseUsers
                )
            );
            return 'Successfully made user an admin!';
        } catch (error) {
            console.log({ error, userEmail, enterpriseOrganizationId });
            dispatch(fetchEnterpriseUsersFailure(error));
            return error.message;
        }
    };
