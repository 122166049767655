import { SAVE_PRICES } from '../../../api/graphQL';
import { client } from '../../../api/client';
import { setPrices } from '../../reducers/generic/projectArchitecture';
export const saveSkillLevel =
    ({ projectId, prices }) =>
    async dispatch => {
        try {
            dispatch(setPrices(prices));

            const { data } = await client.mutate({
                mutation: SAVE_PRICES,
                variables: {
                    projectId,
                    prices
                }
            });

            return 'Changes were saved successfully!';
        } catch (error) {
            console.log({ error });
            return error;
        }
    };
