import { formatHeading, formatParagraph, formatText } from './helper';

export function formatExecutiveSummary(sow, previewMode) {
    let formattedContent = [];
    try {
        let heading = formatHeading(2, [
            formatText('Executive Summary', false)
        ]);
        let paragraph = formatParagraph([
            formatText(sow?.executiveSummary, previewMode)
        ]);
        formattedContent = [heading, paragraph];
    } catch (error) {
        console.log('Error formatting executive summary:', error);
    }
    return formattedContent;
}
