import { formatHeading, formatParagraph, formatText, formatBoldText } from './helper';

export function formatQuote(sow, previewMode, price) {
    
    let formattedContent = [];
    try {
        const totalHours =
        sow?.milestones?.reduce(
            (total, milestone) => total + (milestone.hours || 0),
            0
        ) || 0;

        const scaledPrice = price / 100
        const hourlyRate = price / totalHours / 100

        let heading = formatBoldText('x Hourly Rate: ' + hourlyRate, false)
    

        let heading2 = formatHeading(2, [
            formatBoldText('_________________________\n\nTotal Price: ' + scaledPrice, false)
        ]);
 
        formattedContent = [heading,heading2];
    } catch (error) {
        console.log('Error formatting quote:', error);
    }
    return formattedContent;
}
