import { InformationCircleIcon } from '@heroicons/react/24/outline';
import * as Tooltip from '@radix-ui/react-tooltip';
import { getCountryFlagEmoji } from '../../ClientProjects/ProjectsList/item';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const ProjectName = ({ project }) => {
    const country = project?.country?.code;
    const countryName = project?.country?.name;
    const name = project.name;
    const execSummary = project.execSummary;
    const industryTags = project.industryTags;
    const projectTypeTags = project.projectTypeTags;

    return (
        <div className="flex items-center space-x-3 lg:pl-2 ">
            {country ? (
                <Tooltip.Provider delayDuration={100}>
                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            <span className="text-sm text-gray-500">
                                {getCountryFlagEmoji(country)}
                            </span>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content
                                side="top"
                                align="left"
                                sideOffset={5}
                            >
                                <span className="bg-white text-black p-2 rounded-md">
                                    {countryName}
                                </span>
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
            ) : (
                <div
                    className={classNames(
                        'h-2.5 w-2.5 flex-shrink-0 rounded-full'
                    )}
                    aria-hidden="true"
                />
            )}
            <div className="flex items-center">
                <a className="hover:text-gray-600 flex items-center">
                    <span className="max-w-[300px] truncate text-ellipsis">
                        {name}
                    </span>
                    <Tooltip.Provider delayDuration={100}>
                        <Tooltip.Root>
                            <Tooltip.Trigger asChild>
                                <InformationCircleIcon className="h-5 w-5 ml-2 text-gray-400 cursor-help" />
                            </Tooltip.Trigger>
                            <Tooltip.Portal>
                                <Tooltip.Content
                                    className="max-w-md p-4 bg-white shadow-lg rounded-lg border border-gray-200"
                                    sideOffset={5}
                                >
                                    <p className="text-sm text-gray-700">
                                        {execSummary}
                                    </p>
                                    <Tooltip.Arrow className="fill-white" />
                                </Tooltip.Content>
                            </Tooltip.Portal>
                        </Tooltip.Root>
                    </Tooltip.Provider>
                </a>
                <ProjectTags
                    industryTags={industryTags}
                    projectTypeTags={projectTypeTags}
                />
            </div>
        </div>
    );
};

function ProjectTags({ industryTags, projectTypeTags }) {
    if (!industryTags?.length && !projectTypeTags?.length) return null;

    return (
        <div className="flex flex-col gap-1 ml-2">
            {industryTags?.length > 0 && (
                <div className="flex flex-wrap gap-1">
                    {industryTags.map(tag => (
                        <span
                            key={tag}
                            className="px-2 py-0.5 text-xs font-medium bg-blue-50 text-blue-700 rounded-full"
                        >
                            {tag}
                        </span>
                    ))}
                </div>
            )}
            {projectTypeTags?.length > 0 && (
                <div className="flex flex-wrap gap-1">
                    {projectTypeTags.map(tag => (
                        <span
                            key={tag}
                            className="px-2 py-0.5 text-xs font-medium bg-purple-50 text-purple-700 rounded-full"
                        >
                            {tag}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
}
