import { useSelector } from 'react-redux';
import {
    selectBillingModalOpen,
    toggleBillingModalOpen,
    toggleSubscribeModal
} from '../../../redux/reducers/generic/billing';
import Modal from '../../Components/Modal';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { getEnterpriseStripeLink } from '../../../redux/actions/enterprise/getEnterpriseStripeLink';
import { useEffect, useState } from 'react';

export const EnterpriseBillingModal = ({}) => {
    const billingModalOpen = useSelector(selectBillingModalOpen);
    const dispatch = useDispatch();
    const { enterpriseOrganization } = useEnterpriseOrganization();

    const enterpriseSubscription = enterpriseOrganization?.subscriptionStatus;

    const [stripeLink, setStripeLink] = useState('');

    useEffect(() => {
        const getLink = async () => {
            let link = await dispatch(getEnterpriseStripeLink());
            setStripeLink(link);
        };
        getLink();
    }, []);

    return (
        enterpriseSubscription && (
            <Modal
                open={billingModalOpen}
                onClose={() => {
                    dispatch(toggleBillingModalOpen());
                }}
            >
                <>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Billing
                    </h2>
                    <p className="text-sm leading-6 text-gray-500">
                        Manage your subscription with pre.dev here.
                    </p>

                    <dl className="mt-3 space-y-5 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                        <div className="mt-2 sm:flex">
                            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                Organization Name
                            </dt>
                            <dd className=" flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                <div className="text-gray-900">
                                    {enterpriseOrganization.name}
                                </div>
                            </dd>
                        </div>

                        <div className=" sm:flex">
                            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                Plan
                            </dt>
                            <dd className=" flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                <div className="text-green-800">
                                    {enterpriseSubscription.tier
                                        .replace(/([A-Z])/g, ' $1')
                                        .trim()}
                                </div>
                            </dd>
                        </div>

                        <div className=" sm:flex">
                            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                Subscription Status
                            </dt>
                            <dd className=" flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                <div
                                    className={
                                        enterpriseSubscription.status ===
                                        'active'
                                            ? 'text-green-800'
                                            : 'text-red-800'
                                    }
                                >
                                    {enterpriseSubscription.status === 'active'
                                        ? 'Active'
                                        : 'Inactive'}
                                </div>
                            </dd>
                        </div>

                        {enterpriseSubscription.status !== 'inactive' && (
                            <>
                                <div className=" sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                        Price
                                    </dt>
                                    <dd className=" flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">
                                            ${enterpriseSubscription.price} /{' '}
                                            {enterpriseSubscription.duration.replace(
                                                'ly',
                                                ''
                                            )}
                                        </div>
                                    </dd>
                                </div>

                                <div className=" sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                        Current Period Start
                                    </dt>
                                    <dd className=" flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">
                                            {moment(
                                                enterpriseSubscription.current_period_start
                                            ).format('MMM Do YYYY')}
                                        </div>
                                    </dd>
                                </div>
                                <div className=" sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                        {enterpriseSubscription.cancel_at_period_end
                                            ? 'Subscription End'
                                            : 'Next Billing Date'}
                                    </dt>
                                    <dd className=" flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">
                                            {moment(
                                                enterpriseSubscription.current_period_end
                                            ).format('MMM Do YYYY')}
                                        </div>
                                    </dd>
                                </div>
                            </>
                        )}
                    </dl>

                    <div className="mt-6 flex justify-center">
                        {enterpriseSubscription.status !== 'inactive' ? (
                            <a
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                href={stripeLink}
                            >
                                Update /{' '}
                                {enterpriseSubscription.cancel_at_period_end
                                    ? 'Renew'
                                    : 'Cancel'}{' '}
                                Subscription
                            </a>
                        ) : (
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                onClick={() => {
                                    dispatch(toggleBillingModalOpen());
                                    dispatch(toggleSubscribeModal(true));
                                }}
                            >
                                Upgrade Plan
                            </button>
                        )}
                    </div>
                </>
            </Modal>
        )
    );
};
