import { useState } from 'react';

import Modal from '../../Components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
    addEdgeToAdd,
    addNodeToAdd,
    selectFirstNode
} from '../../../redux/reducers/generic/graphEdits';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';
import { useProject } from '../../../context-providers/Project';
import { addNodesEdgesToGraph } from '../../../redux/actions/solo/addNodesEdgesToGraph';
import {
    clearStateGraphEdits,
    setGraphEditLoading
} from '../../../redux/reducers/generic/graphEdits';

export const CreateNode = ({ open, setOpen, selectedNode }) => {
    const dispatch = useDispatch();

    let { projectArchitecture, graphType, project } = useProject();
    let graph = projectArchitecture.graphs.find(g => g.side == graphType);

    const [label, setLabel] = useState('');
    const [description, setDescription] = useState(null);

    const savingEdits = async () => {
        try {
            await dispatch(
                addNodesEdgesToGraph({
                    projectId: project?._id,
                    side: graphType
                })
            );
            dispatch(clearStateGraphEdits());
            dispatch(setGraphEditLoading(false));
        } catch (err) {
            console.log(err);
            dispatch(setGraphEditLoading(false));
        }
    };

    const handleClick = async () => {
        toast('Connecting new node to graph...');
        if (!label) toast.error('Please provide a label');
        if (!selectedNode) toast.error('Please provide a node to connect to');
        const existingNode = graph.nodes.find(
            node => node.data.label.toLowerCase() === label.toLowerCase()
        );
        if (existingNode) {
            toast.error('A node with this label already exists');
            return;
        }
        const node = {
            id: label.toLowerCase().trim().replace(' ', '_'),
            label,
            description
        };
        dispatch(addNodeToAdd(node));
        dispatch(
            addEdgeToAdd({
                source: selectedNode.id,
                target: node.id,
                id: uuidv4()
            })
        );
        setLabel('');
        setDescription(null);
        setOpen(false);
        savingEdits();
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Create Node
                </h2>
                <p className="text-sm leading-6 text-gray-500">
                    Add a new node to the graph, connected to{' '}
                    <b>{selectedNode?.data.label}</b>.
                </p>
                <div className="mt-5">
                    <label
                        htmlFor="label"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Label
                    </label>
                    <div className="mt-1">
                        <input
                            id="label"
                            name="label"
                            value={label}
                            onChange={e => setLabel(e.target.value)}
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <label
                        htmlFor="description"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Description (optional)
                    </label>
                    <div className="mt-1">
                        <textarea
                            id="description"
                            name="description"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <button
                        onClick={handleClick}
                        className="inline-flex items-center gap-x-4 justify-center rounded-md border border-transparent bg-teal-700 py-2 px-4 text-sm font-medium text-white hover:bg-teal-800 focus:ring-4 focus:ring-gray-200 focus:outline-none"
                    >
                        Submit
                    </button>
                </div>
            </>
        </Modal>
    );
};
