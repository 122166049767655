'use client';

import { buttonVariants } from '../Components/magicui/ui/button';
import { cn } from '../Components/magicui/lib/utils';
import { Loader2 } from 'lucide-react';
import * as React from 'react';
import { getWebsiteDomain } from '../../auth_config';
import { useState } from 'react';
import {
    getAuthorisationURLWithQueryParamsAndSetState,
    thirdPartySignInAndUp
} from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { getRedirectURL } from '../../constants';
import trackEvent from '../../helpers/trackEvent';

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function QuickThirdParty({ className, ...props }: UserAuthFormProps) {
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);
    const [redirectLoading, setRedirectLoading] = useState(false);
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    async function handleThirdPartyCallback() {
        try {
            setRedirectLoading(true);
            trackEvent('emailHeroGoogleLogin', {});
            const response = await thirdPartySignInAndUp();
            if (response.status === 'OK') {
                setRedirectLoading(false);
                navigate(getRedirectURL(params.get('redirectToPath')));
            } else {
                setRedirectLoading(false);
                window.alert(
                    'No email provided by social login. Please use another form of login'
                );
                navigate('/auth');
            }
        } catch (err) {
            setRedirectLoading(false);
            if (err.isSuperTokensGeneralError === true) {
                window.alert(err.message);
            } else {
                window.alert(err);
            }
        }
    }

    React.useEffect(() => {
        if (location.pathname === '/auth/callback/google') {
            handleThirdPartyCallback();
        }
    }, [location]);

    async function thirdPartySignInClicked(name) {
        setRedirectLoading(true);
        setIsGoogleLoading(true);

        try {
            const authUrl = await getAuthorisationURLWithQueryParamsAndSetState(
                {
                    thirdPartyId: name,
                    frontendRedirectURI:
                        getWebsiteDomain() + '/auth/callback/' + name
                }
            );
            window.location.assign(authUrl);
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                window.alert(err.message);
            } else {
                console.log(err);
            }
            setRedirectLoading(false);
        }
    }

    return (
        <div className="dark">
            <div className="container flex h-full w-full p-6 flex-col items-center justify-center">
                <div className="mx-auto flex w-full flex-col justify-center gap-6 sm:w-[350px]">
                    {!redirectLoading ? (
                        <div className={cn('grid gap-6', className)} {...props}>
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <span className="w-full border-t" />
                                </div>
                                <div className="relative flex justify-center text-xs uppercase">
                                    <span className="px-2 text-muted-foreground">
                                        Or continue with
                                    </span>
                                </div>
                            </div>

                            <button
                                type="button"
                                className={cn(
                                    buttonVariants({ variant: 'secondary' }),
                                    'text-white w-full min-w-[200px] mx-auto'
                                )}
                                onClick={() =>
                                    thirdPartySignInClicked('google')
                                }
                                disabled={isGoogleLoading}
                            >
                                {isGoogleLoading ? (
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                ) : (
                                    <svg
                                        className="w-4 h-4 mr-2"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 18 19"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                )}{' '}
                                Google
                            </button>
                        </div>
                    ) : (
                        <div className="w-full">
                            <Loader2 className="mx-auto text-white justify-center h-6 w-6 animate-spin" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
