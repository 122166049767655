import React, { useState } from 'react';
import { HiPencil, HiX } from 'react-icons/hi';
import { Spinner } from '../../../Components/Spinner';
import { useDispatch } from 'react-redux';
import { updateUserHourlyRateOfEnterpriseOrganization } from '../../../../redux/actions/enterprise/updateUserHourlyRateOfEnterpriseOrganization';
import { toast } from 'sonner';

export const HourlyRate = ({ enterpriseOrganizationId, user, isAdmin }) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [tempRate, setTempRate] = useState(user.hourlyRate);
    const [rateLoading, setRateLoading] = useState(false);

    const handleUpdateRate = async () => {
        setRateLoading(true);
        const response = await dispatch(
            updateUserHourlyRateOfEnterpriseOrganization(
                user.userEmail,
                enterpriseOrganizationId,
                tempRate
            )
        );
        setRateLoading(false);
        setIsEditing(false);
        toast(response);
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setTempRate(user.hourlyRate);
    };

    const handleSave = e => {
        e.preventDefault();
        handleUpdateRate();
    };

    const handleInputChange = e => {
        const value = e.target.value;
        setTempRate(value);
    };

    if (!isAdmin) return null;

    return (
        <div>
            <h4 className="text-sm font-medium text-gray-700 mb-1">
                Hourly Rate
            </h4>
            <div className="flex items-center">
                {isEditing ? (
                    <form
                        onSubmit={handleSave}
                        className="flex items-center flex-grow relative"
                    >
                        <input
                            type="number"
                            name="hourlyRate"
                            id="hourlyRate"
                            disabled={rateLoading}
                            onChange={handleInputChange}
                            value={tempRate}
                            className="flex-grow block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="Enter hourly rate"
                        />
                        <button
                            type="submit"
                            className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            disabled={rateLoading}
                        >
                            {rateLoading ? <Spinner w="5" h="5" /> : 'Save'}
                        </button>
                        {!rateLoading && (
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="ml-2 inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                            >
                                <HiX className="h-4 w-4" aria-hidden="true" />
                            </button>
                        )}
                    </form>
                ) : (
                    <>
                        <span className="text-lg font-medium text-gray-900 mr-2">
                            {tempRate ? `$${tempRate}` : 'Not set'}
                        </span>
                        <button
                            onClick={handleEdit}
                            className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            <HiPencil className="h-4 w-4" aria-hidden="true" />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};
