import { client } from '../../../api/client';
import { EXCHANGE_LINKEDIN_CODE_FOR_ACCESS_TOKEN } from '../../../api/graphQL/solo/query';

export const exchangeLinkedInCodeForAccessToken =
    ({ code, state }: { code: string; state: string }) =>
    async (dispatch: any, getState: any) => {
        try {
            console.log('exchanging linkedin code for access token');
            console.log({ code, state });
            const { data } = await client.query({
                query: EXCHANGE_LINKEDIN_CODE_FOR_ACCESS_TOKEN,
                variables: { code, state }
            });

            return data.exchangeLinkedInCodeForAccessToken;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
            return null;
        }
    };
