import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { removeDomainFromEnterpriseOrganization } from '../../../../redux/actions/enterprise/removeDomainFromEnterpriseOrganization';
import { toast } from 'sonner';
import moment from 'moment';

export const DomainList = () => {
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const { enterpriseOrganization, apiDomains } = useEnterpriseOrganization();

    const handleDeleteDomain = async i => {
        const userConfirmed = window.confirm(
            'Are you sure you want to delete this domain?'
        );

        if (userConfirmed) {
            const response = await dispatch(
                removeDomainFromEnterpriseOrganization(
                    enterpriseOrganizationId,
                    i
                )
            );
            toast(response);
        }
    };

    return (
        <div className="max-h-[200px] overflow-y-scroll mt-3.5">
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr className="">
                        <th
                            scope="col"
                            className="pb-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                            <span className="">
                                Domain Whitelist ({apiDomains?.length})
                            </span>
                        </th>
                        <th
                            scope="col"
                            className="hidden sm:flex  px-3 pb-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                            Created
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {apiDomains &&
                        apiDomains.map((domain, i) => (
                            <tr
                                key={i}
                                className="cursor-pointer hover:bg-gray-50"
                            >
                                <td className="whitespace-nowrap py-5 pr-3 text-sm ">
                                    <div className="flex items-center">
                                        <div className="">
                                            <div className="font-medium text-gray-900">
                                                {domain.url}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="hidden sm:flex whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                    {moment(domain.created).format(
                                        'MMM Do YYYY'
                                    )}
                                </td>

                                {enterpriseOrganization.isAdmin && (
                                    <td className="relative whitespace-nowrap py-5 text-right text-sm font-medium">
                                        <a
                                            onClick={() =>
                                                handleDeleteDomain(domain._id)
                                            }
                                            className="cursor-pointer text-red-600 hover:text-red-900 mr-4"
                                        >
                                            Delete
                                            <span className="sr-only">
                                                , {domain.url}
                                            </span>
                                        </a>
                                    </td>
                                )}
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};
