import { HiX } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { removeUserSkillFromEnterpriseOrganization } from '../../../../redux/actions/enterprise/removeUserSkillFromEnterpriseOrganization';
import { toast } from 'sonner';
import { useState } from 'react';
import { Spinner } from '../../../Components/Spinner';

export const SkillTag = ({
    index,
    skill,
    enterpriseOrganizationId,
    isAdmin,
    user
}) => {
    const dispatch = useDispatch();
    const [isDeleting, setIsDeleting] = useState(false);

    const getColorClass = level => {
        if (level <= 3) return 'bg-red-500';
        if (level <= 6) return 'bg-yellow-500';
        if (level <= 8) return 'bg-green-500';
        return 'bg-blue-500';
    };

    const handleDeleteSkill = async () => {
        setIsDeleting(true);

        try {
            const response = await dispatch(
                removeUserSkillFromEnterpriseOrganization(
                    user.userEmail,
                    enterpriseOrganizationId,
                    index
                )
            );
            toast(response);
        } catch (error) {
            toast.error('Failed to delete skill. Please try again.');
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div className="inline-flex items-center bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-1 rounded-full mr-2 mb-2">
            <span className="mr-1">{skill.title}</span>
            {isAdmin && (
                <>
                    <div className="w-16 h-2 bg-gray-300 rounded-full overflow-hidden mr-1">
                        <div
                            className={`h-full ${getColorClass(skill.level)}`}
                            style={{ width: `${(skill.level / 10) * 100}%` }}
                        ></div>
                    </div>
                    <span className="mr-1 text-xs">({skill.level}/10)</span>
                </>
            )}
            {isAdmin &&
                (isDeleting ? (
                    <Spinner w="3" h="3" />
                ) : (
                    <button
                        onClick={handleDeleteSkill}
                        className="ml-1 text-gray-500 hover:text-red-500 focus:outline-none"
                        disabled={isDeleting}
                    >
                        <HiX className="h-3 w-3" />
                    </button>
                ))}
        </div>
    );
};
