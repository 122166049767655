import { ACTIVATE_FILE_FOR_KNOWLEDGE_BASE } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import { updateKnowledgeBaseFile } from '../../reducers/enterprise/enterpriseOrganization';

export const activateFileForKnowledgeBase =
    (enterpriseOrganizationId, fileId) => async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: ACTIVATE_FILE_FOR_KNOWLEDGE_BASE,
                variables: {
                    enterpriseOrganizationId,
                    fileId
                },
                fetchPolicy: 'network-only'
            });

            dispatch(
                updateKnowledgeBaseFile(data.activateFileForKnowledgeBase)
            );

            return 'Successfully updated knowledge base.';
        } catch (error) {
            console.log({ error });
            return error.message;
        }
    };
