import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAgencySchedulingLink } from '../../redux/actions/solo/getAgencySchedulingLink';
import trackEvent from '../../helpers/trackEvent';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

export const AgencySchedulingPage = () => {
    const sessionContext = useSessionContext();

    const [searchParams] = useSearchParams();
    const [schedulingLink, setSchedulingLink] = useState('');
    const dispatch = useDispatch();
    const creditId = searchParams.get('creditId');

    useEffect(() => {
        async function fetchSchedulingLink() {
            if (creditId) {
                try {
                    const link = await dispatch(
                        getAgencySchedulingLink(creditId)
                    );
                    setSchedulingLink(link);
                    trackEvent('meetingScheduledWithAgency', {
                        conversionId: creditId,
                        emailAddress: sessionContext?.accessTokenPayload?.email
                    });
                } catch (error) {
                    // toast.error('Something went wrong');
                    window.location.href = '/';
                }
            }
        }
        fetchSchedulingLink();
    }, [creditId, dispatch]);

    return (
        <div className="w-full h-[calc(100vh)] flex items-center justify-center">
            <div className="mx-auto h-full w-full">
                <div className="flex flex-col items-center justify-center h-full w-full">
                    {schedulingLink && (
                        <iframe
                            src={schedulingLink}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            title="Schedule a meeting"
                            className="min-h-[600px]"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
