import { Spinner } from '../../../Components/Spinner';
import { useSelector } from 'react-redux';
import { selectProjectArchitectureLoading } from '../../../../redux/reducers/generic/projectArchitecture';
import { useProject } from '../../../../context-providers/Project';
import { selectRecommendedTechStackLoading } from '../../../../redux/reducers/generic/project';

export const ViewSOW = () => {
    const { allSearchParams, setSearchParams } = useProject();
    let architectureLoading = useSelector(selectProjectArchitectureLoading);
    let recommendedStackLoading = useSelector(
        selectRecommendedTechStackLoading
    );

    return (
        <div className="flex justify-between my-auto">
            <button
                onClick={() => {
                    setSearchParams({
                        ...allSearchParams,
                        pageType: 'sow'
                    });
                }}
                type="button"
                className="flex gap-x-2 items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center"
            >
                Project Scope
                {architectureLoading || recommendedStackLoading ? (
                    <Spinner w="4" />
                ) : (
                    <span aria-hidden="true">&rarr;</span>
                )}
            </button>
        </div>
    );
};
