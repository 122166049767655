import { useState, useRef, useEffect } from 'react';
import { getApiDomain } from '../../../../auth_config';
import { useProject } from '../../../../context-providers/Project';
import { Icon } from '../TipTap/components/ui/Icon';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import TurndownService from 'turndown';
import {
    selectEditMode,
    selectSow
} from '../../../../redux/reducers/generic/projectArchitecture';

export const Download = ({ editor }) => {
    const { project } = useProject();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [converting, setIsConverting] = useState(false);
    const dropdownRef = useRef(null);
    const isEditMode = useSelector(selectEditMode);

    const turndownService = new TurndownService();

    const sow = useSelector(selectSow);
    const type = sow?.type || 'mvp';

    const downloadMarkdown = () => {
        const element = document.createElement('a');
        let markdown = createMarkdown();
        const file = new Blob([markdown], { type: 'application/md' });
        element.href = URL.createObjectURL(file);
        element.download = `${project?.name}-${type}.md`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const createMarkdown = () => {
        let html = editor.getHTML();
        var markdown = turndownService.turndown(html);
        return markdown;
    };

    const downloadPDF = async () => {
        setIsConverting(true);
        try {
            const response = await fetch(getApiDomain() + '/convert-markdown', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ markdown: createMarkdown() })
            });
            if (!response.ok)
                throw new Error(`HTTP error! status: ${response.status}`);
            const blob = await response.blob();
            downloadBlob(blob, `${project?.name}-${type}.pdf`);
        } catch (error) {
            console.error('Error converting markdown to PDF:', error);
        } finally {
            setIsConverting(false);
        }
    };

    const downloadBlob = (blob, filename) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    const downloadMilestonesCSV = () => {
        if (!sow?.milestones) return;

        const headers = [
            'ID',
            'Type',
            'Parent ID',
            'Title',
            'Description',
            'Complexity',
            'Hours',
            'Roles',
            'Project Name'
        ];

        let csvContent = [headers.join('\t')];
        let id = 1;

        sow.milestones.forEach((milestone, mIndex) => {
            const milestoneId = `M-${id++}`;
            csvContent.push(
                [
                    milestoneId,
                    'Milestone',
                    '',
                    milestone.description,
                    '',
                    '',
                    milestone.hours || '',
                    '',
                    project?.name || ''
                ].join('\t')
            );

            milestone.stories?.forEach((story, sIndex) => {
                const storyId = `S-${id++}`;
                csvContent.push(
                    [
                        storyId,
                        'Story',
                        milestoneId,
                        story.title,
                        story.description,
                        '',
                        story.hours || '',
                        '',
                        project?.name || ''
                    ].join('\t')
                );

                story.subTasks?.forEach((subTask, stIndex) => {
                    csvContent.push(
                        [
                            `ST-${id++}`,
                            'SubTask',
                            storyId,
                            subTask.description,
                            '',
                            subTask.complexityEstimate,
                            subTask.hours || '',
                            subTask.roles?.map(role => role.name).join(' | ') ||
                                '',
                            project?.name || ''
                        ].join('\t')
                    );
                });
            });
        });

        const blob = new Blob([csvContent.join('\n')], {
            type: 'text/csv;charset=utf-8;'
        });
        downloadBlob(blob, `${project?.name}-milestones-stories-subtasks.csv`);
    };

    useEffect(() => {
        const handleClickOutside = event => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    return (
        sow?.completed && (
            <div className="relative" ref={dropdownRef}>
                <button
                    onClick={toggleDropdown}
                    className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-full hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    data-tooltip-id="download-tooltip"
                    data-tooltip-content="Download Options"
                    disabled={converting}
                >
                    {converting ? (
                        <Icon
                            name="Loader"
                            className="w-4 h-4 text-indigo-500 animate-spin"
                        />
                    ) : (
                        <Icon name="Download" className="h-4 w-4" />
                    )}
                </button>
                <Tooltip id="download-tooltip" place="top" />
                {isDropdownOpen && (
                    <div
                        className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${
                            !isEditMode ? 'min-w-[150px]' : 'min-w-[100px]'
                        }`}
                    >
                        <div
                            className="py-1 z-[99]"
                            style={{ zIndex: 999 }}
                            role="menu"
                            aria-orientation="vertical"
                        >
                            <button
                                onClick={() => {
                                    downloadMarkdown();
                                    setIsDropdownOpen(false);
                                }}
                                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left cursor-pointer"
                                role="menuitem"
                            >
                                <Icon
                                    name="FileText"
                                    className="mr-3 h-4 w-4"
                                />
                                <span>Markdown</span>
                            </button>
                            <button
                                onClick={() => {
                                    if (!converting) {
                                        downloadPDF();
                                        setIsDropdownOpen(false);
                                    }
                                }}
                                disabled={converting}
                                className={`flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left ${
                                    converting
                                        ? 'cursor-not-allowed opacity-50'
                                        : 'cursor-pointer'
                                }`}
                                role="menuitem"
                            >
                                <Icon
                                    name="FileText"
                                    className="mr-3 h-4 w-4"
                                />
                                <span>
                                    {converting ? 'Converting...' : 'PDF'}
                                </span>
                                {converting && (
                                    <Icon
                                        name="Loader"
                                        className="ml-2 w-4 h-4 text-indigo-500 animate-spin"
                                    />
                                )}
                            </button>
                            {/* {!isEditMode && (
                                <button
                                    onClick={() => {
                                        downloadMilestonesCSV();
                                        setIsDropdownOpen(false);
                                    }}
                                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left cursor-pointer"
                                    role="menuitem"
                                >
                                    <Icon
                                        name="Table"
                                        className="mr-3 h-4 w-4"
                                    />
                                    <span>Timeline CSV</span>
                                </button>
                            )} */}
                        </div>
                    </div>
                )}
            </div>
        )
    );
};
