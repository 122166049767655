import { PlusCircleIcon, StarIcon } from '@heroicons/react/20/solid';
import { useDispatch, useSelector } from 'react-redux';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { toggleSubscribeModal } from '../../../../redux/reducers/generic/billing';
import PricingCard from '../../../Home/PricingCard';
import { selectSubscribeModal } from '../../../../redux/reducers/generic/billing';

export const UpgradeToPremium = ({ closeSidebar }) => {
    const dispatch = useDispatch();
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const userSubscription = enterpriseOrganization?.subscriptionStatus;
    const open = useSelector(selectSubscribeModal);

    const handleUpgrade = async e => {
        dispatch(toggleSubscribeModal(true));
        if (closeSidebar) {
            closeSidebar();
        }
    };

    return (
        userSubscription?.status &&
        userSubscription.status !== 'active' && (
            <div className="dark">
                {!open && (
                    <PricingCard
                        handleUpgrade={handleUpgrade}
                        isEnterprise={true}
                    />
                )}
            </div>
        )
    );
};
