import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Modal from '../../Components/Modal';
import { MatchWithDev } from '../../ProjectDashboard/Budget/matchWithDev';
import { useProject } from '../../../context-providers/Project';
import { updateMatchWithDev } from '../../../redux/actions/solo/updateMatchWithDev';
import { useDispatch } from 'react-redux';
import { Fireworks } from '@fireworks-js/react';
import { motion, AnimatePresence } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const HeroMatchExperience = ({ chatContainer }) => {
    const { projectId } = useParams();
    const { project } = useProject();
    const [matchLoading, setMatchLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const matchWithDev = project?.matchWithDev;
    const dispatch = useDispatch();

    const setMatch = async val => {
        setMatchLoading(true);
        await dispatch(updateMatchWithDev(projectId, val));
        setMatchLoading(false);
    };

    useEffect(() => {
        const startTime = Date.now();
        const duration = 1000; // 1 second

        const scrollStep = () => {
            const elapsed = Date.now() - startTime;
            const progress = Math.min(elapsed / duration, 1);

            if (chatContainer?.current) {
                const targetScroll = chatContainer.current.scrollHeight;
                const currentScroll = progress * targetScroll;

                chatContainer.current.scrollTo({
                    top: currentScroll,
                    behavior: 'auto'
                });
            }

            if (progress < 1) {
                requestAnimationFrame(scrollStep);
            }
        };

        requestAnimationFrame(scrollStep);
    }, []);

    return (
        !matchWithDev && (
            <AnimatePresence>
                {isVisible && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{
                            opacity: 0,
                            height: 0,
                            transition: { duration: 0.5 },
                            onTransitionEnd: () => {
                                console.log(
                                    'Transition ending, scrolling chat container'
                                );
                                if (chatContainer?.current) {
                                    chatContainer.current.scrollTo({
                                        top: chatContainer.current.scrollHeight,
                                        behavior: 'smooth'
                                    });
                                }
                            },
                            onAnimationEnd: () => {
                                console.log(
                                    'Animation ending, scrolling chat container'
                                );
                                if (chatContainer?.current) {
                                    chatContainer.current.scrollTo({
                                        top: chatContainer.current.scrollHeight,
                                        behavior: 'smooth'
                                    });
                                }
                            }
                        }}
                        transition={{ duration: 1 }}
                        className="relative mt-4 max-w-lg mx-auto overflow-y-auto"
                    >
                        <div className="relative p-6 text-left bg-gradient-to-br from-gray-900/95 to-black/95 rounded-lg border border-gray-800 shadow-xl backdrop-blur-sm">
                            <button
                                onClick={() => setIsVisible(false)}
                                className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors"
                            >
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.5, duration: 0.8 }}
                                className="text-center"
                            >
                                <h3 className="text-lg font-semibold text-white mb-2">
                                    🎉 Congratulations on Starting Your Project!
                                </h3>
                                <p className="text-gray-300 mb-4">
                                    Ready to take the next step?
                                </p>
                                <MatchWithDev
                                    matchWithDev={matchWithDev}
                                    setMatchWithDev={setMatch}
                                    matchLoading={matchLoading}
                                    from="chat"
                                />
                            </motion.div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        )
    );
};
