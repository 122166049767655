import { ChatBubbleIcon } from '@radix-ui/react-icons';
import { cubicBezier, motion } from 'framer-motion';

export function GuidedProjectDiscovery() {
    return {
        Icon: ChatBubbleIcon,
        name: 'Guided Product Planning',
        description:
            'Our agent guides you through the discovery phase of your product, asking insightful questions to get you thinking.',
        href: '/auth/?redirectToPath=%2Fprojects',
        cta: 'Get Started',
        className: 'col-span-3 lg:col-span-1',
        background: (
            <div className="absolute flex justify-center border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_40%)] group-hover:scale-105">
                <FeatureCard8 />
            </div>
        )
    };
}

export function FeatureCard8() {
    const variant1 = {
        initial: {
            y: 0,
            scale: 0.97,
            transition: {
                delay: 0,
                duration: 0.2,
                ease: cubicBezier(0.22, 1, 0.36, 1)
            }
        },
        whileHover: {
            y: -45,
            scale: 1,
            boxShadow:
                'rgba(39,127,245,0.15) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px',
            transition: {
                delay: 0,
                duration: 0.2,
                ease: cubicBezier(0.22, 1, 0.36, 1)
            }
        }
    };
    const variant2 = {
        initial: {
            y: 30,
            opacity: 0,
            scale: 0.97,
            transition: {
                delay: 0,
                duration: 0.2,
                ease: cubicBezier(0.22, 1, 0.36, 1)
            }
        },
        whileHover: {
            y: 10,
            opacity: 1,
            scale: 1,
            boxShadow:
                'rgba(245,40,145,0.05) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px',
            transition: {
                delay: 0,
                duration: 0.2,
                ease: cubicBezier(0.22, 1, 0.36, 1)
            }
        }
    };
    const variant3 = {
        initial: {
            x: 30,
            y: 20,
            opacity: 0,
            transition: {
                delay: 0,
                duration: 0.2,
                ease: cubicBezier(0.22, 1, 0.36, 1)
            }
        },
        whileHover: {
            x: 5,
            y: 5,
            opacity: 1,
            boxShadow:
                'rgba(39,245,76,0.15) 0px 20px 70px -10px, rgba(36,42,66,0.04) 0px 10px 24px -8px, rgba(36,42,66,0.06) 0px 1px 4px -1px',
            transition: {
                delay: 0.05,
                duration: 0.2,
                ease: cubicBezier(0.22, 1, 0.36, 1)
            }
        }
    };

    const containerVariants = {
        initial: {},
        whileHover: {
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    return (
        <div className="dark relative h-full w-full transform-gpu rounded-lg bg-white dark:bg-black md:max-h-[500px]">
            <motion.div
                variants={containerVariants}
                initial="initial"
                whileHover="whileHover"
                className="flex h-full w-full cursor-pointer flex-col items-start justify-between"
            >
                <div className="flex h-full w-full cursor-pointer flex-col items-center justify-center gap-y-1 overflow-hidden rounded-t-xl bg-transparent">
                    <div className="relative flex flex-col items-center justify-center gap-y-2 px-10 py-20">
                        <motion.div
                            variants={variant1}
                            className=" relative flex items-start gap-x-2 rounded-lg border border-neutral-400/20 bg-white p-4 shadow-[0px_0px_40px_-25px_rgba(0,0,0,0.25)] dark:border-neutral-800 dark:bg-neutral-900"
                        >
                            <div className="h-7 w-7 px-[3px] py-[5px] rounded-full bg-black">
                                <img
                                    className="h-full rounded-full"
                                    src="/predev.png"
                                    alt="predev"
                                />
                            </div>
                            <div className="w-[calc(100%-3rem)]">
                                <h3 className="text-white font-semibold">
                                    pre.dev
                                </h3>
                                <p className="text-sm text-neutral-500 dark:text-neutral-400">
                                    What happens after a user clicks on an item
                                    in the main feed?
                                </p>
                            </div>
                        </motion.div>
                        <motion.div
                            variants={variant2}
                            className=" absolute inset-10 -bottom-14 z-[3] m-auto flex h-fit items-start gap-x-2 rounded-lg border border-neutral-400/20 bg-white p-4 shadow-[0px_0px_40px_-25px_rgba(0,0,0,0.25)] dark:border-neutral-800 dark:bg-neutral-900"
                        >
                            <div className="w-8 ">
                                <div className="h-6 w-6 rounded-full bg-blue-500">
                                    <img
                                        className="h-full w-full rounded-full object-cover"
                                        src="https://avatar.vercel.sh/christina"
                                        alt="christina"
                                    />
                                </div>
                            </div>
                            <div className="w-[calc(100%-3rem)]">
                                <h3 className="text-white font-semibold">
                                    James
                                </h3>
                                <p className="text-sm text-neutral-500 dark:text-neutral-400">
                                    You are taken to a user's profile page.
                                </p>
                            </div>
                            <motion.p
                                variants={variant3}
                                className="highlighted absolute -bottom-2 right-2 w-fit rounded-full border bg-cyan-500 px-2 py-0.5 text-xs text-white"
                            >
                                sent
                            </motion.p>
                        </motion.div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}
