import { Graph } from './graphTypes';

export function labelNodesWithDepth(graph: Graph): Graph {
    try {
        // Create a copy of the graph to avoid mutating the input
        const labeledGraph = JSON.parse(JSON.stringify(graph));

        // Create a map to store visited nodes and their depths
        const visited = new Map<string, number>();

        // Helper function for BFS traversal
        function bfs(startNodeId: string) {
            const queue: [string, number][] = [[startNodeId, 0]]; // [nodeId, depth]

            while (queue.length > 0) {
                const [currentId, depth] = queue.shift()!;

                // Skip if we've already visited this node
                if (visited.has(currentId)) continue;

                // Mark node as visited and set its depth
                visited.set(currentId, depth);

                // Find all edges where this node is the source
                const outgoingEdges = labeledGraph.edges.filter(
                    edge =>
                        edge.source === currentId || edge.target === currentId
                );

                // Process both source and target nodes for each edge
                for (const edge of outgoingEdges) {
                    if (depth === 0) {
                        console.log({ edge });
                    }
                    // Check target node
                    if (!visited.has(edge.target)) {
                        queue.push([edge.target, depth + 1]);
                    }
                    // Check source node (for bidirectional relationships)
                    if (!visited.has(edge.source)) {
                        queue.push([edge.source, depth + 1]);
                    }
                }
            }
        }

        // Start BFS from the core node
        bfs(labeledGraph.nodes[0].id);

        // Update all nodes with their calculated depths
        labeledGraph.nodes = labeledGraph.nodes.map(node => ({
            ...node,
            depth: visited.get(node.id) ?? 0
        }));

        return labeledGraph;
    } catch (error) {
        return graph;
    }
}
