import * as Tooltip from '@radix-ui/react-tooltip';
import { TrophyIcon, SparklesIcon, StarIcon } from '@heroicons/react/24/solid';
import { CalendarIcon } from '@radix-ui/react-icons';
import { getUrgencyLabel } from '../../../../ProjectDashboard/Budget/Urgency';
import { ClockIcon, UserCircleIcon } from '@heroicons/react/24/outline';
let formatPhoneNumber = str => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        let intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
            ''
        );
    }

    return null;
};
export const Indicators = ({ project }) => {
    return (
        <>
            <PremiumLeadGenTooltip project={project} />
            <SchedulingLinkTooltip project={project} />
            <UrgencyTooltip project={project} />
            <UserProfileTooltip project={project} />
            <SoloTierTooltip project={project} />
        </>
    );
};
const UserProfileTooltip = ({ project }) => {
    const userProfile = project.userProfile;
    const isRevealed = project.leadGenEmailRevealed;
    // if (!userProfile) return null;

    console.log({ userProfile });

    const fields = {
        businessName: 'Company',
        businessType: 'Industry',
        phoneNumber: 'Phone',
        firstName: 'First Name',
        lastName: 'Last Name',
        linkedinURL: 'LinkedIn',
        twitterProfile: 'X/Twitter',
        schedulingLink: 'Scheduling Link',
        businessURL: 'Website',
        jobTitle: 'Job Title'
    };

    const filledFields = Object.entries(fields).filter(
        ([key]) => userProfile?.[key]
    );
    if (filledFields.length === 0) return null;

    return (
        <Tooltip.Provider delayDuration={100}>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <UserCircleIcon className="h-5 w-5 text-blue-500" />
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        className="bg-white p-4 rounded-md shadow-md min-w-[200px]"
                        sideOffset={5}
                    >
                        <div className="space-y-2">
                            <p className="text-sm font-medium text-gray-900 mb-2">
                                Profile Fields
                            </p>
                            <ul className="space-y-1">
                                {filledFields.map(([key, label]) => (
                                    <li
                                        key={key}
                                        className="flex flex-row gap-1 text-sm text-gray-700"
                                    >
                                        <div className="flex flex-row items-center gap-2">
                                            <div className="w-4 h-4 flex items-center justify-center rounded bg-blue-500 border border-blue-500">
                                                <svg
                                                    className="w-3 h-3 text-white"
                                                    viewBox="0 0 12 12"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M3.795 7.096 2 5.301l-.795.794 2.59 2.59L9.5 3.397 8.705 2.6 3.795 7.096z"
                                                    />
                                                </svg>
                                            </div>
                                            {label}
                                        </div>
                                        {isRevealed && (
                                            <div className="ml-2 text-sm text-black font-bold">
                                                {[
                                                    'schedulingLink',
                                                    'linkedinURL',
                                                    'twitterProfile'
                                                ].includes(key) ? (
                                                    <a
                                                        href={userProfile[key]}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-500 hover:underline"
                                                    >
                                                        {userProfile[key]}
                                                    </a>
                                                ) : key === 'phoneNumber' ? (
                                                    formatPhoneNumber(
                                                        userProfile[key]
                                                    )
                                                ) : (
                                                    userProfile[key]
                                                )}
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <Tooltip.Arrow className="fill-white" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};

const UrgencyTooltip = ({ project }) => {
    const urgency = project.urgency;
    if (!urgency) return null;

    const getUrgencyColor = value => {
        if (value <= 25) return 'text-green-400';
        if (value <= 50) return 'text-yellow-400';
        if (value <= 75) return 'text-orange-400';
        return 'text-red-500';
    };

    return (
        <Tooltip.Provider delayDuration={100}>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <ClockIcon
                        className={`h-5 w-5 ${getUrgencyColor(urgency)}`}
                    />
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        className="bg-white p-2 rounded-md shadow-md"
                        sideOffset={5}
                    >
                        <p className="text-sm text-gray-700">
                            Project Urgency: {getUrgencyLabel(urgency)}
                        </p>
                        <Tooltip.Arrow className="fill-white" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};
const SchedulingLinkTooltip = ({ project }) => {
    const schedulingLink = project.schedulingLink;
    const isRevealed = project.leadGenEmailRevealed;

    return (
        <>
            {schedulingLink && (
                <Tooltip.Provider delayDuration={100}>
                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            {isRevealed ? (
                                <a
                                    href={schedulingLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-block"
                                >
                                    <CalendarIcon className="h-5 w-5 text-green-400 hover:text-green-500 transition-colors" />
                                </a>
                            ) : (
                                <CalendarIcon className="h-5 w-5 text-green-400" />
                            )}
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content
                                className="bg-white p-2 rounded-md shadow-md"
                                sideOffset={5}
                            >
                                <p className="text-sm text-gray-700">
                                    {isRevealed ? (
                                        <a
                                            href={schedulingLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 hover:text-blue-800"
                                        >
                                            {schedulingLink}
                                        </a>
                                    ) : (
                                        'This lead has set a scheduling link for you to automatically schedule a meeting with them.'
                                    )}
                                </p>
                                <Tooltip.Arrow className="fill-white" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
            )}
        </>
    );
};

const PremiumLeadGenTooltip = ({ project }) => {
    const premiumLeadGenEnabled = project.premiumLeadGenEnabled;
    return (
        <>
            {premiumLeadGenEnabled && (
                <Tooltip.Provider delayDuration={100}>
                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            <TrophyIcon className="h-5 w-5 text-yellow-400" />
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content
                                className="bg-white p-2 rounded-md shadow-md"
                                sideOffset={5}
                            >
                                <p className="text-sm text-gray-700">
                                    This lead personally spoke to a pre.dev
                                    representative about building their project.
                                </p>
                                <Tooltip.Arrow className="fill-white" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
            )}
        </>
    );
};

const SoloTierTooltip = ({ project }) => {
    const soloTier = project.soloTier;
    if (!soloTier) return null;

    const getTierIcon = () => {
        if (soloTier === 'SoloPlus')
            return <SparklesIcon className="h-5 w-5 text-purple-400" />;
        if (soloTier === 'SoloPremium')
            return <StarIcon className="h-5 w-5 text-indigo-400" />;
        return null;
    };

    const getTierText = () => {
        if (soloTier === 'SoloPlus')
            return 'This user is a Solo Plus Subscriber';
        if (soloTier === 'SoloPremium')
            return 'This user is a Solo Premium Subscriber';
        return '';
    };

    return (
        <Tooltip.Provider delayDuration={100}>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>{getTierIcon()}</Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        className="bg-white p-2 rounded-md shadow-md"
                        sideOffset={5}
                    >
                        <p className="text-sm text-gray-700">{getTierText()}</p>
                        <Tooltip.Arrow className="fill-white" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};
