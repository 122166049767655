import { TAG_COLLEAGUE } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import { addTagToMessage } from '../../reducers/generic/chatHistory';

export const tagColleague =
    ({ enterpriseOrganizationId, projectId, userEmail, comment, messageId }) =>
    async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: TAG_COLLEAGUE,
                variables: {
                    enterpriseOrganizationId,
                    projectId,
                    userEmail,
                    comment,
                    messageId
                },
                fetchPolicy: 'network-only'
            });

            if (data?.tagColleague) {
                dispatch(addTagToMessage({ messageId, userEmail }));
            }

            return data?.tagColleague;
        } catch (error) {
            console.log({ error });
            return error.message;
        }
    };
