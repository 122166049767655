import React, { useRef, useEffect, useState } from 'react';

const initialPartnerList = [
    {
        name: 'Wharton Cypher',
        image: 'https://1000logos.net/wp-content/uploads/2018/01/Wharton-logo.png',
        link: 'https://cypher.wharton.upenn.edu/'
    },
    {
        name: 'Venture Miami',
        image: 'https://pbs.twimg.com/profile_images/1524126576836808704/dv1lJev4_400x400.jpg',
        link: 'https://venturemiami.com/'
    },
    {
        name: 'Moonbeam',
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6836.png',
        link: 'https://moonbeam.network/'
    },
    {
        name: 'Linkeen',
        image: '/linkeen.svg',
        link: 'https://linkeen.com/'
    },
    {
        name: 'Void Software',
        image: '/void_logo.jpeg',
        link: 'https://void.pt/'
    },
    {
        name: 'Azumo',
        image: '/azumo.svg',
        link: 'https://azumo.com/'
    },
    {
        name: 'Band of Coders',
        image: '/band_of_coders.webp',
        link: 'https://www.bandofcoders.com/'
    }
];
export const Partners = () => {
    const scrollContainerRef = useRef(null);
    const [partnerList, setPartnerList] = useState(initialPartnerList);

    const loadMorePartners = () => {
        // Simulate fetching more partners by duplicating the current list
        setPartnerList(prevPartners => [
            ...prevPartners,
            ...initialPartnerList
        ]);
    };

    const [isAutoScrolling, setIsAutoScrolling] = useState(null);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        const handleScroll = () => {
            // Check if the user has scrolled to the end of the list

            if (
                scrollContainer.scrollLeft + scrollContainer.offsetWidth >=
                scrollContainer.scrollWidth - 100
            ) {
                loadMorePartners();
            }
        };

        let autoScrollInterval;
        const startAutoScroll = () => {
            if (isAutoScrolling) return;
            setIsAutoScrolling(true);
            autoScrollInterval = setInterval(() => {
                scrollContainer.scrollLeft += 1;
            }, 10);
        };

        const stopAutoScroll = () => {
            setIsAutoScrolling(false);
            clearInterval(autoScrollInterval);
        };

        scrollContainer.addEventListener('mouseenter', stopAutoScroll);
        scrollContainer.addEventListener('mouseleave', startAutoScroll);

        startAutoScroll();

        scrollContainer.addEventListener('scroll', handleScroll);

        return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
        };
    }, [scrollContainerRef]);

    return (
        <section className="bg-gray-900 team p-10">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-100 dark:text-white">
                        Partners
                    </h2>
                    <p className="font-light text-gray-400 text-lg">
                        We are proud to partner with the following organizations
                        to help the world accelerate idea to development.
                    </p>
                </div>
                <div
                    className="mt-16 overflow-scroll relative"
                    ref={scrollContainerRef}
                >
                    <div className="w-full flex gap-x-8">
                        {partnerList.map((partner, index) => (
                            <a
                                key={index}
                                href={partner.link}
                                className="flex flex-col items-center justify-center min-w-[200px] h-[200px] rounded-lg border border-gray-200 bg-gray-100 p-4 text-center text-gray-500 hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300 cursor-pointer"
                            >
                                <img
                                    src={partner.image}
                                    alt={partner.name}
                                    className="max-h-[140px] h-full w-full"
                                    style={{
                                        objectFit: 'contain'
                                    }}
                                />
                                <p className="mt-2 text-sm font-medium text-gray-900 dark:text-white">
                                    {partner.name}
                                </p>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};
