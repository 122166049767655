import { TRIGGER_LEAD_GEN_MEETING } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import { updateProject } from '../../reducers/generic/projects';
import { fetchProjectSuccess } from '../../reducers/generic/project';

export const triggerLeadGenMeeting =
    ({ projectId, enterpriseOrganizationId }) =>
    async dispatch => {
        try {
            const { data } = await client.mutate({
                mutation: TRIGGER_LEAD_GEN_MEETING,
                variables: { projectId, enterpriseOrganizationId },
                fetchPolicy: 'network-only'
            });

            if (data?.triggerLeadGenMeeting) {
                dispatch(updateProject(data.triggerLeadGenMeeting));
                dispatch(fetchProjectSuccess(data.triggerLeadGenMeeting));
                return data.triggerLeadGenMeeting;
            }

            return true;
        } catch (error) {
            console.error('Error triggering lead gen meeting:', error);
            return false;
        }
    };
