import { useState } from 'react';

const tabs = [
    { name: 'Code', href: '#', current: true },
    { name: 'Preview', href: '#', current: false }
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const Tabs = ({ currentTab, setCurrentTab }) => {
    const handleTabChange = tabName => {
        setCurrentTab(tabName);
    };

    return (
        <div className="bg-gray-900">
            <div className="sm:hidden max-w-md">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    value={currentTab}
                    onChange={e => handleTabChange(e.target.value)}
                    className="block w-full rounded-md border-gray-700 bg-gray-800 text-white focus:border-blue-500 focus:ring-blue-500"
                >
                    {tabs.map(tab => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <nav
                    aria-label="Tabs"
                    className="isolate flex divide-x divide-gray-700 rounded-lg shadow max-w-xl"
                >
                    {tabs.map((tab, tabIdx) => (
                        <a
                            key={tab.name}
                            href={tab.href}
                            onClick={e => {
                                e.preventDefault();
                                handleTabChange(tab.name);
                            }}
                            aria-current={
                                currentTab === tab.name ? 'page' : undefined
                            }
                            className={classNames(
                                currentTab === tab.name
                                    ? 'text-blue-500'
                                    : 'text-gray-400 hover:text-gray-300',
                                tabIdx === 0 ? 'rounded-l-lg' : '',
                                tabIdx === tabs.length - 1
                                    ? 'rounded-r-lg'
                                    : '',
                                'group relative min-w-0 flex-1 overflow-hidden bg-gray-800 px-3 py-3 text-center text-sm font-medium hover:bg-gray-700 focus:z-10'
                            )}
                        >
                            <span>{tab.name}</span>
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    currentTab === tab.name
                                        ? 'bg-blue-600'
                                        : 'bg-transparent',
                                    'absolute inset-x-0 bottom-0 h-1'
                                )}
                            />
                        </a>
                    ))}
                </nav>
            </div>
        </div>
    );
};
