import { REMOVE_USER_FROM_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import {
    fetchEnterpriseUsersRequest,
    updateNumUsersLeft,
    fetchEnterpriseUsersSuccess,
    fetchEnterpriseUsersFailure
} from '../../reducers/enterprise/enterpriseUsers';

export const removeUserFromEnterpriseOrganization =
    (userEmail, enterpriseOrganizationId) => async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: REMOVE_USER_FROM_ENTERPRISE_ORGANIZATION,
                variables: {
                    userEmail: userEmail,
                    enterpriseOrganizationId: enterpriseOrganizationId
                },
                fetchPolicy: 'network-only'
            });

            dispatch(
                fetchEnterpriseUsersSuccess(
                    data.removeUserFromEnterpriseOrganization.enterpriseUsers
                )
            );

            dispatch(
                updateNumUsersLeft(
                    data.removeUserFromEnterpriseOrganization.numUsersLeft
                )
            );
            return 'Successfully removed user from organization.';
        } catch (error) {
            console.log({ error });
            dispatch(fetchEnterpriseUsersFailure(error));
            return error.message;
        }
    };
