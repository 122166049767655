import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { ChooseOrganization } from './ChooseOrganization';
import { ClientProjects } from './ClientProjects';
import { EnterpriseUsers } from './EnterpriseUsers';
import { Billing } from './Billing';
import { WidgetManagement } from './WidgetManagement';
import { UpgradeToPremium } from './UpgradeToPremium';
import { KnowledgeManagement } from './KnowledgeManagement';
import { useEffect, useRef } from 'react';
import { salesCalendlyLink } from '../../../../constants';
import { LeadGeneration } from './LeadGeneration';

export const SidebarItems = () => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const divRef = useRef(null);

    useEffect(() => {
        const resizeHandler = () => {
            if (divRef.current) {
                const windowHeight = window.innerHeight;
                const topOffset = divRef.current.offsetTop;
                const liElementHeight = 150; // Set the height of your li element here
                const availableHeight =
                    windowHeight - topOffset - liElementHeight;
                divRef.current.style.maxHeight = `${availableHeight}px`;
            }
        };

        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        setTimeout(() => {
            resizeHandler(); // Call it after 20 milliseconds to set the initial height
        }, 20);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [enterpriseOrganization]);

    return (
        <>
            <ChooseOrganization />

            <li className="flex items-center">
                <a
                    href={salesCalendlyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white hover:underline pt-4 text-center w-full"
                >
                    Request Demo
                </a>
            </li>
            <div className=" py-1" />
            <div
                ref={divRef}
                className="my-4 h-full space-y-4 overflow-y-auto px-2"
            >
                <UpgradeToPremium />

                <ClientProjects />
                <LeadGeneration />
                <KnowledgeManagement />
                <WidgetManagement />
                <EnterpriseUsers />

                {enterpriseOrganization && enterpriseOrganization.isAdmin && (
                    <Billing />
                )}
            </div>
        </>
    );
};
