import { FeedbackFish } from '@feedback-fish/react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

export const FeedBackButton = ({ textColor = 'white' }) => {
    const { accessTokenPayload } = useSessionContext();
    const email = accessTokenPayload?.email;

    return (
        <FeedbackFish projectId="66a52eadef3e7f" userId={email}>
            <button
                className={`text-${textColor} opacity-75 font-normal text-md opacity-100 hover:opacity-100`}
            >
                Give us feedback
            </button>
        </FeedbackFish>
    );
};
