import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modalOpen: false,
    profile: null,
    subscriptionLinks: null
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
        setSubscriptionLinks: (state, action) => {
            state.subscriptionLinks = action.payload;
        },
        setProfileModalOpen: (state, action) => {
            state.modalOpen = action.payload;
        }
    }
});

export const { setProfile, setSubscriptionLinks, setProfileModalOpen } =
    profileSlice.actions;

export const selectProfile = state => state.profile.profile;
export const selectSubscriptionLinks = state => state.profile.subscriptionLinks;
export const selectProfileModalOpen = state => state.profile.modalOpen;
export default profileSlice.reducer;
