import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Progress } from '../../ProjectDashboard/ArchitectureGraph/Progress';
import { QuestionExperience } from './question_experience';
import { ProjectDashboard } from '../../ProjectDashboard';
import { motion } from 'framer-motion';
import TextShimmer from '../../Components/magicui/text-shimmer';
import { useDispatch } from 'react-redux';
import { setShowRequestModal } from '../../../redux/reducers/generic/project';
import RequestModal from '../../ProjectDashboard/RequestMeeting/Requestmodal';
import trackEvent from '../../../helpers/trackEvent';

export const HeroExperience = () => {
    const [questionIndex, setQuestionIndex] = useState(0);
    const { projectId } = useParams();
    const [tempProjectId, setTempProjectId] = useState(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Check if scrolled to top and focus input after slight delay
        setTimeout(() => {
            if (window.scrollY === 0) {
                const mainInput = document.getElementById('mainInput');
                if (mainInput) {
                    // mainInput.focus();
                }
            }
        }, 2000);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const openRequestModal = () => {
        try {
            dispatch(setShowRequestModal(true));
        } catch (error) {
            console.log({ error });
        }
    };

    return (
        <>
            {!projectId && !isMobile && (
                <div className="pt-16 absolute top-[10px] left-0 ml-2">
                    <Progress chatLength={questionIndex} total={2} />
                </div>
            )}
            <div className="hero-section pb-10">
                {!projectId && (
                    <div className="flex flex-col gap-y-3 absolute top-[65px] justify-center w-full mb-4">
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 2, delay: 0 }}
                            onClick={e => {
                                try {
                                    trackEvent('landingMatchedWithDev');
                                    openRequestModal();
                                } catch (error) {
                                    console.log({ error });
                                }
                            }}
                            className="mt-4 backdrop-filter-[12px] inline-flex py-2 items-center justify-between rounded-full border border-gray-500 bg-blue-500/20 px-3 text-xs text-white dark:text-black transition-all ease-in hover:cursor-pointer hover:bg-blue-500/30 group gap-1 mx-auto"
                        >
                            <TextShimmer className="inline-flex items-center justify-center text-white text-md sm:text-md">
                                ✨ Match Me with a Dev Agency Now!
                            </TextShimmer>
                        </motion.div>
                    </div>
                )}
                <main className="max-w-screen-xl min-w-0 flex-auto lg:static lg:max-h-full lg:overflow-visible">
                    <section
                        className="mb-8 pb-16 mx-auto flex sm:pb-10 lg:pb-24"
                        style={{
                            marginTop: projectId ? '0px' : '70px'
                        }}
                    >
                        {!projectId ? (
                            <section className="pb-16 mx-auto flex max-w-8xl flex-col overflow-hidden lg:px-20 lg:pb-24">
                                <div className="px-4 pt-20 mb-20">
                                    <QuestionExperience
                                        questionIndex={questionIndex}
                                        tempProjectId={tempProjectId}
                                        setTempProjectId={setTempProjectId}
                                        setQuestionIndex={setQuestionIndex}
                                    />
                                </div>
                            </section>
                        ) : (
                            <div
                                className="w-full flex flex-col "
                                style={{
                                    height: isMobile
                                        ? 'calc(100vh - 135px)'
                                        : 'calc(100vh - 68px)',
                                    marginTop: '58px'
                                }}
                            >
                                <ProjectDashboard />
                            </div>
                        )}
                    </section>
                </main>
                <RequestModal />
            </div>
        </>
    );
};
