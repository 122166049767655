import { REQUEST_ENTERPRISE_MEETING } from '../../../api/graphQL';
import { clientWithAPIKey } from '../../../api/client';
import { setRequestingMeeting } from '../../reducers/generic/project';

export const requestEnterpriseMeeting =
    projectId => async (dispatch, getState) => {
        dispatch(setRequestingMeeting(true));
        try {
            await clientWithAPIKey(getState().enterpriseWidget.apiKey).mutate({
                mutation: REQUEST_ENTERPRISE_MEETING,
                variables: {
                    apiKey: getState().enterpriseWidget.apiKey,
                    projectId: projectId
                }
            });
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }
        dispatch(setRequestingMeeting(false));
    };
