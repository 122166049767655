import { useNavigate } from 'react-router-dom';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { useProjects } from '../../../context-providers/Projects';
import { soloTrialLimit } from '../../../constants';
import { useDispatch } from 'react-redux';
import { toggleSubscribeModal } from '../../../redux/reducers/generic/billing';

const CreateNewProject = ({ closeSidebar }) => {
    const navigate = useNavigate();
    const { subscription } = useSubscriptionPayment();
    let { projects } = useProjects();
    const dispatch = useDispatch();

    const handleNewProjectSubmit = async e => {
        e.preventDefault();
        if (
            projects.length >= soloTrialLimit &&
            !subscription.loading &&
            subscription.status !== 'active'
        ) {
            dispatch(toggleSubscribeModal(true));
        } else navigate(`/projects/new`);
        if (closeSidebar) {
            closeSidebar();
        }
    };

    return (
        <button
            onClick={handleNewProjectSubmit}
            class="flex w-full flex-row-reverse justify-between rounded-lg bg-white border border-1 border-slate-600 text-slate-600 p-4 text-sm font-medium text-slate-200 transition-colors duration-200 hover:bg-slate-200 focus:outline-none "
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 5l0 14"></path>
                <path d="M5 12l14 0"></path>
            </svg>
            <span>New Project</span>
        </button>
    );
};

export default CreateNewProject;
