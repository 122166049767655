import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSignedUploadURL } from '../../../../redux/actions/solo/getSignedUploadURL';
import { addFileToKnowledgeBase } from '../../../../redux/actions/enterprise/addFileToKnowledgeBase';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';
import { selectKnowledgeBaseFiles } from '../../../../redux/reducers/enterprise/enterpriseOrganization';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

const UploadFile = () => {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isUploading, setUploading] = useState(false);
    const { enterpriseOrganizationId } = useParams();
    let fileList = useSelector(selectKnowledgeBaseFiles);
    fileList = fileList.filter(file => file.type === 'hours');
    const { isSubbed } = useSubscriptionPayment();

    const handleFileChange = async e => {
        if (!isSubbed) {
            toast.error(
                'Please subscribe to Enterprise Advanced to update knowledge base.'
            );
            return;
        }
        try {
            const file = e.target.files[0];
            setFile(file);
            if (!file) return;
            setUploading(true);

            // Randomize filename
            let uniqueFilename = uuidv4() + file.name;

            if (file.size > 50 * 1024 * 1024) {
                toast.error('File size should be less than 50MB');
                setUploading(false);
                return;
            }

            // 1. Fetch the signed URL
            const signedUrl = await dispatch(
                getSignedUploadURL(uniqueFilename)
            );

            // 2. Upload the file using the signed URL
            await new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('PUT', signedUrl, true);
                xhr.upload.onprogress = function (event) {
                    if (event.lengthComputable) {
                        const percentComplete =
                            (event.loaded / event.total) * 100;
                        setProgress(percentComplete.toFixed(2));
                        console.log(
                            `File upload progress: ${percentComplete.toFixed(
                                2
                            )}%`
                        );
                    }
                };
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        console.log('File uploaded successfully.');
                        resolve();
                    } else {
                        console.error('File upload failed.');
                        reject(new Error('File upload failed.'));
                    }
                };
                xhr.onerror = function () {
                    console.error('File upload failed.');
                    reject(new Error('File upload failed.'));
                };
                xhr.send(file);
            });

            // 3. Store the key (filename or path in S3) in a state
            const message = await dispatch(
                addFileToKnowledgeBase(
                    enterpriseOrganizationId,
                    file.name,
                    uniqueFilename,
                    'hours'
                )
            );

            setUploading(false);
            setFile(null);
            document.getElementById('hours_upload').value = '';

            if (message.includes('Error')) return toast.error(message);

            toast.success(message);
        } catch (err) {
            console.log(err);
            toast.error('Error uploading file');
        }
    };

    return (
        fileList.length < 10 && (
            <div className="mt-2 flex flex-row justify-center items-center w-full">
                <div className="flex flex-row gap-4 items-center w-full">
                    <input
                        onChange={handleFileChange}
                        accept=".c,.cpp,.csv,.docx,.html,.java,.json,.md,.pdf,.php,.pptx,.py,.rb,.tex,.txt,.css,.jpeg,.jpg,.js,.gif,.png,.tar,.ts,.xlsx,.xml,.zip"
                        className={`block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${
                            isUploading ? 'hidden' : ''
                        }`}
                        id="hours_upload"
                        type="file"
                    />
                    {isUploading && (
                        <div className="w-full flex flex-col gap-y-1">
                            <div className="w-full bg-blue-200 rounded-lg h-10 relative">
                                <div
                                    className="bg-blue-500 h-10 rounded-lg"
                                    style={{ width: `${progress}%` }}
                                >
                                    <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-xs">
                                        {progress}% - Uploading{' '}
                                        {file &&
                                            file.name.substring(0, 20) +
                                                (file.name.length > 20
                                                    ? '...'
                                                    : '')}
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="ml-2"></span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default UploadFile;
