import gql from 'graphql-tag';
import { enterprise_advanced_config, message } from '../helper';

export const GET_ENTERPRISE_ORGANIZATION_BY_API_KEY = gql`
	query enterpriseOrganizationByApiKey($apiKey: String!) {
		enterpriseOrganizationByApiKey(apiKey: $apiKey) {
			name
			_id
			subscriptionStatus {
				status
			}
			${enterprise_advanced_config}
		}
	}
`;

export const GET_PROJECT_BY_ENTERPRISE_WIDGET = gql`
	query projectByEnterpriseOrganizationWidget(
		$apiKey: String!
		$projectId: ID!
	) {
		projectByEnterpriseOrganizationWidget(
			projectId: $projectId
			apiKey: $apiKey
		) {
			_id
			name
			type
			created
			meetingRequested
			chatHistory {
				${message}
			}
			sow_mvp_up_to_date
            sow_custom_up_to_date
			recommendedTechStack {
				name
				reason
			}
		}
	}
`;
