import { useDispatch } from 'react-redux';
import { toggleAdvancedConfiguration } from '../../../../redux/reducers/enterprise/enterpriseOrganization';
import { AdvancedConfigModal } from './AdvancedConfigModal';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { toast } from 'sonner';

export const AdvancedConfig = () => {
    const dispatch = useDispatch();
    const { isSubbed } = useSubscriptionPayment();

    return (
        <>
            <AdvancedConfigModal />
            <button
                onClick={() => {
                    if (!isSubbed) {
                        toast.error(
                            'Please subscribe to Enterprise Premium to access advanced configuration.'
                        );
                        return;
                    }
                    dispatch(toggleAdvancedConfiguration());
                }}
                type="button"
                className="items-center text-blue-600 text-sm font-semibold hover:text-blue-500"
            >
                <Cog8ToothIcon className="h-6" />
            </button>
        </>
    );
};
