import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    billingModalOpen: false,
    subscribeModalOpen: false,
    subscribeBannerOpen: false
};

export const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        toggleBillingModalOpen: (state, action) => {
            let override = action.payload !== undefined;
            state.billingModalOpen = override
                ? action.payload
                : !state.billingModalOpen;
        },
        toggleSubscribeModal: (state, action) => {
            let override = action.payload !== undefined;

            state.subscribeModalOpen = override
                ? action.payload
                : !state.subscribeModalOpen;
        },
        toggleSubscribeBanner: (state, action) => {
            let override = action.payload !== undefined;
            state.subscribeBannerOpen = override
                ? action.payload
                : !state.subscribeBannerOpen;
        }
    }
});
export const {
    toggleBillingModalOpen,
    toggleSubscribeModal,
    toggleSubscribeBanner
} = billingSlice.actions;
export const selectBillingModalOpen = state => state.billing.billingModalOpen;
export const selectSubscribeModal = state => state.billing.subscribeModalOpen;
export const selectSubscribeBanner = state => state.billing.subscribeBannerOpen;

export default billingSlice.reducer;
