import React, { useState, useEffect, useRef } from 'react';
import { HiPlus, HiX, HiInformationCircle } from 'react-icons/hi';
import techStackNames from './tech_stack_names.json';
import { useDispatch } from 'react-redux';
import { addUserSkillToEnterpriseOrganization } from '../../../../redux/actions/enterprise/addUserSkillToEnterpriseOrganization';
import { toast } from 'sonner';
import { Spinner } from '../../../Components/Spinner';

export const AddSkill = ({ enterpriseOrganizationId, isAdmin, user }) => {
    const dispatch = useDispatch();
    const [isAdding, setIsAdding] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [displayedSuggestions, setDisplayedSuggestions] = useState([]);
    const [page, setPage] = useState(1);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showLevelInfo, setShowLevelInfo] = useState(false);
    const [newSkill, setNewSkill] = useState({ title: '', level: 8 });
    const [isLoading, setIsLoading] = useState(false);
    const suggestionsRef = useRef(null);

    const ITEMS_PER_PAGE = 5;

    const handleAddSkill = async () => {
        if (newSkill.title) {
            setIsLoading(true);
            try {
                const response = await dispatch(
                    addUserSkillToEnterpriseOrganization(
                        user.userEmail,
                        enterpriseOrganizationId,
                        newSkill
                    )
                );
                toast(response);
                setNewSkill({ title: '', level: 8 });
            } catch (error) {
                toast.error('Failed to add skill. Please try again.');
            } finally {
                setIsLoading(false);
                setIsAdding(false);
            }
        }
    };

    useEffect(() => {
        if (newSkill.title) {
            const filteredSuggestions = techStackNames.filter(({ name }) =>
                name.toLowerCase().includes(newSkill.title.toLowerCase())
            );
            setSuggestions(filteredSuggestions.map(({ name }) => name));
            setPage(1);
            setShowSuggestions(true);
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    }, [newSkill.title]);

    useEffect(() => {
        setDisplayedSuggestions(suggestions.slice(0, page * ITEMS_PER_PAGE));
    }, [suggestions, page]);

    const handleSkillChange = value => {
        setNewSkill({ ...newSkill, title: value });
    };

    const handleScroll = () => {
        if (suggestionsRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                suggestionsRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                setPage(prevPage => prevPage + 1);
            }
        }
    };

    const handleCancel = () => {
        setIsAdding(false);
        setNewSkill({ title: '', level: 8 });
    };

    const handleSuggestionClick = suggestion => {
        handleSkillChange(suggestion);
        setTimeout(() => {
            setShowSuggestions(false);
        }, 50);
    };

    const getSliderColor = level => {
        if (level <= 3) return 'accent-red-500';
        if (level <= 6) return 'accent-yellow-500';
        if (level <= 8) return 'accent-green-500';
        return 'accent-blue-500';
    };

    if (isAdding) {
        return (
            <div className="inline-flex flex-col items-start bg-gray-100 text-gray-800 text-sm font-medium p-3 rounded-lg">
                <div className="relative w-full">
                    <input
                        type="text"
                        value={newSkill.title}
                        onChange={e => handleSkillChange(e.target.value)}
                        className="w-full  border border-gray-300 rounded-md focus:outline-none"
                        placeholder="Enter skill name"
                        disabled={isLoading}
                    />
                    {showSuggestions && displayedSuggestions.length > 0 && (
                        <ul
                            ref={suggestionsRef}
                            onScroll={handleScroll}
                            className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg max-h-40 overflow-y-auto"
                        >
                            {displayedSuggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() =>
                                        handleSuggestionClick(suggestion)
                                    }
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="flex items-center mt-2">
                    <input
                        type="range"
                        min="1"
                        max="10"
                        value={newSkill.level}
                        onChange={e =>
                            setNewSkill({
                                ...newSkill,
                                level: parseInt(e.target.value)
                            })
                        }
                        className={`w-32 ${getSliderColor(newSkill.level)}`}
                        disabled={isLoading}
                    />
                    <span className="ml-2 w-8 text-center">
                        {newSkill.level}
                    </span>
                    <HiInformationCircle
                        className="ml-2 text-gray-500 cursor-pointer"
                        onMouseEnter={() => setShowLevelInfo(true)}
                        onMouseLeave={() => setShowLevelInfo(false)}
                    />
                    {showLevelInfo && (
                        <div className="absolute mt-1 p-2 bg-white border border-gray-300 rounded shadow-lg z-10">
                            <p>Skill level explanation:</p>
                            <ul className="list-disc pl-5 text-xs">
                                <li>1-3: Beginner</li>
                                <li>4-6: Intermediate</li>
                                <li>7-8: Advanced</li>
                                <li>9-10: Expert</li>
                            </ul>
                        </div>
                    )}
                </div>
                <div className="flex justify-between w-full mt-2">
                    <button
                        onClick={() => {
                            handleAddSkill();
                        }}
                        className="px-3 py-1 gap-x-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-300 flex items-center"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <>
                                <Spinner w="4" h="4" className="mr-2" />
                                Adding...
                            </>
                        ) : (
                            'Add'
                        )}
                    </button>
                    {!isLoading && (
                        <button
                            onClick={handleCancel}
                            className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-300"
                        >
                            Cancel
                        </button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <button
            onClick={() => setIsAdding(true)}
            className="inline-flex items-center bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-md hover:bg-blue-200 transition-colors duration-300"
        >
            <HiPlus className="mr-2" /> Add Skill
        </button>
    );
};
