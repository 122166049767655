import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false
};

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        toggleSidebar: state => {
            state.isOpen = !state.isOpen;
        },
        setSidebarOpen: (state, action) => {
            state.isOpen = action.payload;
        }
    }
});

export const { toggleSidebar, setSidebarOpen } = sidebarSlice.actions;
export const selectSidebarOpen = state => state.sidebar.isOpen;

export default sidebarSlice.reducer;
