import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from '../../../Components/Spinner';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { getSignedUploadURL } from '../../../../redux/actions/solo/getSignedUploadURL';
import { v4 as uuid } from 'uuid';
import { updateEnterpriseOrganizationStringField } from '../../../../redux/actions/enterprise/updateEnterpriseOrganizationStringField';
import { toast } from 'sonner';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export const ImageField = ({ field, title }) => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const dispatch = useDispatch();
    const [fileKey, setFileKey] = useState(null);
    const [isUploading, setUploading] = useState(false);
    const image = enterpriseOrganization?.[field];
    const { isSubbed } = useSubscriptionPayment();

    useEffect(() => {
        setFileKey(image);
    }, [image]);

    const handleImageRemoval = async () => {
        dispatch(
            updateEnterpriseOrganizationStringField(
                enterpriseOrganization._id,
                field,
                null
            )
        );
        setFileKey(null);
    };

    const handleFileChange = async e => {
        if (!isSubbed) {
            toast.error(
                'Please subscribe to Enterprise Advanced to upload images'
            );
            return;
        }
        const file = e.target.files[0];
        if (!file) return;

        // Randomize the filename
        const extension = file.name.split('.').pop();
        const newName = `${uuid()}.${extension}`;

        // Create a new File object with the new name
        const newFile = new File([file], newName, { type: file.type });

        if (file.size > 20 * 1024 * 1024) {
            alert('File is too large. Please upload a file smaller than 20MB.');
            return;
        }

        setUploading(true);

        // 1. Fetch the signed URL
        const signedUrl = await dispatch(
            getSignedUploadURL(newFile.name, true)
        );

        // 2. Upload the file using the signed URL
        await fetch(signedUrl, {
            method: 'PUT',
            body: newFile
        });
        setUploading(false);
        const s3Url =
            'https://logosbucketpredev.s3.us-east-2.amazonaws.com/' +
            newFile.name;
        setFileKey(s3Url);

        dispatch(
            updateEnterpriseOrganizationStringField(
                enterpriseOrganization._id,
                field,
                s3Url
            )
        );
    };
    return (
        <div className="mt-2 flex flex-col justify-between gap-2 rounded-lg border border-dashed border-gray-900/25 px-6 py-4 w-full text-center items-center">
            <div className="w-full">
                <p className="text-sm leading-5 text-black text-center">
                    {title}
                </p>
                <hr className="my-2" />
            </div>
            {isUploading ? (
                <Spinner w={'20'} h={'20'} />
            ) : fileKey ? (
                <img
                    src={fileKey}
                    className="rounded max-h-20"
                    aria-hidden="true"
                />
            ) : (
                <PhotoIcon
                    className="h-20 w-20 text-gray-300"
                    aria-hidden="true"
                />
            )}

            <div className="flex flex-col gap-2 items-center justify-center">
                <div className="flex text-sm w-full leading-6 text-gray-600">
                    <label className="w-full relative cursor-pointer rounded-md border border-blue-400 hover:border-blue-500 focus:border-blue-600 items-center justify-center font-semibold text-blue-600 focus-within:outline-none  hover:text-blue-500">
                        <span className="text-center w-full p-4">
                            {fileKey ? 'Change Image' : 'Upload Image'}
                        </span>
                        <input
                            onChange={handleFileChange}
                            accept="image/*"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                        />
                    </label>
                </div>
                {fileKey && (
                    <div className="flex text-sm w-full leading-6 text-gray-600">
                        <button
                            onClick={handleImageRemoval}
                            className="w-full relative cursor-pointer rounded-md border border-red-400 hover:border-red-500 focus:border-red-600 items-center justify-center font-semibold text-red-600 focus-within:outline-none hover:text-red-500"
                        >
                            <span className="text-center w-full">
                                Remove Image
                            </span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
