import { useSelector } from 'react-redux';
import {
    selectProjectId,
    selectRecommendedTechStack,
    selectRecommendedTechStackLoading,
    selectTechStackVersion
} from '../../../redux/reducers/generic/project';
import { Spinner } from '../../Components/Spinner';
import { TechStackVersions } from './TechStackVersions';
import { TechStackItem } from './TechStackitem';
import { AddTeckStackitem } from './AddTeckStackItem';
import ThumbsUpDown from '../../Components/ThumbsUpDown';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';

export function TechStackTable() {
    const projectTechStack = useSelector(selectRecommendedTechStack);
    let recommendedStackLoading = useSelector(
        selectRecommendedTechStackLoading
    );
    let techStack = projectTechStack;

    const projectId = useSelector(selectProjectId);

    const techStackVersion = useSelector(selectTechStackVersion);
    const { isShare } = useSubscriptionPayment();

    return (
        <div className="mt-4 flex flex-col items-center justify-center gap-4">
            {!recommendedStackLoading && !isShare && <AddTeckStackitem />}

            <table className="max-w-lg h-full rounded-lg w-full text-xs md:text-sm text-left text-gray-500 h-full md:mx-4 h-full flex-grow mb-8">
                <thead className="rounded-lg text-xs sticky top-0 pt-1 text-gray-700 bg-gray-200">
                    <tr className="uppercase">
                        <td scope="col" colSpan="2" className="px-6 py-3 ">
                            <div className="w-full flex flex-row justify-between">
                                <div className="flex gap-x-2 items-center">
                                    {recommendedStackLoading && (
                                        <Spinner w="4" />
                                    )}
                                    Technology Stack
                                </div>
                                <ThumbsUpDown
                                    id={`${projectId}-techstack-${techStackVersion}`}
                                    metadata={['techstack']}
                                    baseColor="gray-500"
                                />
                                <TechStackVersions />
                            </div>
                        </td>
                    </tr>
                </thead>

                <tbody className="sm:rounded-lg dark mx-auto">
                    {techStack.map((tech, idx) => (
                        <TechStackItem
                            key={idx}
                            idx={idx}
                            tech={tech}
                            isShare={isShare}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}
