import { toast } from 'sonner';
import { client } from '../../../api/client';
import { TRANSFER_PROJECT } from '../../../api/graphQL/enterprise/mutator';
import { removeProject } from '../../reducers/generic/projects';

export const transferProject =
    ({ projectId, fromEnterpriseOrganizationId, toEnterpriseOrganizationId }) =>
    async (dispatch, getState) => {
        try {
            let { data } = await client.mutate({
                mutation: TRANSFER_PROJECT,
                variables: {
                    projectId,
                    fromEnterpriseOrganizationId,
                    toEnterpriseOrganizationId
                }
            });

            dispatch(removeProject(projectId));

            if (data.transferProject) {
                toast.success('Project transferred successfully');
            }

            return true;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
            toast.error('Error transferring project');
            return false;
        }
    };
