import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import * as Tooltip from '@radix-ui/react-tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { selectProjectsTotal } from '../../../redux/reducers/generic/projects';
import { motion } from 'framer-motion';

export const Title = () => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const totalProjects = useSelector(selectProjectsTotal);

    return (
        <div className="py-4">
            <div className="min-w-0 flex-1">
                <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate flex items-center">
                    <Tooltip.Provider delayDuration={100}>
                        <Tooltip.Root>
                            <Tooltip.Trigger asChild>
                                <span className="flex items-center cursor-help">
                                    Lead Generation
                                    <InformationCircleIcon className="h-5 w-5 ml-2 text-black" />
                                </span>
                            </Tooltip.Trigger>
                            <Tooltip.Portal>
                                <Tooltip.Content
                                    className="max-w-md p-4 bg-white shadow-lg rounded-lg border border-gray-200"
                                    sideOffset={5}
                                >
                                    <LeadGenExplainerText />
                                    <Tooltip.Arrow className="fill-white" />
                                </Tooltip.Content>
                            </Tooltip.Portal>
                        </Tooltip.Root>
                    </Tooltip.Provider>
                    {totalProjects ? (
                        <div className="ml-4 overflow-hidden p-1">
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    scale: 0.5,
                                    rotate: -10
                                }}
                                animate={{ opacity: 1, scale: 1, rotate: 0 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 260,
                                    damping: 20,
                                    duration: 0.6
                                }}
                                whileHover={{ scale: 1.05, rotate: 2 }}
                                whileTap={{ scale: 0.95 }}
                                className="px-4 py-2 bg-gradient-to-r from-gray-900 to-gray-700 rounded-lg text-white text-xs font-bold shadow-xl border-2 border-gray-600 flex items-center space-x-2"
                            >
                                <span className="animate-pulse">🚀</span>
                                <span>{totalProjects} Projects Available</span>
                            </motion.div>
                        </div>
                    ) : null}
                </h1>
            </div>
        </div>
    );
};
export const LeadGenExplainerText = () => {
    return (
        <>
            <ul className="space-y-4 text-base text-gray-700">
                <li className="mt-4">
                    🔍 <strong>Browse</strong> curated projects from users who
                    have created a pre.dev account specifically to scope out
                    their project needs
                </li>
                <hr className="my-2 border-t border-gray-300" />
                <li>
                    🤝 <strong>Reveal</strong> potential clients' contact
                    details by using credits to connect and grow your business
                </li>
                <hr className="my-2 border-t border-gray-300" />
                <li>
                    📧 <strong>Introduce</strong> yourself effortlessly as
                    pre.dev sends an introduction email between you and the
                    users whose contact details you've revealed
                </li>
                <hr className="my-2 border-t border-gray-300" />
            </ul>
            <p className="mt-3 text-xs text-gray-500 italic">
                Note: pre.dev provides quality leads, but project acquisition
                depends on your follow-up efforts. Projects are removed from the
                list once they are successfully matched with an agency.
            </p>
        </>
    );
};
