import React from 'react';
import { EdgeProps, getBezierPath } from '@xyflow/react';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export default function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd
}: EdgeProps) {
    const xEqual = sourceX === targetX;
    const yEqual = sourceY === targetY;
    const { isSubbed } = useSubscriptionPayment();

    const [edgePath] = getBezierPath({
        // we need this little hack in order to display the gradient for a straight line
        sourceX: xEqual ? sourceX + 1 : sourceX,
        sourceY: yEqual ? sourceY + 1 : sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition
    });

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
        </>
    );
}
