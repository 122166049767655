import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RequestMeeting } from './RequestMeeting';
import { selectEnterpriseOrganization } from '../../../../../redux/reducers/enterprise/enterpriseOrganization';
import { selectEnterpriseWidgetProjectId } from '../../../../../redux/reducers/enterprise_widget/enterpriseWidget';

const ProjectCompleted = () => {
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const projectId = useSelector(selectEnterpriseWidgetProjectId);

    const redirectURL = enterpriseOrganization?.widgetCompletionRedirectURL;
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        if (redirectURL) {
            const timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        clearInterval(timer);
                        window.location.href = redirectURL;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [redirectURL]);

    const helperText =
        enterpriseOrganization?.scheduleMeetingHelperText ||
        'You can schedule a meeting to request developers with us below.';

    return (
        <div className="flex items-center justify-center h-full">
            <div className="mx-auto max-w-screen-sm text-center">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-20 h-20 mx-auto"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                    />
                </svg>

                <p className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
                    Thank you for your responses!
                </p>
                {!enterpriseOrganization?.disableShareLink && (
                    <p className="mb-6 mx-10 font-light text-gray-500 dark:text-gray-400 md:text-lg">
                        You will receive an email with your initial project
                        scope shortly.
                    </p>
                )}
                {enterpriseOrganization?.activateMeetings && (
                    <div className="flex flex-col items-center justify-center mb-6">
                        <p className="mb-6 mx-10 text-gray-500 dark:text-gray-400 md:text-lg">
                            {helperText}
                        </p>
                        <RequestMeeting />
                    </div>
                )}

                {!enterpriseOrganization?.disableViewProjectPlanWidget && (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/share/enterprise/${enterpriseOrganization._id}/${projectId}`}
                        className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                    >
                        View your initial project plan
                    </a>
                )}

                {redirectURL && (
                    <p className="mt-2 text-gray-500 dark:text-gray-400 md:text-lg">
                        Redirecting in {countdown} seconds...
                    </p>
                )}
            </div>
        </div>
    );
};

export default ProjectCompleted;
