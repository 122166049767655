export const formFields = [
    {
        label: 'LinkedIn Profile',
        name: 'linkedinURL',
        type: 'url',
        placeholder: 'https://www.linkedin.com/in/username'
    },
    {
        label: 'X / Twitter Profile',
        name: 'twitterProfile',
        type: 'url',
        placeholder: 'https://x.com/username'
    },
    {
        label: 'First Name',
        name: 'firstName',
        type: 'text',
        placeholder: 'Enter your first name'
    },
    {
        label: 'Last Name',
        name: 'lastName',
        type: 'text',
        placeholder: 'Enter your last name'
    }
];

export const businessFields = [
    {
        label: 'Business Name',
        name: 'businessName',
        type: 'text',
        placeholder: 'Enter your business name'
    },
    {
        label: 'Business URL',
        name: 'businessURL',
        type: 'url',
        placeholder: 'https://www.yourbusiness.com'
    },
    {
        label: 'Job Title',
        name: 'jobTitle',
        type: 'text',
        placeholder: 'Enter your job title'
    },
    {
        label: 'Scheduling Link (Optional)',
        name: 'schedulingLink',
        type: 'url',
        placeholder: 'https://calendly.com/your-link'
    }
];
