import { useDispatch, useSelector } from 'react-redux';
import {
    selectEditMode,
    selectLoadingSowCustom,
    selectLoadingSowMVP,
    selectSow,
    setEditMode,
    selectQuoteMode,
    toggleQuoteMode
} from '../../../../redux/reducers/generic/projectArchitecture';
import * as Switch from '@radix-ui/react-switch';
import { Icon } from '../TipTap/components/ui/Icon';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { toast } from 'sonner';
import { selectProjectArchitectureLoading } from '../../../../redux/reducers/generic/projectArchitecture';
import trackEvent from '../../../../helpers/trackEvent';
import { useProject } from '../../../../context-providers/Project';
export const ModeToggle = () => {
    const isEditMode = useSelector(selectEditMode);
    const mvpLoading = useSelector(selectLoadingSowMVP);
    const customLoading = useSelector(selectLoadingSowCustom);
    const dispatch = useDispatch();
    const sow = useSelector(selectSow);
    const type = sow?.type || 'mvp';
    const isLoadingSow = type === 'mvp' ? mvpLoading : customLoading;
    const { isShare, isHomePage, isLeadGen } = useSubscriptionPayment();
    const { project } = useProject();

    const quoteMode = useSelector(selectQuoteMode);
    let architectureLoading = useSelector(selectProjectArchitectureLoading);

    if (!sow?.completed || isLeadGen) return null;

    const handleChecked = async () => {
        if (!isShare && !isHomePage) {
            if (quoteMode) await dispatch(toggleQuoteMode(false));
            if (!isEditMode) {
                trackEvent('editMode', {
                    userEmail: project.userEmail
                });
            }
            dispatch(setEditMode(!isEditMode));
        } else {
            toast.info('Login to edit the documentation');
        }
    };
    return (
        !architectureLoading &&
        !isLoadingSow && (
            <div className="flex items-center">
                <Switch.Root
                    checked={isEditMode}
                    onCheckedChange={handleChecked}
                    id="edit-mode"
                    className="w-[42px] h-[25px] bg-gray-200 rounded-full relative data-[state=checked]:bg-blue-600 outline-none cursor-pointer"
                >
                    <Switch.Thumb className="block w-[21px] h-[21px] bg-white rounded-full shadow-lg transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
                </Switch.Root>
                <label
                    className="text-sm font-medium text-gray-700 ml-2"
                    htmlFor="edit-mode"
                >
                    Edit Mode
                </label>
            </div>
        )
    );
};
