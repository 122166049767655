import { CORRECT_UI_VIEW_ERRORS } from '../../../api/graphQL';
import { client } from '../../../api/client';

export const correctErrors =
    ({projectId, nodeId, version, errors}) => async dispatch => {
        try {
            await client.mutate({
                mutation: CORRECT_UI_VIEW_ERRORS,
                variables: {
                    projectId,
                    nodeId,
                    version,
                    errors
                }
            });
            
            return
        } catch (error) {
            console.log({ error });
            return error;
        }
    };
