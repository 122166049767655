import { REMOVE_DOMAIN_FROM_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import {
    fetchApiDomainsRequest,
    fetchApiDomainsSuccess,
    fetchApiDomainsFailure
} from '../../reducers/enterprise/clientOnboarding';

export const removeDomainFromEnterpriseOrganization =
    (enterpriseOrganizationId, domainId) => async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: REMOVE_DOMAIN_FROM_ENTERPRISE_ORGANIZATION,
                variables: {
                    domainId: domainId,
                    enterpriseOrganizationId: enterpriseOrganizationId
                },
                fetchPolicy: 'network-only'
            });

            dispatch(
                fetchApiDomainsSuccess(
                    data.removeDomainFromEnterpriseOrganization.apiDomains
                )
            );
            return 'Successfully removed domain from whitelist.';
        } catch (error) {
            console.log({ error });
            dispatch(fetchApiDomainsFailure(error));
            return error.message;
        }
    };
