import { GET_UIVIEW } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    setUIView,
    gettingUIView
} from '../../reducers/generic/projectArchitecture';
import { setChatHistory } from '../../reducers/generic/uiview';

export const getUIView =
    ({ projectId, nodeId, forceNew, image_url }) =>
    async dispatch => {
        try {
            console.log('getting ui view');
            dispatch(gettingUIView(true));
            const { data } = await client.query({
                query: GET_UIVIEW,
                variables: {
                    projectId: projectId,
                    nodeId: nodeId,
                    forceNew: forceNew,
                    image_url: image_url
                },
                fetchPolicy: 'network-only'
            });
            dispatch(gettingUIView(false));
            if (data?.getUIView) {
                dispatch(setUIView(data.getUIView));
                if (data?.getUIView.chatHistory) {
                    dispatch(setChatHistory(data.getUIView.chatHistory));
                }
            }
        } catch (error) {
            console.log({ error });
            if (error.message.includes(`API is temporarily unavailable.`)) {
                alert(
                    `API is temporarily unavailable. Please try again later.`
                );
            }
        }
    };
