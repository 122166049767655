import { client } from '../../../api/client';
import {
    callingLinear,
    setLinearPercentage
} from '../../reducers/generic/linear';
import { SYNC_LINEAR } from '../../../api/graphQL/enterprise/mutator';

export const syncLinear =
    ({ projectId, version, linearProjectId }) =>
    async (dispatch, getState) => {
        try {
            dispatch(callingLinear(true));

            const skillLevel = getState().projectArchitecture.skillLevel;

            await client.mutate({
                mutation: SYNC_LINEAR,
                variables: {
                    projectId,
                    version,
                    linearProjectId,
                    skillLevel
                },
                fetchPolicy: 'network-only'
            });
        } catch (error) {
            console.error('Error syncing Linear:', error);
            dispatch(callingLinear(false));
            dispatch(setLinearPercentage(0));
            throw new Error(error.message);
        }
    };
