import React, { createContext, useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getEnterpriseOrganizationsByUser } from '../redux/actions/enterprise/getEnterpriseOrganizationsByUser';
import {
    clearStateEnterpriseOrganizations,
    selectEnterpriseOrganizations
} from '../redux/reducers/enterprise/enterpriseOrganizations';

const EnterpriseOrganizationsContext = createContext();

export function EnterpriseOrganizationsProvider({ children }) {
    const { doesSessionExist, accessTokenPayload } = useSessionContext();
    const enterpriseOrganizations = useSelector(selectEnterpriseOrganizations);
    const dispatch = useDispatch();

    //determine when to mount this data
    useEffect(() => {
        if (doesSessionExist) {
            dispatch(getEnterpriseOrganizationsByUser());
        } else {
            dispatch(clearStateEnterpriseOrganizations());
        }
    }, [doesSessionExist, accessTokenPayload?.sessionHandle]);

    return (
        <EnterpriseOrganizationsContext.Provider
            value={{ enterpriseOrganizations }}
        >
            {children}
        </EnterpriseOrganizationsContext.Provider>
    );
}

export const useEnterpriseOrganizations = () =>
    useContext(EnterpriseOrganizationsContext);
