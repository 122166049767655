import { formatHeading, formatText } from './helper';

export function formatTItle(sow, previewMode) {
    let formattedContent = [];
    try {
        let heading = formatHeading(1, [formatText(sow?.title, false)]);
        formattedContent = [heading];
    } catch (error) {
        console.log('Error formatting title:', error);
    }
    return formattedContent;
}
