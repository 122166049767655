import { useDispatch, useSelector } from 'react-redux';
import { useProject } from '../../../context-providers/Project';
import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
    selectTechStackVersion,
    selectTechVersionLoading
} from '../../../redux/reducers/generic/project';
import { getRecommendedTechStackByVersion } from '../../../redux/actions/solo/getRecommendedTechStackByVersion';
import { Spinner } from '../../Components/Spinner';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const TechStackVersions = () => {
    const { project } = useProject();
    const dispatch = useDispatch();

    const techStackVersion = useSelector(selectTechStackVersion);

    const handleShowTechStack = async item => {
        dispatch(
            getRecommendedTechStackByVersion(project._id.toString(), item)
        );
    };

    const techStackVersionLoading = useSelector(selectTechVersionLoading);

    let buttons = (
        <>
            {project.techStackVersions.map((item, index) => (
                <Menu.Item key={`tech-stack-version-${index}`}>
                    {({ active }) => (
                        <button
                            onClick={() => handleShowTechStack(item)}
                            className={classNames(
                                active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                'block px-4 py-2 text-sm w-full text-left flex flex-row items-center'
                            )}
                        >
                            Version {item}
                        </button>
                    )}
                </Menu.Item>
            ))}
        </>
    );

    return (
        techStackVersion != null && (
            <Menu
                as="div"
                className="cursor-pointer relative inline-block text-left "
            >
                <div>
                    <Menu.Button className="bg-blue-500 cursor-pointer px-4 text-white inline-flex w-full justify-center items-center rounded-lg py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-700">
                        {techStackVersionLoading ? (
                            <>
                                <span className="mt-1 mb-1 mr-2">
                                    Version {techStackVersion}
                                </span>
                                <Spinner w="4" />
                            </>
                        ) : (
                            <>
                                <span className="mt-1 mb-1">
                                    Version {techStackVersion}
                                </span>
                                <ChevronDownIcon
                                    className="h-6 w-6 text-gray-400"
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-left z-50 absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll max-h-[200px]">
                        <div className="py-1">{buttons}</div>
                    </Menu.Items>
                </Transition>
            </Menu>
        )
    );
};
