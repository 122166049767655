import { useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import {
    InformationCircleIcon,
    ChevronDownIcon,
    ChevronUpIcon
} from '@heroicons/react/24/outline';
import { LeadGenExplainerText } from './title';
export function LeadGenExplainer() {
    const [isMinimized, setIsMinimized] = useState(() => {
        const storedValue = window.localStorage.getItem(
            'leadGenExplainerMinimized'
        );
        return storedValue ? JSON.parse(storedValue) : false;
    });

    const toggleMinimize = () => {
        const newValue = !isMinimized;
        setIsMinimized(newValue);
        window.localStorage.setItem(
            'leadGenExplainerMinimized',
            JSON.stringify(newValue)
        );
    };

    return (
        <div className="flex justify-center">
            <div className="w-full max-w-lg bg-white border border-gray-200 rounded-lg overflow-hidden">
                <div className="px-4 py-2">
                    <div className="flex items-center justify-between">
                        <h2 className="text-md font-semibold flex items-center">
                            Lead Generation Explainer
                        </h2>
                        <div className="flex items-center">
                            <Tooltip.Provider delayDuration={100}>
                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <InformationCircleIcon className="h-6 w-6 text-gray-400 cursor-help mr-2" />
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content
                                            className="max-w-xs p-2 bg-white text-black rounded border border-gray-200"
                                            sideOffset={5}
                                        >
                                            <p className="text-sm">
                                                Learn more about lead generation
                                                on pre.dev
                                            </p>
                                            <Tooltip.Arrow className="fill-white" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>
                            </Tooltip.Provider>
                            <button
                                onClick={toggleMinimize}
                                className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
                            >
                                {isMinimized ? (
                                    <ChevronDownIcon className="h-5 w-5" />
                                ) : (
                                    <ChevronUpIcon className="h-5 w-5" />
                                )}
                            </button>
                        </div>
                    </div>
                    <div
                        className={`overflow-hidden transition-all duration-300 ease-in-out ${
                            isMinimized
                                ? 'max-h-0 opacity-0'
                                : 'max-h-[1000px] opacity-100 mt-4'
                        }`}
                    >
                        <LeadGenExplainerText />
                    </div>
                </div>
            </div>
        </div>
    );
}
