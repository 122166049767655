import { Dropdown, Avatar } from 'flowbite-react';
import { useNavigate, useParams } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { deleteOrganization } from '../../../../redux/actions/enterprise/deleteProject';
import { toast } from 'sonner';

export function Settings() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { enterpriseOrganizationId } = useParams();

    async function onDelete() {
        if (
            window.confirm('Are you sure you want to delete this organization?')
        ) {
            let status = await dispatch(
                deleteOrganization(enterpriseOrganizationId)
            );
            if (status) {
                toast.error(status);
            }
        }
    }

    async function onLogout() {
        await signOut();
        window.location.href = '/';
        navigate('/');
    }

    const { accessTokenPayload } = useSessionContext();

    return (
        <a className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white">
            <div className="flex w-full">
                <Dropdown
                    inline
                    label={
                        <>
                            <Cog6ToothIcon
                                className="h-8 w-8"
                                aria-hidden="true"
                            />
                            <div className="w-full">
                                <h1>Settings</h1>
                            </div>
                        </>
                    }
                >
                    <Dropdown.Header>
                        <span className="block truncate text-sm font-medium">
                            {accessTokenPayload.email}
                        </span>
                    </Dropdown.Header>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={onLogout}>Sign out</Dropdown.Item>
                    <Dropdown.Item onClick={onDelete} className="text-red-500">
                        Delete Organization
                    </Dropdown.Item>
                </Dropdown>
            </div>
        </a>
    );
}
