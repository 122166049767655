import React from 'react';
import { FolderIcon } from '@heroicons/react/24/outline';

export const EmptyProjects = () => {
    return (
        <div className="mt-8 flex flex-col items-center justify-center h-64 border-2 border-dashed border-gray-300 rounded-lg bg-gray-50">
            <FolderIcon
                className="w-16 h-16 text-gray-400 mb-4"
                aria-hidden="true"
            />
            <h2 className="text-2xl font-semibold text-gray-700 mb-2">
                No Projects Found
            </h2>
        </div>
    );
};
