import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FolderIcon } from '@heroicons/react/24/solid';
import { CircleStackIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { clearStateProjects } from '../../../../redux/reducers/generic/projects';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const ClientProjects = ({ closeSidebar, id }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const { pathname } = useLocation();
    const { isLeadGen } = useSubscriptionPayment();

    const onClick = async e => {
        e.preventDefault();

        if (isLeadGen) {
            dispatch(clearStateProjects());
        }

        navigate(
            `/enterprise/dashboard/${enterpriseOrganizationId}/clientProjects`
        );
        if (closeSidebar) {
            closeSidebar();
        }
    };

    let active = pathname.includes('clientProjects');

    return (
        <a
            onClick={onClick}
            className={classNames(
                'text-gray-100 hover:text-blue-300 hover:bg-gray-600',
                'group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold cursor-pointer',
                active ? 'bg-gray-600' : ''
            )}
        >
            <CircleStackIcon
                className="text-gray-400 flex-shrink-0 h-6 w-6"
                aria-hidden="true"
            />
            <span className="truncate">Projects</span>
        </a>
    );
};
