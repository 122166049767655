import { Link, useNavigate, useParams } from 'react-router-dom';
import { FolderIcon, FolderOpenIcon } from '@heroicons/react/24/solid';
import {
    ClipboardDocumentIcon,
    PencilIcon,
    ShareIcon,
    TrashIcon
} from '@heroicons/react/24/outline';
import { toast } from 'sonner';
import { getWebsiteDomain } from '../../../auth_config';
import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deleteProject } from '../../../redux/actions/solo/deleteProject';
import { Spinner } from '../../Components/Spinner';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { editProjectName } from '../../../redux/actions/solo/editProjectName';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const copyToClipboard = text => e => {
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    toast('Share Link Copied!');
};

export const shareLinkButton = (projectId, showShareLink) => {
    return showShareLink ? (
        <button
            onClick={e => {
                e.stopPropagation();
                copyToClipboard(`${getWebsiteDomain()}/share/${projectId}`)(e);
            }}
            className="flex flex-row items-center cursor-pointer text-gray-600 text-left text-2xs hover:text-gray-900"
        >
            <ShareIcon className="h-5" />
        </button>
    ) : null;
};

export const deleteButton = (
    projectId,
    onDeleteConfirm,
    deleting,
    showShareLink
) => {
    return !deleting && showShareLink ? (
        <button
            onClick={e => {
                e.stopPropagation();
                onDeleteConfirm(projectId);
            }}
            className="flex flex-row items-center cursor-pointer text-red-600 text-left text-2xs hover:text-red-900"
        >
            <TrashIcon className="h-5" />
        </button>
    ) : deleting && showShareLink ? (
        <button className="flex flex-row items-center cursor-pointer text-red-600 text-left text-2xs hover:text-red-900">
            <Spinner w="5" />
        </button>
    ) : null;
};

export const editButton = (
    projectId,
    onDeleteConfirm,
    deleting,
    showShareLink
) => {
    return !deleting && showShareLink ? (
        <button
            onClick={e => {
                e.stopPropagation();
                onDeleteConfirm(projectId);
            }}
            className="flex flex-row items-center cursor-pointer text-red-600 text-left text-2xs hover:text-red-900"
        >
            <TrashIcon className="h-5" />
        </button>
    ) : deleting && showShareLink ? (
        <button className="flex flex-row items-center cursor-pointer text-red-600 text-left text-2xs hover:text-red-900">
            <Spinner w="5" />
        </button>
    ) : null;
};
export default function ProjectItem({ project, closeSidebar }) {
    let { name, created, _id, type } = project;
    let { projectId } = useParams();
    const dispatch = useDispatch();

    let [isEditing, setIsEditing] = useState(false);
    let [editedName, setEditedName] = useState(name);
    let editInputRef = useRef(null);

    const [newName, setNewName] = useState(name);

    useEffect(() => {
        setNewName(name);
    }, [name]);

    const { isSubbed } = useSubscriptionPayment();

    useEffect(() => {
        if (isEditing) {
            editInputRef.current && editInputRef.current.focus();
        }
    }, [isEditing]);

    let isChosen = _id === projectId;

    let navigate = useNavigate();

    const onClick = e => {
        e.stopPropagation();
        if (!isChosen) {
            navigate(`/projects/${_id}`);
        }
        if (closeSidebar) {
            closeSidebar(false);
        }
    };

    const onEdit = e => {
        e.stopPropagation();
        setIsEditing(true);
    };

    useEffect(() => {
        const handleOutsideClick = event => {
            if (
                isEditing &&
                editInputRef.current &&
                !editInputRef.current.contains(event.target)
            ) {
                setIsEditing(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isEditing, editInputRef]);

    let [editLoading, setEditLoading] = useState(false);

    const onEditSubmit = async e => {
        if (e.key === 'Enter' || e.key === 'Escape') {
            if (e.key === 'Enter') {
                setEditLoading(true);
                try {
                    let res = await dispatch(editProjectName(_id, editedName));
                    if (res) {
                        toast.success('Project name edited successfully');
                        setNewName(editedName);
                    } else {
                        toast.error('Error editing project name');
                    }
                } catch (e) {
                    console.log(e);
                }
                setEditLoading(false);
            }
            setIsEditing(false);
        }
    };

    let [showShareLink, setShowShareLink] = useState(false);
    let [deleting, setDeleting] = useState(false);
    const onDeleteConfirm = async id => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            setDeleting(true);
            await dispatch(deleteProject(id));
            if (isChosen) navigate('/projects');
            setDeleting(false);
            setShowShareLink(false);
        }
    };

    let colors = {
        web2: 'green',
        web3: 'blue'
    };

    function formatDate(isoDate) {
        const date = new Date(isoDate);
        const now = new Date();
        const isToday = date.toDateString() === now.toDateString();

        if (isToday) {
            return date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit'
            });
        } else {
            return date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit'
            });
        }
    }

    return (
        <li
            onClick={onClick}
            key={_id}
            className="flex flex-row w-full justify-between items-center"
        >
            <button
                onMouseOver={() => setShowShareLink(true)}
                onMouseOut={() => setShowShareLink(false)}
                className={classNames(
                    isChosen
                        ? 'flex w-full flex-col gap-y-2 rounded-lg bg-slate-200 px-3 py-2 text-left transition-colors duration-200 focus:outline-none dark:bg-slate-800'
                        : 'flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-800'
                )}
            >
                {!isEditing ? (
                    <div />
                ) : (
                    editLoading && (
                        <div>
                            <Spinner w="5" />
                        </div>
                    )
                )}

                {!isEditing ? (
                    <div className="flex justify-between items-center w-full">
                        <div className="flex items-center">
                            <h1
                                onClick={onClick}
                                className="text-sm font-medium capitalize text-slate-700 dark:text-slate-200"
                            >
                                {newName}
                            </h1>
                            <span
                                onClick={onClick}
                                className={`inline-flex items-center rounded-md bg-${colors[type]}-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 ml-2`}
                            >
                                {type}
                            </span>
                        </div>
                        <div className="flex items-center gap-2">
                            {isSubbed && (
                                <>
                                    {shareLinkButton(_id, showShareLink)}
                                    {deleteButton(
                                        _id,
                                        onDeleteConfirm,
                                        deleting,
                                        showShareLink
                                    )}
                                    {showShareLink && (
                                        <PencilIcon
                                            onClick={onEdit}
                                            className="h-5 text-gray-600 cursor-pointer hover:text-gray-900"
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <input
                        ref={editInputRef}
                        type="text"
                        value={editedName}
                        onChange={e => setEditedName(e.target.value)}
                        onKeyDown={onEditSubmit}
                        className="truncate w-full rounded-md bg-white px-2 py-0 text-sm leading-6 font-semibold"
                    />
                )}
                <div className="flex items-center w-full justify-between gap-x-2">
                    <p className="text-xs text-slate-500 dark:text-slate-400">
                        {project ? formatDate(project.updated) : ''}
                    </p>
                    {project?.proposals?.length > 0 && (
                        <span className="inline-flex items-center rounded-md bg-gradient-to-r from-black/80 to-gray-800/80 px-2 py-1 text-xs font-medium text-white hover:from-black/90 hover:to-gray-800/90 transition-colors ring-1 ring-inset ring-black">
                            {project?.proposals?.length}{' '}
                            {project?.proposals?.length === 1
                                ? 'proposal'
                                : 'proposals'}
                        </span>
                    )}
                </div>
            </button>
        </li>
    );
}
