import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { UserGroupIcon } from '@heroicons/react/20/solid';
import { useDispatch, useSelector } from 'react-redux';
import {
    toggleSidebar,
    setSidebarOpen,
    selectSidebarOpen
} from '../../../redux/reducers/generic/sidebar';
import SidebarItems from './SidebarItems';
import { useProject } from '../../../context-providers/Project';
import trackEvent from '../../../helpers/trackEvent';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

export default function MobileSidebar() {
    const dispatch = useDispatch();
    const sidebarOpen = useSelector(selectSidebarOpen);
    let { pageType } = useProject();

    const handleToggleSidebar = () => {
        dispatch(toggleSidebar());
    };

    const handleCloseSidebar = () => {
        dispatch(setSidebarOpen(false));
    };

    let title =
        pageType === 'chat'
            ? 'Project Discovery'
            : pageType === 'graph'
              ? 'Architecture'
              : pageType === 'sow'
                ? 'Documentation'
                : pageType === 'roadmap'
                  ? 'Roadmap'
                  : pageType === 'code'
                    ? 'Code Gen (Beta)'
                    : null;
    const { accessTokenPayload } = useSessionContext();

    return (
        <>
            <div
                id="topNav"
                className="h-[46px] justify-between flex bg-black px-4 shadow-sm sm:px-6 lg:hidden"
            >
                <button
                    type="button"
                    className="text-gray-400"
                    onClick={handleToggleSidebar}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>

                {title && (
                    <div className="md:hidden w-full h-full flex items-center justify-center">
                        <span className="text-white font-semibold text-md tracking-wide">
                            {title}
                        </span>
                    </div>
                )}
            </div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="z-50 lg:hidden"
                    onClose={handleCloseSidebar}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 left-0 flex w-16 pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={handleCloseSidebar}
                                        >
                                            <span className="sr-only">
                                                Close sidebar
                                            </span>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div class="max-w-xl w-[300px] h-full rounded-lg border border-slate-300 border-t-0 border-l-0 bg-slate-50 pb-8 dark:border-slate-200/10 dark:bg-slate-900">
                                    <div className="flex justify-between">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <Link to="/">
                                                <img
                                                    className="ml-2 h-8 w-auto rounded-full p-[3px]"
                                                    src="/predevblack.png"
                                                />
                                            </Link>
                                            <Link to="/" className="flex">
                                                <span className="ml-1 font-semibold self-center">
                                                    pre.dev
                                                </span>
                                                <span className="mt-3 ml-2 self-center text-xs font-semibold whitespace-nowrap text-blue-600">
                                                    Solo
                                                </span>
                                            </Link>
                                        </div>
                                        <Link
                                            onClick={() =>
                                                trackEvent(
                                                    'enterpriseSwitchClicked',
                                                    {
                                                        userEmail:
                                                            accessTokenPayload?.email
                                                    }
                                                )
                                            }
                                            to="/enterprise/dashboard"
                                            className="flex items-center"
                                        >
                                            <a class="ml-2 inline-flex text-green-500 items-center justify-center px-2 py-1 me-3 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                <UserGroupIcon className="w-3 h-3 mr-2" />
                                                Enterprise
                                            </a>
                                        </Link>
                                    </div>

                                    <SidebarItems
                                        handleCloseSidebar={handleCloseSidebar}
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
