import { client } from '../../../api/client';
import {
    fetchProjectSuccess,
    fetchProjectFailure,
    setMeetingRequested
} from '../../reducers/generic/project';

import { setChatHistory } from '../../reducers/generic/chatHistory';
import { setProjectArchitecture } from '../../reducers/generic/projectArchitecture';
import { GET_PROJECT_BY_LINK } from '../../../api/graphQL/solo/query';

export const getProjectByLink = projectId => async dispatch => {
    try {
        const { data } = await client.query({
            query: GET_PROJECT_BY_LINK,
            variables: { projectId: projectId },
            fetchPolicy: 'network-only'
        });

        //Set up Project
        dispatch(fetchProjectSuccess(data.projectByLink));

        //Set up Chat History
        dispatch(setChatHistory(data.projectByLink.chatHistory));

        //Set up Project Architecture
        dispatch(
            setProjectArchitecture({
                graphs: data.projectByLink.graphs
            })
        );

        //Set up request meeting status
        dispatch(setMeetingRequested(data.projectByLink.meetingRequested));

        return;
    } catch (error) {
        console.log({ error });
        dispatch(fetchProjectFailure(error));
    }
};
