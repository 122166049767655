import { Spinner } from '../../../Components/Spinner';
import { useDispatch } from 'react-redux';
import { makeUserAdminOfEnterpriseOrganization } from '../../../../redux/actions/enterprise/makeUserAdminOfEnterpriseOrganization';
import { removeUserFromEnterpriseOrganization } from '../../../../redux/actions/enterprise/removeUserFromEnterpriseOrganization';
import { toast } from 'sonner';
import { useState } from 'react';

export const ActionButtons = ({
    enterpriseOrganizationId,
    isAdmin,
    user,
    accessTokenPayload
}) => {
    const dispatch = useDispatch();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [adminLoading, setAdminLoading] = useState(false);

    const handleDeleteUser = async () => {
        const userConfirmed = window.confirm(
            'Are you sure you want to delete this user?'
        );

        if (userConfirmed) {
            setDeleteLoading(true);
            const response = await dispatch(
                removeUserFromEnterpriseOrganization(
                    user.userEmail,
                    enterpriseOrganizationId
                )
            );
            setDeleteLoading(false);
            toast(response);
        }
    };

    const handleMakeUserAdmin = async () => {
        const userConfirmed = window.confirm(
            'Are you sure you want to make this user an admin?'
        );

        if (userConfirmed) {
            setAdminLoading(true);
            const response = await dispatch(
                makeUserAdminOfEnterpriseOrganization(
                    user.userEmail,
                    enterpriseOrganizationId
                )
            );
            setAdminLoading(false);
            toast(response);
        }
    };

    return (
        <div className="flex flex-row space-x-2">
            {deleteLoading || adminLoading ? (
                <Spinner w="4" h="4" />
            ) : (
                <>
                    {isAdmin && user.role !== 'admin' && (
                        <button
                            onClick={handleMakeUserAdmin}
                            className="px-2 py-1 text-xs border border-green-600 text-green-600 rounded-md hover:bg-green-600 hover:text-white transition-colors duration-300"
                            disabled={adminLoading}
                        >
                            Make Admin
                        </button>
                    )}
                    {isAdmin && user.userEmail !== accessTokenPayload.email && (
                        <button
                            onClick={handleDeleteUser}
                            className="px-2 py-1 text-xs border border-red-600 text-red-600 rounded-md hover:bg-red-600 hover:text-white transition-colors duration-300"
                            disabled={deleteLoading}
                        >
                            Delete
                        </button>
                    )}
                </>
            )}
        </div>
    );
};
