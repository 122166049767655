import { GET_SIGNED_DOWNLOAD_URL } from '../../../api/graphQL';
import { client } from '../../../api/client';

export const getSignedDownloadURL = filename => async (dispatch, getState) => {
    try {
        let { data } = await client.mutate({
            mutation: GET_SIGNED_DOWNLOAD_URL,
            variables: { filename: filename }
        });
        const signedURL = data.getSignedDownloadURL;
        return signedURL;
    } catch (error) {
        console.log({ error });
        console.log(JSON.stringify(error, null, 2));
    }
};
