import { useParams } from 'react-router-dom';
import { useEnterpriseOrganization } from '../../../../../context-providers/EnterpriseOrganization';

const MainHeader = () => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    let _id = enterpriseOrganization?._id;
    let noLink = _id === '66a2b9cf8e95b4e598668d7e'; //MOS CREATIVE EXCEPTION

    return (
        <nav className="border-t border-gray-200">
            <div className="flex flex-wrap items-center justify-between mx-auto p-2">
                <div className="flex items-center"></div>
                <a
                    className="flex items-center text-black"
                    href={noLink ? '#' : 'https://pre.dev'}
                    target={noLink ? '' : '_blank'}
                    rel={noLink ? '' : 'noopener noreferrer'}
                >
                    <span className="ml-2 mr-2 self-center text-xs font-thin whitespace-nowrap ">
                        powered by
                    </span>
                    <img
                        src="/predev.png"
                        className="h-6 bg-gray-900 rounded p-1"
                        alt="Company Logo"
                    />
                    <span className="ml-2 self-center text-md font-semibold whitespace-nowrap">
                        pre.dev
                    </span>
                </a>
            </div>
        </nav>
    );
};

export default MainHeader;
