import { createSlice } from '@reduxjs/toolkit';
import { projectsLimit } from '../../../constants';

const initialState = {
    loading: null,
    initLoaded: false,
    data: [],
    error: null,
    skip: 0,
    limit: projectsLimit,
    totalProjects: 0,
    paginateLoading: false,
    searchQuery: '',
    onlyRevealedProjects: false,
    onlyPremiumLeadGenEnabled: false,
    sort: null,
    creationMethodFilter: null,
    matchWithDevsOnly: false,
    ignorePreDevEmails: false,
    searchLoading: false,
    skipClicked: 0
};

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        skipClicked: (state, action) => {
            state.skipClicked = state.skipClicked + 1;
        },
        clearStateProjects: state => {
            console.log('clearStateProjects');
            state.loading = null;
            state.data = [];
            state.error = null;
            state.initLoaded = false;
            state.skip = 0;
            state.paginateLoading = false;
            state.totalProjects = 0;
        },
        updateProject: (state, action) => {
            state.data = state.data.map(project => {
                if (project._id === action.payload._id) {
                    return action.payload;
                }
                return project;
            });
        },
        addToProjects: (state, action) => {
            state.data = [action.payload, ...state.data];
        },
        fetchPaginatedProjects: (state, action) => {
            state.data = state.data.concat(action.payload);
            state.paginateLoading = false;
        },
        fetchProjectsRequest: state => {
            state.loading = true;
        },
        removeProject: (state, action) => {
            state.data = state.data.filter(project => {
                return project._id.toString() !== action.payload;
            });
        },
        fetchProjectsSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload.projects;
            state.totalProjects = action.payload.totalProjects;
            state.error = null;
            state.initLoaded = true;
            state.searchLoading = false;
        },
        paginateProjects: state => {
            state.skip += 20;
            state.paginateLoading = true;
        },
        fetchProjectsFailure: (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchLoading = true;
            state.searchQuery = action.payload;
            state.skip = 0;
        },
        setCreationMethodFilter: (state, action) => {
            state.searchLoading = true;
            state.creationMethodFilter = action.payload;
            state.skip = 0;
        },
        setOnlyRevealedProjects: (state, action) => {
            state.onlyRevealedProjects = action.payload;
        },
        setOnlyPremiumLeadGenEnabled: (state, action) => {
            state.onlyPremiumLeadGenEnabled = action.payload;
        },
        setSort: (state, action) => {
            state.searchLoading = true;
            state.sort = action.payload;
            state.skip = 0;
        },
        setMatchWithDevsOnly: (state, action) => {
            state.matchWithDevsOnly = action.payload;
        },
        setIgnorePreDevEmails: (state, action) => {
            state.ignorePreDevEmails = action.payload;
        }
    }
});
export const {
    addToProjects,
    clearStateProjects,
    fetchProjectsRequest,
    fetchProjectsSuccess,
    fetchProjectsFailure,
    paginateProjects,
    fetchPaginatedProjects,
    setSearchQuery,
    skipClicked,
    removeProject,
    setCreationMethodFilter,
    setSort,
    setMatchWithDevsOnly,
    setIgnorePreDevEmails,
    updateProject,
    setOnlyRevealedProjects,
    setOnlyPremiumLeadGenEnabled
} = projectsSlice.actions;

export const selectProjects = state => state.projects.data;
export const selectProjectsLoading = state => state.projects.loading;
export const selectInitLoaded = state => state.projects.initLoaded;
export const selectProjectsSkip = state => state.projects.skip;
export const selectProjectsTotal = state => {
    return state.projects.totalProjects;
};
export const selectPaginateLoading = state => state.projects.paginateLoading;
export const selectSearchQuery = state => state.projects.searchQuery;
export const selectSearchLoading = state => state.projects.searchLoading;
export const selectSkipClicked = state => state.projects.skipClicked;
export const selectSort = state => state.projects.sort;
export const selectCreationMethodFilter = state =>
    state.projects.creationMethodFilter;
export const selectMatchWithDevsOnly = state =>
    state.projects.matchWithDevsOnly;
export const selectIgnorePreDevEmails = state =>
    state.projects.ignorePreDevEmails;
export const selectOnlyRevealedProjects = state =>
    state.projects.onlyRevealedProjects;
export const selectOnlyPremiumLeadGenEnabled = state =>
    state.projects.onlyPremiumLeadGenEnabled;

export default projectsSlice.reducer;
