import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useProject } from '../../../../../context-providers/Project';
import { skipQuestions } from '../../../../../redux/actions/enterprise_widget/skipQuestions';
import { setWidgetState } from '../../../../../redux/reducers/enterprise_widget/enterpriseWidget';

export const SkipQuestions = ({ isWidget }) => {
    let { chatHistory, projectId, project } = useProject();

    const dispatch = useDispatch();
    const [isSkipping, setIsSkipping] = useState(false);

    const handleSkipQuestions = async () => {
        if (
            window.confirm(
                'Are you sure you want to skip the initial questions?'
            )
        ) {
            setIsSkipping(true);
            await dispatch(skipQuestions(projectId));
            if (isWidget) {
                dispatch(setWidgetState('projectCompleted'));
            }
        }
    };

    return (
        <button
            onClick={handleSkipQuestions}
            className={`min-w-[130px] text-white bg-blue-700 border border-gray-300  hover:bg-blue-800 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-xs px-4 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 ${
                isSkipping ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isSkipping}
        >
            {isSkipping ? 'Skipping...' : 'Skip Questions'}
        </button>
    );
};
