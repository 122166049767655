import { Spinner } from '../../../Components/Spinner';

export function Syncing({ loadingPercent, loadingText }) {
    const text = loadingText || 'Syncing Linear Tasks...';

    return (
        <div className="flex flex-col gap-y-1 w-[300px] px-2">
            <div className="w-full bg-gray-200 border-gray-800 border rounded-lg h-10 relative">
                <div
                    className="bg-blue-300 rounded-lg"
                    style={{
                        width: `${loadingPercent}%`,
                        height: '2.35rem'
                    }}
                >
                    <span className="w-full text-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-black text-sm">
                        <div className="inline-block mr-2 h-5">
                            <Spinner w="5" />
                        </div>
                        <span className="text-sm h-5">
                            {loadingPercent.toFixed(2)}% - {text}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    );
}
