import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import { Spinner } from '../../Components/Spinner';
import { addUserToEnterpriseOrganization } from '../../../redux/actions/enterprise/addUserToEnterpriseOrganization';

export const InviteUsers = ({ enterpriseOrganizationId }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChangeEmail = e => {
        setEmail(e.target.value);
    };

    const submitUser = async () => {
        if (email.length > 0) {
            setLoading(true);
            const response = await dispatch(
                addUserToEnterpriseOrganization(email, enterpriseOrganizationId)
            );
            setLoading(false);
            toast(response.toString());
            setEmail('');
        } else {
            toast('Please type a valid email.');
        }
    };

    return (
        <>
            <h3 className="text-base font-semibold leading-6 text-gray-900">
                Add User
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                    Add the email address of the user you want to invite to the
                    organization.
                </p>
            </div>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    submitUser();
                }}
                className="mt-5 sm:flex sm:items-center"
            >
                <div className="w-full sm:max-w-xs">
                    <label htmlFor="email" className="sr-only">
                        Email
                    </label>
                    <input
                        value={email}
                        type="email"
                        name="email"
                        disabled={loading}
                        onChange={handleChangeEmail}
                        id="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        placeholder="you@example.com"
                    />
                </div>
                <button
                    type="submit"
                    className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:mt-0 sm:w-auto"
                    disabled={loading}
                >
                    {loading ? <Spinner w="5" h="5" /> : <>Invite +</>}
                </button>
            </form>
        </>
    );
};
