import { ADD_DEFAULT_TECH_STACK_ITEM_TO_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import {
    addDefaultTechStackItem,
    removeDefaultTechStackItem,
    updateDefaultTechStackItem
} from '../../reducers/enterprise/enterpriseOrganization';
import { v4 as uuidv4 } from 'uuid';

export const addDefaultTechStackItemToEnterpriseOrganization =
    (name, enterpriseOrganizationId) => async (dispatch, getState) => {
        let _id = uuidv4();
        try {
            dispatch(
                addDefaultTechStackItem({
                    _id,
                    name,
                    enterpriseOrganizationId
                })
            );

            const { data } = await client.mutate({
                mutation:
                    ADD_DEFAULT_TECH_STACK_ITEM_TO_ENTERPRISE_ORGANIZATION,
                variables: {
                    name,
                    enterpriseOrganizationId
                },
                fetchPolicy: 'network-only'
            });

            dispatch(
                updateDefaultTechStackItem({
                    oldId: _id,
                    data: data.addDefaultTechStackItemToEnterpriseOrganization
                })
            );
            return 'Successfully added default teck stack item.';
        } catch (error) {
            console.log({ error });
            dispatch(removeDefaultTechStackItem(_id));
            return error.message;
        }
    };
