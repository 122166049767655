import { Spinner } from '../../Components/Spinner';
import { useSelector } from 'react-redux';
import { selectChatHistory } from '../../../redux/reducers/generic/chatHistory';
import { chatEndingIndex, salesCalendlyLink } from '../../../constants';
import { selectProjectArchitectureLoading } from '../../../redux/reducers/generic/projectArchitecture';
import { Progress } from './Progress';

export const Placeholder = () => {
    let architectureLoading = useSelector(selectProjectArchitectureLoading);

    let { data } = useSelector(selectChatHistory);
    const currentQuestionIndex = Math.max(Math.round(data.length / 2) - 1, 0);

    let isLoading =
        architectureLoading && currentQuestionIndex >= chatEndingIndex;

    return (
        <section className="dark bg-gray-900 h-full py-8">
            {isLoading ? (
                <div className="flex items-center justify-center h-full py-8">
                    <Spinner w="8" />
                    <h2 className="ml-2 text-2xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">
                        Generating architecture...
                    </h2>
                </div>
            ) : (
                <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
                    <div className="max-w-screen-md">
                        <Progress textHeight="sm" />
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                            Let's get started with a few preliminary questions.
                        </h2>
                        <p className=" mb-8 font-light text-gray-500 sm:text-xl dark:text-gray-400">
                            Chat with the pre.dev{' '}
                            <b className="text-white">product expert</b> to
                            answer a few preliminary questions about your app.
                            Once these are answered, a{' '}
                            <b className="text-white">Project Architecture</b>{' '}
                            graph and{' '}
                            <b className="text-white">Documentation</b> will be
                            generated automatically.
                        </p>
                        <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                            <a
                                href={salesCalendlyLink}
                                className="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            >
                                Need help?
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};
