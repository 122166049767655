import { XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export const Header = ({ logo, showLogin }) => {
    return (
        <nav className="bg-black w-full">
            <div
                className={`flex flex-wrap items-center justify-between py-2 px-4`}
            >
                <Link to="https://pre.dev" className="flex items-center">
                    <img
                        src="/predev.png"
                        className="h-6 bg-gray-900 rounded p-1"
                        alt="Company Logo"
                    />
                    <span className="ml-2 self-center text-xl font-semibold whitespace-nowrap text-white">
                        pre.dev
                    </span>
                    {logo && (
                        <>
                            <XMarkIcon className="h-6 w-6 ml-2 self-center text-black" />
                            <img
                                src={logo}
                                className="h-8 ml-2"
                                alt="Company Logo"
                            />
                        </>
                    )}
                </Link>

                {showLogin && (
                    <Link
                        to={'/projects/'}
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-4 py-2 text-center"
                    >
                        Login to pre.dev
                        <span className="ml-2" aria-hidden="true">
                            &rarr;
                        </span>
                    </Link>
                )}
            </div>
        </nav>
    );
};
