import { SEND_PHONE_NUMBER_VERIFICATION_CODE } from '../../../api/graphQL';
import { client } from '../../../api/client';

export const sendPhoneNumberVerificationCode =
    (phoneNumber: string) => async () => {
        try {
            const response = await client.mutate({
                mutation: SEND_PHONE_NUMBER_VERIFICATION_CODE,
                variables: { phoneNumber }
            });
            return response?.data?.sendPhoneNumberVerificationCode;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
            throw error;
        }
    };
