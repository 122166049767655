// 3 main pages of flow
import Chat from './Chat';
import MainHeader from './MainHeader';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    clearWidgetState,
    setAPIKey
} from '../../../../redux/reducers/enterprise_widget/enterpriseWidget';
import { useSelector } from 'react-redux';
import { selectWidgetState } from '../../../../redux/reducers/enterprise_widget/enterpriseWidget';
import CreateNewProject from '../../../CreateNewProject';
import ProjectCompleted from './ProjectCompleted';
import { useProject } from '../../../../context-providers/Project';
import { useEffect } from 'react';
import { selectEnterpriseOrganization } from '../../../../redux/reducers/enterprise/enterpriseOrganization';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

const Widget = ({ apiKeySub }) => {
    let { apiKey } = useParams();
    const dispatch = useDispatch();
    dispatch(setAPIKey(apiKeySub || apiKey));
    const widgetState = useSelector(selectWidgetState);
    const { project } = useProject();
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const { isSubbed } = useSubscriptionPayment();

    useEffect(() => {
        return () => {
            dispatch(clearWidgetState());
        };
    }, [dispatch]);

    return (
        enterpriseOrganization && (
            <section
                className={`h-[590px] border-gray-200 border rounded-lg shadow-lg object-cover mx-auto max-w-[440px] bg-white flex-grow flex flex-col justify-between`}
            >
                {enterpriseOrganization?.subscriptionStatus.status ===
                'active' ? (
                    <>
                        {widgetState === 'initial' && (
                            <CreateNewProject isWidget={true} />
                        )}
                        {widgetState === 'chat' && project && <Chat />}
                        {widgetState === 'chat' && !project && <div></div>}
                        {widgetState === 'projectCompleted' && (
                            <ProjectCompleted />
                        )}
                        <MainHeader />
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center h-full space-y-6 p-8">
                        <img
                            src="/predevblack.png"
                            alt="Pre.dev Logo"
                            className="h-24"
                        />
                        <p className="text-center text-2xl font-semibold text-gray-900">
                            Scoping Widget is not available for this
                            organization
                        </p>
                        <p className="text-center text-md text-gray-600">
                            Please contact your administrator for more
                            information.
                        </p>
                    </div>
                )}
            </section>
        )
    );
};

export default Widget;
