import { ADD_USER_TO_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import {
    fetchEnterpriseUsersRequest,
    fetchEnterpriseUsersSuccess,
    fetchEnterpriseUsersFailure,
    updateNumUsersLeft
} from '../../reducers/enterprise/enterpriseUsers';

export const addUserToEnterpriseOrganization =
    (userEmail, enterpriseOrganizationId) => async (dispatch, getState) => {
        dispatch(fetchEnterpriseUsersRequest());
        try {
            const { data } = await client.mutate({
                mutation: ADD_USER_TO_ENTERPRISE_ORGANIZATION,
                variables: {
                    userEmail: userEmail,
                    enterpriseOrganizationId: enterpriseOrganizationId
                },
                fetchPolicy: 'network-only'
            });

            dispatch(
                fetchEnterpriseUsersSuccess(
                    data.addUserToEnterpriseOrganization.enterpriseUsers
                )
            );
            dispatch(
                updateNumUsersLeft(
                    data.addUserToEnterpriseOrganization.numUsersLeft
                )
            );
            return 'Successfully added user to organization!';
        } catch (error) {
            console.log({ error, userEmail, enterpriseOrganizationId });
            dispatch(fetchEnterpriseUsersFailure(error));
            return error.message;
        }
    };
