import {
    Body,
    Button,
    Container,
    Head,
    Html,
    Img,
    Link,
    Preview,
    Section,
    Text,
    Hr
} from '@react-email/components';
import * as React from 'react';

interface PreDevTriggerMatchEmailProps {
    enterpriseName: string;
    enterpriseURL: string;
    enterpriseSchedulingLink: string;
    clientName: string;
    projectName: string;
    introductionLogo: string;
}

const baseUrl = 'https://pre.dev';

export const PreDevTriggerMatchEmail = ({
    enterpriseName,
    enterpriseSchedulingLink,
    clientName,
    enterpriseURL,
    projectName,
    introductionLogo
}: PreDevTriggerMatchEmailProps) => (
    <Html>
        <Head />
        <Preview>
            You've been matched! Get {projectName} built by {enterpriseName}
        </Preview>
        <Body style={main}>
            <Container style={container}>
                <Text style={title}>
                    Get <b>{projectName}</b> built by{' '}
                    <Link href={enterpriseURL} style={enterpriseLink}>
                        {enterpriseName}
                    </Link>
                </Text>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        marginBottom: '20px',
                        gap: '5px'
                    }}
                >
                    <Link
                        href={enterpriseURL}
                        style={{ display: 'inline-block', height: '50px' }}
                    >
                        <Img
                            src={introductionLogo}
                            alt={enterpriseName}
                            style={{ height: '100%' }}
                        />
                    </Link>
                    <Text
                        style={{
                            margin: '0 10px',
                            fontSize: '24px',
                            fontWeight: 'bold',
                            lineHeight: '50px',
                            height: '50px'
                        }}
                    >
                        &times;
                    </Text>
                    <Link
                        href={baseUrl}
                        style={{ display: 'inline-block', height: '50px' }}
                    >
                        <Img
                            src={`${baseUrl}/predev_logo_name.png`}
                            height="50"
                            alt="Pre Dev"
                            style={{
                                height: '100%',
                                borderRadius: '8px',
                                padding: '5px',
                                background: 'black'
                            }}
                        />
                    </Link>
                </div>

                <Section style={section}>
                    <Text style={text}>
                        Dear <strong>{clientName}</strong>,
                    </Text>
                    <Text style={text}>
                        Congratulations! You've been matched for the project:{' '}
                        <strong>{projectName}</strong>. This is an exciting
                        opportunity facilitated by{' '}
                        <Link href={enterpriseURL} style={enterpriseLink}>
                            {enterpriseName}
                        </Link>{' '}
                        through pre.dev.
                    </Text>
                    <Text style={text}>
                        We're thrilled to bring together your expertise and
                        vision to make this project a success.
                    </Text>
                    <Text style={text}>
                        To kick things off, we encourage you to schedule a
                        meeting and discuss the project in detail:
                    </Text>
                    <Button style={button} href={enterpriseSchedulingLink}>
                        Schedule Your First Meeting
                    </Button>
                    <Text style={reminderText}>
                        This initial meeting is crucial for aligning
                        expectations, discussing project scope, and addressing
                        any preliminary questions. We recommend preparing any
                        relevant documents or ideas beforehand.
                    </Text>
                </Section>

                <Hr style={hr} />

                <Text style={footer}>
                    This match is facilitated by{' '}
                    <Link href={enterpriseURL} style={enterpriseLink}>
                        {enterpriseName}
                    </Link>{' '}
                    through pre.dev. If you have any questions, please contact
                    our
                    <Link href="mailto:arjun@pre.dev" style={supportLink}>
                        {' '}
                        support team
                    </Link>
                    .
                </Text>

                <Img
                    src={`${baseUrl}/predevblack.png`}
                    width="100"
                    height="auto"
                    alt="Pre Dev"
                    style={footerLogo}
                />
                <Text style={copyright}>
                    © 2024 PRE DEV CORP. All Rights Reserved.
                </Text>
            </Container>
        </Body>
    </Html>
);

PreDevTriggerMatchEmail.PreviewProps = {
    enterpriseName: 'TechCorp Solutions',
    enterpriseLogo: 'https://example.com/techcorp-logo.png',
    enterpriseSchedulingLink: 'https://calendly.com/techcorp-meetings',
    introductionLogo: 'https://example.com/introduction-logo.png',
    clientName: 'Alice Johnson',
    developerName: 'Bob Smith',
    projectName: 'Innovative App',
    enterpriseURL: 'https://techcorp.com'
};

export default PreDevTriggerMatchEmail;

const main = {
    backgroundColor: '#f6f9fc',
    color: '#333333',
    fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif'
};

const container = {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '40px 20px'
};

const logo = {
    display: 'block',
    height: '50px'
};

const enterpriseLogoStyle = {
    margin: '20px auto',
    display: 'block'
};

const title = {
    fontSize: '28px',
    fontWeight: 'bold',
    textAlign: 'center' as const,
    margin: '30px 0',
    lineHeight: '1.4',
    color: '#1a1a1a'
};

const section = {
    backgroundColor: '#ffffff',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)'
};

const text = {
    marginTop: '10px',
    fontSize: '16px',
    lineHeight: '24px',
    margin: '0 0 20px 0',
    color: '#333333'
};

const button = {
    fontSize: '16px',
    backgroundColor: '#0070f3',
    color: '#ffffff',
    lineHeight: 1.5,
    borderRadius: '5px',
    padding: '12px 24px',
    textDecoration: 'none',
    textAlign: 'center' as const,
    display: 'inline-block'
};

const reminderText = {
    fontSize: '14px',
    fontStyle: 'italic',
    color: '#666666',
    margin: '20px 0 0 0',
    marginBottom: '10px'
};

const hr = {
    borderColor: '#e6ebf1',
    margin: '30px 0'
};

const footer = {
    fontSize: '14px',
    color: '#666666',
    textAlign: 'center' as const,
    margin: '20px 0'
};

const supportLink = {
    color: '#0070f3',
    textDecoration: 'none'
};

const footerLogo = {
    margin: '20px auto',
    display: 'block',
    opacity: 0.7
};

const copyright = {
    fontSize: '12px',
    color: '#8898aa',
    textAlign: 'center' as const
};

const enterpriseLink = {
    color: '#0070f3',
    textDecoration: 'none'
};
