import { useDispatch, useSelector } from 'react-redux';
import { selectSowVersion } from '../../../../redux/reducers/generic/project';
import { toast } from 'sonner';
import { selectJiraVersion } from '../../../../redux/reducers/generic/jira';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { getClientJiraProjects } from '../../../../redux/actions/enterprise/getClientJiraProjects';
import { syncJira } from '../../../../redux/actions/enterprise/syncJira';

export const SyncNewChanges = ({ projectId }) => {
    const dispatch = useDispatch();
    const version = useSelector(selectSowVersion);
    let jiraVersion = useSelector(selectJiraVersion);
    const [jiraProjects, setJiraProjects] = useState([]);

    useEffect(() => {
        let func = async () => {
            let projects = await dispatch(getClientJiraProjects());
            setJiraProjects(projects);
        };
        func();
    }, []);

    const handleJira = async jiraProjectId => {
        try {
            await dispatch(
                syncJira({
                    projectId,
                    version: version['mvp'],
                    jiraProjectId
                })
            );
        } catch (error) {
            console.log({ error });
            toast.error(error.message);
        }
    };

    return (
        <div className="flex flex-row gap-x-2 items-center justify-center">
            {!jiraVersion && jiraProjects.length > 0 && (
                <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button
                        type="button"
                        className={`${'cursor-pointer'} flex gap-x-2 justify-center text-white bg-blue-500 hover:bg-bleu-400 focus:ring-4 focus:outline-none focus:ring-[#0052CC]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-blue-500 dark:hover:bg-[#0052CC]/30`}
                    >
                        Choose Existng Project to Sync Too
                        <ChevronDownIcon
                            className="-mr-1 mt-1 h-4 w-4 text-gray-400"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="origin-top-left z-50 absolute left-0 mt-2 w-56 max-h-60 overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                {jiraProjects.map(({ id, name }) => (
                                    <Menu.Item key={id}>
                                        {({ active }) => (
                                            <button
                                                onClick={() => handleJira(id)}
                                                className={`${
                                                    active
                                                        ? 'bg-gray-100'
                                                        : 'bg-white'
                                                } group flex items-center w-full px-4 gap-x-4 py-2 text-sm font-medium text-left text-gray-700 rounded-lg hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
                                            >
                                                <img
                                                    src="/atlassian.svg"
                                                    alt="Atlassian"
                                                    className="filter invert"
                                                />
                                                <span className="flex items-center">
                                                    {name}
                                                </span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            )}
            <button
                onClick={() => handleJira()} // Use the correct provider ID
                type="button"
                className={`min-w-[200px] ${'cursor-pointer'} flex gap-x-2 justify-center text-white ${'bg-[#0052CC] hover:bg-[#0052CC]/90'} focus:ring-4 focus:outline-none focus:ring-[#0052CC]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-blue-500 dark:hover:bg-[#0052CC]/30`}
            >
                <img src="/atlassian.svg" />
                {!jiraVersion && 'Create New Jira Project and Sync It'}
                {jiraVersion && 'Sync Changes to Jira'}
            </button>
        </div>
    );
};
