import { useState } from 'react';
import { ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { setSort } from '../../../../redux/reducers/generic/projects';

export const TableHeader = () => {
    const dispatch = useDispatch();

    const [lastUpdatedOrder, setLastUpdatedOrder] = useState(1);
    const [messageCountOrder, setMessageCountOrder] = useState(1);

    const onLastUpdatedSortClick = () => {
        // const newSortOrder = lastUpdatedOrder * -1;
        // setLastUpdatedOrder(newSortOrder);
        // dispatch(setSort({ field: 'updated', order: newSortOrder }));
    };

    const onMessageCountSortClick = () => {
        // const newSortOrder = messageCountOrder * -1;
        // setMessageCountOrder(newSortOrder);
        // dispatch(setSort({ field: 'messageCount', order: newSortOrder }));
    };

    return (
        <thead>
            <tr className="border-t border-gray-200">
                <th
                    className="border-b border-gray-200 bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                >
                    <span className="lg:pl-2">Name</span>
                </th>
                <th
                    className="border-b border-gray-200 bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                >
                    Email
                </th>

                <th
                    className="hidden border-b border-gray-200 text-left bg-gray-50 px-2 py-3 text-sm font-semibold text-gray-900 md:table-cell"
                    scope="col"
                >
                    Budget
                </th>
                <th
                    className="cursor-pointer hidden border-b border-gray-200 bg-gray-50 px-2 py-3 text-right text-sm font-semibold text-gray-900 md:table-cell"
                    scope="col"
                    onClick={onLastUpdatedSortClick}
                >
                    <div className="flex items-center justify-center gap-x-2 w-full">
                        Updated
                        {/* <ArrowsUpDownIcon
                            className="h-5 w-5 hover:text-gray-500 cursor-pointer"
                            aria-hidden="true"
                        /> */}
                    </div>
                </th>
                {/* <th
                    className="cursor-pointer hidden border-b border-gray-200 bg-gray-50 px-2 py-3 text-center text-sm font-semibold text-gray-900 md:table-cell"
                    scope="col"
                    onClick={onMessageCountSortClick}
                >
                    <div className="flex items-center justify-center gap-x-2 w-full">
                        Messages
                        <ArrowsUpDownIcon
                            className="h-5 w-5 hover:text-gray-500 cursor-pointer"
                            aria-hidden="true"
                        />
                    </div>
                </th> */}
            </tr>
        </thead>
    );
};
