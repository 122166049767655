import {
    formatBoldText,
    formatBulletList,
    formatHeading,
    formatListItem,
    formatParagraph,
    formatText
} from './helper';

export const formatTechStack = (sow, previewMode) => {
    const groupTechByUseFor = techStack => {
        const grouped = {};
        techStack.forEach(tech => {
            if (!grouped[tech.useFor]) {
                grouped[tech.useFor] = [];
            }
            grouped[tech.useFor].push(tech.name);
        });
        return grouped;
    };

    const formatTechGroup = (useFor, techs) => {
        try {
            return formatListItem([
                formatParagraph([
                    formatBoldText(`${useFor}: `, false),
                    formatText(techs.join(', '), previewMode, true)
                ])
            ]);
        } catch (error) {
            console.log('Error formatting tech stack group:', error);
            return formatListItem([
                formatParagraph([formatText('Loading...', false)])
            ]);
        }
    };

    let formattedContent = [];
    try {
        let heading = formatHeading(2, [formatText('Tech Stack', false)]);
        let groupedTechStack = groupTechByUseFor(sow?.techStack || []);
        let techItems = Object.entries(groupedTechStack).map(
            ([useFor, techs]) => formatTechGroup(useFor, techs)
        );
        formattedContent = [heading, formatBulletList(techItems)];
    } catch (error) {
        console.log('Error formatting tech stack:', error);
        formattedContent = [
            formatHeading(2, [formatText('Tech Stack', false)]),
            formatParagraph([formatText('Error loading tech stack.', false)])
        ];
    }
    return formattedContent;
};
