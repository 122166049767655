import { UPDATE_ENTERPRISE_ORGANIZATION_BOOLEAN_FIELD } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import { fetchEnterpriseOrganizationSuccess } from '../../reducers/enterprise/enterpriseOrganization';

export const updateEnterpriseOrganizationBooleanField =
    (enterpriseOrganizationId, field, value) => async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: UPDATE_ENTERPRISE_ORGANIZATION_BOOLEAN_FIELD,
                variables: {
                    enterpriseOrganizationId,
                    field,
                    value
                },
                fetchPolicy: 'network-only'
            });

            if (data.updateEnterpriseOrganizationBooleanField) {
                dispatch(
                    fetchEnterpriseOrganizationSuccess(
                        data.updateEnterpriseOrganizationBooleanField
                    )
                );
                return 'Updated ' + field + ' successfully.';
            } else {
                return 'Failed to update ' + field + '.';
            }
        } catch (error) {
            return error.message;
        }
    };
