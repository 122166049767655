import {
    ClockIcon,
    GlobeAltIcon,
    QueueListIcon
} from '@heroicons/react/24/outline';
import { GlobalKnowledgeBase } from './GlobalKnowledgeBase';
import { HourEstimations } from './HourEstimations';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { SoWFormat } from './SoWFormat';
import { DefaultTechStack } from './DefaultTechStack';

export const KnowledgeManagement = () => {
    const sections = [
        {
            title: 'Default Tech Stack',
            icon: <QueueListIcon className="h-6 w-6" />,
            content: <DefaultTechStack />,
            tagline:
                'The recommended tech stack for each project will prioritize this list.'
        },
        {
            title: 'Global Knowledge Base',
            icon: <GlobeAltIcon className="h-6 w-6" />,
            content: <GlobalKnowledgeBase />,
            tagline:
                'Projects built under this organization will leverage the information from the files uploaded and activated to fine tune responses to user queries.'
        },
        {
            title: 'Hour Estimations',
            icon: <ClockIcon className="h-6 w-6" />,
            content: <HourEstimations />,
            tagline:
                'Documentation generated under this organization will leverage the information from the files to better estimate the time required to complete specific task items a project.'
        },
        {
            title: 'Documentation Format',
            icon: <DocumentIcon className="h-6 w-6" />,
            content: <SoWFormat />,
            tagline:
                'Documentation generated under this organization will leverage the format of the activated file.'
        }
    ];

    return (
        <>
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                <div className="min-w-0 flex-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                        Knowledge Management
                    </h3>
                </div>
                <div className="mt-4 flex sm:ml-4 sm:mt-0"></div>
            </div>

            <div className="px-4 sm:px-2 lg:px-4 py-2">
                <div className="grid sm:grid-cols-1 gap-8">
                    {sections.map((section, i) => (
                        <div className="bg-white shadow rounded-lg w-full border border-2">
                            <div className=" mx-4 flex mt-4 gap-x-2 text-base font-semibold items-center text-gray-900 w-full ">
                                {section.icon}
                                <span>{section.title}</span>
                            </div>
                            <div className="mx-4">
                                <p className="my-2 text-sm font-light text-gray-500 dark:text-gray-400">
                                    {section.tagline}
                                </p>
                                {section.content}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
