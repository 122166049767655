import { client } from '../../../api/client';
import { DELETE_PROJECT } from '../../../api/graphQL/solo/mutator';
import { removeProject } from '../../reducers/generic/projects';

export const deleteProject = projectId => async (dispatch, getState) => {
    try {
        projectId = projectId.toString();

        let { data } = await client.mutate({
            mutation: DELETE_PROJECT,
            variables: { projectId }
        });

        setTimeout(() => {
            dispatch(removeProject(projectId));
        }, 200);

        return data.deleteProject;
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
    }
};
