import { useEffect, useRef } from 'react';
import { useProject } from '../../../../context-providers/Project';
import { ChatItem } from './ChatItem';
import { useLoading } from '../../../../context-providers/Loading';
import { StreamingChat } from './StreaminingChat';
import { useDispatch } from 'react-redux';
import { toggleSubscribeBanner } from '../../../../redux/reducers/generic/billing';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { useSelector } from 'react-redux';
import { selectProjectArchitectureLoading } from '../../../../redux/reducers/generic/projectArchitecture';

export const ChatHistory = ({ isSolo }) => {
    let { chatHistory, project } = useProject();
    let dispatch = useDispatch();
    let waitingForChatResponse = chatHistory.waitingForChatResponse;
    let chat = chatHistory.data;

    let { loadingStatus } = useLoading();
    let { isSubbed } = useSubscriptionPayment();

    const chatContainer = useRef(null);
    let architectureLoading = useSelector(selectProjectArchitectureLoading);

    // various effects for the chat including scroll to bottom, refocus, progress, and autosize textarea
    useEffect(() => {
        try {
            if (chatContainer.current || architectureLoading) {
                chatContainer?.current?.scrollTo({
                    top: chatContainer?.current?.scrollHeight
                });
            }
            if (isSolo) {
                if ((chat.length > 10 || project.skip) && !isSubbed) {
                    dispatch(toggleSubscribeBanner(true));
                } else {
                    dispatch(toggleSubscribeBanner(false));
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [chat, waitingForChatResponse, chatContainer, architectureLoading]);

    useEffect(() => {
        //scroll to bottom of the chat
        try {
            if (chatContainer.current) {
                chatContainer?.current?.scrollTo({
                    top: chatContainer?.current?.scrollHeight
                });
            }
        } catch (error) {
            console.log(error);
        }
    }, [loadingStatus]);

    useEffect(() => {
        console.log(chatContainer?.current?.clientHeight);
    }, [chatContainer]);

    return (
        <div
            id="chatScroll"
            className="md:flex md:flex-col justify-end md:justify-start h-full lg:pb-0 overflow-y-auto"
            ref={chatContainer}
        >
            <div id="chatHistory">
                {chat &&
                    chat.map((message, i) => (
                        <ChatItem
                            isSolo={isSolo}
                            message={message}
                            i={i}
                            key={i}
                            length={chat.length}
                        />
                    ))}
                <StreamingChat chatContainer={chatContainer} />
            </div>
        </div>
    );
};
