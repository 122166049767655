import { useProject } from '../../../../context-providers/Project';
import Modal from '../../../Components/Modal';
import { useState } from 'react';
import { Input } from './Input';
import { Summaries } from './Summaries';

export const ExistingProjectContext = () => {
    let { project } = useProject();
    let { hasExistingContext } = project;

    let [open, setOpen] = useState(false);

    return (
        hasExistingContext && (
            <>
                <div className="flex px-6 border-gray-200 p-2">
                    <button
                        onClick={() => setOpen(true)}
                        className="border border-white bg-transparent text-white hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-blue-900"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>

                        <span className='ml-2'>Existing Project Context</span>

                    </button>
                </div>
                <ExistingProjectModal open={open} setOpen={setOpen} />
            </>
        )
    );
};

const ExistingProjectModal = ({ open, setOpen }) => {
    return (
        <Modal maxWidth="max-w-4xl" open={open} onClose={() => setOpen(false)}>
            <div className="flex flex-col w-4xl">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Existing Project Context
                </h2>
                <p className="text-sm text-gray-600 mt-2">
                    You can submit any documents or information here, such as
                    business plans, RFPs, or any other data that helps pre.dev
                    understand the context of your project.
                    <br />
                    <b>
                        Once submitted, you can close this modal and continue
                        your conversation with the pre.dev AI.
                    </b>
                </p>
                <div className="flex flex-col gap-y-2 w-full">
                    <Input />
                    <hr className="my-2" />
                    <Summaries />
                </div>
            </div>
        </Modal>
    );
};
