import { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const EditSubtaskForm = ({ subtask, roles, subtasksLength, setUpdateSubtask, setLocalRoles, allRoles, subtasks}) => {
    const [description, setDescription] = useState(subtask.description);
    const [complexityEstimate, setComplexityEstimate] = useState(subtask.complexityEstimate);
    const [hours, setHours] = useState(subtask.hours);
    const [selectedRoles, setSelectedRoles] = useState(subtask.roles);
    const [index, setIndex] = useState(subtask.index);
    const [showRolesDialog, setShowRolesDialog] = useState(false);
    const [availableRoles, setAvailableRoles] = useState(['Developer', 'Designer', 'Tester']);
  
    const [newRole, setNewRole] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    const handleAddRole = () => {
        if (newRole && !availableRoles.includes(newRole)) {
            setAvailableRoles([...availableRoles, newRole]);
            setLocalRoles([...roles, { name: newRole, shortHand: newRole }]);
            setNewRole('');
        }
    };

    const handleRemoveRole = role => {
        setAvailableRoles(availableRoles.filter(r => r !== role));
        setLocalRoles(roles.filter(r => r.name !== role));
    };

    const handleSelectRole = role => {
        if (!selectedRoles.includes(role)) {
            setSelectedRoles([...selectedRoles, role]);
        } else {
            setSelectedRoles(selectedRoles.filter(r => r !== role));
        }
    };

    useEffect(() => {
        setDescription(subtask.description);
        setComplexityEstimate(subtask.complexityEstimate);
        setHours(subtask.hours);
        setSelectedRoles(subtask.roles);
        setIndex(subtask.index);
    }, [subtask]);

    useEffect(() => {
        setUpdateSubtask({
            ...subtask,
            description,
            complexityEstimate,
            hours,
            roles: selectedRoles,
            index
        });
    }, [description, complexityEstimate, hours, selectedRoles, index, setUpdateSubtask, subtask]);

    return (
        <form className="space-y-4">
            <div>
                <label className="block text-sm font-medium text-gray-300">Description</label>
                <textarea
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    className="mt-1 block w-full p-2 bg-gray-800 text-white rounded-md"
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-300">Complexity Estimate</label>
                <select
                    value={complexityEstimate}
                    onChange={e => setComplexityEstimate(e.target.value)}
                    className="mt-1 block w-full p-2 bg-gray-800 text-white rounded-md"
                >
                    <option value="S">Straightforward</option>
                    <option value="M">Moderate</option>
                    <option value="C">Complex</option>
                </select>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-300">Hours</label>
                <select
                    value={hours}
                    onChange={e => setHours(e.target.value)}
                    className="mt-1 block w-full p-2 bg-gray-800 text-white rounded-md"
                >
                    {Array.from({ length: 101 }, (_, i) => (
                        <option key={i} value={i}>
                            {i}
                        </option>
                    ))}
                </select>
            </div>
            <div>
            {showDropdown && (
                        <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {allRoles.filter(role => role.name && !roles.some(r => r.name === role.name))?.map((availableRole, index) => (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            setLocalRoles([...roles, { name: availableRole.name, shortHand: availableRole.shortHand }]);
                                            setShowDropdown(false);
                                        }}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                        role="menuitem"
                                    >
                                        {availableRole.name} ({availableRole.shortHand})
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                <label className="block text-sm font-medium text-gray-300">Roles</label>
                <div className="mt-1 block w-full p-2 bg-gray-800 text-white rounded-md">
                    {roles.map((role, i) => (
                        <span
                            key={i}
                            className={`inline-block px-3 py-1 mr-2 mb-2 rounded-full cursor-pointer ${
                                selectedRoles.some(selectedRole => selectedRole.name === role.name)
                                    ? 'bg-blue-600 text-white'
                                    : 'bg-gray-600 text-gray-300'
                            }`}
                        >
                            {role.name} ({role.shortHand})
                            <button
                                type="button"
                                onClick={() => {
                                    setLocalRoles(roles.filter(localRole => localRole.name !== role.name));
                                }}
                                className="ml-2 text-gray-400 hover:text-gray-500 focus:outline-none"
                            >
                                <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                            </button>
                        </span>
                    ))}
                    
                    <button
                        type="button"
                        onClick={() => setShowDropdown(!showDropdown)}
                        className="inline-flex items-center px-2 py-0.5 rounded-full text-sm font-medium bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        +
                    </button>
                 
                </div>
            </div>
        
        </form>
    );
};
