import { VERIFY_PHONE_NUMBER } from '../../../api/graphQL';
import { client } from '../../../api/client';

export const verifyPhoneNumber =
    (phoneNumber: string, code: string) => async () => {
        try {
            const response = await client.mutate({
                mutation: VERIFY_PHONE_NUMBER,
                variables: {
                    phoneNumber,
                    code
                }
            });
            return response?.data?.verifyPhoneNumber;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
            throw error;
        }
    };
