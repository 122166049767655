import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { RadioGroup } from '@headlessui/react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const options = [
    {
        value: 'new',
        title: 'New Project',
        label: 'Scope out a new project'
    },
    {
        value: 'existing',
        title: 'Existing Project',
        label: 'Scope out new features for an existing project'
    }
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const NewExistingProjectPicker = ({ option, setOption }) => {
    const handleSelect = e => {
        console.log(e);
        setOption(e);
    };

    return (
        <div className="mt-2 mx-auto w-full text-center mb-2">
            <p className="font-light text-gray-500 dark:text-gray-400 text-sm">
                I want to scope out:
            </p>
            <RadioGroup value={option} onChange={handleSelect}>
                <div className="mt-2 grid grid-flow-col items-center justify-center gap-x-4">
                    {options.map(({ value, title }) => (
                        <>
                            <RadioGroup.Option
                                key={value.id}
                                value={value}
                                data-tooltip-id={value}
                                className={({ active, checked }) =>
                                    classNames(
                                        checked
                                            ? 'border-blue-600 ring-2 ring-blue-600'
                                            : 'border-gray-300',
                                        'relative flex cursor-pointer rounded-lg border bg-white py-2 px-2 gap-x-1 shadow-sm focus:outline-none'
                                    )
                                }
                            >
                                {({ checked, active }) => (
                                    <>
                                        <span className="flex flex-1">
                                            <RadioGroup.Label
                                                as="span"
                                                className="text-sm font-medium text-gray-900"
                                            >
                                                {title}
                                            </RadioGroup.Label>
                                        </span>
                                        <CheckCircleIcon
                                            className={classNames(
                                                !checked ? 'invisible' : '',
                                                'h-5 w-5 text-blue-600'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </>
                                )}
                            </RadioGroup.Option>
                        </>
                    ))}
                </div>

                <>
                    {options.map(({ value, label }) => (
                        <ReactTooltip
                            key={value}
                            id={value}
                            className="max-w-[200px]"
                            place="top"
                            content={label}
                        />
                    ))}
                </>
            </RadioGroup>
        </div>
    );
};

export default NewExistingProjectPicker;
