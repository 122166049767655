import { UPGRADE_TO_WIDGET_MANAGEMENT } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchEnterpriseOrganizationSuccess,
    fetchEnterpriseOrganizationFailure
} from '../../reducers/enterprise/enterpriseOrganization';

export const upgradeToWidgetManagement =
    ({ enterpriseOrganizationId }) =>
    async dispatch => {
        try {
            const { data } = await client.mutate({
                mutation: UPGRADE_TO_WIDGET_MANAGEMENT,
                variables: { enterpriseOrganizationId },
                fetchPolicy: 'network-only'
            });

            if (data.upgradeToWidgetManagement)
                dispatch(
                    fetchEnterpriseOrganizationSuccess(
                        data.upgradeToWidgetManagement
                    )
                );
        } catch (error) {
            console.error('Error upgrading to Widget Management:', error);
            dispatch(fetchEnterpriseOrganizationFailure(error));
        }
    };
