import { client } from '../../../api/client';
import { GET_AGENCY_SCHEDULING_LINK } from '../../../api/graphQL/solo/query';

export const getAgencySchedulingLink =
    creditId => async (dispatch, getState) => {
        try {
            const { data } = await client.query({
                query: GET_AGENCY_SCHEDULING_LINK,
                variables: { creditId }
            });

            return data.getAgencySchedulingLink;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }
    };
