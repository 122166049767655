import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { updateEnterpriseOrganizationStringField } from '../../../../redux/actions/enterprise/updateEnterpriseOrganizationStringField';
import { Spinner } from '../../../Components/Spinner';
import { toast } from 'sonner';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export const InputField = ({ field, label, placeholder, isTextArea }) => {
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const { isSubbed } = useSubscriptionPayment();

    const [value, setValue] = useState(enterpriseOrganization?.[field]);
    const [calendlyLoading, setValueLoading] = useState(null);

    const handleChangeValue = e => {
        setValue(e.target.value);
    };

    const updateValue = async () => {
        if (!isSubbed) {
            toast.error(
                'Please subscribe to Enterprise Advanced to update fields'
            );
            return;
        }
        setValueLoading(true);
        let val = value;
        if (!val) {
            val = '';
        }
        const response = await dispatch(
            updateEnterpriseOrganizationStringField(
                enterpriseOrganizationId,
                field,
                value
            )
        );
        toast.success(response.toString());
        setValueLoading(false);
    };

    useEffect(() => {
        setValue(value);
    }, [value]);

    return (
        <>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    updateValue();
                }}
                className="flex flex-col"
            >
                <p className="text-sm font-medium text-gray-700 mr-4">
                    {label}
                </p>
                <div className="flex flex-row mt-1">
                    {isTextArea ? (
                        <textarea
                            disabled={!enterpriseOrganization.isAdmin}
                            type="text"
                            name="email"
                            onChange={handleChangeValue}
                            value={value}
                            rows="3"
                            id="email"
                            className="w-full block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-2 hover:ring-gray-300"
                            placeholder={placeholder}
                        />
                    ) : (
                        <input
                            disabled={!enterpriseOrganization.isAdmin}
                            type="text"
                            name="email"
                            onChange={handleChangeValue}
                            value={value}
                            id="email"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-2 hover:ring-gray-300"
                            placeholder={placeholder}
                        />
                    )}
                    {enterpriseOrganization.isAdmin && (
                        <button
                            type="submit"
                            className={`bg-blue-600 hover:bg-blue-500 inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ml-3`}
                        >
                            {calendlyLoading ? (
                                <Spinner w="5" h="5" />
                            ) : (
                                <>Update</>
                            )}
                        </button>
                    )}
                </div>
            </form>
        </>
    );
};

// {!value && (
// 	<div className="mt-5 max-w-xl text-sm text-red-500">
// 		<p>Please add a Value link to allow clients to schedule meetings</p>
// 	</div>
// )}
