import { GET_PROJECT_BY_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchProjectSuccess,
    fetchProjectFailure,
    setMeetingRequested
} from '../../reducers/generic/project';

import { setChatHistory } from '../../reducers/generic/chatHistory';
import { setProjectArchitecture } from '../../reducers/generic/projectArchitecture';
import {
    setEnterpriseWidgetProjectId,
    setUserEmail
} from '../../reducers/enterprise_widget/enterpriseWidget';
import { reload } from '../../../constants';

export const getProjectByEnterpriseOrganization =
    ({ projectId, enterpriseOrganizationId }) =>
    async dispatch => {
        try {
            const { data } = await client.query({
                query: GET_PROJECT_BY_ENTERPRISE_ORGANIZATION,
                variables: { projectId, enterpriseOrganizationId },
                fetchPolicy: 'network-only'
            });

            if (data.projectByEnterpriseOrganization === null) {
                // throw new Error("Project not found");
                return;
            }

            //Set Project Id
            dispatch(setEnterpriseWidgetProjectId(projectId));

            // Set User Email
            dispatch(
                setUserEmail(data.projectByEnterpriseOrganization.userEmail)
            );

            //Set up Project
            dispatch(fetchProjectSuccess(data.projectByEnterpriseOrganization));

            //Set up Chat History
            dispatch(
                setChatHistory(data.projectByEnterpriseOrganization.chatHistory)
            );

            //Set up Project Architecture
            dispatch(
                setProjectArchitecture({
                    graphs: data.projectByEnterpriseOrganization.graphs
                })
            );

            //Set up request meeting status
            dispatch(
                setMeetingRequested(
                    data.projectByEnterpriseOrganization.meetingRequested
                )
            );
        } catch (error) {
            console.log({ error });
            dispatch(fetchProjectFailure(error));
            reload();
        }
    };
