import { REMOVE_USER_SKILL_FROM_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import {
    fetchEnterpriseUsersRequest,
    fetchEnterpriseUsersSuccess,
    fetchEnterpriseUsersFailure
} from '../../reducers/enterprise/enterpriseUsers';

export const removeUserSkillFromEnterpriseOrganization =
    (userEmail, enterpriseOrganizationId, skillIndex) =>
    async (dispatch, getState) => {
        dispatch(fetchEnterpriseUsersRequest());
        try {
            const { data } = await client.mutate({
                mutation: REMOVE_USER_SKILL_FROM_ENTERPRISE_ORGANIZATION,
                variables: {
                    userEmail,
                    enterpriseOrganizationId,
                    skillIndex
                },
                fetchPolicy: 'network-only'
            });

            dispatch(
                fetchEnterpriseUsersSuccess(
                    data.removeUserSkillFromEnterpriseOrganization
                        .enterpriseUsers
                )
            );
            return `Successfully removed skill from ${userEmail}'s profile`;
        } catch (error) {
            console.log({
                error,
                userEmail,
                enterpriseOrganizationId,
                skillIndex
            });
            dispatch(fetchEnterpriseUsersFailure(error));
            return error.message;
        }
    };
