import React, { useState, useRef, useEffect } from 'react';
import { HiPencil, HiX } from 'react-icons/hi';
import { Spinner } from '../../../Components/Spinner';
import titleNames from './title_names.json';
import { useDispatch } from 'react-redux';
import { updateUserTitleOfEnterpriseOrganization } from '../../../../redux/actions/enterprise/updateUserTitleOfEnterpriseOrganization';
import { toast } from 'sonner';

export const Title = ({ enterpriseOrganizationId, user, isAdmin }) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [tempTitle, setTempTitle] = useState(user.title);
    const [titleLoading, setTitleLoading] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const suggestionsRef = useRef(null);

    const handleUpdateTitle = async () => {
        setTitleLoading(true);
        const response = await dispatch(
            updateUserTitleOfEnterpriseOrganization(
                user.userEmail,
                enterpriseOrganizationId,
                tempTitle
            )
        );
        setTitleLoading(false);
        setIsEditing(false);
        setShowSuggestions(false);
        toast(response);
    };

    useEffect(() => {
        if (isEditing) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isEditing]);

    const handleClickOutside = event => {
        if (
            suggestionsRef.current &&
            !suggestionsRef.current.contains(event.target)
        ) {
            setShowSuggestions(false);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setTempTitle(user.title);
        setShowSuggestions(false);
    };

    const handleSave = e => {
        e.preventDefault();
        handleUpdateTitle();
    };

    const handleInputChange = e => {
        const value = e.target.value;
        setTempTitle(value);
        if (value.length > 0) {
            const filteredSuggestions = titleNames
                .filter(item =>
                    item.name.toLowerCase().includes(value.toLowerCase())
                )
                .map(item => item.name);
            setSuggestions(filteredSuggestions);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = suggestion => {
        setTempTitle(suggestion);
        setShowSuggestions(false);
    };

    return (
        <div>
            <h4 className="text-sm font-medium text-gray-700 mb-1">Title</h4>
            <div className="flex items-center">
                {isAdmin && isEditing ? (
                    <form
                        onSubmit={handleSave}
                        className="flex items-center flex-grow relative"
                    >
                        <input
                            type="text"
                            name="title"
                            id="title"
                            disabled={titleLoading}
                            onChange={handleInputChange}
                            value={tempTitle}
                            className="flex-grow block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="Enter user's role"
                        />
                        {showSuggestions && suggestions.length > 0 && (
                            <ul
                                ref={suggestionsRef}
                                className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg max-h-60 overflow-y-auto top-full"
                            >
                                {suggestions.map((suggestion, index) => (
                                    <li
                                        key={index}
                                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                        onClick={() =>
                                            handleSuggestionClick(suggestion)
                                        }
                                    >
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}
                        <button
                            type="submit"
                            className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            disabled={titleLoading}
                        >
                            {titleLoading ? <Spinner w="5" h="5" /> : 'Save'}
                        </button>
                        {!titleLoading && (
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="ml-2 inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                            >
                                <HiX className="h-4 w-4" aria-hidden="true" />
                            </button>
                        )}
                    </form>
                ) : (
                    <>
                        <span className="text-lg font-medium text-gray-900 mr-2">
                            {tempTitle || 'No role set'}
                        </span>
                        {isAdmin && (
                            <button
                                onClick={handleEdit}
                                className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                <HiPencil
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                />
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
