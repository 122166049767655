import { UploadWireframe } from './UploadWireframe';
import { useDispatch, useSelector } from 'react-redux';
import { getUIView } from '../../../../redux/actions/solo/getUIView';
import { selectGettingUIView } from '../../../../redux/reducers/generic/projectArchitecture';
import { Spinner } from '../../../Components/Spinner';

export const UiCta = ({ node, projectId, loading }) => {
    const dispatch = useDispatch();
    const gettingUIView = useSelector(selectGettingUIView);
    const generateUI = () => {
        // alert(node._id)
        dispatch(
            getUIView({
                nodeId: node._id,
                projectId: projectId,
                forceNew: true
            })
        );
    };
    return (
        <>
            <div className="dark pt-4 px-4 mx-auto flex flex-col items-center justify-center">
                <div className="flex mb-4 flex-row gap-x-2 items-center justify-center mx-auto max-w-screen-sm text-center">
                    <img
                        src="/predev.png"
                        className="justify-center w-14 bg-black rounded p-1"
                        alt="Company Logo"
                    />
                    <h2 className="text-2xl tracking-tight font-extrabold leading-tight text-slate-50">
                        <span className="text-purple-600">Code</span>{' '}
                        Accelerator
                    </h2>
                </div>
                <div className="mx-auto max-w-screen-sm text-center break-words">
                    <h2 className="mb-4 text-2xl tracking-tight font-extrabold leading-tight text-slate-50 break-words">
                        Let's start building your code for the{' '}
                        <span className="text-blue-600 break-words">
                            {node?.label}
                        </span>{' '}
                        node.
                    </h2>
                    <p className="font-light text-gray-500 dark:text-gray-300 text-lg mb-4 break-words">
                        First, either upload an existing wireframe design or
                        click "Generate UI" to start creating the first version
                        of your view.
                    </p>

                    {!gettingUIView && (
                        <>
                            <button
                                onClick={generateUI}
                                type="button"
                                className="mt-4 flex-none text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-3 text-center md:mr-0 bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Generate UI{' '}
                                <span aria-hidden="true">&rarr;</span>
                            </button>
                            <div className="text-lg my-4 text-bold mx-auto text-slate-50">
                                or
                            </div>
                            <div className="mx-auto flex justify-center break-words">
                                <UploadWireframe
                                    node={node}
                                    projectId={projectId}
                                />
                            </div>
                        </>
                    )}
                    {gettingUIView && (
                        <div className="flex items-center justify-center">
                            <div className="flex items-center justify-center gap-x-2 border border-slate-700 bg-slate-800 p-4 rounded-md w-fit px-4">
                                <Spinner w="6" />
                                <span className="ml-3 text-lg text-slate-50">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
