import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false,
    waitingForChatResponse: false,
    waitingForSuggestion: false,
    error: null
};

export const uiviewSlice = createSlice({
    name: 'uiview',
    initialState,
    reducers: {
        clearStateChatHistory: state => {
            state.data = [];
            state.loading = false;
            state.error = null;
            state.waitingForChatResponse = false;
            state.waitingForSuggestion = false;
        },
        setChatHistory: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        removeLastChat: state => {
            state.data = state.data.slice(0, state.data.length - 1);
        },
        waitingForChatResponse: (state, action) => {
            state.waitingForChatResponse = action.payload;
        },
        waitingForSuggestion: (state, action) => {
            state.waitingForSuggestion = action.payload;
        },
        fetchChatRequest: state => {
            state.loading = true;
        },
        appendChat: (state, action) => {
            state.data = [...state.data, action.payload];
        },
        fetchChatFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        addTagToLastMessage: (state, action) => {
            let tagged = state.data[state.data.length - 1].tagged;
            if (!tagged) {
                state.data[state.data.length - 1].tagged = action.payload;
            } else {
                state.data[state.data.length - 1].tagged +=
                    `,${action.payload}`;
            }
        }
    }
});

export const {
    setChatHistory,
    removeLastChat,
    waitingForChatResponse,
    fetchChatRequest,
    fetchChatFailure,
    appendChat,
    clearStateChatHistory,
    waitingForSuggestion,
    addTagToLastMessage
} = uiviewSlice.actions;
export const selectChatHistory = state => state.uiview;
export const selectWaitingForChatResponse = state =>
    state.uiview.waitingForChatResponse;
export const selectWaitingForSuggestion = state =>
    state.uiview.waitingForSuggestion;

export default uiviewSlice.reducer;
