import { useDispatch, useSelector } from 'react-redux';
import {
    removeKnowledgeBaseFile,
    selectKnowledgeBaseFiles,
    updateKnowledgeBaseFile
} from '../../../redux/reducers/enterprise/enterpriseOrganization';
import { SwitchDefault } from '../WidgetManagement/Helpers/SwitchDefault';
import { getSignedDownloadURL } from '../../../redux/actions/solo/getSignedDownloadURL';
import { activateFileForKnowledgeBase } from '../../../redux/actions/enterprise/activateFileForKnowledgeBase';
import { deactivateFileForKnowledgeBase } from '../../../redux/actions/enterprise/deactivateFileForKnowledgeBase';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../Components/Spinner';
import { toast } from 'sonner';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { removeFileFromKnowledgeBase } from '../../../redux/actions/enterprise/removeFileFromKnowledgeBase';
import { useSubscription } from '@apollo/client';
import { KNOWLEDGE_BASE_FILE_SUBSCRIPTION } from '../../../api/graphQL/solo/subscription';

export const FileList = ({ type }) => {
    let fileList = useSelector(selectKnowledgeBaseFiles);
    fileList = fileList.filter(file => file.type === type);

    return (
        <div className="flex flex-col gap-4">
            <h2
                className={`text-sm font-bold text-gray-500 py-2 border-b border-gray-400`}
                style={{ marginBottom: '-10px' }}
            >
                Files ({fileList.length})
            </h2>
            <div className="max-h-[500px] overflow-auto mb-2">
                <table className="w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                            >
                                <span className="">File Name</span>
                            </th>
                            <th
                                scope="col"
                                className="py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                                Created
                            </th>
                            <th
                                scope="col"
                                className="py-3.5 text-center text-sm font-semibold text-gray-900"
                            >
                                Activated
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {fileList.map((file, i) => (
                            <FileItem file={file} i={i} key={i} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const FileItem = ({ file, i }) => {
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    const handleDownload = async () => {
        const signedUrl = await dispatch(getSignedDownloadURL(file.fileKeyS3));
        window.open(signedUrl, '_blank');
    };

    useSubscription(KNOWLEDGE_BASE_FILE_SUBSCRIPTION, {
        variables: { fileId: file._id.toString() },
        shouldResubscribe: true,
        onData: data => {
            try {
                console.log(data);
                dispatch(
                    updateKnowledgeBaseFile({
                        ...data.data.data.knowledgeBaseFileStream
                    })
                );
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
    });

    const handleActivate = async () => {
        const fileCopy = JSON.parse(JSON.stringify(file));
        dispatch(
            updateKnowledgeBaseFile({
                ...fileCopy,
                loading: true,
                loadingAt: new Date()
            })
        );
        let message = '';
        if (!file.activated) {
            message = await dispatch(
                activateFileForKnowledgeBase(enterpriseOrganizationId, file._id)
            );
        } else {
            message = await dispatch(
                deactivateFileForKnowledgeBase(
                    enterpriseOrganizationId,
                    file._id
                )
            );
        }

        if (message.includes('Error')) {
            toast.error(message);
        } else {
            toast.success(message);
        }
    };

    const handleDelete = async () => {
        try {
            setDeleteLoading(true);
            await dispatch(
                removeFileFromKnowledgeBase(file._id, enterpriseOrganizationId)
            );
        } catch (error) {
            console.log(error);
        }
        setDeleteLoading(false);
    };

    return (
        <tr key={i} className="cursor-pointer hover:bg-gray-50 mb-4">
            <td className="text-sm py-4 align-left">
                <div className="font-medium text-left text-gray-900 break-words">
                    <button
                        className="text-left"
                        onClick={() => handleDownload()}
                    >
                        {file.name}
                    </button>
                </div>
            </td>
            <td className="text-sm text-gray-500 break-words">
                {isMobile
                    ? moment(file.created).format('MM/DD/YY')
                    : moment(file.created).format('MMM DD YYYY: h:mm a')}
            </td>
            <td className="text-center">
                {file?.loading &&
                Date.now() - new Date(file?.loadingAt).getTime() <
                    2 * 60 * 1000 ? (
                    <Spinner w={'6'} />
                ) : (
                    <SwitchDefault
                        setEnabled={() => handleActivate()}
                        enabled={file.activated}
                    />
                )}
            </td>
            {!file.activated && !file.loading && (
                <td className="text-center items-center">
                    {deleteLoading && <Spinner w={'6'} color="white" h="6" />}
                    {!deleteLoading && (
                        <button
                            onClick={() => handleDelete()}
                            className="text-red-600 hover:text-red-700 text-sm"
                        >
                            <TrashIcon className="h-5" />
                        </button>
                    )}
                </td>
            )}
        </tr>
    );
};
