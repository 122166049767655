import { Spinner } from '../Spinner';

export const Loading = ({ text }) => {
    const url = window.location.href;
    let showPreDev =
        url.includes('https://pre.dev') || url.includes('localhost:3000');

    return (
        <div className="flex flex-col justify-center items-center h-full">
            <div className="flex flex-row rounded-xl items-center gap-x-2">
                {/* {showPreDev && (
                    <img
                        src="/predev_logo_name_white.png"
                        className="h-16 rounded-xl"
                        alt=""
                    />
                )} */}
                <div className="flex flex-row h-full items-center justify-center">
                    <Spinner w="12" />
                </div>
            </div>
        </div>
    );
};
