import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

export const FeaturesSection = () => {
    const controls = useAnimation();
    const sectionRef = useRef(null);
    useEffect(() => {
        const sectionNode = sectionRef.current;
        const observer = new IntersectionObserver(
            entries => {
                const [entry] = entries;
                if (entry.isIntersecting) {
                    controls.start({ opacity: 1, y: 0 });
                }
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (sectionNode) {
            observer.observe(sectionNode);
        }

        return () => {
            if (sectionNode) {
                observer.unobserve(sectionNode);
            }
        };
    }, [controls]);

    return (
        <section className="bg-white px-4 pb-10 ">
            <motion.div
                ref={sectionRef}
                initial={{ opacity: 0, y: 20 }}
                animate={controls}
                className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6"
            >
                <div className="mt-8 max-w-screen-md mb-8 lg:mb-16">
                    <h2 className="mb-4 text-3xl sm:text-5xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                        Scaleable ideation to development.
                    </h2>
                    <p className="font-light text-gray-600 text-l sm:text-xl dark:text-gray-400">
                        In-house Project Teams, Software Agencies and Venture
                        Studios spend countless hours on creating project plans
                        and documentation, only to have it wasted on expensive
                        engineering teams due to poor software planning.
                        <br />
                        <br />
                        Instead, allow <b className="text-blue-700">
                            pre.dev
                        </b>{' '}
                        to help plan, build, deliver, and maintain the software
                        you need to take your business to the next level.
                    </p>
                </div>
                <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                    <div>
                        <div className="flex justify-center items-center mb-4 w-20 h-20 rounded-2xl bg-gray-100 lg:h-20 lg:w-20 dark:bg-primary-900">
                            <svg
                                className="w-[48] h-[48px] text-blue-700 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 18"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M16 5h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-2v3l-4-3H8m4-13H2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h2v3l4-3h4a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
                                />
                            </svg>
                        </div>
                        <h3 className="mb-2 text-lg sm:text-xl font-bold dark:text-white">
                            1. Extract project requirements.
                        </h3>
                        <p className="font-light text-md sm:text-lg text-gray-500 dark:text-gray-400">
                            Embed the pre.dev AI Widget into your website with
                            ease, or use it in the dashboard. It's designed to
                            gather project requirements through quick guided
                            conversations with your customers and team.
                        </p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-4 w-20 h-20 rounded-2xl bg-gray-100 lg:h-20 lg:w-20 dark:bg-primary-900">
                            <svg
                                className="w-[48] h-[48px] text-blue-700 dark:text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                                />
                            </svg>
                        </div>
                        <h3 className="mb-2 text-lg sm:text-xl font-bold dark:text-white">
                            2. Analyze the architecture.
                        </h3>
                        <p className="font-light text-md sm:text-lg text-gray-500 dark:text-gray-400">
                            As projects are being discussed with the pre.dev AI,
                            the software architecture and project plan is
                            generated in real-time. Get deep insights into what
                            is being built, and how it will be built.
                        </p>
                    </div>
                    <div>
                        <div className="flex justify-center items-center mb-4 w-20 h-20 rounded-2xl bg-gray-100 lg:h-20 lg:w-20 dark:bg-primary-900">
                            <svg
                                className="w-[48] h-[48px] text-blue-700 dark:text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
                                />
                            </svg>
                        </div>
                        <h3 className="mb-2 text-lg sm:text-xl font-bold dark:text-white">
                            3. Fine tune the experience.
                        </h3>
                        <p className="font-light text-md sm:text-lg text-gray-500 dark:text-gray-400">
                            Tailor the pre.dev AI to your company's needs by
                            configuring the knowledge base it uses, ensuring all
                            interactions are secure and proprietary to your
                            organization. Customize the AI widget to match your
                            brand's look and feel, creating a seamless
                            experience for your users.
                        </p>
                    </div>
                </div>
                <div>
                    <div style={{ position: 'relative' }} className="mt-32">
                        <iframe
                            width="100%"
                            height="500px"
                            src="https://player.vimeo.com/video/900423284?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            title="pre.dev"
                        ></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
            </motion.div>
        </section>
    );
};
