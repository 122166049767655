export const placeholders = [
    'I would like to build an AI-powered personal assistant app...',
    'I would like to build an augmented reality shopping app...',
    'I would like to build a VR travel experience app...',
    'I would like to build a blockchain-based voting app...',
    'I would like to build a machine learning-based recommendation system...',
    'I would like to build a smart home automation app...',
    'I would like to build a fitness tracking app with AI coaching...',
    'I would like to build a language learning app with speech recognition...',
    'I would like to build a telemedicine app with video consultations...',
    'I would like to build a social media platform for pet owners...',
    'I would like to build a real-time collaboration tool for remote teams...',
    'I would like to build a personalized news aggregation app...',
    'I would like to build a financial planning app with AI insights...',
    'I would like to build a travel itinerary planner with AR navigation...',
    'I would like to build a mental health support app with chatbots...',
    'I would like to build a decentralized file storage system...',
    'I would like to build a virtual event platform with networking features...',
    'I would like to build a gamified learning platform for kids...',
    'I would like to build a weather forecasting app with AI predictions...',
    'I would like to build a music streaming app with personalized playlists...',
    'I would like to build a sustainable living tracker with carbon footprint analysis...',
    'I would like to build a virtual interior design platform with AR visualization...',
    'I would like to build a food waste reduction app connecting restaurants and charities...',
    'I would like to build a skill-sharing marketplace with video tutorials...',
    'I would like to build a pet healthcare monitoring system with IoT sensors...',
    'I would like to build a crowdfunding platform for scientific research...',
    'I would like to build an AI-powered resume builder and career advisor...',
    'I would like to build a smart city navigation app for accessibility...',
    'I would like to build a digital art marketplace with NFT integration...',
    'I would like to build a volunteer matching platform with impact tracking...',
    'I would like to build a personal cybersecurity assistant with real-time monitoring...',
    'I would like to build an augmented reality education platform...',
    'I would like to build a sustainable fashion marketplace with blockchain tracking...',
    'I would like to build a mental wellness app with biometric monitoring...',
    'I would like to build a smart parking solution with real-time availability...',
    'I would like to build a collaborative music creation platform...',
    'I would like to build a virtual cooking assistant with AR recipe guides...',
    'I would like to build a community emergency response system...',
    'I would like to build a digital identity verification platform...',
    'I would like to build an AI-powered legal document analyzer...',
    'I would like to build a smart agriculture monitoring system...',
    'I would like to build a virtual museum tour platform with AR exhibits...',
    'I would like to build a peer-to-peer energy trading platform...',
    'I would like to build a voice-controlled smart home entertainment system...',
    'I would like to build a digital inheritance management platform...',
    'I would like to build an AI-powered personal stylist app...',
    'I would like to build a virtual reality meditation space...',
    'I would like to build a blockchain-based supply chain tracker...',
    'I would like to build a smart water consumption monitoring system...',
    'I would like to build an AI-powered language translation app...',
    'I would like to build a virtual reality fitness training platform...',
    'I would like to build a smart waste management system...',
    'I would like to build a digital time capsule with blockchain security...',
    'I would like to build an AI-powered pet behavior analyzer...',
    'I would like to build a virtual reality art creation platform...',
    'I would like to build a smart home security system with AI detection...',
    'I would like to build a digital democracy platform with secure voting...',
    'I would like to build an augmented reality navigation system...',
    'I would like to build a personal finance advisor with AI insights...',
    'I would like to build a virtual reality social platform...',
    'I would like to build a smart energy management system...',
    'I would like to build an AI-powered healthcare diagnostic tool...',
    'I would like to build a blockchain-based credential verification system...',
    'I would like to build a virtual reality education platform...',
    'I would like to build a smart traffic management system...',
    'I would like to build an AI-powered content moderation platform...',
    'I would like to build a decentralized social media platform...',
    'I would like to build a virtual reality therapy platform...',
    'I would like to build a smart waste sorting system with AI...',
    'I would like to build an augmented reality gaming platform...',
    'I would like to build a blockchain-based identity management system...'
];
