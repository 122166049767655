import { GET_PROJECTS_BY_USER } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchProjectsRequest,
    fetchProjectsSuccess,
    fetchProjectsFailure,
    fetchPaginatedProjects
} from '../../reducers/generic/projects';
import { projectsLimit } from '../../../constants';

// Action Creators
export const getProjectsByUser =
    ({ skip = 0, limit = projectsLimit, searchQuery }) =>
    async (dispatch, getState) => {
        dispatch(fetchProjectsRequest());
        try {
            const { data } = await client.query({
                query: GET_PROJECTS_BY_USER,
                variables: { skip, limit, searchQuery },
                fetchPolicy: 'network-only'
            });

            if (!getState().enterpriseOrganization.id)
                if (skip > 0)
                    dispatch(
                        fetchPaginatedProjects(data.projectsByUser.projects)
                    );
                else dispatch(fetchProjectsSuccess(data.projectsByUser));
        } catch (error) {
            console.log({ error });
            dispatch(fetchProjectsFailure(error));
        }
    };
