import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: null,
    error: null,
    numUsersLeft: null,
    loading: null
};

export const enterpriseUsersSlice = createSlice({
    name: 'enterpriseUsers',
    initialState,
    reducers: {
        fetchEnterpriseUsersRequest: (state, action) => {
            state.loading = true;
        },
        updateNumUsersLeft: (state, action) => {
            state.numUsersLeft = action.payload;
        },
        fetchEnterpriseUsersSuccess: (state, action) => {
            state.data = action.payload;
            state.loading = null;
        },
        fetchEnterpriseUsersFailure: (state, action) => {
            state.error = action.payload;
            state.loading = null;
        }
    }
});
export const {
    fetchEnterpriseUsersSuccess,
    fetchEnterpriseUsersRequest,
    fetchEnterpriseUsersFailure,
    updateNumUsersLeft
} = enterpriseUsersSlice.actions;

export const selectEnterpriseUsers = state => state.enterpriseUsers;
export const selectNumUsersLeft = state => state.enterpriseUsers.numUsersLeft;

export default enterpriseUsersSlice.reducer;
