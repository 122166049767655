'use client';
import { cn } from './lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import {
    motion,
    useMotionValue,
    useSpring,
    useTransform,
    useAnimation,
    progress
} from 'framer-motion';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProjectArchitectureLoading } from '../../../redux/reducers/generic/projectArchitecture';
import { UpdatingArchitectureFloater } from '../../ProjectDashboard/UpdatingArchitectureFloater';
import * as Progress from '@radix-ui/react-progress';

export interface DockProps extends VariantProps<typeof dockVariants> {
    className?: string;
    magnification?: number;
    distance?: number;
    children: React.ReactNode;
}

const DEFAULT_MAGNIFICATION = 50;
const DEFAULT_DISTANCE = 140;

const dockVariants = cva(
    'mx-auto w-max h-[58px] lg:mt-4 p-2 flex items-end gap-2 rounded-2xl'
);

const Dock = React.forwardRef<HTMLDivElement, DockProps>(
    (
        {
            className,
            children,
            magnification = DEFAULT_MAGNIFICATION,
            distance = DEFAULT_DISTANCE,
            ...props
        },
        ref
    ) => {
        const mouseX = useMotionValue(Infinity);

        const renderChildren = () => {
            return React.Children.map(children, (child: any) => {
                return React.cloneElement(child, {
                    mouseX: mouseX,
                    magnification: magnification,
                    distance: distance
                });
            });
        };
        let architectureLoading = useSelector(selectProjectArchitectureLoading);

        return (
            <>
                <motion.div
                    ref={ref}
                    onMouseMove={e => mouseX.set(e.pageX)}
                    onMouseLeave={() => mouseX.set(Infinity)}
                    {...props}
                    className={cn(
                        dockVariants({ className }),
                        className,
                        false
                            ? 'relative before:absolute before:inset-0 before:rounded-2xl before:animate-[pulse_2s_ease-in-out_infinite] before:border-2 before:border-blue-500 before:shadow-[0_0_15px_rgba(59,130,246,0.6)] before:bg-blue-500/5'
                            : 'border dark:border-[#707070]'
                    )}
                >
                    {architectureLoading && <div className="" />}
                    {renderChildren()}
                    {architectureLoading && <UpdatingArchitectureFloater />}
                </motion.div>
            </>
        );
    }
);
export const IndeterminateProgressBar = () => {
    return (
        <motion.div
            className="absolute top-0 left-0 right-0 overflow-hidden bg-transparent z-50"
            initial={{ x: '-100%' }}
            animate={{ x: '100%' }}
            transition={{
                duration: 1,
                repeat: Infinity,
                ease: 'linear'
            }}
            style={{ height: '3px' }}
        >
            <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-blue-500 to-transparent" />
        </motion.div>
    );
};

Dock.displayName = 'Dock';

export interface DockIconProps {
    size?: number;
    magnification?: number;
    distance?: number;
    mouseX?: any;
    className?: string;
    label?: string;
    selected?: boolean;
    iconSelect?: any;
    children?: React.ReactNode;
    props?: PropsWithChildren;
    loading?: boolean;
    description?: string;
}
const DockIcon = ({
    size,
    magnification = DEFAULT_MAGNIFICATION,
    distance = DEFAULT_DISTANCE,
    mouseX,
    iconSelect,
    className,
    selected,
    loading,
    label,
    children,
    description,
    ...props
}: DockIconProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const controls = useAnimation();

    const distanceCalc = useTransform(mouseX, (val: number) => {
        const bounds = ref.current?.getBoundingClientRect() ?? {
            x: 0,
            width: 0
        };
        return val - bounds.x - bounds.width / 2;
    });

    let widthSync = useTransform(
        distanceCalc,
        [-distance, 0, distance],
        [40, magnification, 40]
    );

    let width = useSpring(widthSync, {
        mass: 0.1,
        stiffness: 150,
        damping: 12
    });

    const [hover, setHover] = useState(false);

    const handleBounce = () => {
        controls.start({
            y: [0, -30, 0, -15, 0],
            transition: { duration: 0.5 }
        });
        iconSelect();
    };
    return (
        <div>
            <motion.div
                ref={ref}
                style={{ width }}
                onHoverStart={() => setHover(true)}
                onHoverEnd={() => setHover(false)}
                onClick={handleBounce}
                className={cn(
                    'flex aspect-square cursor-pointer items-center justify-center rounded-full relative',
                    className,
                    selected && 'bg-neutral-400/40',
                    !selected && 'hover:bg-gray-800'
                )}
                {...props}
            >
                {hover && (
                    <div
                        style={{ zIndex: 500 }}
                        className="absolute mt-2 top-[50px] w-fit w-[220px] bg-black text-white rounded border border-white"
                    >
                        <div className="px-3 py-2">
                            <div className="text-md font-bold">{label}</div>
                            {description && (
                                <>
                                    <hr className="my-2 border-gray-600" />
                                    <div className="text-sm overflow-hidden text-ellipsis">
                                        {description}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
                <motion.div
                    ref={ref}
                    style={{ width }}
                    onClick={handleBounce}
                    animate={controls}
                    {...props}
                    className={cn(
                        'flex aspect-square cursor-pointer items-center justify-center rounded-full',
                        className
                    )}
                >
                    {children}
                </motion.div>
                {/* </ShineBorder> */}
            </motion.div>
        </div>
    );
};

DockIcon.displayName = 'DockIcon';

export { Dock, DockIcon, dockVariants };
