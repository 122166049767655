import { HeroExperience } from './HeroExperience';
import RadialGradient from '../Components/magicui/RadialGradient';
import { ParticlesEffect } from './HeroExperience/ParticlesEffect';
import PartnerSection from './PartnerSection';
import PlatformComparison from '../Comparison';

export const MatchLanding = () => {
    return (
        <div className="bg-black">
            <div className="max-w-screen-xl mx-auto">
                <ParticlesEffect />
                <RadialGradient />
            </div>

            <HeroExperience />
            {/* <div className="dark max-w-screen-xl mx-auto">
                <PartnerSection />
                <PlatformComparison />
            </div> */}

            <div className="fixed">
                <ParticlesEffect />
            </div>
        </div>
    );
};
