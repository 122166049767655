import { CreditCardIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import { EnterpriseBillingModal } from '../../BillingModal';
import { useDispatch } from 'react-redux';
import { toggleBillingModalOpen } from '../../../../redux/reducers/generic/billing';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const Billing = ({ closeSidebar, id }) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const onClick = async e => {
        e.preventDefault();
        dispatch(toggleBillingModalOpen());

        if (closeSidebar) {
            closeSidebar();
        }
    };

    let active = pathname.includes('billing');

    return (
        <>
            <EnterpriseBillingModal />
            <a
                onClick={onClick}
                className={classNames(
                    'text-gray-100 hover:text-indigo-300 hover:bg-gray-600',
                    'group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold cursor-pointer',
                    active ? 'bg-gray-600' : ''
                )}
            >
                <CreditCardIcon
                    className="text-gray-400 flex-shrink-0 h-6 w-6"
                    aria-hidden="true"
                />
                <span className="truncate">Billing</span>
            </a>
        </>
    );
};
