'use client'

import { Editor as CoreEditor } from '@tiptap/core'
import { memo, useEffect, useState } from 'react'
import { TableOfContentsStorage } from '@tiptap-pro/extension-table-of-contents'
import { cn } from '../../lib/utils'

export type TableOfContentsProps = {
  editor: CoreEditor
  onItemClick?: () => void
}

export const TableOfContents = memo(({ editor, onItemClick }: TableOfContentsProps) => {
  const [data, setData] = useState<TableOfContentsStorage | null>(null)

  useEffect(() => {
    const handler = ({ editor: currentEditor }: { editor: CoreEditor }) => {
      setData({...currentEditor.extensionStorage.tableOfContents});
      setTimeout(() => {
        // Log all h1s and h2s with their respective id and text content
        const headings = document.querySelectorAll('.tiptap.ProseMirror.min-h-full h1, .tiptap.ProseMirror.min-h-full h2, .tiptap.ProseMirror.min-h-full h3');

        let headingsArray = [];
        headings.forEach((heading) => {
          headingsArray.push({
            tag: heading.tagName,
            id: heading.id,
            textContent: heading.textContent
          });
        });

        let json = currentEditor.extensionStorage.tableOfContents;

        json.content.forEach((item, index) => {
          const matchingHeading = headingsArray.find(heading => heading.textContent === item.textContent);
          if (matchingHeading) {
            item.id = matchingHeading.id;
          }
        });

        if (json.content.length === 0) {
          json.content = headingsArray.map(heading => ({
            level: parseInt(heading.tag.slice(1)),
            id: heading.id,
            textContent: heading.textContent,
            isActive: false
          }));
        }

        setData({ ...json })
      },200);
    }

    handler({ editor })

    editor.on('update', handler)
    editor.on('selectionUpdate', handler)

    return () => {
      editor.off('update', handler)
      editor.off('selectionUpdate', handler)
    }
  }, [editor])

  return (
    <>
      <div className="mb-2 text-xs font-semibold uppercase text-neutral-500 dark:text-neutral-400 pt-8 lg:pt-0">
        Table of contents
      </div>
      {data && data.content.length > 0 ? (
        <div className="flex flex-col gap-1">
          {data.content
            .filter(item => item.level > 1)
            .map(item => (
              <a
                key={item.id}
                href={`#${item.id}`}
                style={{ marginLeft: `${1 * (item.level - 2)}rem` }}
                onClick={onItemClick}
                className={cn(
                  'block font-medium text-neutral-500 dark:text-neutral-300 p-1 rounded bg-opacity-10 text-sm hover:text-neutral-800 transition-all hover:bg-black hover:bg-opacity-5 truncate w-full',
                  item.isActive && 'text-neutral-800 bg-neutral-100 dark:text-neutral-100 dark:bg-neutral-900',
                )}
              >
                {item.textContent}
              </a>
            ))}
        </div>
      ) : (
        <div className="text-sm text-neutral-500">Start adding headlines to your document …</div>
      )}
    </>
  )
})

TableOfContents.displayName = 'TableOfContents'
