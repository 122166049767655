import * as math from 'mathjs';
// Function to generate task duration using log-normal distribution informed by t-distribution
function generateLogNormalDuration(
    mu: number,
    sigma: number,
    Z: number
): number {
    return Math.exp(mu + sigma * Z);
}

// Function to calculate mu for log-normal distribution
function calculateMu(taskHourEstimate: number, sigma: number): number {
    return (
        math.log(taskHourEstimate) -
        0.5 * math.log(1 + sigma ** 2 / taskHourEstimate ** 2)
    );
}

const skillToSalary = (skillLevel: string): number => {
    if (skillLevel === 'junior') return 50;
    if (skillLevel === 'intermediate') return 100;
    if (skillLevel === 'senior') return 200;
    return 0;
};

export const updateHourEstimates = (
    milestones: any[],
    skillLevel: string
): any[] => {
    try {
        if (!milestones) return [];
        const sigma =
            skillLevel === 'junior'
                ? 0.83
                : skillLevel === 'intermediate'
                  ? 0.5
                  : 0.1; // Reduced to 0.1 for more aggressive advanced estimates
        const Z_99 =
            skillLevel === 'junior'
                ? 1
                : skillLevel === 'intermediate'
                  ? 0.6
                  : 0.2; // Reduced to 0.2 for more aggressive advanced estimates

        const preCalculatedMu = new Map<number, number>();
        const subTaskDescriptionMap = new Map<string, boolean>();

        // Function to calculate and update sub-task hours
        const updateSubTaskHours = (subTasks: any[]) => {
            return subTasks.reduce((totalHours, subTask) => {
                const subTaskHours = subTask.hours || 0;
                if (!preCalculatedMu.has(subTaskHours)) {
                    preCalculatedMu.set(
                        subTaskHours,
                        calculateMu(subTaskHours, sigma)
                    );
                }
                const subTaskMu = preCalculatedMu.get(subTaskHours) as number;
                const scaledSubTaskHours = generateLogNormalDuration(
                    subTaskMu,
                    sigma,
                    Z_99
                );
                subTask.hours = Math.round(scaledSubTaskHours); // Round to nearest whole number
                if (skillLevel === 'senior') {
                    subTask.hours = Math.round(subTask.hours * 0.6); // Reduce hours by 40% for advanced developers
                }
                subTask.cost = skillToSalary(skillLevel) * subTask.hours;
                return totalHours + subTask.hours;
            }, 0);
        };

        // Function to calculate and update story hours
        const updateStoryHours = (stories: any[]) => {
            return stories.reduce((totalHours, story) => {
                const uniqueSubTasks = story.subTasks.filter(subTask => {
                    if (!subTaskDescriptionMap.has(subTask.description)) {
                        subTaskDescriptionMap.set(subTask.description, true);
                        return true;
                    }
                    return false;
                });
                story.subTasks = uniqueSubTasks;
                const storyTotalHours = updateSubTaskHours(uniqueSubTasks);
                story.hours = Math.round(storyTotalHours); // Round to nearest whole number
                return totalHours + story.hours;
            }, 0);
        };

        // Update epic hours
        milestones.forEach(epic => {
            const epicTotalHours = updateStoryHours(epic.stories);
            epic.stories = epic.stories.filter(story => story.hours > 0);
            epic.hours = Math.round(epicTotalHours); // Round to nearest whole number
        });

        milestones = milestones.filter(milestone => milestone.hours > 0);

        return milestones;
    } catch (error) {
        return [];
    }
};
