import { client } from '../../../api/client';
import { USER_STRIPE_LINK } from '../../../api/graphQL/solo/query';

export const getUserStripeLink = () => async (dispatch, getState) => {
    try {
        let { data } = await client.mutate({
            mutation: USER_STRIPE_LINK
        });
        return data.userStripeLink;
    } catch (error) {
        console.log({ error });
        console.log(JSON.stringify(error, null, 2));
    }
};
