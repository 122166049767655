import { ChatHistory } from './ChatHistory';
import { ChatInput } from './ChatInput';
import { ExistingProjectContext } from './ExistingProjectContext';
import { useSelector } from 'react-redux';
import { selectProjectInternalVersion } from '../../../redux/reducers/generic/project';
import { MigrationBanner } from './migration';

export const Chat = ({ setShowDemoBanner }) => {
    const projectInternalVersion = useSelector(selectProjectInternalVersion);

    return (
        <>
            <ExistingProjectContext />
            <div className="dark overflow-y-scroll">
                <ChatHistory />
            </div>
            {projectInternalVersion == 2 ? (
                <ChatInput setShowDemoBanner={setShowDemoBanner} />
            ) : (
                <MigrationBanner />
            )}
        </>
    );
};
