import { client } from '../../../api/client';
import { REMOVE_FILE_FROM_KNOWLEDGE_BASE } from '../../../api/graphQL/enterprise/mutator';
import { removeKnowledgeBaseFile } from '../../reducers/enterprise/enterpriseOrganization';

export const removeFileFromKnowledgeBase =
    (fileId, enterpriseOrganizationId) => async dispatch => {
        try {
            const { data } = await client.mutate({
                mutation: REMOVE_FILE_FROM_KNOWLEDGE_BASE,
                variables: {
                    fileId,
                    enterpriseOrganizationId
                }
            });

            if (data.removeFileFromKnowledgeBase) {
                dispatch(removeKnowledgeBaseFile(fileId));
            }

            return true;
        } catch (error) {
            console.log({ error });
        }
    };
