import { client } from '../../../api/client';
import { EDIT_NODE } from '../../../api/graphQL/solo/mutator';

export const editNode =
    (nodeId, graphId, projectId, label, description) =>
    async (dispatch, getState) => {
        try {
            console.log(nodeId, graphId, projectId, label, description);
            let { data } = await client.mutate({
                mutation: EDIT_NODE,
                variables: { nodeId, graphId, projectId, label, description }
            });

            return data.editNode;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }
    };
