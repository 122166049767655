import { client } from '../../../api/client';
import { UPDATE_USER_METADATA } from '../../../api/graphQL';

interface KeyValuePair {
    key: string;
    value: string;
}

export const updateUserMetadata =
    (fields: KeyValuePair[]) => async (dispatch: any) => {
        try {
            const { data } = await client.mutate({
                mutation: UPDATE_USER_METADATA,
                variables: { fields }
            });

            return data.updateUserMetadata;
        } catch (error) {
            console.error('Error updating user metadata:', error);
            throw error;
        }
    };
