import { UPDATE_USER_TITLE_OF_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import {
    fetchEnterpriseUsersRequest,
    fetchEnterpriseUsersSuccess,
    fetchEnterpriseUsersFailure
} from '../../reducers/enterprise/enterpriseUsers';

export const updateUserTitleOfEnterpriseOrganization =
    (userEmail, enterpriseOrganizationId, title) =>
    async (dispatch, getState) => {
        dispatch(fetchEnterpriseUsersRequest());
        try {
            const { data } = await client.mutate({
                mutation: UPDATE_USER_TITLE_OF_ENTERPRISE_ORGANIZATION,
                variables: {
                    userEmail,
                    enterpriseOrganizationId,
                    title
                },
                fetchPolicy: 'network-only'
            });

            dispatch(
                fetchEnterpriseUsersSuccess(
                    data.updateUserTitleOfEnterpriseOrganization.enterpriseUsers
                )
            );
            return `Successfully updated ${userEmail}'s title to ${title}`;
        } catch (error) {
            console.log({ error, userEmail, enterpriseOrganizationId });
            dispatch(fetchEnterpriseUsersFailure(error));
            return error.message;
        }
    };
