import React, { useState, useEffect, useMemo, useRef } from 'react';
import { businessTypes } from '../business_types';

export function BusinessType({ formData, setFormData }) {
    const [suggestions, setSuggestions] = useState([]);
    const [displayedSuggestions, setDisplayedSuggestions] = useState([]);
    const [page, setPage] = useState(1);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const suggestionsRef = useRef(null);

    const ITEMS_PER_PAGE = 5;

    useEffect(() => {
        if (formData.businessType) {
            const filteredSuggestions = businessTypes.filter(type =>
                type.toLowerCase().includes(formData.businessType.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
            setPage(1);
            setShowSuggestions(true);
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    }, [formData.businessType]);

    useEffect(() => {
        setDisplayedSuggestions(suggestions.slice(0, page * ITEMS_PER_PAGE));
    }, [suggestions, page]);

    const handleScroll = () => {
        if (suggestionsRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                suggestionsRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                setPage(prevPage => prevPage + 1);
            }
        }
    };

    const handleSuggestionClick = suggestion => {
        setFormData(prev => ({
            ...prev,
            businessType: suggestion
        }));
        setShowSuggestions(false);
    };

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700">
                Industry
            </label>
            {showSuggestions &&
                displayedSuggestions.length > 0 &&
                !displayedSuggestions.includes(formData.businessType) && (
                    <ul
                        ref={suggestionsRef}
                        onScroll={handleScroll}
                        className="absolute z-[100] mt-12 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-y-auto"
                    >
                        {displayedSuggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() =>
                                    handleSuggestionClick(suggestion)
                                }
                            >
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                )}
            <input
                type="text"
                name="businessType"
                value={formData.businessType || ''}
                onChange={handleChange}
                placeholder="e.g. Consulting, Software Development"
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
            />
        </div>
    );
}
