import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    projectEntry: null,
    loadingProjectEntry: false,
    list: null,
    loadingList: false,
    paginateLoading: false,
    entry: null,
    loadingEntry: false,
    totalEntries: 0
};

const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState,
    reducers: {
        upvoteLeaderboard(state, action) {
            let { leaderboardEntryId, remove } = action.payload;
            let increment = remove ? -1 : 1;
            const index = state.list.findIndex(
                entry => entry._id === leaderboardEntryId
            );
            if (index !== -1) {
                state.list[index].upvotes += increment;
                state.list[index].isVotedByUser = !remove;
            }
        },
        fetchLeaderboardProjectEntryStart(state) {
            state.loadingProjectEntry = true;
            state.projectEntry = null;
        },
        fetchLeaderboardListStart(state) {
            state.loadingList = true;
            state.list = null;
        },
        fetchLeaderboardEntryStart(state) {
            state.loadingEntry = true;
            state.entry = null;
        },
        setLeaderboardProjectEntry(state, action) {
            state.projectEntry = action.payload;
            state.loadingProjectEntry = false;
        },
        setLeaderboardList(state, action) {
            state.list = action.payload.entries.reduce(
                (uniqueEntries, entry) => {
                    const existingEntryIndex = uniqueEntries.findIndex(
                        e => e._id === entry._id
                    );
                    if (existingEntryIndex === -1) {
                        uniqueEntries.push(entry);
                    } else {
                        uniqueEntries[existingEntryIndex] = entry;
                    }
                    return uniqueEntries;
                },
                []
            );
            state.totalEntries = action.payload.totalEntries;
            state.loadingList = false;
        },
        setPaginateLoading(state, action) {
            state.paginateLoading = action.payload;
        },
        setLeaderboardEntry(state, action) {
            state.entry = action.payload;
            state.loadingEntry = false;
        }
    }
});

export const {
    upvoteLeaderboard,
    fetchLeaderboardProjectEntryStart,
    fetchLeaderboardListStart,
    fetchLeaderboardEntryStart,
    setLeaderboardProjectEntry,
    setLeaderboardList,
    setLeaderboardEntry,
    setPaginateLoading
} = leaderboardSlice.actions;

export default leaderboardSlice.reducer;

export const selectProjectLeaderboardEntry = state =>
    state.leaderboard.projectEntry;
export const selectLeaderboardList = state => state.leaderboard.list;
export const selectLeaderboardEntry = state => state.leaderboard.entry;
export const selectPaginateLoading = state => state.leaderboard.paginateLoading;
export const selectTotalEntries = state => state.leaderboard.totalEntries;
