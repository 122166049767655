import MarkdownInstance from 'react-markdown';
import { CopyBlock, hybrid } from 'react-code-blocks';

export const Markdown = ({ content, id }) => {
    return (
        <MarkdownInstance
            id={id}
            components={{
                h1(props) {
                    const { node, ...rest } = props;
                    return (
                        <h1
                            className="mb-8 mt-8 text-4xl font-extrabold text-white dark:text-white"
                            style={{
                                fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                            }}
                            {...rest}
                        />
                    );
                },
                h2(props) {
                    const { node, ...rest } = props;
                    return (
                        <h2
                            className="mb-4 mt-8 border-b border-black pb-2 text-2xl font-bold   text-white dark:text-white"
                            style={{
                                fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                            }}
                            {...rest}
                        />
                    );
                },
                h3(props) {
                    const { node, ...rest } = props;
                    return (
                        <h3
                            className="mb-4 mt-8 text-lg font-bold   text-white dark:text-white"
                            style={{
                                fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                            }}
                            {...rest}
                        />
                    );
                },
                h4(props) {
                    const { node, ...rest } = props;
                    return (
                        <h4
                            className="mb-4 mt-8 text-xl font-bold   text-white dark:text-white"
                            style={{
                                fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                            }}
                            {...rest}
                        />
                    );
                },
                h5(props) {
                    const { node, ...rest } = props;
                    return (
                        <h5
                            className="mb-4 text-lg font-bold   text-white dark:text-white"
                            style={{
                                fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                            }}
                            {...rest}
                        />
                    );
                },
                h6(props) {
                    const { node, ...rest } = props;
                    return (
                        <h5
                            className="mb-4 text-md font-bold   text-white dark:text-white"
                            style={{
                                fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                            }}
                            {...rest}
                        />
                    );
                },
                ul(props) {
                    const { node, ...rest } = props;
                    return (
                        <ul
                            className="my-4"
                            style={{
                                fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                            }}
                            {...rest}
                        />
                    );
                },
                li(props) {
                    const { node, ...rest } = props;
                    return (
                        <li
                            className="mb-4 ml-4 text-sm text-white dark:text-white"
                            style={{
                                fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                            }}
                            {...rest}
                        />
                    );
                },
                pre(props) {
                    const { node, ...rest } = props;
                    return <p {...rest} />;
                },
                code(props) {
                    const { node, ...rest } = props;
                    console.log({ rest });
                    return rest.className ? (
                        <CopyBlock
                            text={rest.children}
                            showLineNumbers={true}
                            theme={hybrid}
                            language={rest?.className?.split('-')?.[1]}
                            codeBlock
                            wrapLines
                        />
                    ) : (
                        <p
                            className="text-sm text-white inline whitespace-pre-wrap break-words"
                            style={{
                                fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                            }}
                            {...rest}
                        />
                    );
                },
                a(props) {
                    const { node, ...rest } = props;
                    return (
                        <a
                            className="mb-4 text-sm   text-white dark:text-white"
                            style={{
                                fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                            }}
                            {...rest}
                        />
                    );
                },
                p(props) {
                    const { node, ...rest } = props;
                    return (
                        <p
                            className="mb-4 text-sm sm:text-base text-white"
                            style={{
                                fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                            }}
                            {...rest}
                        />
                    );
                }
            }}
        >
            {content?.replace('【33†source】', '')}
        </MarkdownInstance>
    );
};
