import { GET_THUMBS_RATING } from '../../../api/graphQL';
import { client } from '../../../api/client';

export const getThumbsRating = id => async dispatch => {
    try {
        const { data } = await client.query({
            query: GET_THUMBS_RATING,
            variables: { id }
        });

        return data.getThumbsRating;
    } catch (error) {
        console.log(error);
    }
};
