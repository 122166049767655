import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    jiraProjectURL: null,
    jiraVersion: null,
    jiraPercentage: 0,
    gettingJira: false,
    callingJira: false,
    loadingJira: null,
    jiraLoadingText: null
};

export const jiraSlice = createSlice({
    name: 'jira',
    initialState,
    reducers: {
        setJira: (state, action) => {
            if (!action.payload) {
                state.jiraVersion = null;
                state.jiraPercentage = 0;
                state.jiraProjectURL = null;
            } else {
                state.jiraVersion = action.payload.version;
                state.jiraProjectURL = action.payload.projectURL;
                state.jiraPercentage = action.payload.percentage;
            }
        },
        gettingJira: (state, action) => {
            state.gettingJira = action.payload;
        },
        callingJira: (state, action) => {
            state.callingJira = action.payload;
        },
        setJiraPercentage: (state, action) => {
            state.jiraPercentage = action.payload;
        },
        waitingForJira: (state, action) => {
            state.loadingJira = action.payload;
        },
        setJiraLoadingText: (state, action) => {
            state.jiraLoadingText = action.payload;
        }
    }
});

export const {
    setJira,
    setJiraPercentage,
    gettingJira,
    callingJira,
    waitingForJira,
    setJiraLoadingText
} = jiraSlice.actions;

export const selectJiraPercentage = state => state.jira.jiraPercentage;
export const selectJiraVersion = state => state.jira.jiraVersion;
export const selectGettingJira = state => state.jira.gettingJira;
export const selectCallingJira = state => state.jira.callingJira;
export const selectJiraProjectURL = state => state.jira.jiraProjectURL;
export const selectLoadingJira = state => state.jira.loadingJira;
export const selectJiraLoadingText = state => state.jira.jiraLoadingText;
export default jiraSlice.reducer;
