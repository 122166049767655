import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { toast } from 'sonner';
import moment from 'moment';
import { removeDefaultTechStackItemFromOrganization } from '../../../../redux/actions/enterprise/removeDefaultTechStackItemFromEnterpriseOrganization';
import { selectDefaultTechStackItems } from '../../../../redux/reducers/enterprise/enterpriseOrganization';
import { Spinner } from '../../../Components/Spinner';
import { useState } from 'react';

export const DefaultTechStackItems = () => {
    const defaultTechStackItems = useSelector(selectDefaultTechStackItems);
    return (
        <div className="max-h-[200px] overflow-y-scroll mt-3.5">
            <table className="w-full divide-y divide-gray-300">
                <thead>
                    <tr className="">
                        <th
                            scope="col"
                            className="pb-3.5 px-4 text-left text-sm font-semibold text-gray-900"
                        >
                            <span className="break-words">
                                Name ({defaultTechStackItems.length})
                            </span>
                        </th>
                        <th
                            scope="col"
                            className="hidden sm:table-cell pb-3.5 px-4 text-left text-sm font-semibold text-gray-900"
                        >
                            <span className="break-words">Created</span>
                        </th>
                        <th
                            scope="col"
                            className="pb-3.5 px-4 text-left text-sm font-semibold text-gray-900"
                        >
                            <span className="break-words">Link</span>
                        </th>
                        <th
                            scope="col"
                            className="hidden sm:table-cell pb-3.5 px-4 text-left text-sm font-semibold text-gray-900"
                        >
                            <span className="break-words">Description</span>
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {defaultTechStackItems?.map((item, i) => (
                        <DefaultTechStackItem item={item} i={i} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export const DefaultTechStackItem = ({ item, i }) => {
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const { enterpriseOrganization } = useEnterpriseOrganization();

    const [loading, setLoading] = useState();

    const handleDeleteDomain = async ({ name, _id }) => {
        const userConfirmed = window.confirm(
            'Are you sure you want to delete this item?'
        );

        if (userConfirmed) {
            setLoading(true);
            const response = await dispatch(
                removeDefaultTechStackItemFromOrganization({
                    enterpriseOrganizationId,
                    _id
                })
            );
            setLoading(false);
            toast(response);
        }
    };

    return (
        <tr key={i} className="cursor-pointer hover:bg-gray-50">
            <td className="py-5 px-4 text-sm">
                <div className="flex items-center">
                    <div className="font-medium text-gray-900 break-words">
                        {item.name}
                    </div>
                </div>
            </td>
            <td className="items-center hidden sm:table-cell text-sm text-gray-500 px-4">
                <div className="flex items-center">
                    <div className="flex flex-col break-words">
                        {item.link ? (
                            moment(item.created).format('MMM DD YYYY: h:mm a')
                        ) : (
                            <Spinner w="6" />
                        )}
                    </div>
                </div>
            </td>
            <td className="text-sm text-gray-500 px-4">
                <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline break-words"
                >
                    {item.link ? item.link : null}
                </a>
            </td>

            <td className="hidden sm:table-cell text-sm text-gray-500 px-4 py-2">
                <div className="flex items-center">
                    <div className="break-words">
                        {item.description ? item.description : null}
                    </div>
                </div>
            </td>

            {enterpriseOrganization.isAdmin && item._id && item.name && (
                <td className="relative py-5 text-right text-sm font-medium px-4">
                    <button
                        onClick={() =>
                            handleDeleteDomain({
                                _id: item._id,
                                name: item.name
                            })
                        }
                        disabled={loading}
                        className="cursor-pointer text-red-600 hover:text-red-900 mr-4"
                    >
                        {loading ? <Spinner w="6" /> : 'Delete'}
                    </button>
                </td>
            )}
        </tr>
    );
};
