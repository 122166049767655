import { client } from '../../../api/client';
import { ENTERPRISE_STRIPE_LINK } from '../../../api/graphQL/enterprise/query';

export const getEnterpriseStripeLink = () => async (dispatch, getState) => {
    try {
        let { data } = await client.mutate({
            mutation: ENTERPRISE_STRIPE_LINK,
            variables: {
                enterpriseOrganizationId:
                    getState().enterpriseOrganization.data._id.toString()
            }
        });
        return data.enterpriseStripeLink;
    } catch (error) {
        console.log({ error });
        console.log(JSON.stringify(error, null, 2));
    }
};
