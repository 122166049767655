import { GET_JIRA } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    callingJira,
    setJira,
    setJiraPercentage,
    setJiraLoadingText
} from '../../reducers/generic/jira';

export const getJira =
    ({ projectId }) =>
    async dispatch => {
        try {
            dispatch(callingJira(true));
            const { data } = await client.query({
                query: GET_JIRA,
                variables: {
                    projectId
                },
                fetchPolicy: 'network-only'
            });
            if (data?.getJira) {
                dispatch(setJira(data.getJira));
                dispatch(setJiraPercentage(data.getJira.percentage));
                dispatch(setJiraLoadingText(data.getJira.loadingText));
            }
            dispatch(callingJira(false));
        } catch (error) {
            console.log({ error });
            dispatch(callingJira(false));
            dispatch(setJiraPercentage(0));
            // throw new Error(error.message);
        }
    };
