import React, { useState, useEffect, useRef } from 'react';
import { useProject } from '../../../../context-providers/Project';

export const Progress = () => {
    let { chatHistory } = useProject();
    let chat = chatHistory.data;

    // simple progress calculation we are just going to keep at local state for ease
    const [progress, setProgress] = useState(0); // Initial progress is 0%
    useEffect(() => {
        // Calculate progress percentage and update progress state
        if (chat && chat.length > 1) {
            let newProgress = Math.floor(chat.length / 2);
            setProgress(Math.min(newProgress, 5));
        } else {
            setProgress(0);
        }
    }, [chat]);

    return (
        <div className="w-full">
            <div className="pb-4 px-8 w-full">
                <div className="flex justify-between mb-1 mt-2">
                    <span className="text-sm font-medium text-blue-700 dark:text-white"></span>
                    <span className="text-sm font-medium text-blue-700 dark:text-white">
                        <span className="text-gray-500">
                            {' '}
                            Preliminary Responses{' '}
                        </span>
                        {progress} / 5
                    </span>
                </div>

                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${(progress / 5) * 100}%` }}
                    ></div>
                </div>
            </div>
        </div>
    );
};
