import React, { useEffect, useRef } from 'react';
import ProjectItem from './ProjectItem';
import { useProjects } from '../../../context-providers/Projects';
import CreateNewProject from './createNewProject';
import Settings from './Settings';
import UpgradeToPremium from './UpgradeToPremium';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { FeedBackButton } from '../../Components/FeedbackButton';
import { useDispatch, useSelector } from 'react-redux';
import LoadMoreButton from './LoadMoreButton';
import {
    paginateProjects,
    selectSearchLoading,
    selectProjectsLoading
} from '../../../redux/reducers/generic/projects';
import { SearchProjects } from '../../EnterpriseHome/ClientProjects/Search';
import ReferralCard from './ReferralCard';
import Profile from './Profile';

export default function SidebarItems({ handleCloseSidebar = () => {} }) {
    let { projects } = useProjects();
    const divRef = useRef(null);
    const { isSubbed } = useSubscriptionPayment();
    const dispatch = useDispatch();
    const searchLoading = useSelector(selectSearchLoading);
    const projectsLoading = useSelector(selectProjectsLoading);

    useEffect(() => {
        const div = divRef.current;

        const handleScroll = () => {
            const isBottom =
                div?.scrollTop + div?.clientHeight >= div?.scrollHeight;
            if (isBottom) {
                dispatch(paginateProjects());
            }
        };

        div.addEventListener('scroll', handleScroll);

        return () => {
            div.removeEventListener('scroll', handleScroll);
        };
    }, [divRef]);

    // Update the max-height of projectsList based on window height
    useEffect(() => {
        const resizeHandler = () => {
            if (divRef.current) {
                const windowHeight = window.innerHeight;
                const topOffset = divRef.current.offsetTop;

                const liElementHeight = !isSubbed ? 160 : 160; // Set the height of your li element here
                const availableHeight =
                    windowHeight - topOffset - liElementHeight;
                divRef.current.style.maxHeight = `${availableHeight}px`;
            }
        };

        window.addEventListener('resize', resizeHandler);
        resizeHandler(); // Call it initially to set the initial height
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [isSubbed]);

    return (
        <>
            <div class="flex items-start">
                <h2 class="inline px-5 text-lg font-medium text-slate-800 dark:text-slate-200">
                    Projects
                </h2>
                <span class="rounded-full bg-blue-600 px-2 py-1 text-xs text-slate-200">
                    {projects?.length}
                </span>
            </div>

            {isSubbed ? <SearchProjects /> : null}

            <div
                ref={divRef}
                class="my-4 h-full space-y-4 overflow-y-auto px-2"
            >
                {!searchLoading &&
                    projects.map((project, i) => (
                        <ProjectItem
                            closeSidebar={handleCloseSidebar}
                            project={project}
                            key={i}
                        />
                    ))}
                {!isSubbed && (
                    <UpgradeToPremium closeSidebar={handleCloseSidebar} />
                )}
                {/* <ReferralCard /> */}

                {!searchLoading && (
                    <div className="flex items-cente justify-center">
                        <LoadMoreButton />
                    </div>
                )}
            </div>
            <div class="mx-2">
                <CreateNewProject />
                <div className="mt-2">
                    <FeedBackButton textColor="black" />
                    <div className="flex flex-row gap-2 items-center justify-between">
                        <Settings />
                        <Profile />
                    </div>
                </div>
            </div>
        </>
    );
}
