import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBlockEditor } from '../../../redux/reducers/generic/project';
import TurndownService from 'turndown';
import { useProject } from '../../../context-providers/Project';
import { getSignedUploadURL } from '../../../redux/actions/solo/getSignedUploadURL';
import { v4 as uuidv4 } from 'uuid';
import { migrateProject } from '../../../redux/actions/solo/migrateProject';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { toast } from 'sonner';
import { useParams } from 'react-router-dom';

const turndownService = new TurndownService();

export const MigrationBanner = () => {
    const dispatch = useDispatch();
    const editor = window.blockEditor;
    const { project } = useProject();
    const [fileKeyS3, setFileKeyS3] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const { isSolo, isEnterprise, isShare } = useSubscriptionPayment();
    const { enterpriseOrganizationId } = useParams();
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        let timer;
        if (isUploaded && countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prevCount => prevCount - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isUploaded, countdown]);

    const migrationHandler = async () => {
        if (isShare) {
            toast.error('You need to be a solo or enterprise user to migrate');
            return;
        }
        setIsUploading(true);
        let fileKeyS3 = await uploadFile();
        if (fileKeyS3) {
            setIsUploading(false);
            setIsUploaded(true);
            console.log({
                projectId: project._id,
                fileKeyS3: fileKeyS3
            });
            let proj = await dispatch(migrateProject(project._id, fileKeyS3));
            if (proj) {
                toast.success('Project migrated successfully');
                setTimeout(() => {
                    let url = '/projects/' + proj._id;
                    if (isEnterprise) {
                        url =
                            '/enterprise/dashboard/' +
                            enterpriseOrganizationId +
                            '/clientProjects/' +
                            proj._id;
                    }
                    window.location.href = url;
                }, 5000);
            } else {
                setIsUploading(false);
                setIsUploaded(false);
            }
        }
    };

    const uploadFile = async () => {
        let uniqueFilename = uuidv4() + project.name;
        console.log({ uniqueFilename });
        setFileKeyS3(uniqueFilename);
        const signedUrl = await dispatch(getSignedUploadURL(uniqueFilename));

        const markdown = createMarkdown();
        console.log({ markdown });
        let file = new Blob([markdown], { type: 'application/md' });
        if (markdown.length < 10) {
            toast.error('Unable to migrate project.');
            throw new Error('Unable to migrate project.');
        }

        try {
            await new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('PUT', signedUrl, true);
                xhr.upload.onprogress = function (event) {
                    if (event.lengthComputable) {
                        const percentComplete =
                            (event.loaded / event.total) * 100;
                        setProgress(percentComplete.toFixed(2));
                        console.log(
                            `File upload progress: ${percentComplete.toFixed(
                                2
                            )}%`
                        );
                    }
                };
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        console.log('File uploaded successfully.');
                        resolve();
                    } else {
                        console.error('File upload failed.');
                        reject(new Error('File upload failed.'));
                    }
                };
                xhr.onerror = function () {
                    console.error('File upload failed.');
                    reject(new Error('File upload failed.'));
                };
                xhr.send(file);
            });

            return uniqueFilename;
        } catch (error) {
            console.error('File upload failed:', error);
            return false;
        }
    };

    const createMarkdown = () => {
        let html = editor.getHTML();
        var markdown = turndownService.turndown(html);
        return markdown;
    };

    const { isLeadGen } = useSubscriptionPayment();

    if (isLeadGen) {
        return null;
    }

    return (
        <div className="p-4 rounded-lg shadow-md text-white mt-2">
            <h2 className="text-xl font-bold mb-2">
                <img
                    src="/predev.png"
                    alt="pre.dev logo"
                    className="inline-block h-6 mr-2"
                />
                Upgrade Your Project{' '}
            </h2>
            <p className="mb-4">
                To access pre.dev's latest model and enhanced features, you need
                to migrate your project.
            </p>
            {isUploading ? (
                <div>
                    <p className="mb-2">Migration in progress...</p>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div
                            className="bg-blue-600 h-2.5 rounded-full"
                            style={{ width: `${progress}%` }}
                        ></div>
                    </div>
                    <p className="mt-2">{progress}% complete</p>
                </div>
            ) : isUploaded ? (
                <div>
                    <p className="text-green-500 font-semibold">
                        Migration Complete!
                    </p>
                    <p className="mt-2">
                        Redirecting in {countdown} second
                        {countdown !== 1 ? 's' : ''}...
                    </p>
                </div>
            ) : (
                <button
                    className="bg-white text-purple-600 font-semibold py-2 px-4 rounded hover:bg-purple-100 transition duration-300"
                    onClick={migrationHandler}
                >
                    Migrate Project
                </button>
            )}
        </div>
    );
};
