import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import * as Tooltip from '@radix-ui/react-tooltip';
import { revealProject } from '../../../../redux/actions/enterprise/revealProject';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { toggleSubscribeModal } from '../../../../redux/reducers/generic/billing';

export const RevealEmail = ({ projectId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const { enterpriseOrganization: orgData } = useEnterpriseOrganization();
    const { leadGenCreditsLeft } = orgData?.leadGenData;
    const { isSubbed } = useSubscriptionPayment();

    const handleRevealEmail = async () => {
        if (!isSubbed) {
            dispatch(toggleSubscribeModal(true));
            return;
        }
        try {
            setIsLoading(true);
            if (leadGenCreditsLeft <= 0) {
                toast.error('Purchase more credits to reveal the email.');
                return;
            }
            const result = await dispatch(
                revealProject({
                    projectId,
                    enterpriseOrganizationId
                })
            );
            if (result) {
                // toast.success('Email revealed successfully!');
            } else {
                toast.error('Failed to reveal email. Please try again.');
            }
        } catch (error) {
            console.error('Error revealing email:', error);
            toast.error('An error occurred while revealing the email.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Tooltip.Provider delayDuration={100}>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <button
                        onClick={handleRevealEmail}
                        disabled={isLoading}
                        className={`px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300 ${
                            isLoading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                    >
                        {isLoading ? 'Revealing...' : 'Reveal Email'}
                    </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        className="max-w-md p-4 bg-white shadow-lg rounded-lg border border-gray-200"
                        sideOffset={5}
                    >
                        <p className="text-sm text-gray-700">
                            A credit will be spent to reveal the user's email
                            for this project.
                        </p>
                        <Tooltip.Arrow className="fill-white" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};
