import { Header } from '../Header';
import { Footer } from '../../Footer';
import Widget from './Widget';
import { useSelector } from 'react-redux';
import { selectEnterpriseOrganization } from '../../../redux/reducers/enterprise/enterpriseOrganization';

const ChatWidgetPage = () => {
    let enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    return (
        <div className="flex flex-col justify-between min-h-screen space-y-4">
            <Header logo={enterpriseOrganization?.logo} />

            <div className="w-full">
                <Widget />
            </div>

            <Footer />
        </div>
    );
};

export default ChatWidgetPage;

// took out repeated <br/>
