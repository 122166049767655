import { SAVE_UIVIEW } from '../../../api/graphQL';
import { client } from '../../../api/client';

export const saveUIView =
    ({projectId, nodeId, version, codeFiles}) => async dispatch => {
        try {

            await client.mutate({
                mutation: SAVE_UIVIEW,
                variables: {
                    projectId,
                    nodeId,
                    version,
                    codefiles:codeFiles
                }
            });

            return 'Changes were saved successfully!';
        } catch (error) {
            console.log({ error });
            return error;
        }
    };
