import { PhoneVerification } from './PhoneVerification';

export const WidgetFields = ({
    email,
    setEmail,
    requirePhoneNumber,
    phoneNumber,
    isPhoneVerified,
    setIsPhoneVerified,
    setPhoneNumber,
    businessURL,
    setBusinessURL,
    requireBusinessURL
}) => {
    function validateURL(url) {
        try {
            new URL(url);
            return true;
        } catch {
            return false;
        }
    }

    return (
        <>
            <input
                id="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="border-gray-400 px-4 py-2 w-full outline-none mb-2"
            />
            {requireBusinessURL && (
                <input
                    className="border-gray-400 px-4 py-2 w-full outline-none mb-2"
                    id="businessURL"
                    type="url"
                    placeholder="Business URL (e.g. https://example.com)"
                    value={businessURL}
                    onChange={e => {
                        const url = e.target.value;
                        if (!url || validateURL(url)) {
                            setBusinessURL(url);
                        }
                    }}
                    pattern="https?://.*"
                />
            )}
            {requirePhoneNumber && (
                <PhoneVerification
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    setIsPhoneVerified={setIsPhoneVerified}
                    isPhoneVerified={isPhoneVerified}
                />
            )}
        </>
    );
};
