import { client } from '../../../api/client';
import { UPDATE_PROJECT_WITH_EMAIL } from '../../../api/graphQL/solo/mutator';

export const updateProjectWithEmail =
    (projectId: string, userEmail: string) => async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: UPDATE_PROJECT_WITH_EMAIL,
                variables: { projectId, userEmail }
            });

            return data.updateProjectWithEmail;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }
    };
