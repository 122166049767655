import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { addDomainToEnterpriseOrganization } from '../../../../redux/actions/enterprise/addDomainToEnterpriseOrganization';
import { toast } from 'sonner';
import { Spinner } from '../../../Components/Spinner';

export const AddDomain = () => {
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const { enterpriseOrganization, apiDomainsLoading } =
        useEnterpriseOrganization();

    const [domain, setDomain] = useState('');

    const handleChangeDomain = e => {
        setDomain(e.target.value);
    };
    const submitDomain = async () => {
        if (domain.length > 0) {
            const response = await dispatch(
                addDomainToEnterpriseOrganization(
                    domain,
                    enterpriseOrganizationId
                )
            );
            setDomain('');
            toast(response.toString());
        } else {
            toast('Please add a domain.');
        }
    };

    return (
        <>
            {enterpriseOrganization.isAdmin && (
                <div>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            submitDomain();
                        }}
                    >
                        <div className="mt-5 flex items-center">
                            <div className="flex flex-row w-full max-w-[300px]">
                                <input
                                    type="text"
                                    name="domain"
                                    id="domain"
                                    value={domain}
                                    onChange={handleChangeDomain}
                                    className="w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    placeholder="Example Domain"
                                />
                            </div>

                            <button
                                type="submit"
                                className="min-w-[70px] inline-flex items-center justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ml-3"
                            >
                                {apiDomainsLoading ? (
                                    <Spinner w="5" h="5" />
                                ) : (
                                    <>Add +</>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};
