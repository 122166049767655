import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { addTechStackItem } from '../../../redux/actions/solo/addTechStackItem';
import { Spinner } from '../../Components/Spinner';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';

export const AddTeckStackitem = () => {
    const dispatch = useDispatch();
    const { projectId } = useParams();

    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const { isLeadGen } = useSubscriptionPayment();

    const handleChangeName = e => {
        setName(e.target.value);
    };
    const submitName = async () => {
        if (name.length > 0) {
            setLoading(true);
            try {
                await dispatch(
                    addTechStackItem({
                        projectId,
                        name
                    })
                );
                setLoading(false);
                setName('');
            } catch (err) {
                console.log(err);
                toast(err.message);
            }
            setLoading(false);
            setName('');
        } else {
            toast('Please add a name.');
        }
    };

    if (isLeadGen) return null;
    return (
        <form
            className="flex items-center justify-center"
            onSubmit={e => {
                e.preventDefault();
                submitName();
            }}
        >
            <div className="flex flex-row w-full max-w-[200px]">
                <input
                    type="text"
                    name="name"
                    disabled={loading}
                    id="default_teck_name"
                    value={name}
                    onChange={handleChangeName}
                    className="w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    placeholder="React"
                />
            </div>

            <button
                type="submit"
                disabled={loading}
                className="min-w-[70px] inline-flex items-center justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ml-3"
            >
                {loading ? <Spinner w="5" h="5" /> : <>Add +</>}
            </button>
        </form>
    );
};
