import { Dialog, Transition } from '@headlessui/react';
import {
    Bars3Icon,
    FolderOpenIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Settings } from './SidebarItems/Settings';
import { Link, useLocation } from 'react-router-dom';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { FeedBackButton } from '../../Components/FeedbackButton';
import { SidebarItems } from './SidebarItems';
import { PersonIcon } from '@radix-ui/react-icons';
import { useProject } from '../../../context-providers/Project';
import { useSelector, useDispatch } from 'react-redux';
import {
    toggleSidebar,
    setSidebarOpen,
    selectSidebarOpen
} from '../../../redux/reducers/generic/sidebar';
import trackEvent from '../../../helpers/trackEvent';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

export default function MobileSidebar() {
    const dispatch = useDispatch();
    const sidebarOpen = useSelector(selectSidebarOpen);
    let { enterpriseOrganization } = useEnterpriseOrganization();
    const { pathname } = useLocation();
    let { project, pageType } = useProject();

    const handleToggleSidebar = () => {
        dispatch(toggleSidebar());
    };

    const handleCloseSidebar = () => {
        dispatch(setSidebarOpen(false));
    };

    let title =
        pageType === 'chat'
            ? 'Project Discovery'
            : pageType === 'graph'
              ? 'Architecture'
              : pageType === 'sow'
                ? 'Documentation'
                : pageType === 'roadmap'
                  ? 'Roadmap'
                  : pageType === 'code'
                    ? 'Code Gen'
                    : null;

    const { accessTokenPayload } = useSessionContext();

    return (
        enterpriseOrganization && (
            <>
                <div
                    id="topNav"
                    className="h-[46px] justify-between flex bg-black px-4 shadow-sm sm:px-6 lg:hidden z-[50]"
                >
                    <button
                        type="button"
                        className="text-gray-400"
                        onClick={handleToggleSidebar}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {title && (
                        <div className="md:hidden w-full h-full flex items-center justify-center">
                            <span className="text-white font-semibold text-md tracking-wide">
                                {title}
                            </span>
                        </div>
                    )}
                </div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="z-[40] lg:hidden"
                        onClose={handleCloseSidebar}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel
                                    id="mobile-nav"
                                    className="relative mr-16 flex w-full max-w-xs flex-1 z-[100]"
                                >
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button
                                                type="button"
                                                className="-m-2.5 p-2.5"
                                                onClick={handleCloseSidebar}
                                            >
                                                <span className="sr-only">
                                                    Close sidebar
                                                </span>
                                                <XMarkIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex grow flex-col overflow-y-auto bg-black text-white px-6 z-20">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <div className="flex justify-between items-center text-white -mx-4">
                                                <div className="flex justify-between">
                                                    <div className="flex h-16 shrink-0 items-center">
                                                        <Link to="/">
                                                            <img
                                                                className="ml-2 h-8 w-auto rounded-full p-[3px]"
                                                                src="/predev.png"
                                                            />
                                                        </Link>
                                                        <Link
                                                            to="/"
                                                            className="flex"
                                                        >
                                                            <span className="ml-1 font-semibold self-center">
                                                                pre.dev
                                                            </span>
                                                            <span className="mt-3 ml-2 self-center text-xs font-semibold whitespace-nowrap text-green-500">
                                                                Enterprise
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <Link
                                                        to="/projects"
                                                        className="flex items-center"
                                                        onClick={() =>
                                                            trackEvent(
                                                                'soloSwitchClicked',
                                                                {
                                                                    userEmail:
                                                                        accessTokenPayload?.email
                                                                }
                                                            )
                                                        }
                                                    >
                                                        <a class="ml-4 inline-flex text-blue-600 items-center justify-center px-2 py-1 me-3 text-xs font-medium bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                            <PersonIcon className="w-3 h-3 mr-1" />
                                                            Solo
                                                        </a>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <nav className="flex flex-1 flex-col h-full">
                                            <ul
                                                role="list"
                                                className="flex-grow flex flex-col justify-between"
                                            >
                                                <li>
                                                    <ul
                                                        role="list"
                                                        className="-mx-2 space-y-1"
                                                        onClick={
                                                            handleCloseSidebar
                                                        }
                                                    >
                                                        <SidebarItems />
                                                    </ul>
                                                </li>
                                                <li className="-mx-6 mt-auto border-t border-gray-300">
                                                    <div className="mx-6 mt-4">
                                                        <FeedBackButton />
                                                    </div>
                                                    <Settings />
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </>
        )
    );
}
