import { useProject } from '../../../../context-providers/Project';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import { RevealEmail } from '../../../EnterpriseHome/LeadGeneration/ProjectsList/RevealEmail';
import { SendIntroductionEmail } from '../../../EnterpriseHome/LeadGeneration/ProjectsList/SendIntroductionEmail';

export const LeadGenData = () => {
    const { project } = useProject();
    const { isLeadGen, isSubbed } = useSubscriptionPayment();
    const { enterpriseOrganization: orgData } = useEnterpriseOrganization();

    const leadGenCreditsLeft = orgData?.leadGenData?.leadGenCreditsLeft;

    if (!isLeadGen) {
        return null;
    }

    return (
        <div className="flex flex-row space-x-4 p-4 rounded-lg">
            {!project.leadGenEmailRevealed ? (
                <div className="flex items-center space-x-4">
                    <RevealEmail projectId={project._id} />
                    {isSubbed && (
                        <div className="flex items-center space-x-2">
                            <CurrencyDollarIcon className="h-5 w-5 text-gray-400" />
                            <span className="text-sm font-medium text-gray-300">
                                {leadGenCreditsLeft} Credits Left
                            </span>
                        </div>
                    )}
                </div>
            ) : (
                <>
                    <div className="flex items-center space-x-2">
                        <span>{project.userEmail}</span>
                    </div>
                    {project.leadGenMeetingTriggered ? (
                        <span className="px-3 py-1 text-sm font-medium text-green-700 bg-green-100 rounded-full w-fit">
                            Introduction Sent
                        </span>
                    ) : (
                        <SendIntroductionEmail projectId={project._id} />
                    )}
                </>
            )}
        </div>
    );
};
