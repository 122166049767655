import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getThumbsRating } from '../../../redux/actions/solo/getThumbsRating';
import { updateThumbsRating } from '../../../redux/actions/solo/updateThumbsRating';
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/solid';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';

const ThumbsUpDown = ({ id, metadata, baseColor = 'gray-400' }) => {
    const dispatch = useDispatch();
    const [rating, setRating] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { isSubbed, isShare } = useSubscriptionPayment();

    useEffect(() => {
        const fetchRating = async () => {
            setIsLoading(true);
            const currentRating = await dispatch(getThumbsRating(id));
            if (currentRating) {
                setRating(currentRating?.rating);
            }
            setIsLoading(false);
        };
        fetchRating();
    }, [id, dispatch]);

    const handleRating = async newRating => {
        setIsLoading(true);
        if (!metadata) metadata = [];
        const updatedRating = await dispatch(
            updateThumbsRating(id, newRating, metadata)
        );
        setRating(updatedRating.rating);
        setIsLoading(false);
    };

    return (
        !isShare && (
            <div className="flex items-center opacity-80 hover:opacity-100 transition-opacity duration-200">
                <button
                    onClick={() => handleRating('up')}
                    className={`p-1 transition-colors duration-200 rounded`}
                    disabled={isLoading}
                >
                    <HandThumbUpIcon
                        className={`h-5 w-5 ${
                            rating === 'up'
                                ? 'text-green-500'
                                : `text-${baseColor}`
                        } hover:text-green-600 transition-colors duration-200`}
                    />
                </button>
                <button
                    onClick={() => handleRating('down')}
                    className={`p-1 transition-colors duration-200 rounded`}
                    disabled={isLoading}
                >
                    <HandThumbDownIcon
                        className={`h-5 w-5 ${
                            rating === 'down'
                                ? 'text-red-500'
                                : `text-${baseColor}`
                        } hover:text-red-600 transition-colors duration-200`}
                    />
                </button>
            </div>
        )
    );
};

export default ThumbsUpDown;
