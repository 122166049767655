import { useRef, useState } from 'react';
import { Suggest } from './Suggest';
import { SkipQuestions } from './SkipQuestions';
import { AttachFile } from './AttachFile';
import { Input } from './Input';

export const ChatInput = ({ isSolo, isWidget, isEnterprise, isShare }) => {
    const textareaRef = useRef(null);
    const [answer, setAnswer] = useState('');
    const [image_url, setImageUrl] = useState(null);
    const [fileKeyS3, setFileKeyS3] = useState(null);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isUploading, setUploading] = useState(false);

    return (
        <div className="px-4 left-0 w-full border-t bg-white" id="chatInput">
            <div className="flex flex-row items-center justify-between mt-2 gap-x-2">
                <SkipQuestions isWidget={isWidget} />
                <div className="flex flex-row items-center gap-x-2">
                    <AttachFile
                        isShare={isShare}
                        setImageUrl={setImageUrl}
                        setFileKeyS3={setFileKeyS3}
                        setFile={setFile}
                        setProgress={setProgress}
                        setUploading={setUploading}
                        progress={progress}
                        file={file}
                        isUploading={isUploading}
                    />
                </div>
            </div>
            <Input
                isShare={isShare}
                isSolo={isSolo}
                isWidget={isWidget}
                image_url={image_url}
                setImageUrl={setImageUrl}
                fileKeyS3={fileKeyS3}
                setFileKeyS3={setFileKeyS3}
                setFile={setFile}
                setProgress={setProgress}
                isUploading={isUploading}
                answer={answer}
                setAnswer={setAnswer}
                textareaRef={textareaRef}
            />

            <Suggest
                isShare={isShare}
                textareaRef={textareaRef}
                setAnswer={setAnswer}
                answer={answer}
            />
        </div>
    );
};
