import { client } from '../../../api/client';
import { SET_PROJECT_BUDGET } from '../../../api/graphQL/solo/mutator';
import { setProjectBudget } from '../../reducers/generic/project';

export function updateProjectBudget(projectId: string, budget: number) {
    return async function (dispatch: any) {
        try {
            const { data } = await client.mutate({
                mutation: SET_PROJECT_BUDGET,
                variables: { projectId, budget }
            });

            if (data.setProjectBudget) {
                dispatch(setProjectBudget(budget));
            }

            return data.setProjectBudget;
        } catch (error) {
            console.error('Error updating project budget:', error);
            throw error;
        }
    };
}
