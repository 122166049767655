import React from 'react';
import { getLinkedInAuthUrl } from '../../../../redux/actions/solo/get_linkedin_auth_url';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { setRedirectURL } from '../../../../constants';
import { selectProfile } from '../../../../redux/reducers/generic/profile';
import { useSelector } from 'react-redux';
export function LinkedInVerification() {
    const dispatch = useDispatch();
    const [linkedinAuthUrl, setLinkedinAuthUrl] = useState(null);
    const profile = useSelector(selectProfile);

    useEffect(() => {
        if (!profile?.linkedinProfile) {
            setRedirectURL(window.location.href);
            dispatch(getLinkedInAuthUrl()).then(url => {
                setLinkedinAuthUrl(url);
            });
        }
    }, [dispatch, profile]);

    return (
        <a
            onClick={() => {
                window.localStorage.setItem('openProfile', true);
                window.location.href = profile?.linkedinProfile
                    ? undefined
                    : linkedinAuthUrl;
            }}
            className={`w-fit mx-auto flex items-center justify-center gap-2 px-4 py-2 border rounded-md ${
                profile?.linkedinProfile
                    ? 'bg-gray-100 text-gray-500 border-gray-300 cursor-not-allowed'
                    : 'bg-white text-[#0A66C2] border-[#0A66C2] hover:bg-white hover:scale-105 transition-all'
            }`}
        >
            <img src="/linkedin.png" alt="LinkedIn" className="w-5 h-5" />
            {profile?.linkedinProfile
                ? 'Verified with LinkedIn'
                : 'Verify with LinkedIn'}
        </a>
    );
}
