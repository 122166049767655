import { Link } from 'react-router-dom';

export const ChoosePage = () => {
    return (
        <div className="z-1000 absolute mx-auto flex items-center justify-center w-full top-16 mt-2 md:mt-0">
            <div className="flex justify-center items-center border border-gray-600 rounded-xl px-2 h-12 font-bold bg-black">
                <Link
                    to="/"
                    className={`inline-flex items-center justify-center text-sm py-1 ${
                        window.location.pathname === '/'
                            ? 'text-blue-400 bg-gray-800'
                            : 'text-blue-300 opacity-70 hover:bg-gray-900'
                    } rounded-lg hover:opacity-100 transition-colors duration-300 w-24`}
                >
                    Solo
                </Link>
                <div className="border-r border-gray-400 mx-2 h-6"></div>
                <Link
                    to="/enterprise"
                    className={`inline-flex items-center justify-center text-sm py-1 ${
                        window.location.pathname === '/enterprise'
                            ? 'text-green-400 bg-gray-800'
                            : 'text-green-300 opacity-70 hover:bg-gray-900'
                    } rounded-lg hover:opacity-100 transition-colors duration-300 w-24`}
                >
                    Enterprise
                </Link>
            </div>
        </div>
    );
};
