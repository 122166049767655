import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';

export const Title = ({}) => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    return (
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-2 lg:px-8 mb-4 sticky top-0 bg-white z-5">
            <div className="min-w-0 flex-1">
                <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                    Projects
                </h1>
            </div>
        </div>
    );
};
