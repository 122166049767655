import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { exchangeLinkedInCodeForAccessToken } from '../../redux/actions/solo/exchange_linkedin_code_for_access_token';
import { useDispatch } from 'react-redux';
import { getRedirectURL } from '../../constants';

export function VerifyLinkedIn() {
    const [countdown, setCountdown] = useState(5);
    const [searchParams, setSearchParams] = useSearchParams();
    const [exchanged, setExchanged] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prev => {
                if (prev <= 1) {
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (searchParams.get('code') && searchParams.get('state')) {
            dispatch(
                exchangeLinkedInCodeForAccessToken({
                    code: searchParams.get('code'),
                    state: searchParams.get('state')
                })
            ).then(() => {
                setExchanged(true);
                window.location.href = getRedirectURL();
            });
        }
    }, [searchParams, dispatch]);

    return (
        <div className="flex flex-col items-center justify-center h-screen px-4">
            <img
                src="/predevlogosquareblack.png"
                alt="Meeting scheduled"
                className="w-[100px] h-[100px]"
            />
            <h1 className="text-2xl font-bold">Verifying LinkedIn...</h1>
            <p className="text-lg">
                Redirecting to pre.dev in {countdown} seconds...
            </p>
        </div>
    );
}
