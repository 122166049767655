import { toast } from 'sonner';
import { client } from '../../../api/client';
import { FORK_PROJECT } from '../../../api/graphQL/enterprise/mutator';

export const forkProject =
    ({ projectId, enterpriseOrganizationId }) =>
    async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: FORK_PROJECT,
                variables: {
                    projectId,
                    enterpriseOrganizationId
                }
            });

            if (data.forkProject) {
                return data.forkProject;
            }

            return false;
        } catch (error) {
            console.error('Error forking project:', error);
            toast.error('Error forking project');
            return false;
        }
    };
