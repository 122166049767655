import { client } from '../../../api/client';
import { GET_SOLO_SUBSCRIPTION_LINKS } from '../../../api/graphQL/solo/query';
import { setSubscriptionLinks } from '../../reducers/generic/profile';

export const getSoloSubscriptionLinks = () => async (dispatch, getState) => {
    try {
        let { data } = await client.mutate({
            mutation: GET_SOLO_SUBSCRIPTION_LINKS
        });

        let links = data.getSoloSubscriptionLinks;

        const filteredLinks = links.filter(link => link.trial === false);
        dispatch(setSubscriptionLinks(filteredLinks));

        return filteredLinks;
    } catch (error) {
        console.log({ error });
        console.log(JSON.stringify(error, null, 2));
    }
};
