import ReactDOM from 'react-dom/client';
import App from './App';
import './tailwind.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
