import { useParams } from 'react-router-dom';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { UserItem } from './user_item';

export const UsersList = ({ accessTokenPayload }) => {
    const { enterpriseOrganizationId } = useParams();
    const { enterpriseOrganization, enterpriseUsers } =
        useEnterpriseOrganization();

    // Sort enterpriseUsers by creation date in descending order (newest first)
    const sortedEnterpriseUsers = [...enterpriseUsers].sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
    });

    return (
        <div className="mt-8 flow-root">
            <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4 py-2 sm:px-6 lg:px-8">
                    {sortedEnterpriseUsers &&
                        sortedEnterpriseUsers.map((user, i) => (
                            <UserItem
                                key={i}
                                user={user}
                                enterpriseOrganizationId={
                                    enterpriseOrganizationId
                                }
                                enterpriseOrganization={enterpriseOrganization}
                                accessTokenPayload={accessTokenPayload}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};
