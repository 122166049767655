import { useEffect, useState } from 'react';
import DesktopSidebar from './Sidebars/DesktopSidebar';
import MobileSidebar from './Sidebars/MobileSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { selectInitLoaded } from '../../redux/reducers/generic/projects';
import { useProjects } from '../../context-providers/Projects';
import { Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { SubscribeModal } from './SubscribeModal';
import { clearStateProjects } from '../../redux/reducers/generic/projects';
import { setIsLeadGen } from '../../redux/reducers/generic/project';
import { Loading } from '../Components/Loading';

export default function Home() {
    const [pageHeight, setPageHeight] = useState(window.innerHeight);
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const initLoaded = useSelector(selectInitLoaded);
    const { projects, isAdmin } = useProjects();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (projects.length > 0 && pathname === '/projects') {
            navigate(`/projects/${projects[0]._id}`);
        }
    }, [projects]);

    if (
        !pathname.includes('new') &&
        initLoaded &&
        projects.length === 0 &&
        !isAdmin
    ) {
        navigate('/projects/new');
    }

    useEffect(() => {
        dispatch(clearStateProjects());
        dispatch(setIsLeadGen(false));
        const resizeHandler = () => {
            setPageHeight(window.innerHeight);
            setPageWidth(window.innerWidth);
        };

        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener('resize', resizeHandler);
            dispatch(clearStateProjects());
        };
    }, []);

    const height = pageHeight - (pageWidth < 1024 ? 46 : 0) + 'px';

    return (
        <>
            <div className="z-20">
                <MobileSidebar />
            </div>
            <div
                className="bg-white w-full h-screen flex flex-row "
                style={{ maxHeight: height }}
            >
                <DesktopSidebar />
                <SubscribeModal />
                <div
                    className={`w-full flex h-full overflow-auto lg:h-screen flex-col justify-center`}
                >
                    <div className="w-full flex flex-col h-full">
                        {initLoaded ? <Outlet /> : <Loading text="Project" />}
                    </div>
                </div>
            </div>
        </>
    );
}
