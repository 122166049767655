import { GET_PROJECT_BY_ENTERPRISE_WIDGET } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchProjectSuccess,
    fetchProjectFailure,
    setMeetingRequested
} from '../../reducers/generic/project';

import { setChatHistory } from '../../reducers/generic/chatHistory';

export const getProjectByEnterpriseWidget =
    ({ apiKey, projectId }) =>
    async dispatch => {
        try {
            const { data } = await client.query({
                query: GET_PROJECT_BY_ENTERPRISE_WIDGET,
                variables: { apiKey, projectId },
                fetchPolicy: 'network-only'
            });

            //Set up Project
            dispatch(
                fetchProjectSuccess(data.projectByEnterpriseOrganizationWidget)
            );

            //Set up Chat History
            dispatch(
                setChatHistory(
                    data.projectByEnterpriseOrganizationWidget.chatHistory
                )
            );

            //Set up request meeting status
            dispatch(
                setMeetingRequested(
                    data.projectByEnterpriseOrganizationWidget.meetingRequested
                )
            );
        } catch (error) {
            console.log({ error });
            dispatch(fetchProjectFailure(error));
        }
    };
