import { client } from '../../../api/client';
import { ADD_USER_STORY } from '../../../api/graphQL/solo/mutator';

export const addUserStory =
    (nodeId, projectId, description, priority, acceptanceCriteria) =>
    async (dispatch, getState) => {
        try {
            let { data } = await client.mutate({
                mutation: ADD_USER_STORY,
                variables: {
                    nodeId,
                    projectId,
                    description,
                    priority,
                    acceptanceCriteria
                }
            });

            return data.addUserStory;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }
    };
