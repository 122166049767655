import { useSubscription } from '@apollo/client';
import { ADD_EXISTING_PROJECT_CONTEXT_SUBSCRIPTION } from '../../../../../api/graphQL/solo/subscription';
import { Markdown } from '../../../../Components/Markdown';
import { removeExistingProjectContext } from '../../../../../redux/actions/solo/removeExistingProjectContext';
import { useDispatch, useSelector } from 'react-redux';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Spinner } from '../../../../Components/Spinner';
import { useState, useEffect } from 'react';
import {
    selectExistingProjectContexts,
    updateExistingProjectContext
} from '../../../../../redux/reducers/generic/project';
import { getSignedDownloadURL } from '../../../../../redux/actions/solo/getSignedDownloadURL';

export const Summaries = () => {
    let existingProjectContexts = useSelector(selectExistingProjectContexts);
    return (
        <div className="flex flex-col gap-y-2 w-full">
            <h2 className="text-sm font-semibold">
                Submissions ({existingProjectContexts.length})
            </h2>
            <p className="text-sm text-gray-500 dark:text-gray-400">
                Each submission will be summarized and used to help scope out
                new features.
            </p>
            <div className="flex flex-col gap-y-6 w-full">
                {existingProjectContexts.map(context => (
                    <Summary context={context} />
                ))}
            </div>
        </div>
    );
};

const Summary = ({ context }) => {
    const [deleting, setDeleting] = useState(false);
    const { _id, title, text, summary, fileKeyS3, type, projectId } = context;
    useSubscription(ADD_EXISTING_PROJECT_CONTEXT_SUBSCRIPTION, {
        variables: { existingProjectContextId: _id },
        shouldResubscribe: true,
        onData: data => {
            try {
                dispatch(
                    updateExistingProjectContext({
                        _id,
                        text,
                        title,
                        summary: data.data.data.existingProjectContextStream,
                        fileKeyS3,
                        type,
                        projectId
                    })
                );
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
    });
    const dispatch = useDispatch();

    const onDelete = async () => {
        setDeleting(true);
        await dispatch(
            removeExistingProjectContext({
                existingProjectContextId: _id.toString()
            })
        );
        setDeleting(false);
    };

    const [image_url, setImageUrl] = useState(null);

    const handleDownload = async e => {
        e.preventDefault();

        const signedUrl = await dispatch(getSignedDownloadURL(fileKeyS3));

        // Redirect the user to the signed URL, this will start the download
        window.open(signedUrl, '_blank');
    };

    useEffect(() => {
        let asyncFunc = async () => {
            if (context.image_url && fileKeyS3) {
                const signedUrl = await dispatch(
                    getSignedDownloadURL(fileKeyS3)
                );
                setImageUrl(signedUrl);
            }
        };
        asyncFunc();
    }, []);

    return (
        <div className="flex flex-col gap-y-2 w-full border border-gray-200 rounded-lg p-4 ">
            <div className="flex flex-row justify-between">
                <h3 className="text-sm font-semibold">{title}</h3>
                <button
                    type="button"
                    onClick={onDelete}
                    disabled={deleting}
                    className="text-red-700 hover:text-red-800 focus:outline-none font-medium rounded-lg text-sm px-4 text-center inline-flex items-center"
                >
                    {deleting ? (
                        <Spinner w="4" />
                    ) : (
                        <TrashIcon
                            className="h-4 w-4 focus:outline-none"
                            aria-hidden="true"
                        />
                    )}
                </button>
            </div>
            <hr />
            <div className="grid grid-cols-2 divide-x-2 divide-gray-200">
                <div className="text-sm text-gray-500 px-4">
                    <p className="w-full text-lg text-black text-center">
                        Original:
                    </p>
                    <br />
                    {text ? text : null}
                    {image_url && (
                        <img
                            src={image_url}
                            alt="Message Attachment"
                            className="max-w-full h-auto"
                        />
                    )}
                    {fileKeyS3 && !context.image_url && (
                        <a
                            onClick={handleDownload}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cursor-pointer text-blue-500 hover:underline"
                        >
                            {fileKeyS3.substring(36)}
                        </a>
                    )}
                </div>
                <div className="text-sm text-gray-500 px-4">
                    <p className="w-full text-lg text-black text-center">
                        Summary:
                    </p>
                    {!summary && (
                        <div className="flex items-center gap-x-2 justify-center border border-gray-200 rounded-lg p-2 my-2">
                            Generating Summary...
                            <Spinner w="4" />
                        </div>
                    )}
                    <Markdown content={summary} />
                </div>
            </div>
        </div>
    );
};
