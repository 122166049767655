import { useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import {
    InformationCircleIcon,
    EnvelopeIcon,
    ChevronDownIcon,
    ChevronUpIcon
} from '@heroicons/react/24/outline';
import { InputField } from '../WidgetManagement/Helpers/InputField';
import PreDevTriggerMatchEmail from '../../emails/pre_dev_trigger_match';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import Modal from '../../Components/Modal';
import { ImageField } from '../WidgetManagement/Helpers/ImageField';

export function ContactDetails() {
    const [isMinimized, setIsMinimized] = useState(() => {
        const storedValue = window.localStorage.getItem(
            'contactDetailsMinimized'
        );
        return storedValue ? JSON.parse(storedValue) : false;
    });
    const [showEmailPreview, setShowEmailPreview] = useState(false);

    const toggleMinimize = () => {
        const newValue = !isMinimized;
        setIsMinimized(newValue);
        window.localStorage.setItem(
            'contactDetailsMinimized',
            JSON.stringify(newValue)
        );
    };

    const { enterpriseOrganization } = useEnterpriseOrganization();

    const {
        name: enterpriseName,
        calendlyLink: enterpriseSchedulingLink,
        websiteURL: enterpriseURL,
        introductionLogo: introductionLogo
    } = enterpriseOrganization || {};

    // Note: clientName and projectName are not typically stored in the enterprise organization
    // They would likely come from the current project or client context
    // For now, we'll use example values
    const clientName = 'Example Client';
    const projectName = 'Down to Chill';

    return (
        <div className="flex justify-center">
            <div className="w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
                <div className="px-4 py-2">
                    <div className="flex items-center justify-between">
                        <h2 className="text-md font-semibold flex items-center">
                            <EnvelopeIcon className="h-5 w-5 mr-2 text-gray-600" />
                            Introduction Email Config
                        </h2>
                        <div className="flex items-center">
                            <Tooltip.Provider delayDuration={100}>
                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <InformationCircleIcon className="h-6 w-6 text-gray-400 cursor-help mr-2" />
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content
                                            className="max-w-xs p-2 bg-white text-black rounded border border-gray-200"
                                            sideOffset={5}
                                        >
                                            <p className="text-sm">
                                                Configure these fields for
                                                sending introduction emails
                                            </p>
                                            <Tooltip.Arrow className="fill-white" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>
                            </Tooltip.Provider>
                            <button
                                onClick={toggleMinimize}
                                className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
                            >
                                {isMinimized ? (
                                    <ChevronDownIcon className="h-5 w-5" />
                                ) : (
                                    <ChevronUpIcon className="h-5 w-5" />
                                )}
                            </button>
                        </div>
                    </div>
                    <div
                        className={`overflow-hidden transition-all duration-300 ease-in-out ${
                            isMinimized
                                ? 'max-h-0 opacity-0'
                                : 'max-h-[1000px] opacity-100 mt-4'
                        }`}
                    >
                        <div className="space-y-4 px-1 py-1">
                            <div className="flex flex-col sm:flex-row gap-x-4 items-center">
                                <div className="flex flex-col gap-y-2 min-w-[300px]">
                                    <InputField
                                        field="calendlyLink"
                                        label="Scheduling Link"
                                        placeholder="https://calendly.com/your-link"
                                    />
                                    <InputField
                                        field="websiteURL"
                                        label="Website URL"
                                        placeholder="https://your-website.com"
                                    />
                                    <button
                                        onClick={() =>
                                            setShowEmailPreview(true)
                                        }
                                        className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 mt-4"
                                    >
                                        Preview Introduction Email
                                    </button>
                                </div>
                                <ImageField
                                    field="introductionLogo"
                                    title="Company Logo"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={showEmailPreview}
                onClose={() => setShowEmailPreview(false)}
                title="Preview Introduction Email"
            >
                <div className="mt-4">
                    <div className="flex flex-col gap-y-3 bg-gray-50 pt-4">
                        <div className="bg-white">
                            <PreDevTriggerMatchEmail
                                enterpriseName={
                                    enterpriseName || 'Your Enterprise'
                                }
                                enterpriseURL={
                                    enterpriseURL || 'https://example.com'
                                }
                                enterpriseSchedulingLink={
                                    enterpriseSchedulingLink ||
                                    'https://calendly.com/example'
                                }
                                clientName={clientName}
                                projectName={projectName}
                                introductionLogo={introductionLogo}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
