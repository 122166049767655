import { useParams } from 'react-router-dom';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { InviteUsers } from './invite_users';
import { UsersList } from './users_list';
import { UsersLeft } from './users_left';

export const EnterpriseUsers = ({}) => {
    const { enterpriseOrganizationId } = useParams();
    const { accessTokenPayload } = useSessionContext();
    const { enterpriseOrganization } = useEnterpriseOrganization();

    return (
        <>
            {/* Page title & actions */}
            <UsersLeft />

            <div className="px-4 mt-4">
                <div className="bg-white sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                        {enterpriseOrganization.isAdmin && (
                            <InviteUsers
                                enterpriseOrganizationId={
                                    enterpriseOrganizationId
                                }
                            />
                        )}
                        <UsersList accessTokenPayload={accessTokenPayload} />
                    </div>
                </div>
            </div>
        </>
    );
};
