import { useEffect, useRef, useState } from 'react';
import { useProject } from '../../../context-providers/Project';
import { ChatItem } from './ChatItem';
import { StreamingChat } from './StreaminingChat'; // Ensure correct import
import { useDispatch } from 'react-redux';
import { toggleSubscribeBanner } from '../../../redux/reducers/generic/billing';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { getChatHistory } from '../../../redux/actions/solo/getChatHistory';
import { selectProjectInternalVersion } from '../../../redux/reducers/generic/project';
import { useSelector } from 'react-redux';
import { HeroMatchExperience } from '../../Landing/HeroExperience/HeroMatchExperience';
import React from 'react';

export const ChatHistory = () => {
    const { chatHistory, project } = useProject();
    const dispatch = useDispatch();
    const waitingForChatResponse = chatHistory.waitingForChatResponse;
    const chat = chatHistory.data;

    const { isSubbed, isSolo } = useSubscriptionPayment();

    const chatContainer = useRef(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [hasMoreMessages, setHasMoreMessages] = useState(false);

    const [firstLoad, setFirstLoad] = useState(false);

    useEffect(() => {
        setHasMoreMessages(chat.length < project.messageCount);
    }, [chat.length, project.messageCount]);

    const loadMoreMessages = async () => {
        if (
            isLoadingMore ||
            !hasMoreMessages ||
            chat.length < 10 ||
            chat.length >= project.messageCount
        )
            return;

        setIsLoadingMore(true);
        const initialHeight = chatContainer.current.scrollHeight;

        const result = await dispatch(
            getChatHistory({
                projectId: project._id,
                skip: chat.length,
                limit: 20
            })
        );

        setIsLoadingMore(false);
        if (!result || result.length === 0) {
            setHasMoreMessages(false);
        } else {
            // Maintain scroll position after new messages are added
            setTimeout(() => {
                const newHeight = chatContainer.current.scrollHeight;
                const heightDifference = newHeight - initialHeight;
                chatContainer.current.scrollTop = heightDifference;
            }, 50);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (chat.length === 1) return; // Prevent scrolling when there's only one message

            const { scrollTop } = chatContainer.current;

            if (scrollTop === 0 && chat.length >= 10) {
                console.log('Loading more messages...');
                loadMoreMessages();
            }
        };

        const container = chatContainer.current;
        if (chat.length > 1) {
            container?.addEventListener('scroll', handleScroll);
        }
        return () => container?.removeEventListener('scroll', handleScroll);
    }, [chat, isLoadingMore, hasMoreMessages]);

    useEffect(() => {
        try {
            if ((chat.length > 10 || project.skip) && !isSubbed) {
                dispatch(toggleSubscribeBanner(true));
            } else {
                dispatch(toggleSubscribeBanner(false));
            }
        } catch (error) {
            console.log(error);
        }
    }, [
        chat,
        waitingForChatResponse,
        chatContainer,
        isSubbed,
        dispatch,
        project.skip
    ]);

    useEffect(() => {
        try {
            if (
                chatContainer.current &&
                chat &&
                chat.length > 1 &&
                !firstLoad
            ) {
                setTimeout(() => {
                    chatContainer.current.scrollTop =
                        chatContainer.current.scrollHeight;
                }, 20);

                setFirstLoad(true);
            }
        } catch (error) {
            console.log(error);
        }
    }, [chat, firstLoad]);

    // Scroll to bottom when a new message is added
    useEffect(() => {
        if (chatContainer.current) {
            chatContainer.current.scrollTop =
                chatContainer.current.scrollHeight;
        }
    }, [chat]);

    const projectInternalVersion = useSelector(selectProjectInternalVersion);
    const { isLeadGen, isHomePage } = useSubscriptionPayment();

    const height = projectInternalVersion == 2 ? 230 : isLeadGen ? 100 : 230; // Consider using a constant or a dynamic value

    return (
        <div
            className={`rounded-xl flex max-w-3xl justify-center mx-auto bg-slate-900 p-4 text-sm leading-6 text-slate-900 dark:bg-slate-800 dark:text-slate-300 sm:text-base sm:leading-7 md:flex md:flex-col justify-end md:justify-start h-full lg:pb-0 ${
                chat.length === 1 ? '' : 'overflow-y-auto'
            } dark`}
            ref={chatContainer}
        >
            <div
                id="chatHistory"
                className=""
                style={{
                    height: `calc(100vh - ${height}px)`
                }}
            >
                {isLoadingMore && (
                    <div className="flex justify-center items-center py-4">
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-300"></div>
                    </div>
                )}
                {chat &&
                    chat.map((message, i) => {
                        const messageComponent = (
                            <ChatItem
                                isSolo={isSolo}
                                message={message}
                                i={i}
                                key={message._id || i}
                                length={chat.length}
                            />
                        );

                        if (
                            (isHomePage || isSolo) &&
                            !project.matchWithDev &&
                            message.role === 'assistant' &&
                            i === 2
                        ) {
                            return (
                                <React.Fragment key={`message-${i}`}>
                                    {messageComponent}
                                    <HeroMatchExperience
                                        chatContainer={chatContainer}
                                    />
                                </React.Fragment>
                            );
                        }

                        return messageComponent;
                    })}
                <StreamingChat chatContainer={chatContainer} />
                <div className="flex px-2 py-2"></div>
            </div>
        </div>
    );
};
