import { useProject } from '../../../context-providers/Project';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { toggleSubscribeModal } from '../../../redux/reducers/generic/billing';
import { ScheduleLink } from './scheduleLink';
import { BudgetData } from './BudgetData';
import { QuoteMode } from './quoteMode';
import { MatchWithDev } from './matchWithDev';
import { Header } from './header';
import { UrgencySlider } from './Urgency';
import { setUrgency } from '../../../redux/actions/solo/setUrgency';
import { updateMatchWithDev } from '../../../redux/actions/solo/updateMatchWithDev';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    setBudgetMinimized,
    selectBudgetMinimized
} from '../../../redux/reducers/generic/project';
import { setProfileModalOpen } from '../../../redux/reducers/generic/profile';
import trackEvent from '../../../helpers/trackEvent';

export const Budget = ({ isMobile }) => {
    const { project } = useProject();
    const { isEnterprise, isSubbed, isSolo, isHomePage } =
        useSubscriptionPayment();

    const dispatch = useDispatch();
    const isMinimized = useSelector(selectBudgetMinimized);

    const setIsMinimized = value => {
        dispatch(setBudgetMinimized(value));
    };

    const [urgency, setUrgencyState] = useState(project.urgency || 0);
    const [debouncedUrgency, setDebouncedUrgency] = useState(urgency);
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        async function handleDisableMatch() {
            if (
                searchParams.get('disableMatchWithDev') &&
                project.matchWithDev
            ) {
                setMatchLoading(true);
                await dispatch(updateMatchWithDev(project._id, false));
                setMatchLoading(false);
            }
        }
        handleDisableMatch();
    }, [searchParams, project, dispatch, project._id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedUrgency(urgency);
        }, 500);

        return () => clearTimeout(timer);
    }, [urgency]);

    useEffect(() => {
        if (debouncedUrgency !== project.urgency) {
            dispatch(setUrgency(project._id, debouncedUrgency));
        }
    }, [debouncedUrgency, project._id]);

    const handleUrgencyChange = newUrgency => {
        setUrgencyState(newUrgency);
    };

    let [matchLoading, setMatchLoading] = useState(false);

    const setMatchWithDev = async value => {
        setMatchLoading(true);
        await dispatch(updateMatchWithDev(project._id, value));
        setMatchLoading(false);
    };
    return (
        <div
            className={`w-fit bg-gradient-to-br from-gray-900 to-black max-w-2xl rounded-lg shadow-lg my-2 mx-auto border-2 border-gray-700 py-4 px-4 overflow-y-auto ${
                !isMobile ? 'fixed bottom-[0px] right-[5px] z-[10]' : ''
            }`}
            style={{
                maxHeight: 'calc(100vh - 100px)'
            }}
        >
            <Header
                isMinimized={isMinimized}
                setIsMinimized={setIsMinimized}
                matchWithDev={project.matchWithDev}
                setMatchWithDev={setMatchWithDev}
                matchLoading={matchLoading}
            />
            {!isMinimized && (
                <div
                    className={`overflow-hidden gap-y-2 flex flex-col transition-all duration-400 ease-in-out ${
                        isMinimized ? 'max-h-0 opacity-0' : 'mt-4 opacity-100'
                    }`}
                >
                    <BudgetData />

                    <div className="flex justify-between gap-x-2 gap-y-2">
                        <div className="w-full">
                            {isEnterprise ? (
                                <QuoteMode />
                            ) : (
                                <MatchWithDev
                                    matchWithDev={project.matchWithDev}
                                    setMatchWithDev={setMatchWithDev}
                                    matchLoading={matchLoading}
                                    from="budget"
                                />
                            )}
                        </div>
                    </div>
                    {project.matchWithDev && (isSolo || isHomePage) && (
                        <div className="flex flex-col gap-y-4">
                            {((isSolo && !isSubbed) || isHomePage) && (
                                <JumpToFrontOfQueue />
                            )}
                            <ScheduleLink />
                            <UrgencySlider
                                urgency={urgency}
                                setUrgency={handleUrgencyChange}
                                textColor="text-white"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
export const JumpToFrontOfQueue = () => {
    const dispatch = useDispatch();
    const { projectId, project } = useProject();
    const { isEnterprise, isSubbed, isSolo, isHomePage } =
        useSubscriptionPayment();
    const navigate = useNavigate();
    return (
        <div className="bg-gradient-to-r from-indigo-500 to-purple-600 rounded-lg p-3 shadow-lg">
            <p className="text-white text-md flex items-center gap-2">
                Get priority access to dev agencies
            </p>
            <button
                onClick={() => {
                    if (isSolo) {
                        trackEvent(`jumpToFrontOfQueueSolo`, {
                            projectId,
                            userEmail: project?.userEmail
                        });
                        dispatch(setProfileModalOpen(true));
                    } else {
                        trackEvent(`jumpToFrontOfQueueLanding`, {
                            projectId,
                            userEmail: project?.userEmail
                        });
                        window.localStorage.setItem('openProfile', true);
                        navigate('/auth?redirectToPath=/projects');
                    }
                }}
                className="inline-block mt-2 px-6 py-2.5 bg-white text-black text-sm font-semibold rounded-lg 
                hover:bg-gray-100 hover:scale-105 hover:shadow-lg
                active:scale-95
                transition-all duration-200 ease-out
                shadow-md"
            >
                <span className="flex items-center gap-2">
                    <span>🚀</span>
                    Jump to the Front of the Queue →
                </span>
            </button>
        </div>
    );
};
