import React, { useState } from 'react';
import {
    Check,
    X,
    BrainCircuit,
    Search,
    Lock,
    Building2,
    Code2,
    Users,
    MessageSquareMore
} from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const PlatformComparison = () => {
    const [selectedFeature, setSelectedFeature] = useState(null);

    const platformLogos = {
        'pre.dev': {
            src: '/predev_logo_name-removebg.png',
            height: 12,
            url: 'https://pre.dev'
        },
        'clutch.co': {
            src: '/clutch.png',
            height: 8,
            url: 'https://clutch.co'
        },
        'upwork.com': {
            src: '/upwork.png',
            height: 8,
            url: 'https://upwork.com'
        },
        'bark.com': {
            src: '/bark.png',
            height: 8,
            url: 'https://bark.com'
        },
        'pangea.ai': {
            src: '/pangea.png',
            height: 8,
            url: 'https://pangea.ai'
        }
    };

    const featureIcons = {
        idea_discovery: BrainCircuit,
        free_pricing_estimate: Search,
        no_login_required: Lock,
        architecture_plan: Building2,
        instant_prototype: Code2,
        browse_agencies: Users,
        agency_outreach: MessageSquareMore
    };

    const features = [
        {
            id: 'idea_discovery',
            name: 'AI-Powered Idea Discovery',
            description:
                'Interactive process to explore, refine, and scope project ideas with AI-driven feature recommendations',
            platforms: {
                'pre.dev': {
                    available: true,
                    details:
                        'AI-powered interactive questioning to understand scope, suggest features, and refine ideas automatically'
                },
                'clutch.co': {
                    available: false,
                    details: 'No ideation or scoping tools available'
                },
                'upwork.com': {
                    available: false,
                    details:
                        'Manual discussions with individual freelancers required'
                },
                'bark.com': {
                    available: false,
                    details: 'Basic project description only'
                },
                'pangea.ai': {
                    available: false,
                    details: 'Manual consultation required'
                }
            }
        },
        {
            id: 'free_pricing_estimate',
            name: 'Instant Price Estimate',
            description:
                'Automated, complexity-based project cost estimation without human intervention',
            platforms: {
                'pre.dev': {
                    available: true,
                    details:
                        'Instant AI-generated estimates based on project complexity and scope'
                },
                'clutch.co': {
                    available: false,
                    details: 'Requires manual quotes from service providers'
                },
                'upwork.com': {
                    available: false,
                    details: 'Requires waiting for freelancer proposals'
                },
                'bark.com': {
                    available: false,
                    details: 'Requires waiting for professional quotes'
                },
                'pangea.ai': {
                    available: false,
                    details: 'Requires consultation for pricing'
                }
            }
        },
        {
            id: 'no_login_required',
            name: 'No Login Required',
            description:
                'Access to platform features and information without creating an account',
            platforms: {
                'pre.dev': {
                    available: true,
                    details: 'Full access to AI tools without account'
                },
                'clutch.co': {
                    available: true,
                    details: 'Basic browsing only'
                },
                'upwork.com': {
                    available: false,
                    details: 'Account required for all features'
                },
                'bark.com': {
                    available: false,
                    details: 'Account required for quotes'
                },
                'pangea.ai': { available: true, details: 'Basic browsing only' }
            }
        },
        {
            id: 'architecture_plan',
            name: 'Instant Architecture Plan',
            description:
                'Automated generation of detailed software architecture and system design',
            platforms: {
                'pre.dev': {
                    available: true,
                    details:
                        'Instant AI-generated architecture plans and system design'
                },
                'clutch.co': {
                    available: false,
                    details: 'Requires hiring development companies'
                },
                'upwork.com': {
                    available: false,
                    details: 'Requires hiring freelance architects'
                },
                'bark.com': {
                    available: false,
                    details: 'No architecture planning available'
                },
                'pangea.ai': {
                    available: false,
                    details: 'Requires consultation with development teams'
                }
            }
        },
        {
            id: 'instant_prototype',
            name: 'Instant Prototype',
            description:
                'Automated generation of interactive prototypes and visualizations',
            platforms: {
                'pre.dev': {
                    available: true,
                    details: 'Instant AI-generated interactive prototypes'
                },
                'clutch.co': {
                    available: false,
                    details: 'No prototyping capabilities'
                },
                'upwork.com': {
                    available: false,
                    details: 'Requires hiring freelance developers'
                },
                'bark.com': {
                    available: false,
                    details: 'No prototyping available'
                },
                'pangea.ai': {
                    available: false,
                    details: 'Requires development team engagement'
                }
            }
        },
        {
            id: 'agency_outreach',
            name: 'Pre-Informed Agency Proposals',
            description:
                'Development agencies have complete understanding of project requirements before initial contact',
            platforms: {
                'pre.dev': {
                    available: true,
                    details:
                        'Agencies receive full project scope, architecture, and requirements before reaching out, enabling focused and relevant discussions'
                },
                'clutch.co': {
                    available: false,
                    details:
                        'Agencies reach out with limited understanding, requiring multiple discovery calls'
                },
                'upwork.com': {
                    available: true,
                    details:
                        'Initial proposals based on basic project descriptions, requiring extensive clarification'
                },
                'bark.com': {
                    available: false,
                    details:
                        'Service providers contact based on minimal project information'
                },
                'pangea.ai': {
                    available: false,
                    details:
                        'Teams need detailed discovery phase to understand requirements'
                }
            }
        }
        // {
        //     id: 'browse_agencies',
        //     name: 'Browse Development Agencies',
        //     description:
        //         'Time spent searching and comparing development agencies and their portfolios',
        //     platforms: {
        //         'pre.dev': {
        //             available: false,
        //             details:
        //                 'Skip time-consuming agency research with instant AI solution design'
        //         },
        //         'clutch.co': {
        //             available: true,
        //             details:
        //                 'Hours spent comparing agencies, reading reviews, and evaluating portfolios'
        //         },
        //         'upwork.com': {
        //             available: true,
        //             details:
        //                 'Time-intensive process of reviewing agency profiles and past projects'
        //         },
        //         'bark.com': {
        //             available: true,
        //             details:
        //                 'Manual comparison of local service provider credentials'
        //         },
        //         'pangea.ai': {
        //             available: true,
        //             details:
        //                 'Extended process of evaluating vetted development teams'
        //         }
        //     }
        // }
    ];

    const platforms = [
        'pre.dev',
        'clutch.co',
        'upwork.com',
        'bark.com',
        'pangea.ai'
    ];

    const handleRowClick = feature => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };
    const Tooltip = ({ children }) => (
        <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="absolute z-20 bg-black text-white border border-gray-700 shadow-lg rounded-md p-2 text-sm -bottom-1 left-1/2 transform -translate-x-1/2 translate-y-full w-48 hidden group-hover:block"
        >
            {children}
        </motion.div>
    );

    return (
        <div className="w-full max-w-6xl mx-auto bg-black rounded-lg shadow-md">
            <div className="flex justify-between p-6 border-b border-gray-700">
                <div className="flex items-center gap-x-2">
                    <img
                        src="/predev.png"
                        alt="pre.dev logo"
                        className="h-8 object-contain"
                    />
                    <h2 className="text-xl font-semibold text-white">
                        pre.dev vs Competitors
                    </h2>
                </div>
                <div className="flex items-center gap-x-2">
                    <Link to="/projects">
                        <button className="px-2 py-1 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-medium rounded-lg transition-all duration-200 transform hover:scale-105 shadow-lg hover:shadow-xl">
                            {Math.random() > 0.5
                                ? 'Match with a Dev Agency'
                                : 'Start Building'}
                        </button>
                    </Link>
                </div>
            </div>
            <div className="p-6 overflow-x-auto">
                <table className="w-full border-collapse min-w-[600px]">
                    <thead>
                        <tr>
                            <th className="p-3 bg-gray-900 border border-gray-700 text-left text-sm font-semibold text-white"></th>
                            {platforms.map(platform => (
                                <th
                                    key={platform}
                                    className="p-3 bg-gray-900 border border-gray-700 text-center text-sm font-semibold text-white min-w-[150px]"
                                >
                                    <a
                                        href={`https://${platform}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex flex-col items-center space-y-2"
                                    >
                                        <img
                                            src={platformLogos[platform].src}
                                            alt={`${platform} logo`}
                                            className={`h-${platformLogos[platform].height} object-contain`}
                                        />
                                    </a>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {features.map(feature => {
                            const FeatureIcon = featureIcons[feature.id];
                            const isSelected = selectedFeature === feature;
                            return (
                                <React.Fragment key={feature.id}>
                                    <tr
                                        onClick={() => handleRowClick(feature)}
                                        className={`group/row cursor-pointer hover:bg-gray-900 ${
                                            isSelected ? 'bg-gray-800' : ''
                                        }`}
                                    >
                                        <td className="p-3 border border-gray-700">
                                            <div className="flex items-center space-x-3">
                                                <div className="flex-shrink-0">
                                                    <FeatureIcon className="w-5 h-5 text-gray-400" />
                                                </div>
                                                <div>
                                                    <div className="font-medium text-white">
                                                        {feature.name}
                                                    </div>
                                                    <div className="text-sm text-gray-400 mt-1">
                                                        {feature.description}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        {platforms.map(platform => (
                                            <td
                                                key={platform}
                                                className="p-3 border border-gray-700 text-center relative group"
                                            >
                                                <div className="flex justify-center items-center">
                                                    {feature.platforms[platform]
                                                        .available ? (
                                                        <Check className="w-5 h-5 text-green-500" />
                                                    ) : (
                                                        <X className="w-5 h-5 text-red-500" />
                                                    )}
                                                    {/* <Tooltip>
                                                        {
                                                            feature.platforms[
                                                                platform
                                                            ].details
                                                        }
                                                    </Tooltip> */}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                    {isSelected && (
                                        <tr>
                                            <td
                                                colSpan={platforms.length + 1}
                                                className="p-3 border-t border-gray-700 bg-gray-900"
                                            >
                                                <div className="text-gray-300">
                                                    <strong>Details:</strong>{' '}
                                                    {feature.description}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PlatformComparison;
