import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { forkProject } from '../../../../redux/actions/enterprise/forkProject';
import * as Tooltip from '@radix-ui/react-tooltip';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../ProjectDashboard/Docs/TipTap/components/ui/Spinner/Spinner';

export const ForkProject = ({ project }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const enterpriseOrganizationId = enterpriseOrganization?._id;
    const navigate = useNavigate();

    const handleFork = async () => {
        setIsLoading(true);
        try {
            let forkedId = await dispatch(
                forkProject({
                    projectId: project._id,
                    enterpriseOrganizationId
                })
            );
            if (forkedId) {
                toast.success('Project forked successfully');
                navigate(
                    `/enterprise/dashboard/${enterpriseOrganizationId}/clientProjects/${forkedId}`
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        project.leadGenEmailRevealed && (
            <Tooltip.Provider>
                <Tooltip.Root delayDuration={0}>
                    <Tooltip.Trigger asChild>
                        <button
                            onClick={handleFork}
                            disabled={isLoading}
                            className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50"
                        >
                            {isLoading ? (
                                <Spinner className="w-4 h-4" />
                            ) : (
                                <img
                                    src={'/fork.png'}
                                    className="w-4 h-4"
                                    alt="Fork Project"
                                />
                            )}
                        </button>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                        <Tooltip.Content
                            className="rounded-md bg-gray-900 px-4 py-2 text-sm text-white shadow-md"
                            sideOffset={5}
                            delayDuration={0}
                        >
                            Fork to Internal Projects
                            <Tooltip.Arrow className="fill-gray-900" />
                        </Tooltip.Content>
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider>
        )
    );
};
