import { client } from '../../../api/client';
import { CREATE_ENTERPRISE_PAYMENT_LINK_WITH_QUANTITY_AND_DURATION } from '../../../api/graphQL/enterprise/mutator';

export const createEnterpriseStripeLinkWithQuantityAndDuration = async ({
    enterpriseOrganizationId,
    duration,
    quantity,
    addOns = []
}) => {
    try {
        const { data } = await client.mutate({
            mutation: CREATE_ENTERPRISE_PAYMENT_LINK_WITH_QUANTITY_AND_DURATION,
            variables: {
                enterpriseOrganizationId,
                duration,
                quantity,
                addOns
            }
        });

        return data.createEnterprisePaymentLinkWithQuantityAndDuration?.url;
    } catch (error) {
        console.log({ error });
        throw new Error(error.message);
    }
};
