import { ADD_DOMAIN_TO_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import {
    fetchApiDomainsRequest,
    fetchApiDomainsSuccess,
    fetchApiDomainsFailure
} from '../../reducers/enterprise/clientOnboarding';

export const addDomainToEnterpriseOrganization =
    (url, enterpriseOrganizationId) => async (dispatch, getState) => {
        dispatch(fetchApiDomainsRequest());
        try {
            const { data } = await client.mutate({
                mutation: ADD_DOMAIN_TO_ENTERPRISE_ORGANIZATION,
                variables: {
                    url: url,
                    enterpriseOrganizationId: enterpriseOrganizationId
                },
                fetchPolicy: 'network-only'
            });

            dispatch(
                fetchApiDomainsSuccess(
                    data.addDomainToEnterpriseOrganization.apiDomains
                )
            );
            return 'Successfully added domain to whitelist.';
        } catch (error) {
            console.log({ error });
            dispatch(fetchApiDomainsFailure(error));
            return error.message;
        }
    };
