import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: null,
    error: null,
    loading: null
};

export const clientOnboardingSlice = createSlice({
    name: 'clientOnboarding',
    initialState,
    reducers: {
        fetchApiDomainsRequest: (state, action) => {
            state.loading = true;
        },
        fetchApiDomainsSuccess: (state, action) => {
            state.data = action.payload;
            state.loading = null;
        },
        fetchApiDomainsFailure: (state, action) => {
            state.error = action.payload;
            state.loading = null;
        }
    }
});
export const {
    fetchApiDomainsSuccess,
    fetchApiDomainsRequest,
    fetchApiDomainsFailure
} = clientOnboardingSlice.actions;

export const selectApiDomains = state => state.clientOnboarding;

export default clientOnboardingSlice.reducer;
