import { createSlice } from '@reduxjs/toolkit';
import { getGraphData } from '../../../helpers/graph';
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { mapTasksToArchitecture } from '../../../helpers/mapTasksToArchitecture';

const initialState = {
    seen: true,
    loading: false,
    currentProjectId: null,
    currentQuestionIndex: null,
    error: null,
    graphVersion: null,
    graphVersionLoading: false,
    graphs: [],
    currentNode: null,
    currentEdge: null,
    panelResized: 0,
    og_graphs: [],
    modal: false,
    selectedNode: null,
    uiView: null,
    gettingUIView: false,
    uiViewLoading: false,
    uiViewEditing: false,
    freeNodeLabel: null,
    data: {
        type: 'mvp'
    },
    loadingSoW: {
        mvp: null,
        custom: null
    },
    hideHours: true,
    editMode: false,
    skillLevel: localStorage.getItem('skillLevel') || 'senior',
    loadingMVP: null,
    loadingCustom: null,
    budget: 0,
    budgetFilterActivated:
        localStorage.getItem('budgetFilterActivated') === 'true' || false,
    modal: false,
    prices: null,
    price: null,
    quoteMode: false
};

export const projectArchitectureSlice = createSlice({
    name: 'projectArchitecture',
    initialState,
    reducers: {
        setPanelResized: (state, action) => {
            state.panelResized = state.panelResized + 1;
        },
        setProjectArchitectureSeen: (state, action) => {
            state.seen = action.payload;
        },
        setShowNodeDetailModal: (state, action) => {
            state.modal = !state.modal;
            state.selectedNode = action.payload;
        },
        gettingUIView: (state, action) => {
            state.gettingUIView = action.payload;
        },
        setUIView: (state, action) => {
            state.uiView = action.payload;
        },
        setUIViewEditing: (state, action) => {
            state.uiViewEditing = action.payload;
        },
        waitingForProjectArchitecture: (state, action) => {
            window.localStorage.setItem(
                'waitingForProjectArchitecture',
                Date.now().toString()
            );
            state.loading = action.payload;
        },
        waitingForUIView: (state, action) => {
            state.uiViewLoading = action.payload;
        },
        setGraphVersionLoading: (state, action) => {
            state.graphVersionLoading = action.payload;
        },
        clearStateProjectArchitecture: state => {
            state.graphs = [];
            state.error = null;
            state.loading = false;
            state.currentQuestionIndex = null;
            state.currentProjectId = null;
            state.graphVersion = null;
            state.currentNode = null;
            state.currentEdge = null;
            state.panelResized = 0;
            state.modal = false;
            state.selectedNode = null;
            state.uiView = null;
            state.gettingUIView = false;
            state.uiViewLoading = false;
            state.uiViewEditing = false;
            state.freeNodeLabel = null;
        },
        setGraph: (state, action) => {
            let graph = action.payload;
            if (graph.version) state.graphVersion = graph.version;

            graph = getGraphData(graph);

            let set = false;
            let og_graphs = JSON.parse(JSON.stringify(state.og_graphs)).map(
                g => {
                    if (g.side === graph.side) {
                        set = true;
                        return graph;
                    }
                    return g;
                }
            );
            if (!set) og_graphs.push(graph);
            state.og_graphs = og_graphs;

            const { milestones, graphs } = mapTasksToArchitecture(
                JSON.parse(JSON.stringify(state?.data?.og_milestones)),
                state.skillLevel,
                state.budget,
                state.budgetFilterActivated,
                og_graphs
            );
            state.data.milestones = milestones;
            state.graphs = graphs;

            state.freeNodeLabel = findFreeNodeLabel(graphs);
        },
        setGraphVersion: (state, action) => {
            state.graphVersion = action.payload;
        },
        setProjectArchitecture: (state, action) => {
            try {
                let og_graphs = action.payload.graphs.map(graph =>
                    getGraphData(graph)
                );
                state.og_graphs = og_graphs;
                if (og_graphs && og_graphs[0]?.version) {
                    state.graphVersion = og_graphs[0].version;
                }

                const { milestones, graphs } = mapTasksToArchitecture(
                    state?.data?.og_milestones
                        ? JSON.parse(JSON.stringify(state?.data?.og_milestones))
                        : [],
                    state.skillLevel,
                    state.budget,
                    state.budgetFilterActivated,
                    og_graphs
                );
                if (state.data) state.data.milestones = milestones;
                state.graphs = graphs;

                state.freeNodeLabel = findFreeNodeLabel(graphs);
            } catch (error) {
                console.log({ error });
            }
        },
        setPrices: (state, action) => {
            state.prices = action.payload;
        },
        setPrice: (state, action) => {
            state.price = action.payload;
        },
        setSow: (state, action) => {
            let sow = JSON.parse(JSON.stringify(action.payload));
            if (sow?.milestones) {
                sow.og_milestones = JSON.parse(JSON.stringify(sow.milestones));
                const { milestones, graphs } = mapTasksToArchitecture(
                    JSON.parse(JSON.stringify(sow.og_milestones)),
                    state.skillLevel,
                    state.budget,
                    state.budgetFilterActivated,
                    state.og_graphs
                );
                sow.milestones = milestones;
                state.graphs = graphs;
            }
            sow?.milestones?.forEach(milestone => {
                milestone.stories.forEach(story => {
                    if (!story.title) story.title = story.description;
                });
            });
            state.data = sow;
        },
        setEditMode: (state, action) => {
            state.editMode = action.payload;
        },
        setSkillLevel: (state, action) => {
            state.skillLevel = action.payload;
            localStorage.setItem('skillLevel', action.payload);
            if (state.data?.milestones) {
                const { milestones, graphs } = mapTasksToArchitecture(
                    JSON.parse(JSON.stringify(state?.data?.og_milestones)),
                    action.payload,
                    state.budget,
                    state.budgetFilterActivated,
                    state.og_graphs
                );
                state.data.milestones = milestones;
                state.graphs = graphs;
            }
        },
        setHideHours: (state, action) => {
            state.hideHours = action.payload;
        },
        waitingForSowMVP: (state, action) => {
            state.loadingMVP = action.payload;
        },
        waitingForSowCustom: (state, action) => {
            state.loadingCustom = action.payload;
        },
        setShowSowModal: (state, action) => {
            state.modal = action.payload;
        },
        updateSowWithBudget: (state, action) => {
            const { budget } = action.payload;
            state.budget = budget;
            if (state.data?.milestones) {
                const { milestones, graphs } = mapTasksToArchitecture(
                    JSON.parse(JSON.stringify(state?.data?.og_milestones)),
                    state.skillLevel,
                    budget,
                    state.budgetFilterActivated,
                    state.og_graphs
                );
                state.data.milestones = milestones;
                state.graphs = graphs;
            }
        },
        toggleQuoteMode: (state, action) => {
            state.quoteMode = action.payload;
        },
        setBudgetFilterActivated: (state, action) => {
            state.budgetFilterActivated = action.payload;
            localStorage.setItem(
                'budgetFilterActivated',
                action.payload.toString()
            );
            if (state.data?.milestones && state.budget) {
                const { milestones, graphs } = mapTasksToArchitecture(
                    JSON.parse(JSON.stringify(state?.data?.og_milestones)),
                    state.skillLevel,
                    state.budget,
                    action.payload,
                    state.og_graphs
                );
                state.data.milestones = milestones;
                state.graphs = graphs;
            }
        }
    }
});

export const {
    setGraph,
    waitingForProjectArchitecture,
    waitingForUIView,
    setProjectArchitecture,
    clearStateProjectArchitecture,
    setProjectArchitectureSeen,
    setShowNodeDetailModal,
    setCurrentNode,
    setUIView,
    setGraphVersionLoading,
    setPanelResized,
    setGraphVersion,
    gettingUIView,
    setUIViewEditing,
    setEditMode,
    setHideHours,
    setSow,
    setShowSowModal,
    waitingForSowMVP,
    waitingForSowCustom,
    setSkillLevel,
    updateSowWithBudget,
    setBudgetFilterActivated,
    setPrices,
    toggleQuoteMode,
    setPrice
} = projectArchitectureSlice.actions;

export const selectProjectArchitecture = state => state.projectArchitecture;
export const selectProjectArchitectureSeen = state =>
    state.projectArchitecture.seen;
export const selectProjectArchitectureLoading = state =>
    state.projectArchitecture.loading;
export const selectCurrentNode = state => state.projectArchitecture.currentNode;
export const selectCurrentEdge = state => state.projectArchitecture.currentEdge;
export const selectUIViewEditing = state =>
    state.projectArchitecture.uiViewEditing;
export const selectPanelResized = state =>
    state.projectArchitecture.panelResized;
export const selectGraphVersion = state =>
    state.projectArchitecture.graphVersion;
export const selectGraphVersionLoading = state =>
    state.projectArchitecture.graphVersionLoading;
export const selectShowNodeDetailModal = state =>
    state.projectArchitecture.modal;
export const selectSelectedNode = state =>
    state.projectArchitecture.selectedNode;
export const selectUIView = state => state.projectArchitecture.uiView;
export const selectGettingUIView = state =>
    state.projectArchitecture.gettingUIView;
export const selectUIViewLoading = state =>
    state.projectArchitecture.uiViewLoading;
export const selectFreeNodeLabel = state =>
    state.projectArchitecture.freeNodeLabel;

export const selectSow = state => state.projectArchitecture.data;
export const selectShowSowModal = state => state.projectArchitecture.modal;
export const selectLoadingSowMVP = state =>
    state.projectArchitecture.loadingMVP;
export const selectLoadingSowCustom = state =>
    state.projectArchitecture.loadingCustom;
export const selectSkillLevel = state => state.projectArchitecture.skillLevel;
export const selectHideHours = state => state.projectArchitecture.hideHours;
export const selectEditMode = state => state.projectArchitecture.editMode;
export const selectPrices = state => state.projectArchitecture.prices;
export const selectBudgetFilterActivated = state =>
    state.projectArchitecture.budgetFilterActivated;

export const selectQuoteMode = state => state.projectArchitecture.quoteMode;
export const selectPrice = state => state.projectArchitecture.price;
export const selectGraphs = state => state.projectArchitecture.graphs;

export default projectArchitectureSlice.reducer;

// Create the listener middleware
export const projectArchitectureListenerMiddleware = createListenerMiddleware();

// Add a listener that responds to project budget changes
projectArchitectureListenerMiddleware.startListening({
    matcher: isAnyOf(
        action => action.type === 'project/setProjectBudget',
        action => action.type === 'project/fetchProjectSuccess'
    ),
    effect: (action, listenerApi) => {
        const state = listenerApi.getState();
        const budget =
            action.type === 'project/setProjectBudget'
                ? action.payload
                : state.project.data.budget;
        listenerApi.dispatch(updateSowWithBudget({ budget }));
    }
});

function findFreeNodeLabel(graphs) {
    const frontendGraph = graphs.find(g => g.side === 'frontend');
    if (!frontendGraph) {
        return null;
    }
    const coreNode = frontendGraph.nodes.find(
        node => node.id.toLowerCase() === 'core'
    );
    if (!coreNode) {
        return null;
    }

    const connectedNode = frontendGraph.edges.find(
        edge => edge.source === 'core'
    );
    if (!connectedNode) {
        return null;
    }

    const connectedNodeId = connectedNode.target;

    const connectedNodeData = frontendGraph.nodes.find(
        node => node.id === connectedNodeId
    );

    if (!connectedNodeData) {
        console.log('Connected node data not found');
        return null;
    }

    return connectedNodeData.data.label;
}
