import { EnvelopeIcon } from '@heroicons/react/24/outline';
import * as Tooltip from '@radix-ui/react-tooltip';

export function ExternalMail({ project }) {
    const emailSubject = encodeURIComponent(
        `Contacting You Regarding Building Your Project - ${project.name} - on pre.dev`
    );
    const mailtoLink = `mailto:${project.userEmail}?subject=${emailSubject}`;

    return (
        <Tooltip.Provider delayDuration={100}>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <a
                        href={mailtoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center justify-center px-2 py-1 text-gray-500 hover:text-gray-700 bg-gray-50 hover:bg-gray-100 rounded-md border border-gray-200 shadow-sm transition-colors gap-2"
                    >
                        <EnvelopeIcon className="h-5 w-5" />
                        <span className="text-xs font-medium">Private</span>
                    </a>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        className="bg-white px-3 py-2 rounded-md shadow-lg border border-gray-200"
                        side="top"
                        sideOffset={5}
                    >
                        <p className="text-sm text-gray-700">
                            Send personal email to lead
                        </p>
                        <Tooltip.Arrow className="fill-white" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
}
