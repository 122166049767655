function updateText(text, previewMode, blockFull) {
    if (previewMode) {
        if (blockFull) {
            return text
                .split(' ')
                .map(word => '█'.repeat(Math.ceil(word.length / 2)))
                .join(' ');
        } else {
            const visibleLength = Math.ceil(text.length * 0.3);
            const visiblePart = text.slice(0, visibleLength);
            const hiddenPart = '█'.repeat(text.length - visibleLength);
            return visiblePart + hiddenPart;
        }
    }
    return text;
}

export const formatText = (text, previewMode, blockFull = true) => ({
    type: 'text',
    text: text ? updateText(text, previewMode, blockFull) : 'Loading...'
});

export const formatBoldText = (text, previewMode, blockFull = true) => ({
    type: 'text',
    marks: [{ type: 'bold' }],
    text: text ? updateText(text, previewMode, blockFull) : 'Loading...'
});

export const formatItalicText = (text, previewMode, blockFull = true) => ({
    type: 'text',
    marks: [{ type: 'italic' }],
    text: text ? updateText(text, previewMode, blockFull) : 'Loading...'
});

export const formatParagraph = content => ({
    type: 'paragraph',
    attrs: { class: null, textAlign: 'left' },
    content
});

export const formatBulletList = content => ({
    type: 'bulletList',
    content
});

export const formatListItem = content => ({
    type: 'listItem',
    content
});

export const formatHeading = (level, content) => ({
    type: 'heading',
    attrs: { textAlign: 'left', level },
    content
});
