import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { EmptyProjects } from './EmptyProjects';
import { useProjects } from '../../../context-providers/Projects';
import { ProjectsList } from './ProjectsList';
import { Title } from './title';
import { TopHeader } from './TopHeader';
import LoadMoreButton from '../../Home/Sidebars/LoadMoreButton';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearStateProjects,
    paginateProjects,
    selectProjectsSkip,
    selectProjectsTotal,
    selectSearchLoading
} from '../../../redux/reducers/generic/projects';
import { projectsLimit } from '../../../constants';
import { SearchProjects } from './Search';
import { getApiDomain } from '../../../auth_config';
import {
    setIsLeadGen,
    selectIsLeadGen
} from '../../../redux/reducers/generic/project';

export const ClientProjects = ({}) => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const subscriptionStatus = enterpriseOrganization?.subscriptionStatus;
    const searchLoading = useSelector(selectSearchLoading);
    const { projects } = useProjects();
    if (!projects) projects = [];
    const dispatch = useDispatch();
    const divRef = useRef(null);
    const isLeadGen = useSelector(selectIsLeadGen);

    useEffect(() => {
        if (isLeadGen) {
            dispatch(setIsLeadGen(false));
        }
    }, [dispatch, isLeadGen]);

    const totalProjects = useSelector(selectProjectsTotal);
    const skip = useSelector(selectProjectsSkip);

    useEffect(() => {
        const div = divRef.current;

        const handleScroll = () => {
            let showShowMoreButton = totalProjects > skip + projectsLimit;

            const isBottom =
                div?.scrollTop + div?.clientHeight >= div?.scrollHeight;
            if (isBottom && showShowMoreButton) {
                dispatch(paginateProjects());
            }
        };

        div.addEventListener('scroll', handleScroll);

        return () => {
            div.removeEventListener('scroll', handleScroll);
        };
    }, [divRef, totalProjects, skip, projectsLimit]);

    return (
        <>
            <div className="h-full" ref={divRef} style={{ overflow: 'scroll' }}>
                {/* Main column */}
                <div className="flex flex-col">
                    {/* Page title & actions */}
                    <Title />
                    {projects && projects.length > 0 && <TopHeader />}
                    <div className="flex items-center justify-center px-4 sm:px-2 lg:px-8">
                        <SearchProjects showFilter={true} />
                    </div>
                    {projects && projects.length > 0 ? (
                        <div className="h-full">
                            <ProjectsList />
                            <div id="page-end" />

                            {!searchLoading && (
                                <div className="flex justify-center">
                                    <LoadMoreButton />
                                </div>
                            )}
                        </div>
                    ) : (
                        <EmptyProjects />
                    )}
                </div>
            </div>
        </>
    );
};
