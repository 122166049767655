import { AddDefaultTeckStackitem } from './addDefaultTeckStackItem';
import { DefaultTechStackItems } from './defaultTechStackItems';

export const DefaultTechStack = () => {
    return (
        <>
            <AddDefaultTeckStackitem />
            <DefaultTechStackItems />
        </>
    );
};
