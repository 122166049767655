import { client } from '../../../api/client';
import { GRAPHS_BY_VERSION } from '../../../api/graphQL/solo/query';
import {
    setGraphVersion,
    setGraphVersionLoading,
    setProjectArchitecture
} from '../../reducers/generic/projectArchitecture';

export const getGraphsByVersion = (projectId, version) => async dispatch => {
    try {
        console.log('getGraphsByVersion', projectId, version);
        const waitingForProjectArchitecture = window.localStorage.getItem(
            'waitingForProjectArchitecture'
        );

        try {
            let diff =
                Date.now() - new Date(parseInt(waitingForProjectArchitecture));
            console.log({ diff });
            if (diff < 1000) {
                return;
            }
        } catch (error) {
            console.log({ error });
        }
        dispatch(setGraphVersionLoading(true));
        const { data } = await client.query({
            query: GRAPHS_BY_VERSION,
            variables: { projectId, version },
            fetchPolicy: 'network-only'
        });
        dispatch(setGraphVersion(version));
        if (data.graphsByVersion) {
            dispatch(
                setProjectArchitecture({
                    graphs: data.graphsByVersion
                })
            );
        }
        dispatch(setGraphVersionLoading(false));
    } catch (error) {
        console.log({ error });
        dispatch(setGraphVersionLoading(false));
    }
};
