import { GET_LINEAR } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    callingLinear,
    setLinear,
    setLinearPercentage,
    setLinearLoadingText
} from '../../reducers/generic/linear';

export const getLinear =
    ({ projectId }) =>
    async dispatch => {
        try {
            dispatch(callingLinear(true));
            const { data } = await client.query({
                query: GET_LINEAR,
                variables: {
                    projectId
                },
                fetchPolicy: 'network-only'
            });
            if (data?.getLinear) {
                dispatch(setLinear(data.getLinear));
                dispatch(setLinearPercentage(data.getLinear.percentage));
                dispatch(setLinearLoadingText(data.getLinear.loadingText));
            }
            dispatch(callingLinear(false));
        } catch (error) {
            console.error('Error fetching Linear data:', error);
            dispatch(callingLinear(false));
            dispatch(setLinearPercentage(0));
            // throw new Error(error.message);
        }
    };
