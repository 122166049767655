import { ADD_FILE_TO_KNOWLEDGE_BASE } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import { addKnowledgeBaseFile } from '../../reducers/enterprise/enterpriseOrganization';

export const addFileToKnowledgeBase =
    (enterpriseOrganizationId, name, fileKeyS3, type) =>
    async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: ADD_FILE_TO_KNOWLEDGE_BASE,
                variables: {
                    enterpriseOrganizationId,
                    name,
                    fileKeyS3,
                    type
                },
                fetchPolicy: 'network-only'
            });

            dispatch(addKnowledgeBaseFile(data.addFileToKnowledgeBase));

            return 'Successfully added file to knowledge base.';
        } catch (error) {
            console.log({ error });
            return error.message;
        }
    };
