import { ToggleField } from '../Helpers/ToggleField';
import { InputField } from '../Helpers/InputField';

export const ScheduleMeeting = () => {
    return (
        <div className="">
            <div className="mt-2 max-w-xl text-sm text-gray-500 mb-4">
                <p>
                    Set your Calendly link to allow clients to schedule
                    meetings.
                </p>
            </div>

            <div className="mt-4 mb-4">
                <InputField
                    field="calendlyLink"
                    label="Scheduling Link"
                    placeholder="https://calendly.com/your-link"
                />
            </div>
            <div className="mt-4 mb-4">
                <InputField
                    field="scheduleMeetingHelperText"
                    label="Request Meeting Helper Text"
                    placeholder="You can schedule a meeting to request developers with us below."
                />
            </div>
            <InputField
                field="scheduleMeetingButtonText"
                label="Request Meeting Button Text"
                placeholder="👷 Need Developers?"
            />

            <div className="my-4">
                <ToggleField
                    field="activateMeetings"
                    text="Allow clients to schedule meetings"
                />
            </div>
        </div>
    );
};
