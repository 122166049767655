import { SAVE_SOW } from '../../../api/graphQL';
import { client } from '../../../api/client';
import { setSow } from '../../reducers/generic/projectArchitecture';

export const saveSow =
    ({ projectId, type, version, content }) =>
    async dispatch => {
        try {
            const { data } = await client.mutate({
                mutation: SAVE_SOW,
                variables: {
                    projectId,
                    type,
                    version,
                    content
                }
            });

            // Set the sow in the Redux store
            dispatch(setSow(data.saveSow));

            return 'Changes were saved successfully!';
        } catch (error) {
            console.log({ error });
            return error;
        }
    };
