import { useCallback, useState, useEffect, useRef } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

export const ParticlesEffect = () => {
    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {}, []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={particleOptions}
        />
    );
};

const particleOptions = {
    particles: {
        number: {
            value: 20,
            density: {
                enable: true,
                value_area: 800
            }
        },
        color: {
            value: ['#ffffff', '#ff9900', '#00ff00']
        },
        shape: {
            type: 'circle'
        },
        opacity: {
            value: 0.5,
            random: false,
            animation: {
                enable: true,
                speed: 1,
                minimumValue: 0.1,
                sync: false
            }
        },
        size: {
            value: 8,
            random: true,
            animation: {
                enable: false,
                speed: 20,
                minimumValue: 0.1,
                sync: false
            }
        },
        lineLinked: {
            enable: true,
            distance: 200,
            color: '#ffffff',
            opacity: 0.4,
            width: 1
        },
        move: {
            enable: true,
            speed: 0.5,
            direction: 'none',
            random: false,
            straight: false,
            outModes: {
                default: 'out'
            },
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
        }
    },
    detectRetina: true
};
