import { client } from '../../../api/client';
import { GET_LINKEDIN_AUTH_URL } from '../../../api/graphQL/solo/query';

export const getLinkedInAuthUrl = () => async (dispatch, getState) => {
    try {
        const { data } = await client.query({
            query: GET_LINKEDIN_AUTH_URL
        });

        return data.getLinkedInAuthUrl;
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
    }
};
