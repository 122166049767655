import { client } from '../../../api/client';
import { GET_CLIENT_LINEAR_PROJECTS } from '../../../api/graphQL/enterprise/query';

export const getClientLinearProjects = () => async () => {
    try {
        const { data } = await client.query({
            query: GET_CLIENT_LINEAR_PROJECTS,
            variables: {}
        });

        return data.getClientLinearProjects;
    } catch (error) {
        console.error('Error fetching Linear projects:', error);
        return [];
    }
};
