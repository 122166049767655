import { client } from '../../../api/client';
import { DELETE_USER_STORY } from '../../../api/graphQL/solo/mutator';

export const deleteUserStory = (storyId, projectId) => async (dispatch, getState) => {
    try {
        projectId = projectId.toString();

        let { data } = await client.mutate({
            mutation: DELETE_USER_STORY,
            variables: { storyId, projectId }
        });

        return data.deleteProject;
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
    }
};
