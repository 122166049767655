import { useSelector, useDispatch } from 'react-redux';
import {
    setLinear,
    selectLinearPercentage,
    selectLinearVersion,
    setLinearPercentage,
    gettingLinear,
    selectGettingLinear,
    callingLinear,
    selectCallingLinear,
    selectLoadingLinear,
    setLinearLoadingText
} from '../../../../redux/reducers/generic/linear';
import { useSubscription } from '@apollo/client';
import { LINEAR_SUBSCRIPTION } from '../../../../api/graphQL';
import { FaCheckCircle } from 'react-icons/fa';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { SignInButton } from './SignInButton';
import { SyncNewChanges } from './SyncNewChanges';
import { Syncing } from './Syncing';
import { selectSowVersion } from '../../../../redux/reducers/generic/project';
import { ProjectURL } from './ProjectURL';
import { selectLinearLoadingText } from '../../../../redux/reducers/generic/linear';

export function LinearSync({ projectId }) {
    const { accessTokenPayload, doesSessionExist } = useSessionContext();
    const expiresLinear = accessTokenPayload?.expiresLinear
        ? new Date(accessTokenPayload.expiresLinear)
        : new Date();
    const now = new Date();
    const refreshThreshold = new Date(expiresLinear.getTime() - 30 * 60000);
    const isSignedInWithLinear =
        accessTokenPayload?.isLinear &&
        now < refreshThreshold &&
        doesSessionExist;

    const sowVersion = useSelector(selectSowVersion);
    const linearVersion = useSelector(selectLinearVersion);
    const dispatch = useDispatch();
    const loadingLinear = useSelector(selectLoadingLinear);
    const loadingPercent = useSelector(selectLinearPercentage);
    const retrievingLinear = useSelector(selectGettingLinear);
    const isCallingLinear = useSelector(selectCallingLinear);
    const isLoadingLinear =
        retrievingLinear || isCallingLinear || loadingLinear;
    const loadingText = useSelector(selectLinearLoadingText);
    useSubscription(LINEAR_SUBSCRIPTION, {
        variables: { projectId },
        shouldResubscribe: true,
        onData: ({ data }) => {
            try {
                const { linearStream } = data.data;
                dispatch(setLinearPercentage(linearStream.percentage));
                dispatch(setLinear(linearStream));
                dispatch(gettingLinear(true));
                dispatch(setLinearLoadingText(linearStream.loadingText));

                if (linearStream.percentage >= 100) {
                    dispatch(gettingLinear(false));
                    dispatch(setLinearPercentage(0));
                    dispatch(callingLinear(false));
                    dispatch(setLinearLoadingText(null));
                }
            } catch (error) {
                console.error(error);
            }
        },
        onError: error => {
            console.error(JSON.stringify(error, null, 2));
        }
    });

    const isSynced = linearVersion === sowVersion.mvp;

    return (
        <div
            className={`w-full ${
                !linearVersion ? 'justify-center' : 'justify-between'
            } items-center flex flex-col sm:flex-row px-2`}
        >
            {!isSignedInWithLinear && <SignInButton />}
            {isLoadingLinear && (
                <Syncing
                    loadingPercent={loadingPercent}
                    projectId={projectId}
                    loadingText={loadingText}
                />
            )}
            {isSignedInWithLinear && !isLoadingLinear && !isSynced && (
                <SyncNewChanges projectId={projectId} />
            )}
            {isSignedInWithLinear && !isLoadingLinear && isSynced && (
                <LinearSynced />
            )}
            <ProjectURL />
            {linearVersion && !isLoadingLinear && (
                <span className="flex py-1 items-center text-sm font-medium text-gray-600 bg-gray-100 rounded-md px-3 shadow-sm border border-gray-200 sm:ml-2">
                    Synced to Docs v{linearVersion}
                </span>
            )}
        </div>
    );
}

function LinearSynced() {
    return (
        <button
            disabled={true}
            type="button"
            className="flex gap-x-2 justify-center text-white bg-green-600 hover:bg-green-600 focus:ring-4 focus:outline-none focus:bg-green-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-blue-500 dark:hover:bg-green-600"
        >
            <img src="/linear.svg" alt="Linear" className="h-5 w-5" />
            Linear Synced
            <FaCheckCircle className="text-white" />
        </button>
    );
}
