import { motion } from 'framer-motion';
import { CurrencyDollarIcon, UserGroupIcon } from '@heroicons/react/24/outline';

export function MatchWithDev() {
    return {
        Icon: UserGroupIcon,
        name: 'Budget Filter & Dev Matching',
        description:
            'Our AI automatically filters project scope based on your budget and matches you with a vetted dev agency.',
        href: '/auth/?redirectToPath=%2Fprojects',
        cta: 'Get Started',
        className: 'col-span-3 lg:col-span-1',
        background: (
            <div className="absolute h-full w-full border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_70%)] group-hover:scale-105">
                <BudgetFilterCard />
            </div>
        )
    };
}

function BudgetFilterCard() {
    const containerVariants = {
        initial: {},
        whileHover: {
            scale: 1.05,
            transition: {
                duration: 0.3,
                ease: 'easeInOut'
            }
        }
    };

    return (
        <div className="relative h-full w-full transform-gpu rounded-lg bg-white dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)]">
            <motion.div
                variants={containerVariants}
                initial="initial"
                whileHover="whileHover"
                className="flex h-full w-full cursor-pointer flex-col items-center justify-between p-6"
            >
                <img
                    src="/budget.png"
                    alt="Budget Filter and Dev Matching"
                    className="w-full h-auto max-h-72 object-contain rounded-lg shadow-lg"
                />
            </motion.div>
        </div>
    );
}
