import {
    selectSubscribeModal,
    toggleSubscribeModal
} from '../../../redux/reducers/generic/billing';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../Components/Modal';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import PricingSection from '../../Pricing/pricing-section';

export const SubscribeModal = () => {
    const open = useSelector(selectSubscribeModal);
    const dispatch = useDispatch();
    const { enterpriseOrganization } = useEnterpriseOrganization();
    let subscriptionLinks = enterpriseOrganization?.subscriptionLinks;

    return (
        <div className="dark">
            <Modal
                open={open}
                onClose={() => dispatch(toggleSubscribeModal())}
                maxWidth="max-w-6xl dark z-50"
                bgColor="bg-black"
            >
                <PricingSection
                    subscriptionLinks={subscriptionLinks?.filter(
                        link => link.trial === false
                    )}
                    type={'enterprise'}
                />
            </Modal>
        </div>
    );
};
