import { GET_ENTERPRISE_ORGANIZATION_BY_API_KEY } from '../../../api/graphQL';
import { client, clientWithAPIKey } from '../../../api/client';
import {
    fetchEnterpriseOrganizationRequest,
    fetchEnterpriseOrganizationSuccess,
    fetchEnterpriseOrganizationFailure
} from '../../reducers/enterprise/enterpriseOrganization';

export const getEnterpriseOrganizationByApiKey =
    apiKey => async (dispatch, getState) => {
        dispatch(fetchEnterpriseOrganizationRequest());
        try {
            let { data } = await clientWithAPIKey(apiKey).query({
                query: GET_ENTERPRISE_ORGANIZATION_BY_API_KEY,
                variables: { apiKey },
                fetchPolicy: 'network-only'
            });

            if (getState().enterpriseOrganization.data == null)
                dispatch(
                    fetchEnterpriseOrganizationSuccess({
                        ...data.enterpriseOrganizationByApiKey,
                        isWidget: true
                    })
                );
        } catch (error) {
            console.log({ error });
            dispatch(fetchEnterpriseOrganizationFailure(error));
        }
    };
