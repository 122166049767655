import { REMOVE_DEFAULT_TECH_STACK_ITEM_FROM_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import { removeDefaultTechStackItem } from '../../reducers/enterprise/enterpriseOrganization';

export const removeDefaultTechStackItemFromOrganization =
    ({ _id, enterpriseOrganizationId }) =>
    async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation:
                    REMOVE_DEFAULT_TECH_STACK_ITEM_FROM_ENTERPRISE_ORGANIZATION,
                variables: {
                    defaultTechStackItemId: _id,
                    enterpriseOrganizationId
                },
                fetchPolicy: 'network-only'
            });

            dispatch(removeDefaultTechStackItem(_id));
            return 'Successfully removed default tech stack item.';
        } catch (error) {
            console.log({ error });
            return error.message;
        }
    };
