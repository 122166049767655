import { useNavigate, useLocation } from 'react-router-dom';
import {
    toggleSubscribeModal,
    selectSubscribeModal
} from '../../../redux/reducers/generic/billing';
import { HandCoins } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { salesCalendlyLink } from '../../../constants';

export const DemoBanner = ({ showDemoBanner, setShowDemoBanner }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const open = useSelector(selectSubscribeModal);
    const isDemo = pathname.includes('demo') || pathname.includes('share');

    const handleUpgrade = async e => {
        dispatch(toggleSubscribeModal(true));
    };

    const onGetStarted = () => {
        if (isDemo) navigate('/auth');
        else {
            handleUpgrade();
        }
    };
    return (
        <>
            {showDemoBanner && !open && (
                <div className="dark">
                    <div
                        id="marketing-banner"
                        style={{ zIndex: 100 }}
                        class="fixed z-80 flex flex-col md:flex-row justify-between w-[calc(100%-2rem)] p-4 py-6 -translate-x-1/2 bg-white border border-gray-100 rounded-lg shadow-sm left-1/2 bottom-4 dark:bg-gray-700 dark:border-gray-600"
                    >
                        <div class="z-10 flex flex-col items-start mb-3 me-4 md:items-center md:flex-row md:mb-0">
                            <a
                                href="/"
                                class="flex items-center mb-2 border-gray-200 md:pe-4 md:me-4 md:border-e md:mb-0 dark:border-gray-600"
                            >
                                <img
                                    src="/predev.png"
                                    class="h-6 me-2"
                                    alt="pre.dev Logo"
                                />
                                <span class="self-center text-lg font-semibold whitespace-nowrap dark:text-white">
                                    pre.dev
                                </span>
                            </a>
                            <p class="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                                {!isDemo
                                    ? `Continue working on your product with an
                                    unlimited plan designed for you.`
                                    : 'Log in to continue chatting with pre.dev about your first project for free and take the first step towards transforming your product workflow.'}
                            </p>
                        </div>
                        <div class="flex items-center flex-shrink-0">
                            {/* <a
                                href={
                                    pathname.includes('demo')
                                        ? '#features'
                                        : { salesCalendlyLink }
                                }
                                class="inline-flex items-center justify-center px-3 py-2 me-3 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                            >
                                <svg
                                    class="w-3 h-3 me-2"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 18"
                                >
                                    <path d="M9 1.334C7.06.594 1.646-.84.293.653a1.158 1.158 0 0 0-.293.77v13.973c0 .193.046.383.134.55.088.167.214.306.366.403a.932.932 0 0 0 .5.147c.176 0 .348-.05.5-.147 1.059-.32 6.265.851 7.5 1.65V1.334ZM19.707.653C18.353-.84 12.94.593 11 1.333V18c1.234-.799 6.436-1.968 7.5-1.65a.931.931 0 0 0 .5.147.931.931 0 0 0 .5-.148c.152-.096.279-.235.366-.403.088-.167.134-.357.134-.55V1.423a1.158 1.158 0 0 0-.293-.77Z" />
                                </svg>{' '}
                                Learn more
                            </a> */}
                            <a
                                onClick={onGetStarted}
                                class="cursor-pointer inline-flex items-center justify-center px-3 py-2 me-2 text-xs font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            >
                                Get started{' '}
                                <svg
                                    class="w-3 h-3 ms-2 rtl:rotate-180"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 10"
                                >
                                    <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M1 5h12m0 0L9 1m4 4L9 9"
                                    />
                                </svg>
                            </a>
                            <button
                                onClick={() => setShowDemoBanner(false)}
                                type="button"
                                class="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
                            >
                                <svg
                                    class="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span class="sr-only">Close banner</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
