import React, { useState, useEffect } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { MobileSidebar } from './MobileSidebar';
import { FeedBackButton } from '../Components/FeedbackButton';

export const NavigationBar = ({ onGetStarted, forceBlack }) => {
    const sessionContext = useSessionContext();

    const handleGetStartedClick = () => {
        onGetStarted();
    };

    let loginText =
        sessionContext.doesSessionExist === true
            ? 'Enterprise Dashboard'
            : 'Log in';

    const [navBackground, setNavBackground] = useState(
        forceBlack
            ? 'bg-black'
            : window.scrollY > 0
              ? 'bg-black'
              : 'bg-transparent'
    );

    useEffect(() => {
        if (!forceBlack) {
            const handleScroll = () => {
                setNavBackground(
                    window.scrollY > 0 ? 'bg-black' : 'bg-transparent'
                );
            };

            // Set the background when the component mounts in case the page is not at the top
            handleScroll();

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [forceBlack]);

    return (
        <nav
            className={`dark fixed top-0 w-full z-10 transition-colors duration-500 ${navBackground}`}
        >
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <a href="/enterprise" className="flex items-center lg:pl-10">
                    <img
                        src="/predev.png"
                        className="h-10"
                        alt="Company Logo"
                    />
                    <span className="ml-2 self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                        pre.dev
                    </span>
                    <span className="mt-3 ml-2 self-center text-sm font-semibold whitespace-nowrap text-green-400">
                        Enterprise
                    </span>
                </a>

                <div className="hidden md:flex md:order-2 gap-6">
                    <FeedBackButton />
                    <button
                        onClick={handleGetStartedClick}
                        type="button"
                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800 sm:block"
                    >
                        {loginText} <span aria-hidden="true">&rarr;</span>
                    </button>
                </div>
                <MobileSidebar
                    loginText={loginText}
                    handleGetStartedClick={handleGetStartedClick}
                />
            </div>
        </nav>
    );
};
