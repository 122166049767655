import { UPGRADE_TO_LEAD_GEN_TIER } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchEnterpriseOrganizationSuccess,
    fetchEnterpriseOrganizationFailure
} from '../../reducers/enterprise/enterpriseOrganization';

export const upgradeToLeadGenTier =
    ({ enterpriseOrganizationId, newTier }) =>
    async dispatch => {
        try {
            const { data } = await client.mutate({
                mutation: UPGRADE_TO_LEAD_GEN_TIER,
                variables: { enterpriseOrganizationId, newTier },
                fetchPolicy: 'network-only'
            });

            if (data.upgradeToLeadGenTier)
                dispatch(
                    fetchEnterpriseOrganizationSuccess(
                        data.upgradeToLeadGenTier
                    )
                );
        } catch (error) {
            console.error('Error upgrading to Lead Gen Tier:', error);
            dispatch(fetchEnterpriseOrganizationFailure(error));
        }
    };
