
/**
 * Generates test files for each component file provided.
 * @param {Array} files - Array of file objects with `path` and `code` properties.
 * @returns {Object} An object containing paths as keys and test file content as values.
 */
export const generateTestFiles = (files) => {
  return files.reduce((acc, file) => {
    if (file.path.endsWith('.tsx')) { // Adjust as necessary for .js or .jsx files
      const testFilePath = file.path.replace(/\.tsx$/, '.test.tsx');
      const componentName = file.path.split('/').pop().replace(/\.tsx$/, '');
      const importPath = file.path.substring(0, file.path.lastIndexOf('/'));

      // Basic test file content. Customize as needed for your testing setup.
      const testFileContent = `
        import React from 'react';
        import { render, screen } from '@testing-library/react';
        import ${componentName} from './${importPath}/${componentName}';

        describe('${componentName}', () => {
          test('renders without crashing', () => {
            render(<${componentName} />);
            const linkElement = screen.getByText(/replace this with something from your component/i);
            expect(linkElement).toBeInTheDocument();
          });
        });
      `;

      acc[testFilePath] = { code: testFileContent.trim() };
    }
    return acc;
  }, {});
};

// sanpack helper files / functions
export const allTestsPassed = (specs) => {
  if (typeof specs !== 'object' || specs === null) {
    console.error('Invalid specs: must be a non-null object');
    return;
  }

  let allTestsPass = true;

  // Iterate over each file in the specs
  Object.values(specs).forEach(({ describes }) => {
    // Iterate over each describe block
    Object.values(describes).forEach(({ tests }) => {
      // Iterate over each test
      Object.values(tests).forEach(({ status, errors }) => {
        // Check if any test did not pass or has errors
        if (status !== 'pass' || errors.length > 0) {
          allTestsPass = false;
        }
      });
    });
  });

}

export const extractErrorsWithPaths = (specs) => {
  let errorObjects = [];

  Object.values(specs).forEach(({ describes, name: filePath, error }) => {
    // Handle top-level errors first
    if (error) {
      console.log(error)
      const modifiedPath = filePath.replace(/\.test(?=\.tsx$)/, '');
      let errorMessage = error.message;

      // If there are mapped errors, include the first relevant line number in the message
      if (error.mappedErrors && error.mappedErrors.length > 0) {
        const firstError = error.mappedErrors.find(e => e.lineNumber);
        if (firstError) {
          errorMessage += ` (Line: ${firstError.lineNumber})`;
        }
      }

      errorObjects.push({
        path: modifiedPath,
        message: errorMessage,
      });
    }

    // Proceed with nested describes
    Object.values(describes).forEach(({ tests }) => {
      Object.values(tests).forEach((test) => {
        if (test.status !== 'pass' || test.errors.length > 0) {
          test.errors.forEach(error => {
            let modifiedPath = test.path.replace('.test.tsx', '');
            let errorMessage = error.message;

            // Attempt to include line number in the error message
            if (error.mappedErrors && error.mappedErrors.length > 0) {
              const firstMappedError = error.mappedErrors.find(e => e.lineNumber);
              if (firstMappedError) {
                errorMessage += ` (Line: ${firstMappedError.lineNumber})`;
              }
            }

            errorObjects.push({
              path: modifiedPath + '.tsx',
              message: errorMessage,
            });
          });
        }
      });
    });
  });

  return errorObjects;
}



export const indexTsx = `
  import React, { StrictMode } from "react";
  import { createRoot } from "react-dom/client";
  import "./styles.css";
  import Container from "./Container";

  const root = createRoot(document.getElementById("root"));
  root.render(
    <StrictMode>
      <Container />
    </StrictMode>
  );
`

export const stylesCss = `

/* General styles for form elements */
[type='text'],
input:not([type]),
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
textarea,
select,
.form-input,
.form-textarea,
.form-select,
.form-multiselect {
  appearance: none;
  background-color: #fff;
  border-color: #6b7280; /* Based on colors.gray.500 */
  border-width: 1px;
  border-radius: 0px;
  padding: 0.5rem 0.75rem; /* Based on spacing[2] and spacing[3] */
  font-size: 1rem; /* Based on baseFontSize */
  line-height: 1.5; /* Based on baseLineHeight */
  --tw-shadow: 0 0 #0000;
}

/* Focus state */
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
textarea:focus,
select:focus,
.form-input:focus,
.form-textarea:focus,
.form-select:focus,
.form-multiselect:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb; /* Based on colors.blue.600 */
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb; /* Based on colors.blue.600 */
}

/* Placeholder styles */
[type='text']::placeholder,
[type='email']::placeholder,
[type='url']::placeholder,
[type='password']::placeholder,
[type='number']::placeholder,
[type='date']::placeholder,
[type='datetime-local']::placeholder,
[type='month']::placeholder,
[type='search']::placeholder,
[type='tel']::placeholder,
[type='time']::placeholder,
[type='week']::placeholder,
textarea::placeholder,
.form-input::placeholder,
.form-textarea::placeholder {
  color: #6b7280; /* Based on colors.gray.500 */
  opacity: 1;
}

/* Select element arrow customization */
select,
.form-select {
  background-image: url("data:image/svg+xml,..."); /* Use an actual data URI for the arrow icon */
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem; /* Adjust based on spacing[10] */
}

[type='checkbox'],
[type='radio'],
.form-checkbox,
.form-radio {
  appearance: none;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  user-select: none;
  flex-shrink: 0;
  height: 1rem; /* Based on spacing[4] */
  width: 1rem; /* Based on spacing[4] */
  color: #2563eb; /* Based on colors.blue.600 */
  background-color: #fff;
  border-color: #6b7280; /* Based on colors.gray.500 */
`

export const containerTsx = `
import {React, useEffect, Suspense} from 'react';
import App from './App'
import html2canvas from 'html2canvas';
import { BrowserRouter as Router } from 'react-router-dom';
import { Inspector } from 'react-dev-inspector'
//import { Button } from "@/components/ui/button"
const Container: React.FC = () => {
  useEffect(()=>{
    setTimeout(()=>{
      if (document.fonts) {
        document.fonts.ready.then(() => {

        // Inside your useEffect in Container.tsx
        html2canvas(document.getElementById('el'), { allowTaint: true, useCORS: true }).then(
          canvas => {
            let imgData = canvas.toDataURL('image/png');
            //           var a = document.createElement('a');
            //           a.href = imgData;
            //           a.download = 'screeshot.png';
            //           a.click();          
            // // Use window.postMessage to communicate with the parent window
            window.parent.postMessage({ type: 'screenshot', imgData: imgData }, '*');
          
          }
        );
        })  
      }
    },1000)

  },[])

  return (
    <div id="el">
      <Router>
      <Inspector active={true} />
      <App />
      </Router>
    </div>
  );
}

export default Container;
`


// path: '/postcss.config.js'
export const postcss_config =
  `
  export default {
    plugins: {
      tailwindcss: {},
      autoprefixer: {},
    },
  };
  `


export const components_json = `
{
	"$schema": "https://ui.shadcn.com/schema.json",
	"style": "new-york",
	"rsc": false,
	"tsx": true,
	"tailwind": {
		"config": "tailwind.config.js",
		"css": "styles.css",
		"baseColor": "slate",
		"cssVariables": true,
		"prefix": ""
	},
	"aliases": {
		"components": "@/components",
    "utils": "@/lib/utils",
    "ui": "@/components/ui",
    "lib": "@/lib",
    "hooks": "@/hooks"
	}
}

`

export const button = `
import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground shadow hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
`
export const lib = `
import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

`

export const ts_config = `
{
          "compilerOptions": {
            "target": "es6",
            "module": "commonjs",
            "strict": true,
            "esModuleInterop": true,
            "skipLibCheck": true,
            "forceConsistentCasingInFileNames": true,
            "moduleResolution": "node",
            "outDir": "./dist",
            "rootDir": "./src",
            "typeRoots": ["./node_modules/@types"],
            "baseUrl": ".",
            "paths": {
              "*": ["node_modules/*", "src/types/*"],
              "@/*": ["./src/*"]
            }
          },
          "include": ["src/**/*"],
          "exclude": ["node_modules", "dist"]
        }
  `

export const vite_config_ts = `
import { defineConfig } from 'vite'
import react from '@vitejs/plugin-react'
import path from "path"

// https://vitejs.dev/config/
export default defineConfig({
  plugins: [react()],
  resolve: {
    alias: {
      "@": path.resolve(__dirname, "./src"),
    },
  },  
})

`

export const index_css = `
@tailwind base;
@tailwind components;
@tailwind utilities;
  `

export const tailwind_config = `
    /** @type {import('tailwindcss').Config} */
export default {
  content: [
    "./index.html",
    "./src/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    extend: {},
  },
  plugins: [],
}`
export const indexRouterTsx = `
  import React, { StrictMode } from "react";
  import { createRoot } from "react-dom/client";
  import "./styles.css";
  import App from "./App";

  const root = createRoot(document.getElementById("root"));
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
`

export const routerAppTsx = ({ routes }) => {
  return `
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const routes = ${routes};

const Placeholder = () => <div>This component is not yet implemented.</div>;
const loadComponent = (componentName) => {
  return React.lazy(() => import('./components/' + componentName).catch(() => ({ default: Placeholder })));
};

// Helper function to render routes recursively
const renderRoutes = (routes, parentPath = '') => {
  return routes.map((route, index) => {
    const Component = loadComponent(route.component);
    const fullPath = (parentPath + route.path); // Ensure no double slashes in the path

    // Find child routes
    const childRoutes = routes.filter(r => r.path.startsWith(route.path + '/') && r.path !== route.path);

    if (childRoutes.length > 0) {
      return (
        <Route key={index} path={route.path} element={<Component />}>
          {renderRoutes(childRoutes, route.path + '/')}
        </Route>
      );
    }

    return <Route key={index} path={route.path} element={<Component />} />;
  });
};

const App = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {renderRoutes(routes)}
      </Routes>
    </Suspense>
  </Router>
);

export default App;
`;
};