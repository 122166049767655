import { client } from '../../../api/client';
import { DELETE_NODE } from '../../../api/graphQL/solo/mutator';

export const deleteNode = (nodeId, graphId, projectId ) => async (dispatch, getState) => {
    try {
        
        let { data } = await client.mutate({
            mutation: DELETE_NODE,
            variables: { nodeId, graphId, projectId}
        });

        return data.deleteNode
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
    }
};
