import { client } from '../../../api/client';
import { GET_PROJECT_BY_USER, GET_USER_PROFILE } from '../../../api/graphQL';
import { setProfile } from '../../reducers/generic/profile';
import { fetchProjectSuccess } from '../../reducers/generic/project';

export const getUserProfile = () => async (dispatch: any, getState: any) => {
    try {
        const { data } = await client.query({
            query: GET_USER_PROFILE,
            fetchPolicy: 'network-only'
        });

        dispatch(setProfile(data.getUserProfile));

        if (getState().project?.projectId) {
            const { data } = await client.query({
                query: GET_PROJECT_BY_USER,
                variables: { projectId: getState().project?.projectId },
                fetchPolicy: 'network-only'
            });

            //Set up Project
            dispatch(fetchProjectSuccess(data.projectByUser));
        }

        return data.getUserProfile;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};
