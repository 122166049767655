import { ChevronDown, ChevronUp } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { ProposalButton } from './ProposalButton';
import { MatchWithDev } from './matchWithDev';
import { setProfileModalOpen } from '../../../redux/reducers/generic/profile';
import { useNavigate } from 'react-router-dom';
import trackEvent from '../../../helpers/trackEvent';
import { useProject } from '../../../context-providers/Project';

export const Header = ({
    isMinimized,
    setIsMinimized,
    matchWithDev,
    setMatchWithDev,
    matchLoading
}) => {
    const dispatch = useDispatch();
    const { isSolo, isHomePage, isSubbed } = useSubscriptionPayment();
    const { project, projectId } = useProject();

    const navigate = useNavigate();

    const toggleMinimize = () => {
        const newValue = !isMinimized;
        setIsMinimized(newValue);
        window.localStorage.setItem(
            'budgetMinimized',
            JSON.stringify(newValue)
        );
    };

    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex justify-between items-center gap-x-4">
                <h2 className="text-xl font-bold text-gray-100 flex items-center gap-x-4">
                    {isMinimized ? (
                        <div
                            className="flex items-center gap-x-2 cursor-pointer"
                            onClick={() => {
                                if (isMinimized) {
                                    toggleMinimize();
                                }
                            }}
                        >
                            <img
                                src="/predev.png"
                                alt="Budget"
                                className="h-5 cursor-pointer"
                            />
                            <span className="text-md">Budget </span>
                            <ProposalButton />
                        </div>
                    ) : (
                        <>
                            <img
                                onClick={() => {
                                    if (isMinimized) {
                                        toggleMinimize();
                                    }
                                }}
                                src="/predev.png"
                                alt="Budget"
                                className="h-5 cursor-pointer"
                            />
                            <span className="text-md">Budget </span>
                            <ProposalButton />
                        </>
                    )}
                </h2>
                <div className="hidden sm:block flex items-center">
                    <button
                        onClick={toggleMinimize}
                        className="text-gray-100 hover:text-gray-300 transition-colors duration-200"
                    >
                        {!isMinimized ? (
                            <ChevronDown size={20} />
                        ) : (
                            <ChevronUp size={20} />
                        )}
                    </button>
                </div>
            </div>
            {isMinimized && (isSolo || isHomePage) && !matchWithDev && (
                <MatchWithDev
                    matchWithDev={matchWithDev}
                    setMatchWithDev={setMatchWithDev}
                    matchLoading={matchLoading}
                    smallVersion={true}
                    from="budget_header"
                />
            )}
            {(isSolo || isHomePage) && !isSubbed && (
                <button
                    onClick={() => {
                        if (isSolo) {
                            dispatch(setProfileModalOpen(true));
                            trackEvent('getSoloPlusFreeSolo', {
                                userEmail: project?.userEmail,
                                projectId: projectId
                            });
                        } else if (isHomePage) {
                            trackEvent('getUnlimitedAccess', {
                                userEmail: project?.userEmail,
                                projectId: projectId
                            });
                            window.localStorage.setItem('openProfile', true);
                            navigate('/auth?redirectToPath=/projects');
                        }
                    }}
                    className="w-fit inline-flex items-center gap-x-2 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-600 to-indigo-600 rounded-md shadow-sm hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-200 transform hover:scale-105"
                >
                    {isHomePage
                        ? '🎁 Get Unlimited Access'
                        : '🎁 Get Unlimited Access'}
                    <span className="">→</span>
                </button>
            )}
        </div>
    );
};
