import {
    formatBoldText,
    formatBulletList,
    formatHeading,
    formatListItem,
    formatParagraph,
    formatText
} from './helper';

export const formatCoreFunctionalities = (sow, previewMode) => {
    const formatFunctionality = func => {
        try {
            return formatListItem([
                formatParagraph([
                    formatBoldText(`${func?.name}: `, false),
                    formatText(`${func?.description} `, previewMode),
                    formatText('(Priority: ', previewMode, true),
                    formatBoldText(func?.priority, previewMode, true),
                    formatText(')', previewMode, true)
                ])
            ]);
        } catch (error) {
            console.log('Error formatting core functionality:', error);
            return formatListItem([
                formatParagraph([formatText('Loading...', false)])
            ]);
        }
    };

    let formattedContent = [];
    try {
        let heading = formatHeading(2, [
            formatText('Core Functionalities', false)
        ]);
        let functionalities =
            sow?.coreFunctionalities?.map(formatFunctionality) || [];
        formattedContent = [heading, formatBulletList(functionalities)];
    } catch (error) {
        console.log('Error formatting core functionalities:', error);
        formattedContent = [
            formatHeading(2, [formatText('Core Functionalities', false)]),
            formatParagraph([
                formatText('Error loading core functionalities.', false)
            ])
        ];
    }
    return formattedContent;
};
