import { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useProjects } from '../../../../context-providers/Projects';
import { useSelector } from 'react-redux';
import { selectSearchLoading } from '../../../../redux/reducers/generic/projects';
import { TableHeader } from './header';
import { TableItem } from './item';
import { getCountryFlagEmoji } from '../../ClientProjects/ProjectsList/item';

export const ProjectsList = ({}) => {
    const { projects } = useProjects();
    if (!projects) projects = [];

    const searchLoading = useSelector(selectSearchLoading);

    const onClickProject = project => {
        window.open(`${window.location.href}/${project._id}`, '_blank');
    };

    return (
        <>
            <MobileProjectsList
                projects={projects}
                searchLoading={searchLoading}
                onClickProject={onClickProject}
            />
            <DesktopProjectsList
                projects={projects}
                searchLoading={searchLoading}
                onClickProject={onClickProject}
            />
        </>
    );
};

const DesktopProjectsList = ({ projects, searchLoading }) => {
    const [currentProject, setCurrentProject] = useState(null);

    return (
        <div className="mt-4 hidden sm:block">
            <div className="inline-block min-w-full border-b border-gray-200 align-middle">
                <table className="min-w-full">
                    <TableHeader />
                    <tbody
                        className={`divide-y divide-gray-100 bg-white ${
                            searchLoading ? 'opacity-50' : ''
                        }`}
                    >
                        {projects.map((project, i) => (
                            <TableItem
                                currentProject={currentProject}
                                setCurrentProject={setCurrentProject}
                                key={project._id}
                                project={project}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const MobileProjectsList = ({ projects, searchLoading, onClickProject }) => {
    return (
        <div className="sm:hidden mt-4">
            <ul
                role="list"
                className={`divide-y divide-gray-100 border-t border-gray-200 ${
                    searchLoading ? 'opacity-50' : ''
                }`}
            >
                {projects.map((project, i) => (
                    <li
                        key={project._id}
                        onClick={() => onClickProject(project)}
                    >
                        <a className="cursor-pointer group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-2">
                            <span className="flex items-center space-x-3 truncate">
                                <span className="text-sm text-gray-500">
                                    {getCountryFlagEmoji(
                                        project?.country?.code
                                    )}
                                </span>
                                <span
                                    onClick={() => onClickProject(project)}
                                    className="truncate text-sm font-medium leading-6"
                                >
                                    {project.name}
                                </span>
                                <div className="text-xs flex flex-shrink-0 -space-x-1">
                                    {project.userEmail}
                                </div>
                            </span>
                            <ChevronRightIcon
                                className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};
