import RadialGradient from '../Components/magicui/RadialGradient';
import { ParticlesEffect } from '../Landing/HeroExperience/ParticlesEffect';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { PDFViewer } from './pdf';
import { useState, useEffect } from 'react';

export const DevAgencyLanding = () => {
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: 0
    });

    useEffect(() => {
        const calculateDimensions = () => {
            const width = window.innerWidth;
            const height = width / 1.8;
            if (height * 2 < window.innerHeight) {
                setDimensions({ width, height: height * 2 });
            } else {
                setDimensions({ width, height });
            }
        };

        calculateDimensions();
        window.addEventListener('resize', calculateDimensions);

        return () => window.removeEventListener('resize', calculateDimensions);
    }, []);

    return (
        <div className="bg-black mt-[57px] w-full h-[calc(100vh-57px)] flex items-center justify-center">
            <div
                className="mx-auto h-full p-2"
                style={{
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`
                }}
            >
                <ParticlesEffect />
                <div className="flex flex-col items-center justify-center h-full border-2 border-white">
                    <PDFViewer />
                </div>
                <RadialGradient />
            </div>
        </div>
    );
};
