import { client } from '../../../api/client';
import { fetchSowStatusesSuccess } from '../../reducers/generic/project';

import { GET_SOW_STATUSES } from '../../../api/graphQL/solo/query';

export const getSowStatuses = projectId => async dispatch => {
    try {
        const { data } = await client.query({
            query: GET_SOW_STATUSES,
            variables: { projectId: projectId },
            fetchPolicy: 'network-only'
        });

        dispatch(fetchSowStatusesSuccess(data.sowStatuses));
    } catch (error) {
        console.log({ error });
    }
};
