import { ADD_USER_SKILL_TO_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import {
    fetchEnterpriseUsersRequest,
    fetchEnterpriseUsersSuccess,
    fetchEnterpriseUsersFailure
} from '../../reducers/enterprise/enterpriseUsers';

export const addUserSkillToEnterpriseOrganization =
    (userEmail, enterpriseOrganizationId, skill) =>
    async (dispatch, getState) => {
        dispatch(fetchEnterpriseUsersRequest());
        try {
            const { data } = await client.mutate({
                mutation: ADD_USER_SKILL_TO_ENTERPRISE_ORGANIZATION,
                variables: {
                    userEmail,
                    enterpriseOrganizationId,
                    skill
                },
                fetchPolicy: 'network-only'
            });

            dispatch(
                fetchEnterpriseUsersSuccess(
                    data.addUserSkillToEnterpriseOrganization.enterpriseUsers
                )
            );
            return `Successfully added skill ${skill.title} to ${userEmail}'s profile`;
        } catch (error) {
            console.log({ error, userEmail, enterpriseOrganizationId, skill });
            dispatch(fetchEnterpriseUsersFailure(error));
            return error.message;
        }
    };
