import { client } from '../../../api/client';
import {
    setRecommendedTechStack,
    setTechStackVersion,
    setTechVersionLoading
} from '../../reducers/generic/project';

import { RECOMMENDED_TECH_STACK_BY_VERSION } from '../../../api/graphQL/solo/query';

export const getRecommendedTechStackByVersion =
    (projectId, version) => async dispatch => {
        try {
            dispatch(setTechVersionLoading(true));
            const { data } = await client.query({
                query: RECOMMENDED_TECH_STACK_BY_VERSION,
                variables: { projectId, version },
                fetchPolicy: 'network-only'
            });

            if (data.recommendedTechStackByVersion) {
                dispatch(setTechStackVersion(version));

                dispatch(
                    setRecommendedTechStack(data.recommendedTechStackByVersion)
                );
            }
            dispatch(setTechVersionLoading(false));
        } catch (error) {
            console.log({ error });
            dispatch(setTechVersionLoading(false));
        }
    };
