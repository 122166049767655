import { client } from '../../../api/client';
import { ADD_EXISTING_PROJECT_CONTEXT } from '../../../api/graphQL/solo/mutator';
import { addToExistingProjectContexts } from '../../reducers/generic/project';

export const addExistingProjectContext =
    ({ fileKeyS3, text, image_url, projectId, type }) =>
    async dispatch => {
        try {
            const { data } = await client.mutate({
                mutation: ADD_EXISTING_PROJECT_CONTEXT,
                variables: {
                    fileKeyS3,
                    text,
                    image_url,
                    projectId,
                    type
                }
            });

            if (data.addExistingProjectContext) {
                dispatch(
                    addToExistingProjectContexts(data.addExistingProjectContext)
                );
            }

            return true;
        } catch (error) {
            console.log({ error });
            throw new Error(error);
        }
    };
