import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import React, { useEffect } from 'react';
import { useProjects } from '../../../../context-providers/Projects';
import { useSelector } from 'react-redux';
import { selectSearchLoading } from '../../../../redux/reducers/generic/projects';
import { TransferModal } from './TransferModal';
import { TableHeader } from './header';
import { TableItem } from './item';

const colors = [
    'bg-blue-500',
    'bg-green-500',
    'bg-yellow-300',
    'bg-orange-500',
    'bg-purple-600',
    'bg-red-500'
];

function getRandomColor(i) {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const ProjectsList = ({}) => {
    const navigate = useNavigate();
    const { projects } = useProjects();
    if (!projects) projects = [];

    const [bgColors, setBgColors] = useState([]);
    //projects = proyectos
    useEffect(() => {
        if (projects.length > 0) {
            setBgColors(projects.map(() => getRandomColor()));
        }
    }, [projects]);

    const searchLoading = useSelector(selectSearchLoading);

    const onClickProject = project => {
        navigate(project._id);
    };
    const [open, setOpen] = useState(false);
    const [project, setProject] = useState(null);

    const atleastOnePhoneNumber = projects.some(project => project.phoneNumber);

    return (
        <>
            <TransferModal project={project} open={open} setOpen={setOpen} />
            <MobileProjectsList
                projects={projects}
                searchLoading={searchLoading}
                onClickProject={onClickProject}
                setProject={setProject}
                bgColors={bgColors}
                atleastOnePhoneNumber={atleastOnePhoneNumber}
            />
            <DesktopProjectsList
                projects={projects}
                searchLoading={searchLoading}
                onClickProject={onClickProject}
                setProject={setProject}
                bgColors={bgColors}
                setOpen={setOpen}
                atleastOnePhoneNumber={atleastOnePhoneNumber}
            />
        </>
    );
};

const DesktopProjectsList = ({
    projects,
    searchLoading,
    bgColors,
    setProject,
    setOpen,
    atleastOnePhoneNumber
}) => {
    return (
        <div className="mt-4 hidden sm:block h-full overflow-y-auto">
            <div className="inline-block min-w-full border-b border-gray-200 align-middle">
                <table className="min-w-full">
                    <TableHeader
                        atleastOnePhoneNumber={atleastOnePhoneNumber}
                    />
                    <tbody
                        className={`divide-y divide-gray-100 bg-white ${
                            searchLoading ? 'opacity-50' : ''
                        }`}
                    >
                        {projects.map((project, i) => (
                            <TableItem
                                key={project._id}
                                project={project}
                                setProject={setProject}
                                bgColor={bgColors[i]}
                                setOpen={setOpen}
                                atleastOnePhoneNumber={atleastOnePhoneNumber}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const MobileProjectsList = ({
    projects,
    searchLoading,
    onClickProject,
    bgColors
}) => {
    return (
        <div className="sm:hidden mt-4">
            <ul
                role="list"
                className={`divide-y divide-gray-100 border-t border-gray-200 ${
                    searchLoading ? 'opacity-50' : ''
                }`}
            >
                {projects.map((project, i) => (
                    <li
                        key={project._id}
                        onClick={() => onClickProject(project)}
                    >
                        <a className="cursor-pointer group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-2">
                            <span className="flex items-center space-x-3 truncate">
                                <span
                                    className={classNames(
                                        bgColors && bgColors[i],
                                        'h-2.5 w-2.5 flex-shrink-0 rounded-full'
                                    )}
                                    aria-hidden="true"
                                />
                                <span
                                    onClick={() => onClickProject(project)}
                                    className="truncate text-sm font-medium leading-6"
                                >
                                    {project.name}
                                </span>
                                <div className="text-xs flex flex-shrink-0 -space-x-1">
                                    {project.userEmail}
                                </div>
                            </span>
                            <ChevronRightIcon
                                className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};
