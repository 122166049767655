import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { toast } from 'sonner';
import { addDefaultTechStackItemToEnterpriseOrganization } from '../../../../redux/actions/enterprise/addDefaultTechStackItemToEnterpriseOrganization';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export const AddDefaultTeckStackitem = () => {
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const { isSubbed } = useSubscriptionPayment();

    const [name, setName] = useState('');

    const handleChangeName = e => {
        setName(e.target.value);
    };
    const submitName = async () => {
        if (!isSubbed) {
            toast.error(
                'Please subscribe to Enterprise Premium to update knowledge base.'
            );
            return;
        }
        if (name.length > 0) {
            dispatch(
                addDefaultTechStackItemToEnterpriseOrganization(
                    name,
                    enterpriseOrganizationId
                )
            );
            setName('');
        } else {
            toast('Please add a name.');
        }
        document.getElementById('default_teck_name').focus();
    };

    return (
        <>
            {enterpriseOrganization.isAdmin && (
                <div>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            submitName();
                        }}
                    >
                        <div className="mt-5 flex items-center">
                            <div className="flex flex-row w-full max-w-[300px]">
                                <input
                                    type="text"
                                    name="name"
                                    id="default_teck_name"
                                    value={name}
                                    onChange={handleChangeName}
                                    className="w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    placeholder="React"
                                />
                            </div>

                            <button
                                type="submit"
                                className="min-w-[70px] inline-flex items-center justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ml-3"
                            >
                                Add +
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};
