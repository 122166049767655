import { ScheduleMeeting } from './ScheduleMeeting';
import { AdvancedConfig } from './AdvancedConfig';
import { WidgetIntegrationGuide } from './WidgetIntegrationGuide';
import { CalendarDaysIcon, CodeBracketIcon } from '@heroicons/react/24/outline';

export const WidgetManagement = () => {
    return (
        <>
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                <div className="min-w-0 flex-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                        Widget Management
                    </h3>
                </div>
                <div className="mt-4 flex sm:ml-4 sm:mt-0"></div>
            </div>

            <div className="px-4 sm:px-2 lg:px-4 py-2">
                <div className="grid sm:grid-cols-1 gap-8">
                    <div className="bg-white shadow rounded-lg w-full border border-2">
                        <div className="flex m-4">
                            <div className="flex text-base font-semibold items-center text-gray-900 w-full ">
                                <CodeBracketIcon className="h-6 w-6 mr-2 inline-block" />
                                <span>Widget Integration Guide</span>
                            </div>
                            <AdvancedConfig />
                        </div>
                        <div className="mx-4">
                            <WidgetIntegrationGuide />
                        </div>
                    </div>

                    <div className="bg-white shadow rounded-lg w-full border border-2">
                        <div className="flex m-4">
                            <div className="flex text-base font-semibold items-center text-gray-900 w-full ">
                                <CalendarDaysIcon className="h-6 w-6 mr-2 inline-block" />
                                <span>Schedule Meeting Management</span>
                            </div>
                        </div>
                        <div className="mx-4">
                            <ScheduleMeeting />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
