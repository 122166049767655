import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { toast } from 'sonner';
import { getWebsiteDomain } from '../../../../auth_config';
import { AddDomain } from '../Domains/addDomain';
import { DomainList } from '../Domains/domainList';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { SalesHeader } from '../../LeadGeneration';
import { upgradeToWidgetManagement } from '../../../../redux/actions/enterprise/upgradeToWidgetManagement';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Spinner } from '../../../Components/Spinner';

export const WidgetIntegrationGuide = () => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const { isSubbed } = useSubscriptionPayment();
    const dispatch = useDispatch();

    const widgetManagementActivated =
        enterpriseOrganization?.widgetManagementActivated;

    const apiKey = enterpriseOrganization?.apiKey;

    const copyToClipboard = text => e => {
        navigator.clipboard.writeText(text);
        toast('Copied to clipboard');
    };

    let iframe_str = `
	<iframe height="590px" width="420px" src="${getWebsiteDomain()}/iframe/enterprise/chat/${apiKey}"/>`;

    let clientOnboardingLink = apiKey
        ? `${getWebsiteDomain()}/enterprise/chat/${apiKey}?`
        : '';

    const [upgrading, setUpgrading] = useState(false);

    const onClickUpgrade = async () => {
        setUpgrading(true);
        await dispatch(
            upgradeToWidgetManagement({
                enterpriseOrganizationId: enterpriseOrganization._id
            })
        );
        setUpgrading(false);
    };

    return (
        <>
            <div className="flex flex-col">
                <p className="text-sm text-gray-500">
                    Follow the steps below to integrate the pre.dev widget into
                    your website
                    {widgetManagementActivated && (
                        <>
                            or use{' '}
                            <a
                                href={clientOnboardingLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                            >
                                this link
                            </a>{' '}
                            to onboard your clients.
                        </>
                    )}
                </p>
                <p className="text-sm text-gray-500">
                    {' '}
                    Visit{' '}
                    <a
                        href="https://demo-agency.pre.dev"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                    >
                        demo-agency.pre.dev
                    </a>{' '}
                    to see an example of the widget.
                </p>
            </div>

            <div className="mt-6">
                <ol className="list-decimal gap-y-6 divide-y">
                    <li className="mb-4 text-sm leading-5 text-gray-500 ml-4">
                        <p className="text-black">Embed HTML Code</p>
                        <p className="mt-4 flex justify-between">
                            {widgetManagementActivated ? (
                                <code
                                    className="cursor-pointer text-blue-500 underline break-all"
                                    onClick={copyToClipboard(iframe_str)}
                                >
                                    {iframe_str}
                                </code>
                            ) : !isSubbed ? (
                                <SalesHeader
                                    text="Unlock Widget Management"
                                    addOn="WidgetManagement"
                                />
                            ) : (
                                <div className="bg-white shadow-lg rounded-lg p-6 max-w-md border border-gray-200">
                                    <h3 className="text-xl font-bold text-gray-900 mb-3">
                                        Add Widget Management
                                    </h3>
                                    <p className="text-gray-700 mb-4 text-sm">
                                        Enhance your subscription with widget
                                        management features for improved client
                                        interactions.
                                    </p>
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-2xl font-bold text-gray-900">
                                            $249
                                        </span>
                                        <span className="text-gray-600 text-sm">
                                            /month
                                        </span>
                                    </div>
                                    <button
                                        onClick={onClickUpgrade}
                                        disabled={upgrading}
                                        className={`w-full py-2 px-4 rounded-md text-white font-semibold text-base transition-all duration-200 ${
                                            upgrading
                                                ? 'bg-blue-400 cursor-not-allowed'
                                                : 'bg-blue-600 hover:bg-blue-700 hover:shadow-md'
                                        }`}
                                    >
                                        {upgrading ? (
                                            <span className="flex items-center justify-center space-x-2">
                                                <Spinner w="4" />
                                                <span>Adding...</span>
                                            </span>
                                        ) : (
                                            'Add to Subscription'
                                        )}
                                    </button>
                                </div>
                            )}
                        </p>
                    </li>
                    <li className="mt-4 text-sm leading-5 text-gray-500 ml-4">
                        <p className="text-black mt-4">Add Respective Domain</p>
                        <p className="mt-4 flex justify-between">
                            Wherever you are using the widget, make sure to add
                            the respective domain to your organization on the
                            onboarding page.
                        </p>
                        <AddDomain />
                        <DomainList />
                    </li>
                </ol>
            </div>
        </>
    );
};
