import { appendChat } from '../../reducers/generic/chatHistory';

// Initializes the chathistory directly and then disables text input
export const addToChatHistory = chat => async (dispatch, getState) => {
    let chatHistory = getState().chatHistory.data;

    if (chat && chat.role !== chatHistory[chatHistory.length - 1]?.role) {
        dispatch(appendChat(chat));
    }
};
