import { GET_SIGNED_UPLOAD_URL } from '../../../api/graphQL';
import { client } from '../../../api/client';

export const getSignedUploadURL =
    (filename, isLogo) => async (dispatch, getState) => {
        try {
            let { data } = await client.mutate({
                mutation: GET_SIGNED_UPLOAD_URL,
                variables: { filename: filename, isLogo: isLogo }
            });
            const signedURL = data.getSignedUploadURL;
            return signedURL;
        } catch (error) {
            console.log({ error });
            console.log(JSON.stringify(error, null, 2));
        }
    };
