import { useDispatch, useSelector } from 'react-redux';
import {
    paginateProjects,
    selectPaginateLoading,
    selectProjectsSkip,
    selectProjectsTotal
} from '../../../redux/reducers/generic/projects';
import { Spinner } from '../../Components/Spinner';
import { projectsLimit } from '../../../constants';

export default function LoadMoreButton() {
    const dispatch = useDispatch();
    const loading = useSelector(selectPaginateLoading);
    const totalProjects = useSelector(selectProjectsTotal);
    const skip = useSelector(selectProjectsSkip);

    let showShowMoreButton = totalProjects > skip + projectsLimit;

    return (
        showShowMoreButton && (
            <button
                onClick={() => {
                    if (!loading) dispatch(paginateProjects());
                }}
                className="mt-2 px-4 w-[200px] gap-2 py-2 mb-2 text-black items-center flex justify-center rounded hover:bg-gray-100 bg-gray-200"
            >
                {loading ? (
                    <>
                        <Spinner
                            h="6"
                            w="6"
                            className="mr-2"
                            color="white"
                            borderWidth="2"
                        />
                    </>
                ) : (
                    'Load more'
                )}
            </button>
        )
    );
}
