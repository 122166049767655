import { client } from '../../../api/client';
import { EDIT_PROJECT_NAME } from '../../../api/graphQL/solo/mutator';

export const editProjectName =
    (projectId, name) => async (dispatch, getState) => {
        try {
            projectId = projectId.toString();

            let { data } = await client.mutate({
                mutation: EDIT_PROJECT_NAME,
                variables: { projectId, name }
            });

            return data.editProjectName;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }
    };
