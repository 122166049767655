import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SwitchDefault } from './SwitchDefault';
import { useEnterpriseOrganization } from '../../../../context-providers/EnterpriseOrganization';
import { toast } from 'sonner';
import { updateEnterpriseOrganizationBooleanField } from '../../../../redux/actions/enterprise/updateEnterpriseOrganizationBooleanField';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export const ToggleField = ({ field, text }) => {
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const { enterpriseOrganization } = useEnterpriseOrganization();

    const fieldState = enterpriseOrganization?.[field];
    const [switchState, setSwitchState] = useState(fieldState);
    const { isSubbed } = useSubscriptionPayment();

    const toggleFieldState = async () => {
        if (!isSubbed) {
            toast.error(
                'Please subscribe to Enterprise Advanced to update fields'
            );
            return;
        }
        const newSwitchState = !switchState;
        setSwitchState(newSwitchState);
        const response = await dispatch(
            updateEnterpriseOrganizationBooleanField(
                enterpriseOrganizationId,
                field,
                newSwitchState
            )
        );
        toast(response.toString());
    };

    useEffect(() => {
        setSwitchState(fieldState);
    }, [fieldState]);

    return (
        <div className="flex items-center justify-between border-gray-400 border gap-x-4 bg-gray-100 p-2 rounded-lg">
            <div className="flex flex-row items-center justify-between w-full">
                <div className="text-sm text-gray-700">
                    <p>{text}</p>
                </div>
                <SwitchDefault
                    setEnabled={toggleFieldState}
                    enabled={switchState}
                />
            </div>
        </div>
    );
};
