import Modal from '../../../Components/Modal';
import { Budget } from '../../Budget';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import {
    selectShowBudgetModal,
    setShowBudgetModal
} from '../../../../redux/reducers/generic/project';
import { useDispatch, useSelector } from 'react-redux';
import trackEvent from '../../../../helpers/trackEvent';
import { useProject } from '../../../../context-providers/Project';

export const BudgetButton = () => {
    const { isHomePage, isSolo } = useSubscriptionPayment();
    const dispatch = useDispatch();
    const isModalOpen = useSelector(selectShowBudgetModal);
    const { projectId, project } = useProject();

    const handleOpenModal = () => {
        window.localStorage.setItem('budgetMinimized', JSON.stringify(false));
        dispatch(setShowBudgetModal(true));
    };

    const handleCloseModal = () => {
        trackEvent('findDevelopersClicked', {
            projectId,
            userEmail: project?.userEmail
        });
        dispatch(setShowBudgetModal(false));
    };

    return (
        <>
            <button
                onClick={handleOpenModal}
                className="flex items-center justify-center px-4 py-2 gap-x-2 bg-white text-black font-medium rounded-lg shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-300 ease-in-out"
            >
                <img src="/predevblack.png" alt="Budget" className="h-5" />
                {isSolo || isHomePage ? 'Find Developers' : 'Budget'}
            </button>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                maxWidth="max-w-xl"
                bgColor="bg-black"
            >
                <div className="flex justify-center pt-4">
                    <Budget isMobile={true} />
                </div>
            </Modal>
        </>
    );
};
