import { SKIP_QUESTIONS } from '../../../api/graphQL';
import { client } from '../../../api/client';
import { skipClicked } from '../../reducers/generic/projects';

export const skipQuestions = projectId => async (dispatch, getState) => {
    try {
        await client.mutate({
            mutation: SKIP_QUESTIONS,
            variables: { projectId: projectId }
        });
        dispatch(skipClicked());
        return;
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
    }
};
