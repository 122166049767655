import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Ensure you have uuid installed for generating unique filenames
import { getSignedUploadURL } from '../../../../../redux/actions/solo/getSignedUploadURL';
import { getSignedDownloadURL } from '../../../../../redux/actions/solo/getSignedDownloadURL';

export const useScreenshot = ({
    setImageUrl,
    setUploading,
    setFileKeyScreenshot,
    dispatch
}) => {
    const handleScreenshotEvent = useCallback(
        async imgData => {
            try {
                if (!imgData) return;
                setUploading(true);

                // Convert base64 to a Blob
                const blob = await (await fetch(imgData)).blob();

                // Randomize filename
                let uniqueFilename = uuidv4() + '.png';

                if (blob.size > 50 * 1024 * 1024) {
                    setUploading(false);
                    return;
                }

                // 1. Fetch the signed URL
                const signedUrl = await dispatch(
                    getSignedUploadURL(uniqueFilename)
                );

                // 2. Upload the file using the signed URL
                await new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open('PUT', signedUrl, true);
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            console.log('Screenshot uploaded successfully.');
                            resolve();
                        } else {
                            console.error('Screenshot upload failed.');
                            reject(new Error('Screenshot upload failed.'));
                        }
                    };
                    xhr.onerror = function () {
                        console.error('Screenshot upload failed.');
                        reject(new Error('Screenshot upload failed.'));
                    };
                    xhr.send(blob);
                });

                const url = await dispatch(
                    getSignedDownloadURL(uniqueFilename)
                );

                setImageUrl(url);

                setFileKeyScreenshot(uniqueFilename);

                setUploading(false);
            } catch (err) {
                console.log(err);
            }
        },
        [dispatch, setImageUrl, setUploading, setFileKeyScreenshot]
    );

    return { handleScreenshotEvent };
};
