import { createSlice } from '@reduxjs/toolkit';

interface LinearState {
    linearProjectURL: string | null;
    linearVersion: string | null;
    linearPercentage: number;
    gettingLinear: boolean;
    callingLinear: boolean;
    loadingLinear: boolean | null;
    linearLoadingText: string | null;
}

const initialState: LinearState = {
    linearProjectURL: null,
    linearVersion: null,
    linearPercentage: 0,
    gettingLinear: false,
    callingLinear: false,
    loadingLinear: null,
    linearLoadingText: null
};

export const linearSlice = createSlice({
    name: 'linear',
    initialState,
    reducers: {
        setLinear: (state, action) => {
            if (!action.payload) {
                state.linearVersion = null;
                state.linearPercentage = 0;
                state.linearProjectURL = null;
            } else {
                state.linearVersion = action.payload.version;
                state.linearProjectURL = action.payload.projectURL;
                state.linearPercentage = action.payload.percentage;
            }
        },
        gettingLinear: (state, action) => {
            state.gettingLinear = action.payload;
        },
        callingLinear: (state, action) => {
            state.callingLinear = action.payload;
        },
        setLinearPercentage: (state, action) => {
            state.linearPercentage = action.payload;
        },
        setLinearLoadingText: (state, action) => {
            state.linearLoadingText = action.payload;
        },
        waitingForLinear: (state, action) => {
            state.loadingLinear = action.payload;
        }
    }
});

export const {
    setLinear,
    setLinearPercentage,
    gettingLinear,
    callingLinear,
    waitingForLinear,
    setLinearLoadingText
} = linearSlice.actions;

export const selectLinearPercentage = (state: { linear: LinearState }) =>
    state.linear.linearPercentage;
export const selectLinearVersion = (state: { linear: LinearState }) =>
    state.linear.linearVersion;
export const selectGettingLinear = (state: { linear: LinearState }) =>
    state.linear.gettingLinear;
export const selectCallingLinear = (state: { linear: LinearState }) =>
    state.linear.callingLinear;
export const selectLinearProjectURL = (state: { linear: LinearState }) =>
    state.linear.linearProjectURL;
export const selectLoadingLinear = (state: { linear: LinearState }) =>
    state.linear.loadingLinear;
export const selectLinearLoadingText = (state: { linear: LinearState }) =>
    state.linear.linearLoadingText;

export default linearSlice.reducer;
