import { GET_PROJECT_BY_USER } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchProjectSuccess,
    fetchProjectFailure,
    setMeetingRequested
} from '../../reducers/generic/project';

import { setChatHistory } from '../../reducers/generic/chatHistory';
import { setProjectArchitecture } from '../../reducers/generic/projectArchitecture';

export const getProjectByUser = projectId => async dispatch => {
    try {
        const { data } = await client.query({
            query: GET_PROJECT_BY_USER,
            variables: { projectId: projectId },
            fetchPolicy: 'network-only'
        });

        //Set up Project
        dispatch(fetchProjectSuccess(data.projectByUser));

        //Set up Chat History
        dispatch(setChatHistory(data.projectByUser.chatHistory));

        //Set up Project Architecture
        dispatch(
            setProjectArchitecture({
                graphs: data.projectByUser.graphs
            })
        );

        //Set up request meeting status
        dispatch(setMeetingRequested(data.projectByUser.meetingRequested));
    } catch (error) {
        console.log({ error });
        dispatch(fetchProjectFailure(error));
    }
};
