import { useNavigate } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { setRedirectURL } from '../../constants';

const SectionItem = ({
    imageSrc,
    title,
    description,
    buttonText,
    buttonOnClick,
    isImageRight
}) => (
    <div
        className={`flex flex-col ${
            isImageRight ? 'lg:flex-row-reverse' : 'lg:flex-row'
        } lg:items-center gap-y-8 sm:gap-y-12 lg:gap-x-16 xl:gap-x-24`}
    >
        <div className={imageSrc ? '' : 'lg:order-2'}>
            <img
                className="object-cover w-full rounded-lg shadow-lg dark:hidden"
                src={imageSrc}
                alt=""
            />
        </div>

        <div className="w-full space-y-6 lg:max-w-lg shrink-0 xl:max-w-2xl">
            <div className="space-y-3">
                <h3 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl dark:text-white">
                    {title}
                </h3>
                <p className="text-base font-normal text-gray-500 sm:text-lg dark:text-gray-400">
                    {description}
                </p>
            </div>

            <a
                onClick={buttonOnClick}
                title=""
                className="text-white bg-blue-700 justify-center hover:bg-blue-800 inline-flex items-center focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                role="button"
            >
                {buttonText}
                <svg
                    aria-hidden="true"
                    className="w-5 h-5 ml-2 -mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </a>
        </div>
    </div>
);

export const Portfolio = () => {
    const navigate = useNavigate();
    const sessionContext = useSessionContext();

    const onGetStarted = () => {
        setRedirectURL('/enterprise/dashboard');
        if (sessionContext.doesSessionExist === true) {
            navigate('/enterprise/dashboard');
        } else {
            navigate('/auth/?redirectToPath=%2Fenterprise%2Fdashboard');
        }
    };

    const sectionItems = [
        {
            imageSrc: '/enterprise_2.png',
            title: 'Embeddable pre.dev Widget',
            description:
                'Provide your clients with an easy-to-use widget for project inquiries, featuring a conversational AI and an option to book meetings directly.',
            buttonText: 'Get Started Today',
            buttonOnClick: onGetStarted,
            isImageRight: false
        },
        {
            imageSrc: '/enterprise_1.png',
            title: 'Enterprise Dashboard',
            description:
                "Manage all of your client's projects in one place for your organization. Invite team-members to the organization to collaborate and delegate project management.",
            buttonText: 'Get Started Today',
            buttonOnClick: onGetStarted,
            isImageRight: true
        },
        {
            imageSrc: '/enterprise_3.png',
            title: 'Analyze Project Details',
            description:
                'Be fully prepared for client meetings with access to conversations, estimated project architectures, and component-wise Statements of Work (SoW) including rough hourly estimates.',
            buttonText: 'Create Account',
            buttonOnClick: onGetStarted,
            isImageRight: false
        },
        {
            imageSrc: '/enterprise_4.png',
            title: 'Statements of Work',
            description:
                'Generate component-wise Statements of Work (SoW) with estimated hours and costs for your clients.',
            buttonText: 'Get Started Today',
            buttonOnClick: onGetStarted,
            isImageRight: true
        },
        {
            imageSrc: '/jira.png',
            title: 'Jira & Linear Integration',
            description:
                'Resync your roadmap with Jira or Linear as your project architecture develops over time.',
            buttonText: 'Get Started Today',
            buttonOnClick: onGetStarted,
            isImageRight: false
        },
        {
            imageSrc: '/enterprise_5.png',
            title: 'Configurable Knowledge Base',
            description:
                "Configure the pre.dev AI to understand your organization's specific needs and requirements.",
            buttonText: 'Get Started Today',
            buttonOnClick: onGetStarted,
            isImageRight: true
        }
    ];

    return (
        <section className="bg-white antialiased">
            <div className="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24">
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="text-3xl font-extrabold leading-tight tracking-tight text-gray-900 sm:text-4xl dark:text-white">
                        AI Product Architect
                    </h2>
                    <p className="mt-4 text-base font-normal text-gray-500 sm:text-xl dark:text-gray-400">
                        Save time and money by leveraging pre.dev to instantly
                        understand internal and client project requirements
                        through generated software architecture and plans.
                    </p>
                </div>

                <div className="mt-12 space-y-16 sm:mt-16">
                    {sectionItems.map((item, index) => (
                        <SectionItem key={index} {...item} />
                    ))}
                </div>
            </div>
        </section>
    );
};
