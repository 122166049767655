
import Marquee from "../Components/magicui/marquee";

const companies = [
  "/azumo.svg",
  "/makeitmvp.png",
  "/ory.png",
  '/moonbeam.png',
  '/vm.svg',
  '/linkeen.svg',
  '/goldfish_code.svg',
  '/cypher.png',
  '/instazoid.png',
  '/flashdocs.svg',
  '/symfa.svg',
  'https://static.wixstatic.com/media/69c71b_07ad1a7d1cf84494a6d80750f8618107~mv2.png/v1/crop/x_1,y_0,w_1195,h_408/fill/w_564,h_180,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01,enc_auto/165Ventures.png',
  'https://static.wixstatic.com/media/a2d472_ebe2d78ce5f4472cbe227cb9e97e22d0~mv2.png/v1/crop/x_48,y_0,w_4103,h_2100/fill/w_324,h_166,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Hand%20Tennis%20Logo%20copy%20(2).png'
];

export default function ClientSection() {

  return (
    <section id="companies">
      <div className="py-14">
        <div className="container mx-auto px-4 md:px-8">
          <h3 className="text-center text-sm font-semibold text-gray-500">
            TRUSTED BY LEADING TEAMS
          </h3>
          <div className="relative mt-6">
            <Marquee className="max-w-full [--duration:40s]">
              {companies.map((logo, idx) => (
                <img
                  key={idx}
                  src={logo}
                  className="h-10 w-50 dark:brightness-0 dark:invert"
                  alt={logo}
                />
              ))}
            </Marquee>
            <div className="pointer-events-none absolute inset-y-0 left-0 h-full w-1/3 bg-gradient-to-r from-white dark:from-black"></div>
            <div className="pointer-events-none absolute inset-y-0 right-0 h-full w-1/3 bg-gradient-to-l from-white dark:from-black"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
