import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: null,
    initLoaded: false,
    data: [],
    error: null
};

export const enterpriseOrganizationsSlice = createSlice({
    name: 'enterpriseOrganizations',
    initialState,
    reducers: {
        clearStateEnterpriseOrganizations: state => {
            state.loading = null;
            state.data = [];
            state.error = null;
            state.initLoaded = false;
        },
        addToEnterpriseOrganizations: (state, action) => {
            state.data = [action.payload, ...state.data];
        },
        fetchEnterpriseOrganizationsRequest: state => {
            state.loading = true;
        },
        fetchEnterpriseOrganizationsSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
            state.initLoaded = true;
        },
        fetchEnterpriseOrganizationsFailure: (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.payload;
        }
    }
});
export const {
    addToEnterpriseOrganizations,
    clearStateEnterpriseOrganizations,
    fetchEnterpriseOrganizationsRequest,
    fetchEnterpriseOrganizationsSuccess,
    fetchEnterpriseOrganizationsFailure
} = enterpriseOrganizationsSlice.actions;

export const selectEnterpriseOrganizations = state =>
    state.enterpriseOrganizations.data;
export const selectEnterpriseOrganizationsLoading = state =>
    state.enterpriseOrganizations.loading;
export const selectInitLoaded = state =>
    state.enterpriseOrganizations.initLoaded;

export default enterpriseOrganizationsSlice.reducer;
