import { GET_CHAT_HISTORY } from '../../../api/graphQL';
import { client } from '../../../api/client';
import { prependChatHistory } from '../../reducers/generic/chatHistory';

export const getChatHistory =
    ({ projectId, skip, limit }) =>
    async dispatch => {
        try {
            console.log({
                projectId,
                skip,
                limit
            });
            const { data } = await client.query({
                query: GET_CHAT_HISTORY,
                variables: { projectId, skip, limit },
                fetchPolicy: 'network-only'
            });

            if (data?.getChatHistory) {
                dispatch(prependChatHistory(data.getChatHistory));
            }

            return data?.getChatHistory?.length;
        } catch (error) {
            console.log(error);
            return false;
        }
    };
