import { client } from '../../../api/client';
import { MATCH_WITH_DEV } from '../../../api/graphQL/solo/mutator';
import { setMatchWithDev } from '../../reducers/generic/project';

export const updateMatchWithDev =
    (projectId: string, value: boolean) => async (dispatch: any) => {
        try {
            const { data } = await client.mutate({
                mutation: MATCH_WITH_DEV,
                variables: { projectId, value }
            });

            if (data.matchWithDev) {
                dispatch(setMatchWithDev(value));
            }

            return data.matchWithDev;
        } catch (error) {
            console.error('Error updating match with dev:', error);
            throw error;
        }
    };
