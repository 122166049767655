import { useDispatch } from 'react-redux';
import { client } from '../../../../api/client';
import {
    INITIATE_SUGGESTION,
    SUGGESTION_SUBSCRIPTION
} from '../../../../api/graphQL';
import { waitingForSuggestion } from '../../../../redux/reducers/generic/chatHistory';
import { Spinner } from '../../../Components/Spinner';
import { useSubscription } from '@apollo/client';
import { useProject } from '../../../../context-providers/Project';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { toast } from 'sonner';
import { useLocation } from 'react-router-dom';
import { FaMagic } from 'react-icons/fa'; // Importing the wand icon
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import trackEvent from '../../../../helpers/trackEvent';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

export const Suggest = ({ textareaRef, setAnswer, answer, isShare }) => {
    let { chatHistory, projectId, project } = useProject();
    let chat = chatHistory.data;
    let waiting =
        chatHistory.waitingForChatResponse || chatHistory.waitingForSuggestion;

    let [randId, setRandId] = useState('');
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const soloNoGo = isShare && chat.length > 10;
    const { isLeadGen } = useSubscriptionPayment();
    const { accessTokenPayload } = useSessionContext();

    useSubscription(SUGGESTION_SUBSCRIPTION, {
        variables: { projectId, randId },
        shouldResubscribe: true,
        onData: data => {
            try {
                const { suggestion, completed } =
                    data.data.data.suggestionStream;
                setAnswer(suggestion);
                textareaRef.current.scrollTop =
                    textareaRef.current.scrollHeight + 20;
                textareaRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end'
                });

                if (completed) {
                    document.getElementById('answer').focus();
                    textareaRef.current.value += ' ';
                    textareaRef.current.style.overflowY = 'scroll';
                    dispatch(waitingForSuggestion(false));
                }
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
        // client: clientWithAPIKey(apiKey),
    });

    const handleSuggest = async () => {
        if (waiting) return;
        if (isShare) {
            //setShowDemoBanner(true);

            if (pathname.includes('share')) {
                toast.error(
                    'Log in and upgrade to premium for unlimited access.'
                );
                return;
            }
            if (pathname.includes('demo')) {
                if (chat.length > 10) {
                    toast.error('Log in to continue chatting.');
                    return;
                }
            }
        }

        if (soloNoGo) {
            toast.warning(
                'Log in and upgrade to premium for unlimited access.'
            );
            return;
        }

        dispatch(waitingForSuggestion(true));
        try {
            setAnswer('');
            let randId = uuid();
            setRandId(randId);
            trackEvent('answerForMe', {
                userEmail: project.userEmail,
                userId: accessTokenPayload?.userId
            });
            let data = await client.mutate({
                mutation: INITIATE_SUGGESTION,
                variables: { projectId, randId }
            });
            let suggestion = data.data.initiateSuggestionStream.suggestion;

            if (answer !== suggestion) setAnswer(suggestion);
            dispatch(waitingForSuggestion(false));
        } catch (error) {
            console.log(error);
            dispatch(waitingForSuggestion(false));
        }
        return;
    };

    if (isLeadGen) {
        return null;
    }

    return (
        <button
            onClick={!waiting ? handleSuggest : null}
            className={`${
                waiting
                    ? 'text-gray-500 bg-gray-300 cursor-not-allowed'
                    : 'text-white bg-gradient-to-r from-green-600 to-blue-700 hover:from-green-700 hover:to-blue-800'
            }
                 rounded-lg px-4 py-2 shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 text-xs
                 flex items-center gap-x-2 justify-center
                 `}
        >
            {chatHistory.waitingForSuggestion ? (
                <span className="flex items-center gap-x-2">
                    <Spinner w="3" />
                    Answering...
                </span>
            ) : (
                <span className="flex gap-x-2 items-center">
                    <FaMagic className="" /> Answer for me
                </span>
            )}
        </button>
    );
};
