import { GET_PROJECT_BY_LEAD_GEN } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchProjectSuccess,
    fetchProjectFailure,
    setMeetingRequested
} from '../../reducers/generic/project';

import { setChatHistory } from '../../reducers/generic/chatHistory';
import { setProjectArchitecture } from '../../reducers/generic/projectArchitecture';
import {
    setEnterpriseWidgetProjectId,
    setUserEmail
} from '../../reducers/enterprise_widget/enterpriseWidget';
import { reload } from '../../../constants';

export const getProjectByLeadGen =
    ({ projectId, enterpriseOrganizationId }) =>
    async dispatch => {
        try {
            const { data } = await client.query({
                query: GET_PROJECT_BY_LEAD_GEN,
                variables: { projectId, enterpriseOrganizationId },
                fetchPolicy: 'network-only'
            });

            if (data.projectByLeadGen === null) {
                return;
            }

            dispatch(setEnterpriseWidgetProjectId(projectId));
            dispatch(setUserEmail(data.projectByLeadGen.userEmail));
            dispatch(fetchProjectSuccess(data.projectByLeadGen));
            dispatch(setChatHistory(data.projectByLeadGen.chatHistory));
            dispatch(
                setProjectArchitecture({
                    graphs: data.projectByLeadGen.graphs
                })
            );
            dispatch(
                setMeetingRequested(data.projectByLeadGen.meetingRequested)
            );
        } catch (error) {
            console.log({ error });
            dispatch(fetchProjectFailure(error));
            reload();
        }
    };
