import { Button } from '../Components/magicui/ui/button';
import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons';
import { motion } from 'framer-motion';
import { cn } from '../Components/magicui/lib/utils';
import { freeTrialActivated } from '../../constants';
import { basicPlan, basicPlanEnterprise } from '../Pricing/prices';

export default function PricingCard({ handleUpgrade, isEnterprise }) {
    let plan = basicPlan;
    if (isEnterprise) {
        plan = basicPlanEnterprise;
    }
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
                duration: 1,
                ease: [0.21, 0.47, 0.32, 0.98]
            }}
            className={cn(
                'z-10 -mx-1 mb-2 bg-black bottom-0 left-0 flex flex-col gap-4 rounded-2xl border p-4 text-black dark:text-white overflow-hidden ',
                {
                    'border-2 border-[var(--color-one)] dark:border-[var(--color-one)]':
                        plan.isMostPopular
                }
            )}
        >
            <div className="flex items-center">
                <br />
                <div className="ml-4">
                    <h2 className="-ml-4 text-base font-semibold leading-7">
                        Your Current Plan:
                    </h2>
                    <span className="-ml-4 text-2xl underline font-bold text-black dark:text-white">
                        Basic
                    </span>
                    <p className="-ml-4 mt-1 text-xs leading-5 text-black/70 dark:text-white">
                        {plan.description}
                    </p>
                </div>
            </div>

            <Button
                onClick={handleUpgrade}
                className={cn(
                    'group relative w-full text-black gap-2 overflow-hidden text-lg font-semibold tracking-tighter',
                    'transform-gpu ring-offset-current transition-all duration-300 ease-out hover:ring-2 hover:ring-primary hover:ring-offset-2'
                )}
            >
                <span className="absolute right-0 h-32 w-8 translate-x-12 rotate-12 transform-gpu bg-white opacity-10 transition-all duration-1000 ease-out group-hover:-translate-x-96 dark:bg-black" />
                <p className="px-2 text-md">
                    {freeTrialActivated
                        ? 'Start Premium Free Trial'
                        : 'Upgrade Account'}
                </p>
            </Button>
            {plan.features && plan.features.length > 0 && (
                <ul className="flex flex-col gap-2 font-normal">
                    {plan.features.map((feature, idx) => (
                        <li
                            key={idx}
                            className="flex items-center gap-3 text-xs font-medium text-black dark:text-white"
                        >
                            <CheckIcon className="h-5 w-5 shrink-0 rounded-full bg-green-400 p-[2px] text-black dark:text-white" />
                            <span className="flex">{feature}</span>
                        </li>
                    ))}
                </ul>
            )}
            {plan.missingFeatures && plan.missingFeatures.length > 0 && (
                <ul className="flex flex-col gap-2 font-normal">
                    {plan.missingFeatures.map((feature, idx) => (
                        <li
                            key={idx}
                            className="flex items-center gap-3 text-xs font-medium text-black dark:text-white"
                        >
                            <Cross2Icon className="h-5 w-5 shrink-0 rounded-full bg-red-400 p-[2px] text-black dark:text-white" />
                            <span className="flex">{feature}</span>
                        </li>
                    ))}
                </ul>
            )}
        </motion.div>
    );
}
