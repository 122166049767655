import { Editor, useEditor } from '@tiptap/react';
import Collaboration from '@tiptap/extension-collaboration';
import type { Doc as YDoc } from 'yjs';

import { ExtensionKit } from '../extensions/extension-kit';
import { useSidebar } from './useSidebar';

declare global {
    interface Window {
        editor: Editor | null;
    }
}

export const useBlockEditor = ({ ydoc }: { ydoc: YDoc }) => {
    const leftSidebar = useSidebar();
    const editor = useEditor(
        {
            onCreate: ({ editor }) => {
                if (editor.isEmpty) {
                    editor.commands.setContent(JSON.stringify(''));
                }
            },
            autofocus: true,
            extensions: [
                ...ExtensionKit({}),
                Collaboration.configure({
                    document: ydoc
                })
            ],
            editorProps: {
                attributes: {
                    autocomplete: 'off',
                    autocorrect: 'off',
                    autocapitalize: 'off',
                    class: 'min-h-full'
                }
            }
        },
        [ydoc]
    );

    window.editor = editor;

    return { editor, leftSidebar };
};
