import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';

interface TrustedByProps {
    isInView: boolean;
}
interface PartnerLogoProps {
    src: string;
    alt: string;
}
function PartnerLogo({ src, alt }: PartnerLogoProps) {
    return (
        <div className="bg-white/5 backdrop-blur-sm rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-200">
            <img
                alt={alt}
                src={src}
                width={158}
                height={48}
                className="max-h-12 w-full object-contain"
            />
        </div>
    );
}

const partners = [
    // { src: '/gcp.png', alt: 'Google Cloud Partners' },
    { src: '/vm.svg', alt: 'venture miami' },
    { src: '/cyph.png', alt: 'Wharton cypher' },
    { src: '/polkadot.png', alt: 'Polkadot' },
    { src: '/moonbeam.png', alt: 'Moonbeam' }
];

function TrustedBy({ isInView }: TrustedByProps) {
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 1 }}
        >
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <h2 className="text-center text-2xl font-semibold text-white">
                    Trusted By Startup Ecosystems Worldwide
                </h2>
                <div className="mx-auto mt-10 flex justify-center max-w-lg sm:max-w-xl lg:mx-0 lg:max-w-none">
                    <div className="mx-auto grid grid-cols-2 items-center gap-x-8 gap-y-10 sm:gap-x-10 lg:grid-cols-4">
                        {partners.map(partner => (
                            <PartnerLogo
                                key={partner.alt}
                                src={partner.src}
                                alt={partner.alt}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

interface StatsProps {
    isInView: boolean;
}

function Stats({ isInView }: StatsProps) {
    const stats = [
        { id: 1, value: '8000+', name: 'Projects Created' },
        { id: 2, value: '60+', name: ' Weekly Matches' },
        { id: 3, value: '$5k-$250k+', name: 'Project Budgets' },
        { id: 4, value: '20+', name: 'Professional Dev Agencies' }
    ];

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 1 }}
        >
            <div className="bg-transparent py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-4">
                        {stats.map(stat => (
                            <div
                                key={stat.id}
                                className="mx-auto flex max-w-xs flex-col gap-y-4"
                            >
                                <dt className="text-base/7 text-gray-400">
                                    {stat.name}
                                </dt>
                                <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                                    {stat.value}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </motion.div>
    );
}

export default function PartnerSection() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: '-100px' });

    return (
        <div ref={ref} className="bg-transparent pb-32">
            <TrustedBy isInView={isInView} />
            <Stats isInView={isInView} />
        </div>
    );
}
