import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { selectNumUsersLeft } from '../../../redux/reducers/enterprise/enterpriseUsers';
import { addSeatsToEnterpriseOrganization } from '../../../redux/actions/enterprise/addSeatsToEnterpriseOrganization';

export const UsersLeft = ({}) => {
    const { enterpriseOrganizationId } = useParams();
    const numUsersLeft = useSelector(selectNumUsersLeft);
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const dispatch = useDispatch();
    const [seats, setSeats] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleAddSeats = async () => {
        const duration = enterpriseOrganization.subscriptionStatus.duration;
        const pricePerSeat = duration === 'year' ? 999 : 99; // $999/year or $99/month
        const totalPrice = seats * pricePerSeat;
        const periodText = duration === 'year' ? 'year' : 'month';

        const userConfirmed = window.confirm(
            `Are you sure you want to add ${seats} seats? This will cost an additional $${totalPrice}/${periodText}.`
        );

        if (userConfirmed) {
            setLoading(true);
            const response = await dispatch(
                addSeatsToEnterpriseOrganization(
                    enterpriseOrganizationId,
                    seats
                )
            );
            setLoading(false);
            toast(response);
            setSeats('');
        }
    };

    return (
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
            <div className="min-w-0 flex-1">
                <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
                    Enterprise Users
                </h1>
            </div>
            <div className="mt-4 flex sm:ml-4 sm:mt-0">
                # Users Left: {numUsersLeft}
            </div>
            {enterpriseOrganization.subscriptionStatus.status === 'active' && (
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        handleAddSeats();
                    }}
                >
                    <div className="mt-4 flex sm:ml-4 sm:mt-0">
                        <input
                            type="number"
                            value={seats}
                            onChange={e => setSeats(e.target.value)}
                            className="block w-20 rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                            placeholder="Seats"
                        />
                        <button
                            type="submit"
                            className="ml-2 inline-flex items-center justify-center rounded-md bg-blue-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            disabled={loading}
                        >
                            {loading ? 'Adding...' : 'Add Seats'}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};
