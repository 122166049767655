import { client } from '../../../api/client';
import { ADD_NODES_EDGES_TO_PROJECT_GRAPH } from '../../../api/graphQL/solo/mutator';
import { selectGraphEdits } from '../../reducers/generic/graphEdits';
import { getSowStatuses } from './getSowStatuses';

// Initializes the chathistory directly and then disables text input
export const addNodesEdgesToGraph =
    ({ projectId, side }) =>
    async (dispatch, getState) => {
        try {
            let nodesEdges = selectGraphEdits(getState());
            await client.mutate({
                mutation: ADD_NODES_EDGES_TO_PROJECT_GRAPH,
                variables: {
                    projectId,
                    side,
                    nodesEdges
                }
            });

            dispatch(getSowStatuses(projectId));

            return true;
        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    };
