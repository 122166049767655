import { Settings } from './SidebarItems/Settings';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { Link } from 'react-router-dom';
import { FeedBackButton } from '../../Components/FeedbackButton';
import { SidebarItems } from './SidebarItems';
import { PersonIcon } from '@radix-ui/react-icons';
import { requestDevelopersCalendlyLink } from '../../../constants';
import { useDispatch } from 'react-redux';
import { clearStateProjects } from '../../../redux/reducers/generic/projects';
import trackEvent from '../../../helpers/trackEvent';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

export default function DesktopSidebar() {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const dispatch = useDispatch();
    const { accessTokenPayload } = useSessionContext();

    return (
        enterpriseOrganization && (
            <div className="hidden lg:block lg:inset-y-0 lg:flex w-[275px] lg:flex-col h-screen">
                <div className="flex grow flex-col bg-black px-6">
                    <div className="flex justify-between items-center text-white -mx-4">
                        <div className="flex justify-between">
                            <div className="flex h-16 shrink-0 items-center">
                                <Link to="/">
                                    <img
                                        className="ml-2 h-8 w-auto rounded-full p-[3px]"
                                        src="/predev.png"
                                    />
                                </Link>
                                <Link to="/" className="flex">
                                    <span className="ml-1 font-semibold self-center">
                                        pre.dev
                                    </span>
                                    <span className="mt-3 ml-2 self-center text-xs font-semibold whitespace-nowrap text-green-500">
                                        Enterprise
                                    </span>
                                </Link>
                            </div>
                            <Link
                                onClick={() => {
                                    dispatch(clearStateProjects());
                                    trackEvent('soloSwitchClicked', {
                                        userEmail: accessTokenPayload?.email
                                    });
                                }}
                                to="/projects"
                                className="flex items-center"
                            >
                                <span className="ml-4 inline-flex text-blue-600 items-center justify-center px-2 py-1 me-3 text-xs font-medium bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                    <PersonIcon className="w-3 h-3 mr-1" />
                                    Solo
                                </span>
                            </Link>
                        </div>
                    </div>

                    <nav className="flex flex-1 flex-col h-full text-white">
                        <ul role="list" className="flex flex-1 flex-col">
                            <li className="">
                                <ul role="list" className="-mx-2 space-y-1">
                                    <SidebarItems />
                                </ul>
                            </li>

                            <li className="-mx-6 mt-auto inset">
                                {/* <div className="mx-6 mt-4">
                                    <a
                                        href={requestDevelopersCalendlyLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-white hover:underline"
                                    >
                                        Request Developers
                                    </a>
                                </div> */}
                                <div className="mx-6 mt-4">
                                    <FeedBackButton />
                                </div>
                                <Settings />
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        )
    );
}
