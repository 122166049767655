import { SET_PROJECT_URGENCY } from '../../../api/graphQL/solo/mutator';
import { client } from '../../../api/client';
import { fetchProjectSuccess } from '../../reducers/generic/project';
import { updateProject } from '../../reducers/generic/projects';
export const setUrgency = (projectId, urgency) => async dispatch => {
    try {
        const { data } = await client.mutate({
            mutation: SET_PROJECT_URGENCY,
            variables: { projectId, urgency },
            fetchPolicy: 'network-only'
        });

        if (data?.setProjectUrgency) {
            dispatch(fetchProjectSuccess(data.setProjectUrgency));
            dispatch(updateProject(data.setProjectUrgency));
        }

        return data?.setProjectUrgency;
    } catch (error) {
        console.error('Error setting urgency:', error);
        if (error.graphQLErrors) {
            const apiUnavailableError = error.graphQLErrors.find(err =>
                err.message.includes('API is temporarily unavailable')
            );
            if (apiUnavailableError) {
                alert(
                    'API is temporarily unavailable. Please try again later.'
                );
            }
        }
        throw error;
    }
};
