import UploadFile from './UploadFile';
import { FileList } from '../FileList';

export const HourEstimations = () => {
    return (
        <div className="flex flex-col gap-4">
            <UploadFile />
            <FileList type="hours" />
        </div>
    );
};
