import { HiUserCircle, HiPlus } from 'react-icons/hi';
import { AddSkill } from './add_skill';
import { SkillTag } from './skill_tag';
import { Title } from './title';
import { ActionButtons } from './action_buttons';
import { HourlyRate } from './hourly_rate';

export const UserItem = ({
    user,
    enterpriseOrganizationId,
    enterpriseOrganization,
    accessTokenPayload
}) => {
    let isAdmin = enterpriseOrganization?.isAdmin;
    return (
        <div className="bg-white shadow rounded-lg p-6 mb-4">
            <div className="flex items-center justify-between mb-4">
                <UserAvatar userEmail={user.userEmail} role={user.role} />
                <ActionButtons
                    isAdmin={isAdmin}
                    user={user}
                    accessTokenPayload={accessTokenPayload}
                    enterpriseOrganizationId={enterpriseOrganizationId}
                />
            </div>
            <div className="mb-4">
                <Title
                    isAdmin={isAdmin}
                    user={user}
                    enterpriseOrganizationId={enterpriseOrganizationId}
                />
            </div>
            <div className="mb-4">
                <HourlyRate
                    isAdmin={isAdmin}
                    user={user}
                    enterpriseOrganizationId={enterpriseOrganizationId}
                />
            </div>
            <div className="">
                <h4 className="text-sm font-medium text-gray-900 mb-2">
                    Skills
                </h4>
                <div className="flex flex-wrap gap-2">
                    {user?.skills?.map((skill, index) => (
                        <SkillTag
                            isAdmin={isAdmin}
                            key={index}
                            index={index}
                            skill={skill}
                            enterpriseOrganizationId={enterpriseOrganizationId}
                            user={user}
                        />
                    ))}
                    {isAdmin && (
                        <AddSkill
                            user={user}
                            enterpriseOrganizationId={enterpriseOrganizationId}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const UserAvatar = ({ userEmail, role }) => (
    <div className="flex items-center space-x-3">
        <div className="relative">
            <HiUserCircle className="text-gray-400 h-14 w-14" />
            {role === 'admin' && (
                <span className="absolute bottom-0 right-0 bg-blue-500 text-white text-xs font-bold px-1 py-0.5 rounded-full">
                    Admin
                </span>
            )}
        </div>
        <div>
            <h3 className="text-md font-semibold text-gray-900 truncate max-w-[200px]">
                {userEmail}
            </h3>
        </div>
    </div>
);
