const emojis = ['😎', '🤓', '🤩', '👨‍💻', '👩‍💻', '🧑‍💻', '🧑‍💻'];

export const getGraphData = els => {
    if (!els) {
        return null;
    }

    const data = {
        version: els.version,
        side: els.side,
        nodes: els.nodes.map((node, index) => ({
            _id: node._id.toString(),
            id: node.id,
            type: 'turbo',
            reactPage: node?.reactPage,
            data: {
                _id: node._id.toString(),
                label: node.label,
                hours: node.hours,
                description: node.description,
                emoji: '',
                side: els.side,
                created: node.created,
                reactPage: node?.reactPage,
                opacity: 100
            },
            position: { x: 0, y: 0 }
        })),
        edges: els['edges'].map(link => ({
            id: link._id,
            source: link.source,
            target: link.target,
            data: {
                description: link.description
            }
        }))
    };

    if (els.side === 'user flow') {
        // Assign unique emojis to branches stemming from the core node
        const coreNode = data.nodes.find(node => node.id === 'core');
        if (coreNode) {
            const branches = data.edges.filter(edge => edge.source === 'core');
            const assignedNodes = new Set();

            branches.forEach((branch, index) => {
                const targetNode = data.nodes.find(
                    node => node.id === branch.target
                );
                if (targetNode && !assignedNodes.has(targetNode.id)) {
                    const emoji = emojis[index % emojis.length];
                    targetNode.data.emoji = emoji;
                    assignedNodes.add(targetNode.id);

                    // Assign the same emoji down the branch
                    const assignEmojiToBranch = (nodeId, emoji) => {
                        const childBranches = data.edges.filter(
                            edge => edge.source === nodeId
                        );
                        childBranches.forEach(childBranch => {
                            const childNode = data.nodes.find(
                                node => node.id === childBranch.target
                            );
                            if (childNode && !assignedNodes.has(childNode.id)) {
                                childNode.data.emoji = emoji;
                                assignedNodes.add(childNode.id);
                                assignEmojiToBranch(childNode.id, emoji);
                            }
                        });
                    };

                    assignEmojiToBranch(targetNode.id, emoji);
                }
            });
        }
    }

    return data;
};

export const getDateTime = () => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };
    return new Date().toLocaleDateString(undefined, options);
};
