import { REVEAL_PROJECT } from '../../../api/graphQL/enterprise/mutator';
import { client } from '../../../api/client';
import { updateProject } from '../../reducers/generic/projects';
import { fetchProjectSuccess } from '../../reducers/generic/project';
import { decrementLeadGenCredits } from '../../reducers/enterprise/enterpriseOrganization';

export const revealProject =
    ({ projectId, enterpriseOrganizationId }) =>
    async dispatch => {
        try {
            const { data } = await client.mutate({
                mutation: REVEAL_PROJECT,
                variables: { projectId, enterpriseOrganizationId },
                fetchPolicy: 'network-only'
            });

            if (data?.revealProject) {
                dispatch(updateProject(data.revealProject));
                dispatch(decrementLeadGenCredits(1));
                dispatch(fetchProjectSuccess(data.revealProject));
                return data.revealProject;
            }

            return true;
        } catch (error) {
            console.error('Error revealing project:', error);
            return false;
        }
    };
