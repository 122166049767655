import { ChatItem } from './ChatItem';
import { useSubscription } from '@apollo/client';
import { FOLLOW_UP_QUESTION_SUBSCRIPTION } from '../../../api/graphQL';
import { useEffect, useState } from 'react';
import { useProject } from '../../../context-providers/Project';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectWaitingForChatResponse,
    waitingForChatResponse
} from '../../../redux/reducers/generic/chatHistory';
import { addToChatHistory } from '../../../redux/actions/solo/addToChatHistory';

export const StreamingChat = ({ chatContainer }) => {
    const dispatch = useDispatch();
    let { chatHistory, projectId } = useProject();
    let chat = chatHistory.data;
    let lastIndex = chat[chat.length - 1]?.index;
    const [message, setMessage] = useState({
        role: 'assistant',
        index: lastIndex,
        content: ''
    });
    const waiting = useSelector(selectWaitingForChatResponse);

    useSubscription(FOLLOW_UP_QUESTION_SUBSCRIPTION, {
        variables: { projectId, nodeId: null },
        shouldResubscribe: true,
        onData: data => {
            try {
                let msg = data.data.data.chatStream;

                //User chat has come in
                if (msg.completed && msg.role === 'user') {
                    dispatch(waitingForChatResponse(true));
                    dispatch(addToChatHistory(msg, true)); //someone else has answered
                    setMessage({
                        role: 'assistant',
                        index: msg.index,
                        content: '',
                        _id: msg._id
                    });
                    scrollToBottom({ chatContainer });
                }

                //Assistant chat has come in
                else if (msg.completed && msg.role === 'assistant') {
                    console.log('Assistant chat has come in');
                    console.log(msg);
                    dispatch(waitingForChatResponse(false));
                    dispatch(addToChatHistory(msg, true));
                    setMessage(prevMessage => ({
                        ...prevMessage,
                        _id: msg._id
                    }));
                }

                //Assistant chat is slowly coming in
                else if (!msg.completed) {
                    setMessage(msg);
                }

                scrollToBottom({ chatContainer });
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
        // client: clientWithAPIKey(apiKey),
    });

    let isLoading =
        waiting &&
        (lastIndex == message.index || message.role !== 'user') &&
        chat[chat.length - 1].role === 'user';

    return (
        waiting && <ChatItem message={{ ...message, isLoading }} chat={chat} />
    );
};

function scrollToBottom({ chatContainer }) {
    const threshold = chatContainer?.current?.clientHeight * 0.1;
    const isNearBottom =
        chatContainer?.current?.scrollHeight -
            chatContainer?.current?.scrollTop <=
        chatContainer?.current?.clientHeight + threshold;

    try {
        if (isNearBottom) {
            setTimeout(() => {
                chatContainer?.current?.scrollTo({
                    top: chatContainer?.current?.scrollHeight
                });
            }, 50);
        }
    } catch (error) {
        console.log(error);
    }
}
