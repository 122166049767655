import Terminal, { TerminalOutput } from 'react-terminal-ui';
import React, { useEffect, useRef } from 'react';

const TerminalWithAutoScroll = ({ lines }) => {
    const terminalRef = useRef(null);

    // Scroll to bottom when lines are added
    useEffect(() => {
        if (terminalRef.current) {
            const terminalElement = terminalRef.current;
            terminalElement.scrollTop = terminalElement.scrollHeight;
        }
    }, [lines]);

    return (
        <Terminal
            height={'400px'}
            onInput={terminalInput =>
                console.log(`New terminal input received: '${terminalInput}'`)
            }
            name="Planning file structure..."
        >
            <div ref={terminalRef} style={{ overflowY: 'auto' }}>
                <TerminalOutput>{lines}</TerminalOutput>
            </div>
        </Terminal>
    );
};

// onInput={ terminalInput => console.log(`New terminal input received: '${ terminalInput }'`) }
export const PlanningView = ({ uiView }) => {
    return (
        <>
            <TerminalWithAutoScroll lines={uiView ? uiView.currentPlan : ''} />
        </>
    );
};
