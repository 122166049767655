import { useProject } from '../../../context-providers/Project';
import { useEffect, useState } from 'react';
import { Calendar } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { setSchedulingLink } from '../../../redux/actions/solo/setSchedulingLink';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { toast } from 'sonner';

export const ScheduleLink = () => {
    const { project } = useProject();
    const { isSolo, isHomePage } = useSubscriptionPayment();
    const dispatch = useDispatch();
    const { accessTokenPayload } = useSessionContext();
    const userEmail = accessTokenPayload?.email;
    const canEdit = project.userEmail === userEmail || isHomePage || isSolo;
    const [tempSchedulingLink, setTempSchedulingLink] = useState(
        project?.schedulingLink
    );
    const [schedulingLinkLoading, setSchedulingLinkLoading] = useState(false);

    useEffect(() => {
        setTempSchedulingLink(project?.schedulingLink);
    }, [project?.schedulingLink]);

    const updateSchedulingLink = async () => {
        setSchedulingLinkLoading(true);
        try {
            if (
                !tempSchedulingLink.includes('calendly.com') &&
                !tempSchedulingLink.includes('hubspot.com') &&
                !tempSchedulingLink.includes('cal.com')
            ) {
                toast.error(
                    'Please enter a valid Calendly, HubSpot, or Cal.com link.'
                );
                return;
            }
            await dispatch(setSchedulingLink(project._id, tempSchedulingLink));
            toast.success('Scheduling link updated successfully.');
        } catch (error) {
            console.log('Error updating scheduling link:', error);
            toast.error(error.message);
        } finally {
            setSchedulingLinkLoading(false);
        }
    };

    return (
        canEdit && (
            <div className="w-full bg-gradient-to-br from-gray-900 to-black rounded-lg shadow-lg border-2 border-gray-700 py-4 px-4">
                <p className="text-sm font-medium text-white">
                    Scheduling Link
                </p>
                <div className="flex items-center space-x-2 mt-3">
                    <Calendar size={20} className="text-gray-300" />
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            updateSchedulingLink();
                        }}
                        disabled={tempSchedulingLink === project.schedulingLink}
                        className="flex items-center space-x-2 w-full"
                    >
                        <input
                            type="text"
                            value={tempSchedulingLink}
                            onChange={e =>
                                setTempSchedulingLink(e.target.value)
                            }
                            placeholder="Enter Calendly or HubSpot link"
                            className="flex-grow bg-gray-700 text-white rounded px-2 py-1 text-sm"
                        />
                        {tempSchedulingLink !== project.schedulingLink && (
                            <button
                                type="submit"
                                className="bg-blue-500 text-white px-2 py-1 rounded text-sm"
                                disabled={schedulingLinkLoading}
                            >
                                {schedulingLinkLoading ? 'Saving...' : 'Save'}
                            </button>
                        )}
                    </form>
                </div>
                <p className="text-xs text-gray-300 mt-1">
                    Allow dev agencies to schedule a meeting with you
                </p>
            </div>
        )
    );
};
