import { client } from '../../../api/client';
import { DELETE_SUBTASK } from '../../../api/graphQL/solo/mutator';

export const deleteSubTask = ({subTaskId}) => async (dispatch, getState) => {
    try {
        
        let { data } = await client.mutate({
            mutation: DELETE_SUBTASK,
            variables: { subTaskId }
        });

        return data.addSubTask;
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
    }
};
