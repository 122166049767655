import { useSelector } from 'react-redux';
import { selectJiraProjectURL } from '../../../../redux/reducers/generic/jira';

export const ProjectURL = () => {
    const url = useSelector(selectJiraProjectURL);

    return url ? (
        <div className="flex items-center justify-between my-2">
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="
            border border-slate-300 py-2 px-4 text-left text-sm font-medium text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75
            text-blue-600 hover:text-blue-800 visited:text-purple-600 font-medium
            cursor-pointer
            "
            >
                Jira Project Link
            </a>
        </div>
    ) : null;
};
