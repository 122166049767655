import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    nodesToAdd: [],
    edgesToAdd: [],
    nodesToDelete: [],
    edgesToDelete: [],
    editMode: false,
    addEdgeMode: false,
    addNodeMode: false,
    firstNode: null,
    graphEditLoading: false
};

export const graphEditsSlice = createSlice({
    name: 'graphEdits',
    initialState,
    reducers: {
        clearStateGraphEdits: state => {
            state.nodesToAdd = [];
            state.edgesToAdd = [];
            state.nodesToDelete = [];
            state.edgesToDelete = [];
            state.editMode = false;
            state.addEdgeMode = false;
            state.addNodeMode = false;
            state.firstNode = null;
        },
        setGraphEditLoading: (state, action) => {
            state.graphEditLoading = action.payload;
        },
        toggleEditMode: (state, action) => {
            state.editMode = !state.editMode;
            state.addEdgeMode = false;
            state.addNodeMode = false;
            state.firstNode = null;
        },
        setFirstNode: (state, action) => {
            state.firstNode = action.payload;
        },
        toggleAddEdgeMode: (state, action) => {
            state.addEdgeMode = !state.addEdgeMode;
            state.firstNode = null;
        },
        toggleAddNodeMode: (state, action) => {
            state.addNodeMode = !state.addNodeMode;
            state.firstNode = null;
        },
        addNodeToAdd: (state, action) => {
            if (!state.nodesToAdd.some(node => node.id === action.payload.id)) {
                state.nodesToAdd.push(action.payload);
            }
        },
        addEdgeToAdd: (state, action) => {
            if (
                !state.edgesToAdd.some(
                    edge =>
                        (edge.source === action.payload.source &&
                            edge.target === action.payload.target) ||
                        (edge.source === action.payload.target &&
                            edge.target === action.payload.source)
                )
            ) {
                state.edgesToAdd.push(action.payload);
                state.firstNode = null;
            }
        },
        addNodeToDelete: (state, action) => {
            const nodeIndexToAdd = state.nodesToAdd.findIndex(
                node => node.id === action.payload.id
            );
            if (nodeIndexToAdd !== -1) {
                state.nodesToAdd.splice(nodeIndexToAdd, 1);
            } else if (
                !state.nodesToDelete.some(node => node.id === action.payload.id)
            ) {
                state.nodesToDelete.push(action.payload);
            }
        },
        addEdgeToDelete: (state, action) => {
            const edgeIndex = state.edgesToAdd.findIndex(
                edge =>
                    (edge.source === action.payload.source &&
                        edge.target === action.payload.target) ||
                    (edge.source === action.payload.target &&
                        edge.target === action.payload.source)
            );
            if (edgeIndex !== -1) {
                state.edgesToAdd.splice(edgeIndex, 1);
            } else if (
                !state.edgesToDelete.some(edge => edge.id === action.payload.id)
            )
                state.edgesToDelete.push(action.payload);
        }
    }
});
export const {
    clearStateGraphEdits,
    addNodeToAdd,
    addEdgeToAdd,
    addNodeToDelete,
    toggleEditMode,
    addEdgeToDelete,
    setGraphEditLoading,
    toggleAddEdgeMode,
    toggleAddNodeMode,
    setFirstNode
} = graphEditsSlice.actions;

export const selectEditMode = state => state.graphEdits.editMode;
export const selectNodesToAdd = state => state.graphEdits.nodesToAdd;
export const selectEdgesToAdd = state => state.graphEdits.edgesToAdd;
export const selectNodesToDelete = state => state.graphEdits.nodesToDelete;
export const selectEdgesToDelete = state => state.graphEdits.edgesToDelete;
export const selectGraphEditLoading = state =>
    state.graphEdits.graphEditLoading;

export const selectFirstNode = state => state.graphEdits.firstNode;
export const selectAddEdgeMode = state => state.graphEdits.addEdgeMode;
export const selectAddNodeMode = state => state.graphEdits.addNodeMode;
export const selectGraphEdits = state => {
    const nodesToAdd = JSON.parse(JSON.stringify(state.graphEdits.nodesToAdd));
    const edgesToAdd = JSON.parse(JSON.stringify(state.graphEdits.edgesToAdd));
    const nodesToDelete = JSON.parse(
        JSON.stringify(state.graphEdits.nodesToDelete)
    );
    const edgesToDelete = JSON.parse(
        JSON.stringify(state.graphEdits.edgesToDelete)
    );
    let nodes = nodesToAdd
        .map(node => {
            node.action = 'add';
            return node;
        })
        .concat(
            nodesToDelete.map(node => {
                node.action = 'delete';
                return node;
            })
        );
    let edges = edgesToAdd
        .map(edge => {
            edge.action = 'add';
            return edge;
        })
        .concat(
            edgesToDelete.map(edge => {
                edge.action = 'delete';
                return edge;
            })
        );

    return { nodes, edges };
};

export default graphEditsSlice.reducer;
