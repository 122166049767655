import React, { useState } from 'react';
import { getWebsiteDomain } from '../../../auth_config';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

// Chat bot interview and graph/pricing table
export const EmptyProjects = () => {
    const { enterpriseOrganization } = useEnterpriseOrganization();

    return (
        <div className="pt-16 px-4 mx-auto max-w-screen-xl lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900">
                    Get started scoping your projects.
                </h2>
                <p className="mx-20 font-light text-gray-500 dark:text-gray-400 text-lg">
                    As you create projects, you'll see them listed below.
                </p>
                <div className="flex flex-col max-w-sm items-center justify-center w-full mx-auto">
                    <Link
                        to={`/enterprise/dashboard/${enterpriseOrganization?._id?.toString()}/new-project/${
                            enterpriseOrganization.apiKey
                        }?noEmail=true`}
                        className="mt-6 cursor-pointer inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
                    >
                        <PlusIcon
                            className="h-5 w-5 text-white mr-2"
                            aria-hidden="true"
                        />
                        New Internal Project
                    </Link>
                </div>
            </div>
        </div>
    );
};
