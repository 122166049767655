import { UPDATE_THUMBS_RATING } from '../../../api/graphQL';
import { client } from '../../../api/client';

export const updateThumbsRating =
    (id, rating, metadata) => async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: UPDATE_THUMBS_RATING,
                variables: {
                    id,
                    rating,
                    metadata
                }
            });

            return data.updateThumbsRating;
        } catch (error) {
            console.log(error);
        }
    };
