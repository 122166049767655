import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSubscription } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../api/client';
import {
    GET_PROJECT_LOADING_STATUS,
    PROJECT_LOADING_STATUS_SUBSCRIPTION
} from '../api/graphQL';
import { waitingForChatResponse } from '../redux/reducers/generic/chatHistory';
import { waitingForChatResponse as waitingForChatResponseUIView } from '../redux/reducers/generic/uiview';

import {
    waitingForProjectArchitecture,
    waitingForUIView
} from '../redux/reducers/generic/projectArchitecture';
import {
    waitingForSowMVP,
    waitingForSowCustom
} from '../redux/reducers/generic/projectArchitecture';
import { waitingForJira } from '../redux/reducers/generic/jira';

import {
    waitingForRecommendedJobRoles,
    waitingForRecommendedTechStack
} from '../redux/reducers/generic/project';

import { useProject } from './Project';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const dispatch = useDispatch();
    let { projectId } = useProject();

    const [loadingStatus, setLoadingStatus] = useState({});

    async function loadingStatusUpdate() {
        try {
            const { data } = await client.query({
                query: GET_PROJECT_LOADING_STATUS,
                variables: { projectId: projectId },
                fetchPolicy: 'network-only'
            });

            if (data.loadingStatusByProject) {
                let loading = data.loadingStatusByProject;

                dispatch(waitingForChatResponse(loading.chat === 'true'));
                dispatch(
                    waitingForProjectArchitecture(
                        loading.architecture === 'true'
                    )
                );
                dispatch(waitingForSowMVP(loading.sow_mvp === 'true'));
                dispatch(waitingForSowCustom(loading.sow_custom === 'true'));
                dispatch(waitingForJira(loading.jira === 'true'));

                dispatch(
                    waitingForRecommendedTechStack(
                        loading.recommendedTechStack === 'true'
                    )
                );
                dispatch(waitingForUIView(loading.uiview));

                dispatch(
                    waitingForChatResponseUIView(loading.chatui === 'true')
                );
                dispatch(
                    waitingForRecommendedJobRoles(
                        loading.recommendedJobRoles === 'true'
                    )
                );

                setLoadingStatus(loading);
            }
        } catch (error) {
            console.log({ error });
        }
    }

    // called on mount
    useEffect(() => {
        if (projectId) loadingStatusUpdate();

        return () => {
            setLoadingStatus({});
        };
    }, [projectId]);

    useSubscription(PROJECT_LOADING_STATUS_SUBSCRIPTION, {
        variables: { projectId: projectId || '' },
        shouldResubscribe: true,
        onData: data => {
            try {
                let prev = loadingStatus;

                const {
                    recommendedTechStack,
                    recommendedJobRoles,
                    architecture,
                    chat,
                    sow_mvp,
                    sow_custom,
                    jira,
                    uiview,
                    chatui
                } = data.data.data.loadingStatusStream;

                if (recommendedTechStack !== prev.recommendedTechStack) {
                    dispatch(
                        waitingForRecommendedTechStack(
                            recommendedTechStack === 'true'
                        )
                    );
                }
                if (recommendedJobRoles !== prev.recommendedJobRoles) {
                    dispatch(
                        waitingForRecommendedJobRoles(
                            recommendedJobRoles === 'true'
                        )
                    );
                }
                if (chat !== prev.chat) {
                    dispatch(waitingForChatResponse(chat === 'true'));
                }
                if (sow_mvp !== prev.sow_mvp) {
                    dispatch(waitingForSowMVP(sow_mvp === 'true'));
                }
                if (sow_custom !== prev.sow_custom) {
                    dispatch(waitingForSowCustom(sow_custom === 'true'));
                }
                if (jira !== prev.jira) {
                    dispatch(waitingForJira(jira === 'true'));
                }
                if (architecture !== prev.architecture) {
                    dispatch(
                        waitingForProjectArchitecture(architecture === 'true')
                    );
                }
                if (uiview !== prev.uiview) {
                    dispatch(waitingForUIView(uiview));
                }

                if (chatui !== prev.chatui) {
                    dispatch(waitingForChatResponseUIView(chatui === 'true'));
                }

                setLoadingStatus(data.data.data.loadingStatusStream);
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
    });

    return (
        <LoadingContext.Provider value={{ loadingStatus }}>
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = () => useContext(LoadingContext);
