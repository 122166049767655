import { cn } from '../../Components/magicui/lib/utils';
import Marquee from '../../Components/magicui/marquee';
import { BoltIcon } from '@heroicons/react/24/outline';

export function ProductDocumentation() {
    return {
        Icon: BoltIcon,
        name: 'Product Documentation',
        description:
            'Efficiently manage multiple versions of product and feature docs by augmenting your intelligence with AI. ',
        className: 'col-span-3 lg:col-span-1',
        href: '/auth/?redirectToPath=%2Fprojects',
        cta: 'Get Started',
        background: (
            <Marquee
                pauseOnHover
                className="absolute top-20 [--duration:20s] [mask-image:linear-gradient(to_top,transparent_0%,#000_40%)] "
            >
                {files.map((f, idx) => (
                    <figure
                        key={idx}
                        className={cn(
                            'relative w-32 cursor-pointer overflow-hidden rounded-xl border p-4',
                            'border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]',
                            'dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]',
                            'transform-gpu blur-[1px] transition-all duration-300 ease-out hover:blur-none'
                        )}
                    >
                        <div className="flex flex-row items-center gap-2">
                            <div className="flex flex-col">
                                <figcaption className="text-sm font-medium dark:text-white ">
                                    {f.name}
                                </figcaption>
                            </div>
                        </div>
                        <blockquote className="mt-2 text-xs text-white">
                            {f.body}
                        </blockquote>
                    </figure>
                ))}
            </Marquee>
        )
    };
}

const files = [
    {
        name: 'Rideshare Docs',
        body: (
            <>
                Overview
                <br />
                User Stories
                <br />
                Tech Stack
                <br />
                Deliverables
                <br />
                Milestones
            </>
        )
    },
    {
        name: 'Admin Feature Docs',
        body: (
            <>
                Overview
                <br />
                User Stories
                <br />
                Tech Stack
                <br />
                Deliverables
                <br />
                Milestones
            </>
        )
    },
    {
        name: 'DeFi Protocol Docs',
        body: (
            <>
                Overview
                <br />
                User Stories
                <br />
                Tech Stack
                <br />
                Deliverables
                <br />
                Milestones
            </>
        )
    },
    {
        name: 'API Feature Docs',
        body: (
            <>
                Overview
                <br />
                User Stories
                <br />
                Tech Stack
                <br />
                Deliverables
                <br />
                Milestones
            </>
        )
    },
    {
        name: 'FintechApp Docs',
        body: (
            <>
                Overview
                <br />
                User Stories
                <br />
                Tech Stack
                <br />
                Deliverables
                <br />
                Milestones
            </>
        )
    }
];
