import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { useEnterpriseOrganization } from '../../../context-providers/EnterpriseOrganization';
import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import { useProjects } from '../../../context-providers/Projects';
import { UpgradeToPremium } from '../Sidebars/SidebarItems/UpgradeToPremium';

const colors = [
    'bg-blue-500',
    'bg-green-500',
    'bg-yellow-300',
    'bg-orange-500',
    'bg-purple-600',
    'bg-red-500'
];

function getRandomColor(i) {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const TopHeader = ({}) => {
    const navigate = useNavigate();
    const { enterpriseOrganization } = useEnterpriseOrganization();
    const { projects } = useProjects();

    if (!projects) projects = [];

    const [bgColors, setBgColors] = useState([]);
    //projects = proyectos
    useEffect(() => {
        if (projects.length > 0) {
            setBgColors(projects.map(() => getRandomColor()));
        }
    }, [projects]);

    const projectsRecent = projects.length > 0 ? projects.slice(0, 2) : [];
    const subscriptionStatus = enterpriseOrganization?.subscriptionStatus;

    const canAccessProjects =
        subscriptionStatus.status === 'active' || projects.length < 2;

    const onClickProject = project => {
        navigate(project._id);
    };
    return (
        enterpriseOrganization && (
            <div className="mt-4 px-4 sm:px-2 lg:px-8 w-full">
                <h2 className="text-sm font-medium text-gray-900">
                    Recent Projects
                </h2>
                <ul
                    role="list"
                    className="mt-3 mb-4 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 xl:grid-cols-4"
                >
                    {projectsRecent.map((project, i) => (
                        <li
                            key={project._id}
                            onClick={() => onClickProject(project)}
                            className="cursor-pointer relative col-span-1 flex rounded-md shadow-sm"
                        >
                            <div
                                className={classNames(
                                    bgColors && bgColors[i],
                                    ' flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                                )}
                            >
                                {project.name.slice(0, 1)}
                            </div>
                            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                <div className="flex-1 truncate px-4 py-2 text-sm">
                                    <a className="font-mediumw whitespace-nowrap overflow-x-auto text-ellipsis max-w-xs text-gray-900 hover:text-gray-600">
                                        {project.name}
                                    </a>
                                    <p className="text-gray-500">
                                        {project.userEmail}
                                    </p>
                                </div>
                            </div>
                        </li>
                    ))}

                    {canAccessProjects ? (
                        <li className="cursor-pointer relative col-span-1 flex rounded-md shadow-sm">
                            <Link
                                to={`/enterprise/dashboard/${enterpriseOrganization._id?.toString()}/new-project/${
                                    enterpriseOrganization.apiKey
                                }?noEmail=true`}
                                className="flex-1 flex items-center justify-center px-4 py-2 text-sm border border-black border-dashed rounded-md text-black bg-white hover:bg-gray-200"
                            >
                                <PlusIcon
                                    className="h-5 w-5 text-black mr-2"
                                    aria-hidden="true"
                                />
                                New Internal Project
                            </Link>
                        </li>
                    ) : null}
                </ul>
                <hr className="my-4" />
            </div>
        )
    );
};
